import React, { Component } from "react";
//import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "./guilds.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { PickList } from "primereact/picklist";
const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

const rareza = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
};

let iconoPower =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be578d7d-cbe7-4133-62d7-e881e6086400/public";

const tipos = [
  { name: "Reset" },
  { name: "FIRE" },
  { name: "WATER" },
  { name: "GROUND" },
  { name: "WIND" },
  { name: "LAVA" },
  { name: "ICE" },
  { name: "STEEL" },
  { name: "LIGHTNING" },
  { name: "ATOMIC" },
  { name: "POISON" },
  { name: "CRYSTAL" },
  { name: "LASER" },
  { name: "INFERNAL" },
  { name: "CELESTIAL" },
  { name: "SPIRITUAL" },
  { name: "SINISTER" },
];

const nombres = [
  { name: "Reset" },
  { name: "ALYRAN - THE MAD ELF" },
  { name: "VASS - THE HERALD OF FALLEN" },
  { name: "HANZO - THE SOULESS NINJA" },
  { name: "PURPPLEKONG" },
  { name: "ENNORE KILNAMA" },
  { name: "ANDRAS - THE WOLF SWORD" },
  { name: "KAYN - THE WILL OF DEATH" },
  { name: "OZARK - NIGHTMARE KING" },
  { name: "IRIN - THE WAR QUEEN" }
];

export default class BattleZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      battleData: [],
      nfts: [],
      target: [],
    };

    this.itemTemplate = this.itemTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.sendPvp = this.sendPvp.bind(this);
  }

  render() {
    return (
      <>
        <h1>Usernames</h1>
        <Accordion>
          {this.state.usuarios.map((usuario) => {
            return this.renderUser(usuario);
          })}
        </Accordion>

        {this.state.battleData.length == 10 ? (
          ""
        ) : (
          <>
            <h1>Nfts</h1>

            <h1 style={{ textAlign: "center" }}>
              Please select 10 nfts for guilds battle
            </h1>

            <PickList
              source={this.state.nfts}
              target={this.state.target}
              itemTemplate={this.itemTemplate}
              sourceHeader="Available"
              targetHeader="Selected"
              sourceStyle={{ height: "342px" }}
              targetStyle={{ height: "342px" }}
              onChange={this.onChange}
            />

            <Button
              onClick={(e) => this.sendPvp()}
              className="p-button-success"
              label="submit"
            ></Button>
          </>
        )}
      </>
    );
  }

  sendPvp() {
    let string = this.state.target.map((e) => e._id).toString();
    console.log(string);
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (this.state.target.length != 10) {
      Swal.close();
      alert("Please select 10 nfts to battle on guilds");
      return;
    }

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation {\\n  setNftsToGuild(nfts:\\"${string}\\"){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((e) => e.json())
      .then(async (response) => {
        console.log(
          "🚀 ~ file: inventory.js:1185 ~ Inventory ~ .then ~ response",
          response
        );

        if (response?.data?.setNftsToGuild) {
          await Swal.fire({
            icon: "info",
            title: response.data.setNftsToGuild.message,
            showConfirmButton: true,
            timer: 1500,
          });
          window.location.reload();
        }
      })
      .finally((e) => {
        Swal.close();
      });
  }

  onChange(event) {
    if (event.target.length > 10) {
      return;
    }
    this.setState({
      nfts: event.source,
      target: event.target,
    });
  }

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div style={{ textAlign: "center" }} className="image-container">
          <img
            style={{ width: "20%" }}
            src={`${this.getImagen(item.properties)}`}
            onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.properties.p1}
          />
        </div>
        <div className="product-list-detail">
          <div className="grid">
            <div className="col-4">
              {this.GetImage("RAIDPOWER")}
              {this.getPower(item.properties)}
            </div>
            <div className="col-4">
              {this.GetImage("HEALTH")}
              {item.properties.p3}
            </div>
            <div className="col-4">
              {this.GetImage("ATTACK")}
              {item.properties.p4}
            </div>
            <div className="col-4">
              {this.GetImage("DEFENSE")}
              {item.properties.p5}
            </div>
            <div className="col-4">
              {this.GetImage("CRITICAL")}
              {item.properties.p6}
            </div>
            <div className="col-4">
              {this.GetImage("AFFINITY", item.properties.p7)}
              {item.properties.p7}
            </div>
          </div>
        </div>
      </div>
    );
  }

  GetImage(type, data) {
    switch (type) {
      case "RAIDPOWER":
        return (
          <img src={iconoPower} style={{ maxWidth: "30%" }} alt="HEALTH" />
        );

      case "HEALTH":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
            style={{
              maxWidth: "30%",
              aspectRatio: "3/2",
              objectFit: "contain",
            }}
            alt="HEALTH"
          />
        );
      case "ATTACK":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
            style={{
              maxWidth: "30%",
              aspectRatio: "3/2",
              objectFit: "contain",
            }}
            alt="ATTACK"
          />
        );
      case "DEFENSE":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
            style={{
              maxWidth: "30%",
              aspectRatio: "3/2",
              objectFit: "contain",
            }}
            alt="DEFENSE"
          />
        );
      case "CRITICAL":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
            style={{
              maxWidth: "30%",
              aspectRatio: "3/2",
              objectFit: "contain",
            }}
            alt="CRITICAL"
          />
        );
      case "AFFINITY":
        return (
          <img
            src={SKILL[data]}
            style={{
              maxWidth: "30%",
              aspectRatio: "3/2",
              objectFit: "contain",
            }}
            alt="AFFINITY"
          />
        );
    }
  }

  getImagen(res) {
    if (res.p10?.includes("special")) {
      return images[res.p1 + " " + res.p10];
    }
    return images[res.p1];
  }

  getPower(properties) {
    let atk = parseInt(properties?.p4);
    let def = parseInt(properties?.p5);
    let critic = parseInt(properties?.p6);
    return Math.trunc(atk * (critic * 0.2) + def * 3);
  }

  renderUser(user) {
    return (
      <AccordionTab header={user.username}>
        {this.renderCharacters(user.username)}
      </AccordionTab>
    );
  }

  renderCharacters(username) {
    let u = this.state.battleData.find((user) => user.username === username);
    console.log(u);

    if (u) {
      var characters = [];

      for (let user in u.character) {
        characters.push(
          <article className="card-guild">
            <header className="card-guild-header">
              <p>
                {this.getPower({
                  p4: u.character[user].attack,
                  p5: u.character[user].defense,
                  p6: u.character[user].critical,
                })}{" "}
                {this.GetImage("RAIDPOWER")}
              </p>
              <h2>ID:{u.character[user].id}</h2>
            </header>

            <h5>
              {" "}
              {this.GetImage("CRITICAL")}
              {u.character[user].critical}
            </h5>
            <h5>
              {this.GetImage("ATTACK")} {u.character[user].attack}
            </h5>
            <h5>
              {this.GetImage("DEFENSE")}
              {u.character[user].defense}
            </h5>
            <h5>
              {this.GetImage("HEALTH")} {u.character[user].life}
            </h5>
            <h5>
              {" "}
              {this.GetImage("AFFINITY", u.character[user].type)}
              {u.character[user].type}
            </h5>

            <div className="card-guild-author">
              <a className="author-avatar" href="#">
                <img src={images[u.character[user].name]} />
              </a>
              <svg className="half-circle" viewBox="0 0 106 57">
                <path d="M102 4c0 27.1-21.9 49-49 49S4 31.1 4 4"></path>
              </svg>

              <div className="author-name">
                <div className="author-name-prefix">Author</div>
                {u.character[user].name}
              </div>
            </div>
          </article>
        );
      }

      return <section className="card-guild-list">{characters}</section>;
    }
  }

  async componentDidMount() {
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let response = await fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getGuildBattle{\\n    success\\n    message\\n    data{\\n      usuarios{\\n  username\\n  path\\n  lvl\\n  xp\\n  guild\\n  xpdonated\\n      }\\n      battleData{\\n        username\\n        character{\\n          id\\n  name\\n  life\\n  attack\\n  defense\\n  critical\\n  type\\n        }\\n      }\\n    }\\n    \\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        Dnt: "1",
      },
      method: "POST",
    });

    response = await response.json();

    if (response) {
      if (response.data.getGuildBattle.data) {
        this.setState({
          usuarios: response.data.getGuildBattle.data.usuarios,
          battleData: response.data.getGuildBattle.data.battleData,
        });
      }
    } else {
      //alert("error, try refreshing page");
    }

    utils.getAllPlayerConquers().then((res) => {
      res = res.filter((element) => {
        return (
          element.properties.p8 != "collector" &&
          element.properties.p6 != "0" &&
          element.properties.p2 != "land"
        );
      });

      res = res.sort(
        (a, b) => this.getPower(b.properties) - this.getPower(a.properties)
      );
      this.setState({
        ...this.state,
        nfts: res,
      });

      Swal.close();
      // console.log("nfts", res);
    });
  }
}
