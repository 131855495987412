import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import HivePay from "../utils/HivePay";
import Swal from "sweetalert2";
import Leaderboard from "./modals/leaderboard";
import Dropdown from "react-bootstrap/Dropdown";
import { PrimeIcons } from "primereact/api";

import Profile from "../componentes/profile/profile";
import { ScrollPanel } from "primereact/scrollpanel";

import GuildFarming from "./guilds/farming";
import LandFarming from "./LandFarming";

import TokenStats from "./extras/tokenstats";
import Npvp from "./pvp/npvp";
import { ProgressBar } from "primereact/progressbar";

import rank1 from "../assets/img/ranking/RANK-1.webp";
import rank2 from "../assets/img/ranking/RANK-2.webp";
import rank3 from "../assets/img/ranking/RANK-3.webp";
import rank4 from "../assets/img/ranking/RANK-4.webp";
class SidebarGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleLeft: false,
      leaderboard: false,
      profile: false,
      displayBattleRoyale: false
    };
  }

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick, btn }, ref) => (
      <p
        style={{ cursor: "pointer" }}
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        - {children}
        <i className={btn}></i>
      </p>
    ));

    let img = `https://images.hive.blog/u/${localStorage.getItem(
      "username"
    )}/avatar`;

    let username = JSON.parse(localStorage.getItem("userData"));

    let render = this.props.principal ? this.conSidebar(CustomToggle, img, username) : this.conSidebar(CustomToggle, img, username);


    return (
      <>
        <GuildFarming
          visible={this.state.displayGuildFarming}
          close={() => {
            this.setState({
              displayGuildFarming: false,
            });
          }}
        ></GuildFarming>

        {this.state.displayBattleRoyale && (
          <>
            <Npvp
              visible={this.state.displayBattleRoyale}
              close={() => {
                this.setState({ displayBattleRoyale: false });
              }}
            ></Npvp>
          </>
        )}

        <LandFarming
          visible={this.state.displayLandsFarming}
          close={() => {
            this.setState({
              displayLandsFarming: false,
            });
          }}
        ></LandFarming>

        <TokenStats
          visible={this.state.displaytokenStats}
          close={() => {
            this.setState({
              displaytokenStats: false,
            });
          }}
        ></TokenStats>

        {this.state.leaderboard && (
          <Leaderboard
            displayposition={this.state.leaderboard}
            Hide={() =>
              this.setState({
                ...this.state,
                leaderboard: false,
              })
            }
          />
        )}



        {render} </>
    );
  }

  conSidebar(CustomToggle, img, username) {
    return <Sidebar
      visible={this.props.visibleLeft}
      onHide={() => this.props.onClose()}
    >

      <div
        style={{ textAlign: "center" }}
        className="align-content-center align-items-center justify-content-center"
      >


        <div className="col-12">
          <div className="avatar-wrapper">
            <div className="avatar-container">
              <img src={rank1} className="rank-image" alt="Rank background" ></img>
              <Avatar size="xlarge" shape="circle" className="avatar-image" image={img} />
            </div>
          </div>
        </div>


        <div className="col-12">
          League: {username.league} <br></br> XP: {username.xp}
          <h3
          >
            {localStorage.getItem("username")}
          </h3>
          <ProgressBar color="green" value={100}></ProgressBar>
        </div>
      </div>
      <ScrollPanel
        style={{ width: "100%", height: "100%" }}
        className="custombar1"
      >
        <Profile
          displayBasic={this.state.profile}
          xp={username.xp}
          guild={username.guild}
          side={username.path}
          totalUserStake={username.cantidad_tokens}
          timeRetiro={username.fecha_solicitud_retiro}
          utilityToken={username.utilityToken}
          resourcesInGame={username.resourcesInGame}
          essenceFarmer={username?.essenceFarmer}
          essenceFisher={username?.essenceFisher}
          essenceMiner={username?.essenceMiner}
          url={
            !username.token
              ? ""
              : `https://game.infernalcoliseum.fun/login?token=${username.token}`
          }
          onHide={() => {
            this.setState({ ...this.state, profile: false });
          }}
        />



        <Dropdown>
          <Dropdown.Toggle onClick={() => this.setState({ ...this.state, profile: true })} as={CustomToggle} id="dropdown-autoclose-true"  >
            Dashboard
          </Dropdown.Toggle>
        </Dropdown>



        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              window.location.href = "/";
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Map
          </Dropdown.Toggle>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              window.location.href = "/inventory";
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Inventory
          </Dropdown.Toggle>
        </Dropdown>


        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              window.open("https://game.infernalcoliseum.fun/store");
            }}
            as={CustomToggle}

            id="dropdown-autoclose-true"
          >
            STORE
          </Dropdown.Toggle>
        </Dropdown>


        <Dropdown>
          <Dropdown.Toggle
            btn={PrimeIcons.ANGLE_DOWN}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Purchase
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/market">Marketplace</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            btn={PrimeIcons.ANGLE_DOWN}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Open packs
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                window.location = "/open?v=1"
              }}
            >
              Packs V1
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                window.location = "/open?v=2"
              }}
            >
              Packs V2
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                window.location = "/open?v=3"
              }}
            >
              Packs V3
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>


        <Dropdown>
          <Dropdown.Toggle
            btn={PrimeIcons.ANGLE_DOWN}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Open packs (H-engine)
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                this.openPacks();
              }}
            >
              Packs V1
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                this.openPacksv2();
              }}
            >
              Packs V2
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                this.openPacksv3();
              }}
            >
              Packs V3
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            btn={PrimeIcons.ANGLE_DOWN}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            XP
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/xp">IXP Pool</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                Swal.fire({
                  title: "Update xp",
                  input: "number",
                  inputAttributes: {
                    autocapitalize: "off",
                  },
                  showCancelButton: true,
                  confirmButtonText: "burn",
                  showLoaderOnConfirm: true,
                  preConfirm: (login) => {
                    const HP = new HivePay("infernalcoliseum");
                    HP.setItemName("more xp")
                      .setItemDescription("get more xp")
                      .setMerchant_email("blackmirague@gmail.com")
                      .setNotifyUrl(
                        "https://guerrerosconsultoresas.com.co/IF/HPixp.php"
                      )
                      .setReturnUrl("https://game.infernalcoliseum.fun")
                      .setAmount(1)
                      .setQuantity(login)
                      .setBaseCurrency("IXP")
                      .setPayCurrencies(["IXP"]);

                    HP.submit();
                  },
                  allowOutsideClick: () => false,
                });
              }}
            >
              Update XP
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            btn={PrimeIcons.ANGLE_DOWN}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Community
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/guilds">Guilds</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                this.setState({
                  ...this.state,
                  leaderboard: true,
                });
              }}
            >
              Leaderboards
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              window.open("https://docs.infernalcoliseum.fun");
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Docs
          </Dropdown.Toggle>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              window.location.href = "/stake";
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Stake
          </Dropdown.Toggle>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              this.setState({ displaytokenStats: true });
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            STATS
          </Dropdown.Toggle>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              this.setState({ displayGuildFarming: true });
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Farming
          </Dropdown.Toggle>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              this.setState({ displayLandsFarming: true });
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Lands
          </Dropdown.Toggle>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              this.setState({ displayBattleRoyale: true });
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            PVP
          </Dropdown.Toggle>
        </Dropdown>



        {/* 
      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.open("https://game.infernalcoliseum.fun/dungeon");
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Dungeon
        </Dropdown.Toggle>
      </Dropdown>

      {/* 
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-true">
          <h3>Players: {this.state.KNALAND + this.state.NOGROD}</h3>
          <ul>
            <li>KNALAND: {this.state.KNALAND}</li>
            <li>NOGROD: {this.state.NOGROD}</li>
          </ul>
        </Dropdown.Toggle>
      </Dropdown>

        */}

        <Dropdown>
          <Dropdown.Toggle
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            as={CustomToggle}
            id="dropdown-autoclose-true"
          >
            Logout
          </Dropdown.Toggle>
        </Dropdown>
      </ScrollPanel>
    </Sidebar>
  }

  sinSidebar(CustomToggle, img, username) {

    return <ScrollPanel
      style={{ width: "100%", height: "100%", zIndex: "9999999" }}
      className="custombar1"
    >
      <div
        style={{ textAlign: "center" }}
        className="align-content-center align-items-center justify-content-center"
      >
        <div className="col-12">
          <Avatar size="xlarge" shape="circle" image={img} />
        </div>
        <div className="col-12" style={{ color: "white" }}>
          XP: {username.xp}
          <h3
            style={{ cursor: "pointer", color: "white" }}
            onClick={() => this.setState({ ...this.state, profile: true })}
          >
            {localStorage.getItem("username").toUpperCase()}
          </h3>
        </div>
      </div>

      <Profile
        displayBasic={this.state.profile}
        xp={username.xp}
        lvl={username?.lvl}
        guild={username.guild}
        side={username.path}
        totalUserStake={username.cantidad_tokens}
        timeRetiro={username.fecha_solicitud_retiro}
        utilityToken={username.utilityToken}
        resourcesInGame={username.resourcesInGame}
        essenceFarmer={username?.essenceFarmer}
        essenceFisher={username?.essenceFisher}
        essenceMiner={username?.essenceMiner}
        percentageToken={username?.percentageToken}
        url={
          !username.token
            ? ""
            : `https://game.infernalcoliseum.fun/login?token=${username.token}`
        }
        onHide={() => {
          this.setState({ ...this.state, profile: false });
        }}
      />

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.location.href = "/";
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Map
        </Dropdown.Toggle>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.location.href = "/inventory";
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Inventory
        </Dropdown.Toggle>
      </Dropdown>


      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.open("https://game.infernalcoliseum.fun/store");
          }}
          as={CustomToggle}

          id="dropdown-autoclose-true"
        >
          STORE
        </Dropdown.Toggle>
      </Dropdown>


      <Dropdown>
        <Dropdown.Toggle
          btn={PrimeIcons.ANGLE_DOWN}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Purchase
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/market">Marketplace</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          btn={PrimeIcons.ANGLE_DOWN}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Open packs
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              this.openPacks();
            }}
          >
            Packs V1
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              this.openPacksv2();
            }}
          >
            Packs V2
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              this.openPacksv3();
            }}
          >
            Packs V3
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          btn={PrimeIcons.ANGLE_DOWN}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          XP
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/xp">IXP Pool</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              Swal.fire({
                title: "Update xp",
                input: "number",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "burn",
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                  const HP = new HivePay("infernalcoliseum");
                  HP.setItemName("more xp")
                    .setItemDescription("get more xp")
                    .setMerchant_email("blackmirague@gmail.com")
                    .setNotifyUrl(
                      "https://guerrerosconsultoresas.com.co/IF/HPixp.php"
                    )
                    .setReturnUrl("https://game.infernalcoliseum.fun")
                    .setAmount(1)
                    .setQuantity(login)
                    .setBaseCurrency("IXP")
                    .setPayCurrencies(["IXP"]);

                  HP.submit();
                },
                allowOutsideClick: () => false,
              });
            }}
          >
            Update XP
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          btn={PrimeIcons.ANGLE_DOWN}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Community
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/guilds">Guilds</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              this.setState({
                ...this.state,
                leaderboard: true,
              });
            }}
          >
            Leaderboards
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.open("https://docs.infernalcoliseum.fun");
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Docs
        </Dropdown.Toggle>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.location.href = "/stake";
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Stake
        </Dropdown.Toggle>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            this.setState({ displaytokenStats: true });
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          STATS
        </Dropdown.Toggle>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            this.setState({ displayGuildFarming: true });
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Farming
        </Dropdown.Toggle>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            this.setState({ displayLandsFarming: true });
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Lands
        </Dropdown.Toggle>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            this.setState({ displayBattleRoyale: true });
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          PVP
        </Dropdown.Toggle>
      </Dropdown>



      {/* 
      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            window.open("https://game.infernalcoliseum.fun/dungeon");
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Dungeon
        </Dropdown.Toggle>
      </Dropdown>

      {/* 
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-true">
          <h3>Players: {this.state.KNALAND + this.state.NOGROD}</h3>
          <ul>
            <li>KNALAND: {this.state.KNALAND}</li>
            <li>NOGROD: {this.state.NOGROD}</li>
          </ul>
        </Dropdown.Toggle>
      </Dropdown>

        */}

      <Dropdown>
        <Dropdown.Toggle
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          as={CustomToggle}
          id="dropdown-autoclose-true"
        >
          Logout
        </Dropdown.Toggle>
      </Dropdown>
    </ScrollPanel>

  }

  openPacksv2() {
    let person = prompt("Please enter number of packs to open");
    if (isNaN(person)) {
      alert("Please enter a valid number");
      return;
    }
    if (person <= 0) {
      alert("Please enter a valid number");
      return;
    } else {
      const HP = new HivePay("infernalcoliseum");
      HP.setItemName("Open Pack v2")
        .setItemDescription("Open pack v2")
        .setMerchant_email("blackmirague@gmail.com")
        .setNotifyUrl(
          "https://guerrerosconsultoresas.com.co/IF/HPpresaleOpenPacksV2.php"
        )
        .setReturnUrl("https://game.infernalcoliseum.fun")
        .setAmount(1)
        .setQuantity(person)
        .setBaseCurrency("ICPVTWO")
        .setPayCurrencies(["ICPVTWO"]);

      HP.submit();
    }
  }

  openPacksv3() {
    let person = prompt("Please enter number of packs to open");
    if (isNaN(person)) {
      alert("Please enter a valid number");
      return;
    }
    if (person <= 0) {
      alert("Please enter a valid number");
      return;
    } else {
      const HP = new HivePay("infernalcoliseum");
      HP.setItemName("Open Pack v3")
        .setItemDescription("Open pack v3")
        .setMerchant_email("blackmirague@gmail.com")
        .setNotifyUrl(
          "https://guerrerosconsultoresas.com.co/IF/HPpresaleOpenPacksV3.php"
        )
        .setReturnUrl("https://game.infernalcoliseum.fun")
        .setAmount(1)
        .setQuantity(person)
        .setBaseCurrency("ICPVTHREE")
        .setPayCurrencies(["ICPVTHREE"]);

      HP.submit();
    }
  }

  openPacks() {
    let person = prompt("Please enter number of packs to open");
    if (isNaN(person)) {
      alert("Please enter a valid number");
      return;
    }
    if (person <= 0) {
      alert("Please enter a valid number");
      return;
    } else {
      const HP = new HivePay("infernalcoliseum");
      HP.setItemName("Open Pack")
        .setItemDescription("Open pack")
        .setMerchant_email("blackmirague@gmail.com")
        .setNotifyUrl(
          "https://guerrerosconsultoresas.com.co/IF/HPpresaleOpenPacks.php"
        )
        .setReturnUrl("https://game.infernalcoliseum.fun")
        .setAmount(1)
        .setQuantity(person)
        .setBaseCurrency("ICPVONE")
        .setPayCurrencies(["ICPVONE"]);

      HP.submit();
    }
  }

  componentDidMount() {
    /*
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getNumberPlayers {\\n    KNALAND\\n    NOGROD\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        this.setState({
          ...this.state,
          KNALAND: response.data.getNumberPlayers.KNALAND,
          NOGROD: response.data.getNumberPlayers.NOGROD,
        });
      }); */
  }

}

export default SidebarGame;
