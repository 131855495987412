export const mapAreas = [
  {
    fullname: "Africa",
    name: "africa",
    tittle: "africa",
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1528, 1259, 1499, 1297, 1495, 1306, 1484, 1329, 1478, 1337, 1469, 1340,
      1455, 1341, 1444, 1349, 1440, 1351, 1435, 1352, 1407, 1355, 1400, 1358,
      1395, 1364, 1389, 1372, 1381, 1376, 1372, 1381, 1365, 1388, 1353, 1396,
      1338, 1396, 1323, 1393, 1315, 1396, 1314, 1404, 1330, 1443, 1336, 1449,
      1345, 1448, 1356, 1440, 1367, 1432, 1381, 1429, 1390, 1432, 1400, 1441,
      1407, 1450, 1411, 1459, 1406, 1467, 1399, 1475, 1395, 1482, 1395, 1491,
      1397, 1507, 1390, 1522, 1385, 1531, 1385, 1540, 1391, 1576, 1391, 1582,
      1384, 1585, 1372, 1587, 1359, 1586, 1343, 1584, 1327, 1584, 1319, 1583,
      1314, 1577, 1306, 1567, 1294, 1564, 1272, 1562, 1265, 1558, 1263, 1551,
      1260, 1538, 1253, 1529, 1240, 1523, 1223, 1516, 1217, 1527, 1213, 1538,
      1209, 1547, 1200, 1551, 1194, 1552, 1184, 1556, 1179, 1559, 1178, 1565,
      1180, 1576, 1179, 1590, 1170, 1614, 1169, 1619, 1173, 1624, 1182, 1636,
      1186, 1649, 1190, 1663, 1189, 1668, 1186, 1672, 1181, 1679, 1182, 1688,
      1192, 1722, 1195, 1731, 1201, 1737, 1210, 1740, 1214, 1742, 1217, 1746,
      1224, 1771, 1226, 1781, 1223, 1785, 1213, 1788, 1200, 1793, 1180, 1809,
      1168, 1801, 1158, 1792, 1155, 1778, 1157, 1764, 1155, 1743, 1146, 1725,
      1135, 1710, 1130, 1706, 1123, 1706, 1102, 1708, 1098, 1707, 1096, 1704,
      1093, 1680, 1090, 1673, 1083, 1669, 1053, 1659, 1056, 1645, 1062, 1631,
      1084, 1598, 1088, 1590, 1084, 1582, 1061, 1563, 1038, 1545, 1030, 1539,
      1021, 1535, 1019, 1534, 998, 1526, 975, 1524, 966, 1523, 959, 1517, 944,
      1503, 924, 1497, 873, 1487, 874, 1477, 876, 1463, 875, 1456, 869, 1452,
      857, 1445, 850, 1443, 842, 1444, 829, 1449, 818, 1451, 808, 1448, 803,
      1442, 802, 1435, 803, 1413, 800, 1408, 795, 1408, 776, 1419, 768, 1421,
      761, 1419, 754, 1416, 743, 1410, 740, 1404, 742, 1391, 743, 1382, 737,
      1374, 724, 1362, 715, 1347, 717, 1332, 730, 1320, 761, 1307, 767, 1302,
      765, 1294, 758, 1280, 753, 1263, 754, 1245, 756, 1241, 761, 1240, 777,
      1242, 788, 1244, 800, 1243, 813, 1248, 825, 1250, 836, 1245, 846, 1236,
      853, 1232, 862, 1229, 871, 1233, 881, 1240, 888, 1244, 895, 1242, 904,
      1239, 913, 1241, 928, 1247, 942, 1256, 954, 1265, 961, 1268, 967, 1263,
      973, 1250, 981, 1239, 994, 1237, 996, 1237, 1010, 1237, 1018, 1235, 1022,
      1229, 1024, 1214, 1030, 1208, 1040, 1205, 1050, 1204, 1064, 1204, 1076,
      1198, 1085, 1194, 1091, 1193, 1095, 1198, 1104, 1226, 1108, 1232, 1115,
      1230, 1128, 1224, 1138, 1213, 1148, 1204, 1161, 1204, 1197, 1213, 1207,
      1216, 1214, 1215, 1219, 1209, 1229, 1181, 1232, 1176, 1238, 1177, 1255,
      1187, 1269, 1192, 1283, 1190, 1285, 1191, 1296, 1197, 1308, 1198, 1320,
      1192, 1321, 1191, 1329, 1188, 1335, 1189, 1340, 1195, 1352, 1204, 1366,
      1202, 1379, 1199, 1392, 1196, 1403, 1189, 1411, 1188, 1429, 1186, 1430,
      1187, 1431, 1185, 1436, 1185, 1447, 1193, 1460, 1196, 1474, 1196, 1487,
      1202, 1491, 1203, 1496, 1203, 1510, 1201, 1519, 1201, 1523, 1205, 1524,
      1214, 1524, 1233, 1528, 1259,
    ],

    left: 60,
    top: 59,
  },
  {
    fullname: "Africa",
    name: "africa",
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2533, 2559, 2530, 2554, 2524, 2547, 2517, 2544, 2508, 2546, 2501, 2546,
      2491, 2540, 2482, 2533, 2477, 2522, 2476, 2520, 2471, 2513, 2465, 2507,
      2461, 2500, 2457, 2488, 2451, 2478, 2445, 2473, 2438, 2472, 2415, 2471,
      2393, 2464, 2390, 2460, 2392, 2456, 2408, 2438, 2411, 2433, 2413, 2428,
      2406, 2401, 2399, 2394, 2391, 2394, 2372, 2397, 2355, 2390, 2341, 2379,
      2328, 2368, 2314, 2359, 2308, 2353, 2305, 2344, 2300, 2319, 2311, 2308,
      2321, 2302, 2332, 2300, 2344, 2303, 2358, 2308, 2373, 2307, 2394, 2307,
      2413, 2313, 2428, 2318, 2443, 2316, 2457, 2311, 2462, 2307, 2461, 2300,
      2452, 2287, 2431, 2261, 2414, 2232, 2405, 2221, 2398, 2219, 2391, 2222,
      2385, 2232, 2384, 2244, 2386, 2254, 2383, 2262, 2377, 2268, 2369, 2269,
      2353, 2266, 2338, 2262, 2323, 2257, 2308, 2255, 2307, 2229, 2309, 2221,
      2316, 2220, 2330, 2218, 2337, 2206, 2342, 2195, 2344, 2188, 2341, 2181,
      2338, 2171, 2340, 2162, 2342, 2156, 2343, 2146, 2337, 2137, 2326, 2131,
      2302, 2122, 2295, 2118, 2291, 2110, 2286, 2097, 2278, 2086, 2268, 2083,
      2256, 2084, 2248, 2084, 2239, 2083, 2236, 2079, 2237, 2070, 2240, 2056,
      2243, 2050, 2250, 2049, 2275, 2051, 2285, 2051, 2292, 2046, 2291, 2040,
      2286, 2034, 2282, 2028, 2278, 2019, 2279, 2010, 2283, 2002, 2288, 2000,
      2299, 2003, 2314, 2007, 2329, 2011, 2331, 2006, 2335, 1991, 2345, 1979,
      2347, 1976, 2353, 1969, 2354, 1963, 2350, 1956, 2336, 1934, 2332, 1927,
      2335, 1920, 2339, 1910, 2340, 1899, 2341, 1885, 2345, 1874, 2355, 1868,
      2366, 1865, 2380, 1861, 2389, 1863, 2400, 1872, 2424, 1892, 2435, 1884,
      2453, 1870, 2462, 1850, 2467, 1840, 2476, 1834, 2488, 1829, 2498, 1821,
      2506, 1816, 2515, 1821, 2540, 1841, 2551, 1850, 2558, 1863, 2561, 1867,
      2566, 1867, 2576, 1865, 2584, 1863, 2593, 1861, 2601, 1862, 2609, 1871,
      2615, 1883, 2619, 1893, 2617, 1903, 2613, 1917, 2616, 1923, 2625, 1927,
      2656, 1931, 2670, 1938, 2685, 1945, 2679, 1966, 2674, 1977, 2661, 1975,
      2653, 1970, 2642, 1968, 2635, 1972, 2632, 1982, 2632, 2001, 2638, 2019,
      2640, 2029, 2640, 2039, 2642, 2054, 2654, 2064, 2667, 2075, 2668, 2091,
      2656, 2105, 2640, 2112, 2630, 2119, 2626, 2127, 2627, 2139, 2637, 2166,
      2656, 2188, 2669, 2204, 2672, 2220, 2666, 2234, 2655, 2248, 2646, 2264,
      2642, 2271, 2635, 2275, 2624, 2281, 2613, 2289, 2604, 2300, 2604, 2315,
      2607, 2324, 2609, 2342, 2604, 2359, 2603, 2363, 2599, 2377, 2603, 2387,
      2615, 2394, 2632, 2400, 2639, 2406, 2638, 2416, 2633, 2422, 2624, 2431,
      2614, 2441, 2613, 2450, 2619, 2462, 2625, 2473, 2626, 2487, 2620, 2499,
      2605, 2518, 2600, 2525, 2592, 2530, 2576, 2540, 2561, 2551, 2548, 2558,
      2533, 2559,
    ],
    left: 60,
    top: 59,
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1528, 1259, 1499, 1297, 1495, 1306, 1484, 1329, 1478, 1337, 1469, 1340,
      1455, 1341, 1444, 1349, 1440, 1351, 1435, 1352, 1407, 1355, 1400, 1358,
      1395, 1364, 1389, 1372, 1381, 1376, 1372, 1381, 1365, 1388, 1353, 1396,
      1338, 1396, 1323, 1393, 1315, 1396, 1314, 1404, 1330, 1443, 1336, 1449,
      1345, 1448, 1356, 1440, 1367, 1432, 1381, 1429, 1390, 1432, 1400, 1441,
      1407, 1450, 1411, 1459, 1406, 1467, 1399, 1475, 1395, 1482, 1395, 1491,
      1397, 1507, 1390, 1522, 1385, 1531, 1385, 1540, 1391, 1576, 1391, 1582,
      1384, 1585, 1372, 1587, 1359, 1586, 1343, 1584, 1327, 1584, 1319, 1583,
      1314, 1577, 1306, 1567, 1294, 1564, 1272, 1562, 1265, 1558, 1263, 1551,
      1260, 1538, 1253, 1529, 1240, 1523, 1223, 1516, 1217, 1527, 1213, 1538,
      1209, 1547, 1200, 1551, 1194, 1552, 1184, 1556, 1179, 1559, 1178, 1565,
      1180, 1576, 1179, 1590, 1170, 1614, 1169, 1619, 1173, 1624, 1182, 1636,
      1186, 1649, 1190, 1663, 1189, 1668, 1186, 1672, 1181, 1679, 1182, 1688,
      1192, 1722, 1195, 1731, 1201, 1737, 1210, 1740, 1214, 1742, 1217, 1746,
      1224, 1771, 1226, 1781, 1223, 1785, 1213, 1788, 1200, 1793, 1180, 1809,
      1168, 1801, 1158, 1792, 1155, 1778, 1157, 1764, 1155, 1743, 1146, 1725,
      1135, 1710, 1130, 1706, 1123, 1706, 1102, 1708, 1098, 1707, 1096, 1704,
      1093, 1680, 1090, 1673, 1083, 1669, 1053, 1659, 1056, 1645, 1062, 1631,
      1084, 1598, 1088, 1590, 1084, 1582, 1061, 1563, 1038, 1545, 1030, 1539,
      1021, 1535, 1019, 1534, 998, 1526, 975, 1524, 966, 1523, 959, 1517, 944,
      1503, 924, 1497, 873, 1487, 874, 1477, 876, 1463, 875, 1456, 869, 1452,
      857, 1445, 850, 1443, 842, 1444, 829, 1449, 818, 1451, 808, 1448, 803,
      1442, 802, 1435, 803, 1413, 800, 1408, 795, 1408, 776, 1419, 768, 1421,
      761, 1419, 754, 1416, 743, 1410, 740, 1404, 742, 1391, 743, 1382, 737,
      1374, 724, 1362, 715, 1347, 717, 1332, 730, 1320, 761, 1307, 767, 1302,
      765, 1294, 758, 1280, 753, 1263, 754, 1245, 756, 1241, 761, 1240, 777,
      1242, 788, 1244, 800, 1243, 813, 1248, 825, 1250, 836, 1245, 846, 1236,
      853, 1232, 862, 1229, 871, 1233, 881, 1240, 888, 1244, 895, 1242, 904,
      1239, 913, 1241, 928, 1247, 942, 1256, 954, 1265, 961, 1268, 967, 1263,
      973, 1250, 981, 1239, 994, 1237, 996, 1237, 1010, 1237, 1018, 1235, 1022,
      1229, 1024, 1214, 1030, 1208, 1040, 1205, 1050, 1204, 1064, 1204, 1076,
      1198, 1085, 1194, 1091, 1193, 1095, 1198, 1104, 1226, 1108, 1232, 1115,
      1230, 1128, 1224, 1138, 1213, 1148, 1204, 1161, 1204, 1197, 1213, 1207,
      1216, 1214, 1215, 1219, 1209, 1229, 1181, 1232, 1176, 1238, 1177, 1255,
      1187, 1269, 1192, 1283, 1190, 1285, 1191, 1296, 1197, 1308, 1198, 1320,
      1192, 1321, 1191, 1329, 1188, 1335, 1189, 1340, 1195, 1352, 1204, 1366,
      1202, 1379, 1199, 1392, 1196, 1403, 1189, 1411, 1188, 1429, 1186, 1430,
      1187, 1431, 1185, 1436, 1185, 1447, 1193, 1460, 1196, 1474, 1196, 1487,
      1202, 1491, 1203, 1496, 1203, 1510, 1201, 1519, 1201, 1523, 1205, 1524,
      1214, 1524, 1233, 1528, 1259,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1989, 1926, 1996, 1910, 2003, 1893, 2003, 1887, 2000, 1882, 1991, 1873,
      1990, 1862, 1995, 1850, 2002, 1834, 2001, 1818, 1995, 1802, 1992, 1789,
      1997, 1778, 2005, 1768, 2005, 1759, 1998, 1749, 1992, 1738, 1992, 1725,
      2010, 1724, 2025, 1721, 2036, 1713, 2046, 1702, 2048, 1699, 2052, 1683,
      2061, 1670, 2070, 1657, 2074, 1653, 2081, 1656, 2101, 1669, 2110, 1673,
      2120, 1670, 2130, 1667, 2141, 1669, 2148, 1670, 2152, 1667, 2155, 1661,
      2168, 1625, 2172, 1619, 2176, 1616, 2183, 1616, 2199, 1618, 2200, 1630,
      2201, 1641, 2209, 1650, 2218, 1659, 2223, 1664, 2229, 1664, 2241, 1663,
      2250, 1665, 2256, 1671, 2265, 1679, 2276, 1679, 2284, 1678, 2290, 1679,
      2293, 1684, 2297, 1714, 2313, 1710, 2328, 1705, 2336, 1703, 2342, 1708,
      2348, 1712, 2354, 1711, 2362, 1708, 2373, 1705, 2383, 1708, 2392, 1713,
      2399, 1721, 2402, 1732, 2406, 1750, 2416, 1766, 2421, 1775, 2435, 1770,
      2449, 1764, 2454, 1764, 2457, 1767, 2461, 1777, 2462, 1788, 2463, 1795,
      2465, 1802, 2476, 1834, 2467, 1840, 2462, 1850, 2453, 1870, 2435, 1884,
      2424, 1892, 2400, 1872, 2389, 1863, 2380, 1861, 2366, 1865, 2355, 1868,
      2345, 1874, 2341, 1885, 2340, 1899, 2339, 1910, 2335, 1920, 2332, 1927,
      2336, 1934, 2350, 1956, 2354, 1963, 2353, 1969, 2347, 1976, 2345, 1979,
      2335, 1991, 2331, 2006, 2329, 2011, 2314, 2007, 2299, 2003, 2288, 2000,
      2283, 2002, 2279, 2010, 2278, 2019, 2282, 2028, 2286, 2034, 2291, 2040,
      2292, 2046, 2285, 2051, 2275, 2051, 2250, 2049, 2243, 2050, 2240, 2056,
      2237, 2070, 2236, 2079, 2239, 2083, 2248, 2084, 2256, 2084, 2268, 2083,
      2278, 2086, 2286, 2097, 2291, 2110, 2295, 2118, 2302, 2122, 2326, 2131,
      2337, 2137, 2343, 2146, 2342, 2156, 2340, 2162, 2338, 2171, 2341, 2181,
      2344, 2188, 2342, 2195, 2337, 2206, 2330, 2218, 2316, 2220, 2309, 2221,
      2307, 2229, 2308, 2255, 2277, 2264, 2267, 2262, 2261, 2255, 2256, 2244,
      2247, 2232, 2233, 2228, 2226, 2229, 2222, 2235, 2214, 2251, 2205, 2266,
      2197, 2277, 2187, 2280, 2174, 2277, 2159, 2268, 2151, 2265, 2144, 2265,
      2118, 2270, 2094, 2279, 2085, 2286, 2080, 2297, 2052, 2312, 2046, 2317,
      2045, 2324, 2043, 2331, 2038, 2335, 2031, 2339, 2017, 2345, 2009, 2348,
      2002, 2343, 1992, 2331, 1982, 2322, 1970, 2316, 1946, 2311, 1923, 2312,
      1911, 2310, 1904, 2304, 1898, 2295, 1898, 2287, 1906, 2276, 1912, 2269,
      1912, 2262, 1905, 2254, 1896, 2245, 1882, 2232, 1874, 2216, 1866, 2200,
      1864, 2192, 1865, 2184, 1870, 2176, 1873, 2166, 1877, 2156, 1885, 2149,
      1888, 2145, 1889, 2140, 1896, 2115, 1905, 2100, 1922, 2095, 1924, 2095,
      1939, 2096, 1950, 2091, 1957, 2077, 1961, 2071, 1969, 2069, 1989, 2068,
      1995, 2066, 1998, 2062, 2007, 2046, 2006, 2037, 1996, 2027, 1989, 2018,
      1990, 2012, 1993, 2007, 1996, 1998, 1998, 1986, 2008, 1980, 2019, 1976,
      2026, 1972, 2030, 1965, 2033, 1961, 2038, 1961, 2063, 1969, 2068, 1970,
      2072, 1969, 2086, 1957, 2086, 1955, 2085, 1952, 2077, 1939, 2071, 1933,
      2062, 1934, 2048, 1939, 2037, 1943, 2033, 1944, 2029, 1943, 2015, 1936,
      1999, 1933, 1993, 1931, 1989, 1926,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1989, 1926, 1977, 1923, 1966, 1927, 1954, 1933, 1950, 1937, 1950, 1942,
      1956, 1957, 1958, 1964, 1955, 1972, 1945, 1978, 1933, 1978, 1924, 1974,
      1919, 1973, 1915, 1977, 1909, 1987, 1904, 1998, 1896, 2008, 1882, 2009,
      1872, 2009, 1863, 2012, 1846, 2020, 1835, 2019, 1818, 2013, 1801, 2008,
      1791, 2006, 1782, 2011, 1778, 2012, 1773, 2011, 1743, 1996, 1736, 1989,
      1732, 1979, 1727, 1970, 1716, 1968, 1702, 1967, 1694, 1967, 1690, 1971,
      1688, 1978, 1686, 1990, 1684, 2008, 1686, 2026, 1688, 2040, 1687, 2044,
      1683, 2046, 1667, 2047, 1661, 2049, 1660, 2055, 1660, 2083, 1659, 2095,
      1646, 2091, 1634, 2084, 1620, 2077, 1616, 2077, 1614, 2080, 1606, 2113,
      1603, 2125, 1599, 2134, 1592, 2136, 1583, 2132, 1575, 2126, 1571, 2117,
      1565, 2102, 1563, 2095, 1565, 2088, 1572, 2075, 1578, 2064, 1583, 2056,
      1589, 2055, 1596, 2058, 1604, 2061, 1608, 2061, 1611, 2058, 1630, 2015,
      1631, 2010, 1629, 2005, 1619, 1995, 1614, 1992, 1609, 1993, 1591, 2002,
      1585, 2003, 1580, 2000, 1567, 1985, 1559, 1979, 1550, 1977, 1537, 1976,
      1525, 1970, 1518, 1968, 1511, 1970, 1498, 1977, 1487, 1984, 1481, 1995,
      1475, 2003, 1466, 2004, 1428, 2006, 1412, 2004, 1405, 1991, 1403, 1979,
      1403, 1967, 1410, 1958, 1420, 1951, 1427, 1944, 1427, 1934, 1427, 1927,
      1430, 1919, 1437, 1901, 1441, 1893, 1440, 1886, 1432, 1878, 1423, 1872,
      1414, 1872, 1406, 1876, 1403, 1881, 1403, 1887, 1404, 1913, 1403, 1920,
      1397, 1924, 1387, 1928, 1376, 1927, 1372, 1926, 1368, 1929, 1348, 1952,
      1343, 1957, 1335, 1956, 1319, 1953, 1303, 1953, 1293, 1951, 1285, 1944,
      1278, 1935, 1269, 1927, 1263, 1922, 1255, 1920, 1246, 1918, 1242, 1909,
      1235, 1884, 1232, 1877, 1227, 1874, 1219, 1874, 1197, 1875, 1191, 1852,
      1181, 1829, 1179, 1819, 1180, 1809, 1200, 1793, 1213, 1788, 1223, 1785,
      1226, 1781, 1224, 1771, 1217, 1746, 1214, 1742, 1210, 1740, 1201, 1737,
      1195, 1731, 1192, 1722, 1182, 1688, 1181, 1679, 1186, 1672, 1189, 1668,
      1190, 1663, 1186, 1649, 1182, 1636, 1173, 1624, 1169, 1619, 1170, 1614,
      1179, 1590, 1180, 1576, 1178, 1565, 1179, 1559, 1184, 1556, 1194, 1552,
      1200, 1551, 1209, 1547, 1213, 1538, 1217, 1527, 1223, 1516, 1240, 1523,
      1253, 1529, 1260, 1538, 1263, 1551, 1265, 1558, 1272, 1562, 1294, 1564,
      1306, 1567, 1314, 1577, 1319, 1583, 1327, 1584, 1343, 1584, 1359, 1586,
      1372, 1587, 1384, 1585, 1391, 1582, 1391, 1576, 1385, 1540, 1385, 1531,
      1390, 1522, 1397, 1507, 1395, 1491, 1395, 1482, 1399, 1475, 1406, 1467,
      1411, 1459, 1407, 1450, 1400, 1441, 1390, 1432, 1381, 1429, 1367, 1432,
      1356, 1440, 1345, 1448, 1336, 1449, 1330, 1443, 1314, 1404, 1315, 1396,
      1323, 1393, 1338, 1396, 1353, 1396, 1365, 1388, 1372, 1381, 1381, 1376,
      1389, 1372, 1395, 1364, 1400, 1358, 1407, 1355, 1435, 1352, 1440, 1351,
      1444, 1349, 1455, 1341, 1469, 1340, 1478, 1337, 1484, 1329, 1495, 1306,
      1499, 1297, 1528, 1259, 1530, 1260, 1540, 1268, 1547, 1279, 1545, 1290,
      1540, 1301, 1537, 1309, 1537, 1315, 1542, 1319, 1560, 1321, 1572, 1324,
      1576, 1328, 1577, 1334, 1572, 1349, 1566, 1366, 1567, 1373, 1570, 1376,
      1578, 1386, 1587, 1395, 1599, 1397, 1612, 1396, 1623, 1401, 1634, 1408,
      1648, 1408, 1658, 1406, 1663, 1406, 1665, 1410, 1665, 1448, 1663, 1454,
      1659, 1458, 1655, 1461, 1655, 1466, 1656, 1506, 1668, 1501, 1681, 1495,
      1689, 1490, 1692, 1485, 1692, 1479, 1695, 1465, 1701, 1460, 1716, 1461,
      1731, 1465, 1736, 1466, 1740, 1469, 1751, 1479, 1765, 1485, 1767, 1488,
      1769, 1492, 1775, 1515, 1778, 1524, 1786, 1530, 1807, 1541, 1798, 1549,
      1789, 1555, 1761, 1571, 1754, 1581, 1759, 1592, 1777, 1605, 1782, 1611,
      1788, 1616, 1799, 1617, 1810, 1617, 1826, 1617, 1838, 1622, 1841, 1635,
      1837, 1650, 1836, 1658, 1842, 1664, 1863, 1674, 1867, 1678, 1868, 1684,
      1865, 1702, 1869, 1708, 1900, 1715, 1921, 1721, 1935, 1723, 1950, 1721,
      1963, 1716, 1971, 1715, 1979, 1718, 1992, 1725, 1992, 1738, 1998, 1749,
      2005, 1759, 2005, 1768, 1997, 1778, 1992, 1789, 1995, 1802, 2001, 1818,
      2002, 1834, 1995, 1850, 1990, 1862, 1991, 1873, 2000, 1882, 2003, 1887,
      2003, 1893, 1996, 1910, 1989, 1926,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4146, 1656, 4141, 1662, 4137, 1669, 4139, 1678, 4139, 1680, 4143, 1691,
      4142, 1701, 4133, 1710, 4129, 1715, 4129, 1721, 4132, 1744, 4132, 1755,
      4128, 1760, 4118, 1760, 4082, 1760, 4074, 1761, 4063, 1768, 4056, 1780,
      4051, 1789, 4042, 1795, 4036, 1800, 4038, 1808, 4049, 1833, 4054, 1845,
      4054, 1859, 4054, 1875, 4057, 1891, 4059, 1895, 4063, 1897, 4090, 1903,
      4100, 1905, 4112, 1909, 4119, 1920, 4126, 1932, 4132, 1940, 4135, 1949,
      4142, 1956, 4151, 1960, 4157, 1962, 4172, 1967, 4181, 1978, 4187, 1992,
      4187, 1999, 4183, 2013, 4177, 2025, 4162, 2013, 4144, 2011, 4130, 2012,
      4116, 2014, 4102, 2020, 4095, 2027, 4094, 2036, 4097, 2046, 4091, 2051,
      4089, 2059, 4088, 2081, 4087, 2088, 4084, 2092, 4077, 2095, 4060, 2100,
      4057, 2106, 4063, 2125, 4064, 2133, 4058, 2139, 4053, 2142, 4041, 2150,
      4035, 2160, 4037, 2175, 4036, 2181, 4032, 2187, 4008, 2208, 4001, 2203,
      3992, 2196, 3981, 2192, 3967, 2189, 3957, 2186, 3950, 2178, 3942, 2170,
      3930, 2170, 3909, 2176, 3891, 2164, 3874, 2152, 3869, 2149, 3863, 2148,
      3837, 2140, 3809, 2136, 3780, 2130, 3766, 2129, 3757, 2128, 3753, 2125,
      3751, 2116, 3745, 2093, 3742, 2089, 3737, 2089, 3728, 2092, 3719, 2095,
      3711, 2095, 3703, 2088, 3697, 2085, 3690, 2085, 3676, 2082, 3668, 2070,
      3665, 2065, 3660, 2058, 3651, 2057, 3642, 2058, 3635, 2053, 3628, 2049,
      3616, 2044, 3604, 2048, 3594, 2051, 3585, 2046, 3578, 2041, 3573, 2035,
      3558, 2022, 3540, 2014, 3534, 2010, 3532, 2002, 3533, 1985, 3540, 1970,
      3542, 1964, 3541, 1959, 3532, 1924, 3540, 1920, 3548, 1920, 3563, 1919,
      3578, 1916, 3579, 1899, 3577, 1882, 3577, 1876, 3579, 1873, 3585, 1872,
      3600, 1867, 3610, 1855, 3621, 1833, 3624, 1809, 3625, 1805, 3627, 1792,
      3629, 1785, 3635, 1784, 3648, 1787, 3658, 1786, 3665, 1780, 3672, 1776,
      3678, 1780, 3698, 1803, 3703, 1808, 3707, 1807, 3712, 1797, 3721, 1792,
      3732, 1791, 3744, 1793, 3754, 1790, 3758, 1780, 3758, 1769, 3761, 1758,
      3770, 1750, 3778, 1742, 3786, 1733, 3793, 1728, 3802, 1730, 3827, 1742,
      3832, 1743, 3835, 1738, 3842, 1702, 3845, 1697, 3851, 1696, 3857, 1696,
      3868, 1692, 3874, 1685, 3875, 1673, 3872, 1654, 3872, 1637, 3877, 1621,
      3882, 1604, 3885, 1586, 3887, 1579, 3891, 1573, 3900, 1572, 3905, 1570,
      3908, 1565, 3914, 1535, 3917, 1529, 3924, 1529, 3951, 1533, 3959, 1537,
      3975, 1532, 3988, 1528, 3996, 1528, 4002, 1533, 4014, 1549, 4019, 1552,
      4024, 1551, 4041, 1545, 4058, 1544, 4075, 1544, 4095, 1548, 4116, 1548,
      4136, 1547, 4143, 1548, 4145, 1551, 4147, 1559, 4140, 1571, 4135, 1581,
      4135, 1592, 4140, 1603, 4143, 1608, 4141, 1614, 4136, 1630, 4142, 1647,
      4146, 1656,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4698, 1588, 4695, 1597, 4687, 1604, 4649, 1626, 4646, 1630, 4646, 1636,
      4653, 1648, 4656, 1660, 4651, 1670, 4642, 1679, 4640, 1690, 4643, 1703,
      4651, 1715, 4658, 1725, 4658, 1732, 4633, 1749, 4624, 1750, 4617, 1747,
      4610, 1745, 4604, 1747, 4598, 1754, 4598, 1763, 4603, 1781, 4610, 1799,
      4622, 1791, 4634, 1783, 4639, 1781, 4645, 1783, 4648, 1792, 4641, 1833,
      4640, 1851, 4639, 1865, 4633, 1877, 4628, 1886, 4629, 1892, 4637, 1900,
      4651, 1909, 4667, 1915, 4677, 1919, 4682, 1923, 4684, 1929, 4684, 1941,
      4679, 1951, 4673, 1959, 4674, 1968, 4683, 2001, 4684, 2009, 4682, 2016,
      4678, 2029, 4676, 2041, 4673, 2047, 4668, 2050, 4656, 2052, 4645, 2050,
      4635, 2048, 4627, 2054, 4611, 2067, 4598, 2083, 4593, 2087, 4563, 2109,
      4554, 2093, 4542, 2080, 4534, 2071, 4529, 2058, 4525, 2049, 4516, 2047,
      4502, 2049, 4460, 2056, 4418, 2060, 4407, 2062, 4401, 2066, 4397, 2076,
      4390, 2090, 4379, 2098, 4363, 2100, 4349, 2098, 4330, 2098, 4313, 2105,
      4304, 2113, 4303, 2125, 4310, 2135, 4318, 2141, 4326, 2147, 4323, 2152,
      4311, 2168, 4294, 2178, 4284, 2184, 4280, 2188, 4282, 2192, 4294, 2203,
      4301, 2209, 4306, 2218, 4306, 2228, 4301, 2233, 4295, 2235, 4291, 2236,
      4282, 2238, 4278, 2237, 4273, 2231, 4272, 2229, 4264, 2218, 4257, 2216,
      4244, 2222, 4235, 2226, 4228, 2219, 4224, 2211, 4218, 2206, 4210, 2204,
      4188, 2208, 4167, 2216, 4147, 2227, 4144, 2231, 4143, 2237, 4140, 2245,
      4133, 2250, 4116, 2255, 4110, 2256, 4106, 2258, 4098, 2264, 4089, 2265,
      4079, 2263, 4052, 2254, 4031, 2235, 4019, 2221, 4008, 2208, 4032, 2187,
      4036, 2182, 4037, 2175, 4035, 2160, 4041, 2150, 4053, 2142, 4058, 2139,
      4063, 2133, 4063, 2125, 4056, 2107, 4060, 2100, 4077, 2095, 4083, 2092,
      4087, 2088, 4088, 2081, 4089, 2059, 4091, 2051, 4097, 2046, 4127, 2069,
      4135, 2072, 4143, 2068, 4164, 2050, 4176, 2025, 4183, 2013, 4187, 2000,
      4187, 1992, 4181, 1978, 4172, 1967, 4157, 1962, 4151, 1960, 4142, 1956,
      4135, 1950, 4132, 1940, 4126, 1932, 4119, 1920, 4112, 1910, 4100, 1905,
      4090, 1904, 4063, 1897, 4059, 1895, 4057, 1891, 4054, 1875, 4054, 1859,
      4054, 1846, 4049, 1833, 4038, 1808, 4036, 1800, 4042, 1795, 4051, 1789,
      4056, 1780, 4063, 1768, 4074, 1761, 4082, 1760, 4118, 1760, 4129, 1760,
      4132, 1756, 4132, 1745, 4129, 1721, 4130, 1715, 4133, 1710, 4142, 1702,
      4144, 1691, 4139, 1680, 4139, 1678, 4137, 1670, 4141, 1662, 4146, 1656,
      4160, 1655, 4171, 1656, 4181, 1660, 4196, 1664, 4208, 1666, 4222, 1670,
      4232, 1678, 4238, 1692, 4243, 1700, 4252, 1704, 4259, 1707, 4271, 1716,
      4286, 1720, 4300, 1724, 4307, 1724, 4313, 1720, 4320, 1716, 4329, 1712,
      4342, 1710, 4350, 1711, 4363, 1715, 4369, 1717, 4383, 1721, 4392, 1718,
      4401, 1707, 4409, 1698, 4418, 1691, 4442, 1676, 4451, 1674, 4459, 1677,
      4467, 1685, 4472, 1690, 4476, 1691, 4482, 1689, 4496, 1682, 4508, 1674,
      4512, 1661, 4514, 1647, 4515, 1637, 4511, 1629, 4508, 1624, 4509, 1619,
      4516, 1601, 4518, 1592, 4520, 1583, 4526, 1577, 4533, 1577, 4541, 1581,
      4548, 1586, 4564, 1589, 4579, 1587, 4582, 1585, 4584, 1581, 4595, 1551,
      4600, 1543, 4609, 1538, 4619, 1538, 4624, 1540, 4628, 1545, 4635, 1561,
      4643, 1572, 4656, 1575, 4670, 1574, 4682, 1574, 4691, 1581, 4698, 1588,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2698, 2141, 2703, 2132, 2713, 2127, 2718, 2129, 2723, 2138, 2720, 2148,
      2713, 2152, 2706, 2151, 2700, 2146, 2698, 2141,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2533, 2559, 2530, 2554, 2524, 2547, 2517, 2544, 2508, 2546, 2501, 2546,
      2491, 2540, 2482, 2533, 2477, 2522, 2476, 2520, 2471, 2513, 2465, 2507,
      2461, 2500, 2457, 2488, 2451, 2478, 2445, 2473, 2438, 2472, 2415, 2471,
      2393, 2464, 2390, 2460, 2392, 2456, 2408, 2438, 2411, 2433, 2413, 2428,
      2406, 2401, 2399, 2394, 2391, 2394, 2372, 2397, 2355, 2390, 2341, 2379,
      2328, 2368, 2314, 2359, 2308, 2353, 2305, 2344, 2300, 2319, 2311, 2308,
      2321, 2302, 2332, 2300, 2344, 2303, 2358, 2308, 2373, 2307, 2394, 2307,
      2413, 2313, 2428, 2318, 2443, 2316, 2457, 2311, 2462, 2307, 2461, 2300,
      2452, 2287, 2431, 2261, 2414, 2232, 2405, 2221, 2398, 2219, 2391, 2222,
      2385, 2232, 2384, 2244, 2386, 2254, 2383, 2262, 2377, 2268, 2369, 2269,
      2353, 2266, 2338, 2262, 2323, 2257, 2308, 2255, 2307, 2229, 2309, 2221,
      2316, 2220, 2330, 2218, 2337, 2206, 2342, 2195, 2344, 2188, 2341, 2181,
      2338, 2171, 2340, 2162, 2342, 2156, 2343, 2146, 2337, 2137, 2326, 2131,
      2302, 2122, 2295, 2118, 2291, 2110, 2286, 2097, 2278, 2086, 2268, 2083,
      2256, 2084, 2248, 2084, 2239, 2083, 2236, 2079, 2237, 2070, 2240, 2056,
      2243, 2050, 2250, 2049, 2275, 2051, 2285, 2051, 2292, 2046, 2291, 2040,
      2286, 2034, 2282, 2028, 2278, 2019, 2279, 2010, 2283, 2002, 2288, 2000,
      2299, 2003, 2314, 2007, 2329, 2011, 2331, 2006, 2335, 1991, 2345, 1979,
      2347, 1976, 2353, 1969, 2354, 1963, 2350, 1956, 2336, 1934, 2332, 1927,
      2335, 1920, 2339, 1910, 2340, 1899, 2341, 1885, 2345, 1874, 2355, 1868,
      2366, 1865, 2380, 1861, 2389, 1863, 2400, 1872, 2424, 1892, 2435, 1884,
      2453, 1870, 2462, 1850, 2467, 1840, 2476, 1834, 2488, 1829, 2498, 1821,
      2506, 1816, 2515, 1821, 2540, 1841, 2551, 1850, 2558, 1863, 2561, 1867,
      2566, 1867, 2576, 1865, 2584, 1863, 2593, 1861, 2601, 1862, 2609, 1871,
      2615, 1883, 2619, 1893, 2617, 1903, 2613, 1917, 2616, 1923, 2625, 1927,
      2656, 1931, 2670, 1938, 2685, 1945, 2679, 1966, 2674, 1977, 2661, 1975,
      2653, 1970, 2642, 1968, 2635, 1972, 2632, 1982, 2632, 2001, 2638, 2019,
      2640, 2029, 2640, 2039, 2642, 2054, 2654, 2064, 2667, 2075, 2668, 2091,
      2656, 2105, 2640, 2112, 2630, 2119, 2626, 2127, 2627, 2139, 2637, 2166,
      2656, 2188, 2669, 2204, 2672, 2220, 2666, 2234, 2655, 2248, 2646, 2264,
      2642, 2271, 2635, 2275, 2624, 2281, 2613, 2289, 2604, 2300, 2604, 2315,
      2607, 2324, 2609, 2342, 2604, 2359, 2603, 2363, 2599, 2377, 2603, 2387,
      2615, 2394, 2632, 2400, 2639, 2406, 2638, 2416, 2633, 2422, 2624, 2431,
      2614, 2441, 2613, 2450, 2619, 2462, 2625, 2473, 2626, 2487, 2620, 2499,
      2605, 2518, 2600, 2525, 2592, 2530, 2576, 2540, 2561, 2551, 2548, 2558,
      2533, 2559,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2533, 2559, 2520, 2569, 2504, 2574, 2483, 2583, 2461, 2590, 2449, 2591,
      2441, 2583, 2437, 2571, 2437, 2560, 2434, 2545, 2426, 2533, 2416, 2527,
      2405, 2533, 2399, 2540, 2386, 2550, 2370, 2553, 2362, 2554, 2353, 2559,
      2349, 2569, 2350, 2583, 2352, 2593, 2351, 2604, 2350, 2611, 2353, 2618,
      2363, 2635, 2371, 2644, 2379, 2645, 2389, 2639, 2400, 2628, 2410, 2624,
      2421, 2627, 2430, 2634, 2432, 2646, 2430, 2671, 2434, 2697, 2434, 2705,
      2436, 2717, 2446, 2725, 2459, 2734, 2473, 2741, 2482, 2747, 2483, 2759,
      2472, 2793, 2469, 2810, 2478, 2826, 2483, 2831, 2495, 2846, 2500, 2864,
      2503, 2878, 2503, 2892, 2495, 2904, 2481, 2907, 2463, 2904, 2445, 2901,
      2435, 2900, 2423, 2900, 2402, 2897, 2382, 2889, 2374, 2883, 2370, 2872,
      2370, 2860, 2378, 2844, 2384, 2833, 2385, 2821, 2385, 2799, 2385, 2789,
      2384, 2780, 2380, 2760, 2384, 2741, 2384, 2734, 2382, 2727, 2373, 2712,
      2362, 2701, 2346, 2699, 2325, 2698, 2304, 2690, 2292, 2680, 2288, 2665,
      2288, 2645, 2288, 2625, 2288, 2618, 2285, 2612, 2276, 2592, 2267, 2572,
      2262, 2563, 2255, 2556, 2244, 2551, 2232, 2552, 2214, 2560, 2199, 2573,
      2189, 2583, 2177, 2589, 2169, 2594, 2168, 2602, 2174, 2609, 2189, 2624,
      2203, 2639, 2205, 2644, 2205, 2650, 2201, 2654, 2188, 2656, 2174, 2652,
      2164, 2653, 2157, 2660, 2152, 2670, 2144, 2679, 2135, 2682, 2124, 2678,
      2115, 2670, 2093, 2653, 2071, 2644, 2047, 2646, 2025, 2648, 2003, 2649,
      1970, 2651, 1934, 2649, 1899, 2641, 1883, 2632, 1873, 2618, 1870, 2599,
      1867, 2584, 1856, 2572, 1847, 2569, 1837, 2570, 1828, 2574, 1791, 2600,
      1783, 2608, 1781, 2619, 1777, 2637, 1767, 2649, 1751, 2657, 1731, 2664,
      1704, 2673, 1679, 2685, 1662, 2697, 1653, 2716, 1647, 2733, 1641, 2739,
      1632, 2738, 1629, 2736, 1607, 2714, 1584, 2694, 1579, 2686, 1577, 2668,
      1576, 2650, 1576, 2628, 1576, 2622, 1581, 2618, 1594, 2609, 1606, 2599,
      1622, 2584, 1636, 2567, 1645, 2554, 1659, 2530, 1667, 2514, 1665, 2495,
      1649, 2452, 1646, 2438, 1649, 2423, 1656, 2402, 1675, 2397, 1682, 2393,
      1682, 2386, 1681, 2380, 1682, 2374, 1687, 2363, 1694, 2353, 1705, 2346,
      1711, 2342, 1711, 2334, 1707, 2324, 1707, 2314, 1715, 2307, 1725, 2302,
      1732, 2298, 1741, 2293, 1747, 2286, 1747, 2277, 1745, 2269, 1744, 2254,
      1753, 2242, 1768, 2229, 1778, 2220, 1783, 2215, 1783, 2208, 1778, 2195,
      1778, 2188, 1782, 2184, 1814, 2166, 1821, 2166, 1842, 2177, 1865, 2184,
      1864, 2192, 1866, 2200, 1874, 2216, 1882, 2232, 1896, 2245, 1905, 2254,
      1912, 2262, 1912, 2269, 1906, 2276, 1898, 2287, 1898, 2295, 1904, 2304,
      1911, 2310, 1923, 2312, 1946, 2311, 1970, 2316, 1982, 2322, 1992, 2331,
      2002, 2343, 2009, 2348, 2017, 2345, 2031, 2339, 2038, 2335, 2043, 2331,
      2045, 2324, 2046, 2317, 2052, 2312, 2080, 2297, 2086, 2305, 2094, 2310,
      2103, 2308, 2113, 2306, 2123, 2306, 2136, 2307, 2155, 2305, 2171, 2314,
      2182, 2320, 2193, 2325, 2208, 2326, 2221, 2316, 2229, 2305, 2233, 2301,
      2239, 2302, 2258, 2308, 2276, 2316, 2288, 2319, 2300, 2319, 2305, 2344,
      2308, 2353, 2314, 2359, 2328, 2368, 2341, 2379, 2355, 2390, 2372, 2397,
      2391, 2394, 2399, 2394, 2406, 2401, 2413, 2428, 2411, 2433, 2408, 2438,
      2392, 2456, 2390, 2460, 2393, 2464, 2415, 2471, 2438, 2472, 2445, 2473,
      2451, 2478, 2457, 2488, 2461, 2500, 2465, 2507, 2471, 2513, 2476, 2520,
      2477, 2522, 2482, 2533, 2491, 2540, 2501, 2546, 2508, 2546, 2517, 2544,
      2524, 2547, 2530, 2554, 2533, 2559,

      2196, 2731, 2208, 2735, 2228, 2746, 2249, 2755, 2261, 2762, 2279, 2781,
      2282, 2789, 2280, 2798, 2272, 2803, 2263, 2801, 2252, 2793, 2239, 2789,
      2208, 2778, 2182, 2759, 2174, 2749, 2176, 2740, 2187, 2733, 2196, 2731,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1283, 1190, 1285, 1178, 1289, 1167, 1292, 1160, 1299, 1155, 1303, 1149,
      1300, 1133, 1299, 1127, 1300, 1121, 1304, 1116, 1323, 1104, 1324, 1097,
      1310, 1076, 1302, 1056, 1303, 1036, 1315, 1021, 1320, 1016, 1321, 1010,
      1321, 992, 1345, 993, 1362, 991, 1377, 982, 1384, 976, 1395, 972, 1405,
      974, 1421, 980, 1437, 979, 1453, 976, 1461, 970, 1474, 958, 1487, 945,
      1495, 939, 1504, 941, 1523, 955, 1534, 967, 1545, 975, 1559, 976, 1573,
      976, 1586, 981, 1596, 983, 1605, 980, 1615, 977, 1630, 974, 1645, 975,
      1660, 982, 1669, 988, 1680, 989, 1692, 993, 1698, 1004, 1703, 1014, 1712,
      1020, 1721, 1024, 1728, 1031, 1694, 1075, 1686, 1080, 1666, 1079, 1656,
      1076, 1647, 1070, 1643, 1065, 1633, 1057, 1624, 1057, 1614, 1065, 1601,
      1078, 1598, 1083, 1599, 1089, 1600, 1095, 1595, 1100, 1590, 1104, 1582,
      1110, 1573, 1114, 1565, 1111, 1558, 1104, 1545, 1100, 1534, 1102, 1526,
      1106, 1516, 1112, 1504, 1113, 1493, 1113, 1483, 1117, 1475, 1123, 1472,
      1132, 1469, 1145, 1457, 1152, 1450, 1157, 1446, 1163, 1442, 1175, 1436,
      1185, 1431, 1185, 1430, 1185, 1429, 1186, 1411, 1188, 1403, 1189, 1392,
      1196, 1379, 1199, 1366, 1202, 1352, 1204, 1340, 1195, 1335, 1189, 1329,
      1188, 1321, 1191, 1320, 1192, 1308, 1198, 1296, 1197, 1285, 1191, 1283,
      1190,

      //otra islita
      1451, 784, 1448, 803, 1448, 822, 1444, 833, 1435, 842, 1424, 847, 1411,
      844, 1404, 837, 1396, 827, 1385, 820, 1369, 809, 1365, 802, 1369, 795,
      1379, 783, 1387, 775, 1399, 771, 1419, 763, 1435, 760, 1446, 767, 1451,
      784,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2484, 1258, 2460, 1249, 2452, 1248, 2441, 1251, 2429, 1248, 2425, 1249,
      2421, 1252, 2419, 1255, 2413, 1265, 2408, 1269, 2402, 1269, 2392, 1267,
      2379, 1264, 2368, 1268, 2359, 1278, 2357, 1282, 2349, 1291, 2342, 1292,
      2331, 1287, 2320, 1282, 2314, 1284, 2304, 1292, 2296, 1300, 2274, 1292,
      2252, 1281, 2247, 1279, 2241, 1280, 2220, 1286, 2212, 1287, 2205, 1285,
      2193, 1278, 2180, 1274, 2174, 1272, 2169, 1268, 2135, 1245, 2128, 1236,
      2124, 1217, 2120, 1199, 2119, 1179, 2118, 1171, 2109, 1167, 2087, 1164,
      2079, 1159, 2076, 1150, 2076, 1146, 2075, 1130, 2069, 1115, 2066, 1103,
      2057, 1097, 2046, 1095, 2036, 1095, 2036, 1075, 2037, 1055, 2045, 1035,
      2067, 1007, 2077, 1000, 2089, 995, 2101, 996, 2111, 1003, 2126, 1016,
      2142, 1023, 2159, 1029, 2203, 1048, 2241, 1077, 2247, 1086, 2243, 1096,
      2236, 1105, 2226, 1112, 2214, 1122, 2205, 1136, 2205, 1153, 2204, 1167,
      2207, 1182, 2212, 1190, 2220, 1192, 2268, 1193, 2282, 1192, 2295, 1188,
      2313, 1183, 2332, 1183, 2356, 1185, 2378, 1194, 2392, 1197, 2405, 1190,
      2416, 1182, 2426, 1174, 2436, 1168, 2445, 1172, 2454, 1177, 2461, 1183,
      2472, 1193, 2486, 1199, 2501, 1209, 2507, 1216, 2508, 1222, 2505, 1229,
      2498, 1239, 2484, 1258,

      //otra islita

      2002, 1023, 2000, 1028, 1993, 1034, 1935, 1066, 1927, 1068, 1918, 1065,
      1909, 1057, 1895, 1046, 1879, 1040, 1868, 1033, 1865, 1022, 1872, 1011,
      1884, 1002, 1896, 992, 1899, 990, 1914, 988, 1929, 985, 1944, 985, 1956,
      985, 1975, 990, 1990, 1001, 2001, 1017, 2002, 1023,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      800, 1243, 800, 1221, 797, 1210, 787, 1203, 780, 1201, 769, 1197, 761,
      1189, 755, 1176, 758, 1163, 761, 1154, 759, 1144, 749, 1114, 767, 1111,
      784, 1114, 848, 1146, 863, 1152, 869, 1157, 872, 1164, 873, 1180, 876,
      1192, 887, 1199, 902, 1201, 916, 1193, 921, 1186, 939, 1163, 964, 1146,
      974, 1139, 982, 1128, 994, 1114, 1012, 1108, 1023, 1105, 1033, 1105, 1042,
      1107, 1086, 1122, 1096, 1124, 1107, 1121, 1118, 1117, 1129, 1112, 1134,
      1100, 1138, 1087, 1141, 1073, 1142, 1069, 1147, 1066, 1158, 1063, 1170,
      1063, 1182, 1062, 1191, 1053, 1204, 1039, 1220, 1031, 1239, 1029, 1245,
      1029, 1259, 1028, 1272, 1020, 1293, 999, 1301, 993, 1310, 989, 1321, 992,
      1321, 1010, 1320, 1016, 1315, 1021, 1303, 1036, 1302, 1056, 1310, 1076,
      1324, 1097, 1323, 1104, 1304, 1116, 1300, 1121, 1299, 1127, 1300, 1133,
      1303, 1149, 1299, 1155, 1292, 1160, 1289, 1167, 1285, 1178, 1283, 1190,
      1269, 1192, 1255, 1187, 1238, 1177, 1232, 1176, 1229, 1181, 1219, 1209,
      1214, 1215, 1207, 1216, 1197, 1213, 1161, 1204, 1148, 1204, 1138, 1213,
      1128, 1224, 1115, 1230, 1108, 1232, 1104, 1226, 1095, 1198, 1091, 1193,
      1085, 1194, 1076, 1198, 1064, 1204, 1050, 1204, 1040, 1205, 1030, 1208,
      1024, 1214, 1022, 1229, 1018, 1235, 1010, 1237, 996, 1237, 994, 1237, 981,
      1239, 973, 1250, 967, 1263, 961, 1268, 954, 1265, 942, 1256, 928, 1247,
      913, 1241, 904, 1239, 895, 1242, 888, 1244, 881, 1240, 871, 1233, 862,
      1229, 853, 1232, 846, 1236, 836, 1245, 825, 1250, 813, 1248, 800, 1243,

      //otra islita
      960, 814, 995, 822, 1016, 830, 1031, 846, 1042, 857, 1050, 868, 1052, 879,
      1050, 885, 1044, 890, 1031, 900, 1022, 912, 1012, 924, 1005, 929, 996,
      926, 984, 914, 970, 903, 955, 896, 936, 884, 924, 864, 924, 860, 921, 851,
      920, 843, 924, 834, 934, 824, 945, 817, 960, 814,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      5054, 2528, 5066, 2529, 5073, 2529, 5079, 2524, 5082, 2515, 5079, 2507,
      5075, 2502, 5064, 2493, 5055, 2482, 5047, 2470, 5043, 2459, 5046, 2449,
      5054, 2439, 5064, 2433, 5075, 2434, 5113, 2444, 5151, 2451, 5166, 2458,
      5173, 2473, 5173, 2486, 5165, 2496, 5164, 2497, 5145, 2514, 5128, 2532,
      5121, 2556, 5114, 2579, 5108, 2594, 5097, 2605, 5074, 2620, 5050, 2632,
      5024, 2645, 5009, 2648, 4996, 2642, 4990, 2634, 4996, 2626, 5005, 2614,
      5008, 2601, 5011, 2571, 5013, 2560, 5020, 2550, 5036, 2534, 5044, 2529,
      5054, 2528,

      4648, 2347, 4658, 2353, 4664, 2353, 4674, 2346, 4684, 2340, 4695, 2341,
      4703, 2344, 4713, 2347, 4722, 2353, 4726, 2364, 4729, 2370, 4735, 2373,
      4778, 2390, 4791, 2391, 4809, 2389, 4827, 2391, 4835, 2390, 4841, 2384,
      4847, 2374, 4852, 2365, 4858, 2353, 4868, 2348, 4879, 2346, 4890, 2345,
      4897, 2348, 4914, 2365, 4909, 2373, 4893, 2385, 4878, 2400, 4863, 2414,
      4858, 2419, 4857, 2425, 4856, 2438, 4858, 2451, 4860, 2467, 4857, 2494,
      4856, 2504, 4850, 2512, 4832, 2529, 4812, 2544, 4800, 2547, 4789, 2543,
      4775, 2532, 4765, 2528, 4755, 2530, 4735, 2538, 4716, 2547, 4707, 2549,
      4697, 2546, 4681, 2538, 4666, 2535, 4653, 2540, 4644, 2546, 4633, 2549,
      4625, 2533, 4616, 2515, 4612, 2496, 4611, 2486, 4607, 2479, 4600, 2476,
      4591, 2473, 4580, 2469, 4573, 2461, 4571, 2449, 4569, 2441, 4561, 2436,
      4529, 2426, 4541, 2408, 4557, 2394, 4577, 2385, 4586, 2380, 4597, 2372,
      4610, 2367, 4618, 2362, 4632, 2353, 4648, 2347,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      5420, 2265, 5420, 2246, 5425, 2228, 5427, 2214, 5425, 2200, 5423, 2175,
      5426, 2160, 5435, 2149, 5441, 2141, 5448, 2124, 5457, 2109, 5464, 2093,
      5469, 2083, 5479, 2078, 5496, 2073, 5513, 2065, 5528, 2053, 5532, 2034,
      5531, 2002, 5533, 1987, 5540, 1974, 5549, 1966, 5561, 1964, 5568, 1972,
      5572, 1990, 5574, 2007, 5582, 2021, 5587, 2030, 5591, 2047, 5583, 2063,
      5569, 2081, 5559, 2095, 5552, 2111, 5545, 2123, 5536, 2128, 5523, 2128,
      5507, 2124, 5496, 2126, 5489, 2133, 5486, 2141, 5490, 2151, 5502, 2173,
      5506, 2197, 5512, 2234, 5512, 2242, 5508, 2253, 5500, 2259, 5488, 2259,
      5479, 2256, 5469, 2258, 5461, 2268, 5456, 2280, 5455, 2292, 5450, 2306,
      5440, 2313, 5429, 2308, 5423, 2298, 5420, 2287, 5420, 2265,

      5497, 1816, 5500, 1825, 5501, 1835, 5501, 1848, 5506, 1860, 5508, 1867,
      5508, 1875, 5508, 1895, 5508, 1902, 5507, 1909, 5501, 1925, 5498, 1941,
      5496, 1960, 5498, 1979, 5496, 1992, 5488, 2002, 5475, 2001, 5465, 1998,
      5454, 2000, 5448, 2008, 5450, 2017, 5457, 2024, 5466, 2032, 5469, 2040,
      5462, 2045, 5450, 2045, 5433, 2041, 5415, 2039, 5406, 2037, 5398, 2033,
      5388, 2032, 5379, 2035, 5372, 2045, 5373, 2056, 5378, 2064, 5384, 2076,
      5384, 2088, 5380, 2100, 5374, 2108, 5364, 2125, 5361, 2144, 5364, 2164,
      5373, 2199, 5373, 2213, 5370, 2226, 5368, 2240, 5371, 2254, 5383, 2283,
      5385, 2304, 5375, 2323, 5358, 2344, 5354, 2353, 5359, 2362, 5362, 2367,
      5384, 2389, 5406, 2410, 5413, 2423, 5407, 2436, 5397, 2446, 5393, 2455,
      5397, 2464, 5408, 2474, 5412, 2484, 5410, 2495, 5402, 2502, 5390, 2508,
      5384, 2517, 5383, 2530, 5383, 2540, 5378, 2553, 5365, 2560, 5353, 2559,
      5345, 2559, 5337, 2560, 5312, 2568, 5287, 2572, 5276, 2576, 5266, 2583,
      5254, 2595, 5245, 2610, 5240, 2616, 5235, 2622, 5224, 2644, 5209, 2664,
      5206, 2671, 5204, 2679, 5195, 2692, 5182, 2699, 5166, 2697, 5152, 2692,
      5137, 2691, 5123, 2689, 5114, 2682, 5111, 2673, 5115, 2663, 5125, 2651,
      5136, 2639, 5152, 2632, 5162, 2627, 5171, 2622, 5190, 2606, 5197, 2582,
      5201, 2561, 5210, 2544, 5225, 2529, 5230, 2526, 5241, 2513, 5244, 2500,
      5240, 2484, 5234, 2473, 5204, 2434, 5196, 2422, 5185, 2399, 5172, 2376,
      5169, 2360, 5179, 2347, 5191, 2339, 5207, 2325, 5212, 2306, 5210, 2287,
      5201, 2271, 5179, 2250, 5171, 2240, 5175, 2228, 5192, 2211, 5212, 2186,
      5216, 2176, 5215, 2165, 5209, 2142, 5208, 2119, 5208, 2096, 5212, 2075,
      5220, 2054, 5227, 2046, 5236, 2040, 5275, 2015, 5288, 2001, 5291, 1983,
      5291, 1967, 5291, 1951, 5292, 1936, 5290, 1922, 5290, 1917, 5292, 1912,
      5291, 1900, 5287, 1887, 5290, 1874, 5291, 1867, 5285, 1863, 5266, 1852,
      5249, 1839, 5238, 1825, 5240, 1808, 5274, 1809, 5280, 1808, 5284, 1804,
      5292, 1790, 5305, 1785, 5321, 1788, 5323, 1789, 5337, 1791, 5348, 1801,
      5354, 1809, 5362, 1814, 5371, 1813, 5387, 1808, 5392, 1809, 5395, 1814,
      5396, 1836, 5400, 1843, 5407, 1847, 5413, 1848, 5418, 1844, 5423, 1841,
      5430, 1839, 5438, 1838, 5445, 1836, 5456, 1830, 5469, 1826, 5479, 1818,
      5485, 1817, 5497, 1816,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      5202, 1321, 5223, 1316, 5232, 1312, 5239, 1305, 5240, 1303, 5251, 1293,
      5260, 1291, 5268, 1295, 5278, 1305, 5291, 1320, 5306, 1333, 5311, 1335,
      5317, 1333, 5330, 1323, 5335, 1308, 5336, 1297, 5340, 1287, 5364, 1301,
      5376, 1315, 5384, 1324, 5395, 1327, 5408, 1331, 5431, 1337, 5443, 1340,
      5452, 1347, 5467, 1357, 5476, 1364, 5481, 1375, 5489, 1396, 5493, 1409,
      5499, 1436, 5498, 1446, 5492, 1451, 5483, 1450, 5475, 1447, 5466, 1445,
      5456, 1448, 5436, 1457, 5399, 1464, 5389, 1462, 5383, 1459, 5381, 1453,
      5376, 1432, 5372, 1412, 5371, 1407, 5368, 1403, 5357, 1395, 5344, 1390,
      5330, 1390, 5317, 1392, 5305, 1395, 5297, 1396, 5290, 1392, 5283, 1385,
      5275, 1379, 5265, 1377, 5248, 1375, 5232, 1373, 5221, 1370, 5214, 1361,
      5205, 1346, 5200, 1334, 5202, 1321,

      5497, 1815, 5485, 1816, 5479, 1817, 5469, 1825, 5456, 1829, 5445, 1835,
      5438, 1838, 5430, 1839, 5423, 1841, 5418, 1844, 5413, 1848, 5407, 1847,
      5400, 1843, 5396, 1835, 5395, 1813, 5392, 1808, 5387, 1807, 5371, 1812,
      5362, 1813, 5354, 1808, 5348, 1800, 5337, 1790, 5323, 1788, 5321, 1787,
      5305, 1784, 5292, 1789, 5284, 1803, 5280, 1807, 5274, 1808, 5240, 1807,
      5239, 1797, 5235, 1790, 5228, 1787, 5204, 1787, 5182, 1794, 5167, 1800,
      5151, 1798, 5122, 1792, 5096, 1792, 5091, 1790, 5089, 1785, 5091, 1770,
      5100, 1758, 5112, 1745, 5119, 1729, 5129, 1710, 5140, 1691, 5156, 1665,
      5169, 1637, 5173, 1628, 5179, 1620, 5189, 1617, 5202, 1614, 5209, 1602,
      5212, 1592, 5219, 1575, 5231, 1560, 5235, 1556, 5240, 1545, 5234, 1534,
      5225, 1526, 5215, 1516, 5209, 1501, 5212, 1486, 5219, 1467, 5227, 1456,
      5240, 1452, 5257, 1452, 5273, 1449, 5279, 1450, 5284, 1455, 5287, 1469,
      5289, 1495, 5294, 1505, 5304, 1508, 5327, 1507, 5349, 1501, 5377, 1493,
      5407, 1495, 5413, 1495, 5427, 1497, 5438, 1503, 5448, 1511, 5457, 1524,
      5464, 1545, 5468, 1563, 5466, 1582, 5459, 1637, 5462, 1671, 5464, 1686,
      5462, 1700, 5462, 1712, 5466, 1719, 5476, 1725, 5486, 1731, 5496, 1741,
      5496, 1755, 5493, 1771, 5497, 1786, 5499, 1801, 5497, 1815,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [5089, 1448, 5499, 1848],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4061, 684, 4065, 713, 4064, 719, 4060, 723, 4053, 724, 4046, 725, 4041,
      730, 4039, 740, 4039, 750, 4039, 756, 4038, 761, 4033, 764, 4021, 768,
      4017, 770, 4016, 775, 4018, 802, 3997, 809, 3981, 813, 3964, 812, 3958,
      812, 3950, 813, 3938, 815, 3929, 822, 3924, 834, 3918, 853, 3914, 858,
      3909, 860, 3897, 860, 3887, 860, 3868, 867, 3849, 869, 3845, 871, 3841,
      875, 3829, 888, 3816, 894, 3802, 891, 3787, 888, 3773, 888, 3766, 887,
      3762, 882, 3752, 854, 3756, 846, 3759, 838, 3764, 831, 3772, 823, 3779,
      813, 3787, 807, 3792, 801, 3792, 793, 3789, 787, 3782, 786, 3769, 784,
      3759, 776, 3753, 773, 3746, 773, 3732, 776, 3720, 776, 3708, 770, 3700,
      759, 3702, 745, 3710, 733, 3722, 726, 3737, 723, 3751, 724, 3765, 723,
      3776, 715, 3800, 698, 3813, 684, 3822, 667, 3822, 658, 3818, 648, 3811,
      641, 3802, 637, 3792, 628, 3793, 616, 3800, 604, 3811, 596, 3828, 588,
      3841, 574, 3852, 566, 3864, 572, 3879, 582, 3893, 590, 3919, 605, 3946,
      618, 3963, 631, 3972, 637, 3983, 639, 4000, 640, 4017, 640, 4027, 639,
      4035, 643, 4041, 652, 4061, 684,

      3369, 1022, 3353, 1022, 3340, 1022, 3311, 1023, 3304, 1024, 3297, 1022,
      3289, 1015, 3285, 1007, 3287, 997, 3295, 988, 3312, 978, 3318, 971, 3320,
      962, 3316, 952, 3295, 922, 3288, 907, 3284, 891, 3281, 869, 3274, 857,
      3261, 849, 3249, 846, 3237, 852, 3231, 861, 3228, 872, 3221, 885, 3208,
      891, 3198, 893, 3186, 892, 3178, 882, 3170, 869, 3163, 854, 3160, 849,
      3154, 848, 3138, 849, 3126, 848, 3120, 844, 3118, 838, 3119, 832, 3121,
      827, 3128, 812, 3133, 797, 3141, 783, 3153, 775, 3167, 772, 3175, 772,
      3202, 773, 3229, 773, 3241, 772, 3251, 766, 3267, 758, 3290, 746, 3310,
      730, 3317, 723, 3333, 717, 3349, 721, 3370, 733, 3390, 746, 3403, 750,
      3416, 746, 3433, 738, 3450, 735, 3465, 731, 3476, 719, 3485, 708, 3495,
      697, 3500, 693, 3506, 692, 3518, 692, 3554, 691, 3589, 679, 3595, 677,
      3599, 680, 3606, 690, 3609, 700, 3601, 706, 3582, 714, 3562, 721, 3551,
      726, 3546, 733, 3544, 745, 3544, 755, 3541, 765, 3534, 771, 3530, 772,
      3524, 774, 3509, 783, 3495, 794, 3480, 804, 3470, 819, 3462, 835, 3459,
      853, 3460, 871, 3462, 874, 3473, 880, 3486, 879, 3494, 871, 3501, 859,
      3511, 852, 3524, 849, 3536, 845, 3544, 837, 3548, 825, 3553, 814, 3562,
      806, 3568, 804, 3575, 806, 3581, 818, 3580, 822, 3572, 850, 3567, 878,
      3564, 902, 3563, 916, 3560, 924, 3556, 930, 3547, 933, 3536, 939, 3529,
      950, 3524, 960, 3518, 969, 3510, 975, 3500, 976, 3478, 974, 3460, 972,
      3445, 972, 3432, 979, 3422, 991, 3416, 1006, 3407, 1017, 3394, 1021, 3380,
      1021, 3369, 1022,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4386, 1026, 4385, 1007, 4385, 989, 4380, 972, 4378, 965, 4377, 958, 4377,
      949, 4383, 942, 4396, 930, 4404, 925, 4413, 922, 4441, 933, 4449, 934,
      4456, 931, 4469, 920, 4484, 912, 4498, 904, 4508, 900, 4519, 906, 4527,
      917, 4532, 930, 4538, 932, 4553, 936, 4564, 946, 4570, 960, 4580, 980,
      4594, 996, 4612, 1009, 4622, 1014, 4633, 1015, 4662, 1017, 4671, 1015,
      4679, 1010, 4713, 982, 4745, 959, 4758, 956, 4770, 963, 4818, 1002, 4824,
      1008, 4823, 1017, 4813, 1033, 4797, 1043, 4778, 1050, 4773, 1053, 4772,
      1059, 4774, 1074, 4774, 1089, 4770, 1103, 4767, 1118, 4766, 1133, 4768,
      1155, 4771, 1177, 4770, 1189, 4764, 1199, 4754, 1206, 4740, 1213, 4735,
      1210, 4731, 1211, 4716, 1220, 4702, 1232, 4695, 1236, 4685, 1237, 4677,
      1235, 4667, 1228, 4662, 1224, 4658, 1221, 4643, 1213, 4633, 1200, 4627,
      1195, 4616, 1192, 4606, 1189, 4597, 1190, 4589, 1196, 4584, 1199, 4578,
      1199, 4570, 1198, 4560, 1196, 4556, 1192, 4553, 1183, 4552, 1175, 4549,
      1168, 4542, 1164, 4533, 1161, 4515, 1155, 4496, 1158, 4472, 1159, 4448,
      1153, 4434, 1127, 4432, 1120, 4435, 1114, 4443, 1108, 4452, 1103, 4463,
      1099, 4469, 1092, 4465, 1083, 4450, 1071, 4438, 1060, 4436, 1045, 4434,
      1038, 4427, 1035, 4386, 1026,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      5202, 1321, 5200, 1299, 5204, 1276, 5204, 1260, 5195, 1247, 5185, 1235,
      5178, 1220, 5172, 1210, 5163, 1193, 5159, 1182, 5153, 1169, 5152, 1155,
      5150, 1136, 5138, 1120, 5127, 1107, 5114, 1096, 5110, 1091, 5110, 1084,
      5111, 1082, 5116, 1067, 5117, 1052, 5115, 1037, 5117, 1016, 5128, 1000,
      5145, 989, 5151, 988, 5181, 992, 5191, 996, 5200, 1002, 5216, 1014, 5236,
      1029, 5253, 1047, 5267, 1061, 5285, 1070, 5298, 1072, 5310, 1068, 5323,
      1065, 5335, 1068, 5348, 1070, 5361, 1070, 5380, 1069, 5400, 1066, 5410,
      1062, 5414, 1056, 5414, 1046, 5414, 1030, 5416, 1014, 5421, 1002, 5429,
      992, 5447, 985, 5459, 987, 5467, 996, 5485, 1022, 5505, 1046, 5515, 1059,
      5532, 1088, 5540, 1108, 5543, 1128, 5544, 1142, 5543, 1150, 5540, 1154,
      5532, 1155, 5517, 1156, 5511, 1156, 5498, 1155, 5486, 1157, 5473, 1171,
      5463, 1187, 5458, 1198, 5455, 1206, 5450, 1213, 5443, 1221, 5438, 1229,
      5432, 1237, 5422, 1238, 5409, 1238, 5397, 1242, 5387, 1244, 5377, 1242,
      5354, 1240, 5342, 1245, 5336, 1256, 5335, 1272, 5340, 1287, 5336, 1297,
      5335, 1308, 5330, 1323, 5317, 1333, 5311, 1335, 5306, 1333, 5291, 1320,
      5278, 1305, 5268, 1295, 5260, 1291, 5251, 1293, 5240, 1303, 5239, 1305,
      5232, 1312, 5223, 1316, 5202, 1321,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4386, 1026, 4357, 1038, 4348, 1042, 4342, 1048, 4340, 1058, 4338, 1063,
      4334, 1068, 4328, 1074, 4322, 1074, 4315, 1069, 4307, 1059, 4297, 1054,
      4286, 1058, 4277, 1065, 4260, 1074, 4242, 1078, 4223, 1081, 4219, 1080,
      4216, 1076, 4211, 1062, 4213, 1049, 4213, 1038, 4206, 1030, 4194, 1024,
      4180, 1026, 4175, 1029, 4165, 1033, 4159, 1034, 4154, 1031, 4145, 1022,
      4138, 1014, 4136, 1003, 4135, 995, 4133, 988, 4130, 975, 4132, 962, 4131,
      955, 4126, 949, 4102, 929, 4077, 911, 4068, 906, 4062, 902, 4061, 898,
      4062, 892, 4063, 880, 4055, 872, 4036, 857, 4032, 852, 4033, 845, 4045,
      820, 4046, 815, 4042, 812, 4018, 802, 4016, 775, 4017, 770, 4021, 768,
      4033, 764, 4038, 761, 4039, 756, 4039, 750, 4039, 740, 4041, 730, 4046,
      725, 4053, 724, 4060, 723, 4064, 719, 4065, 713, 4061, 684, 4078, 682,
      4094, 685, 4096, 685, 4139, 678, 4144, 674, 4142, 667, 4130, 657, 4115,
      652, 4106, 651, 4098, 647, 4090, 638, 4086, 629, 4088, 623, 4098, 619,
      4114, 617, 4130, 621, 4173, 639, 4181, 645, 4184, 654, 4189, 673, 4201,
      687, 4218, 697, 4228, 703, 4233, 713, 4238, 723, 4247, 732, 4255, 739,
      4260, 748, 4277, 772, 4300, 789, 4322, 804, 4337, 823, 4344, 848, 4349,
      859, 4367, 879, 4381, 902, 4384, 904, 4388, 904, 4396, 901, 4404, 900,
      4409, 907, 4413, 922, 4404, 925, 4396, 930, 4383, 942, 4377, 949, 4377,
      958, 4378, 965, 4380, 972, 4385, 989, 4385, 1007, 4386, 1026,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      400, 1571, 401, 1557, 403, 1548, 410, 1542, 418, 1538, 423, 1538, 428,
      1539, 439, 1543, 444, 1543, 447, 1540, 452, 1529, 461, 1525, 472, 1527,
      480, 1527, 483, 1525, 486, 1517, 490, 1500, 493, 1491, 502, 1487, 507,
      1484, 510, 1479, 516, 1468, 525, 1460, 530, 1454, 536, 1448, 543, 1446,
      555, 1450, 568, 1458, 571, 1461, 573, 1466, 584, 1498, 589, 1505, 598,
      1504, 603, 1502, 606, 1499, 616, 1492, 627, 1492, 647, 1492, 657, 1493,
      672, 1499, 688, 1500, 703, 1500, 707, 1499, 711, 1497, 722, 1488, 736,
      1485, 746, 1481, 752, 1472, 752, 1470, 764, 1457, 781, 1456, 787, 1461,
      799, 1482, 805, 1491, 813, 1498, 803, 1515, 800, 1531, 808, 1546, 816,
      1551, 824, 1559, 821, 1569, 807, 1588, 799, 1602, 800, 1616, 809, 1629,
      816, 1633, 829, 1642, 833, 1657, 833, 1661, 837, 1666, 844, 1668, 852,
      1667, 889, 1663, 927, 1671, 910, 1702, 905, 1706, 900, 1707, 889, 1705,
      878, 1706, 873, 1709, 871, 1715, 871, 1723, 871, 1733, 868, 1741, 859,
      1748, 857, 1750, 851, 1759, 843, 1763, 833, 1764, 827, 1765, 816, 1770,
      809, 1780, 805, 1799, 812, 1817, 815, 1824, 811, 1831, 795, 1849, 787,
      1855, 778, 1853, 771, 1852, 764, 1854, 745, 1859, 727, 1862, 725, 1859,
      721, 1847, 710, 1839, 678, 1823, 672, 1817, 677, 1809, 701, 1789, 709,
      1775, 710, 1766, 706, 1757, 697, 1743, 684, 1732, 668, 1720, 661, 1715,
      654, 1713, 647, 1718, 640, 1727, 636, 1736, 637, 1745, 634, 1753, 625,
      1754, 615, 1751, 600, 1748, 588, 1750, 580, 1756, 577, 1767, 572, 1783,
      559, 1793, 547, 1803, 540, 1817, 540, 1827, 544, 1836, 551, 1848, 555,
      1861, 554, 1867, 548, 1869, 544, 1869, 533, 1869, 527, 1871, 522, 1876,
      517, 1886, 511, 1894, 504, 1900, 492, 1892, 482, 1882, 476, 1877, 469,
      1873, 455, 1864, 444, 1851, 434, 1837, 431, 1827, 435, 1818, 442, 1812,
      450, 1813, 464, 1821, 473, 1825, 483, 1824, 490, 1820, 495, 1813, 495,
      1804, 489, 1784, 480, 1765, 467, 1736, 466, 1722, 471, 1712, 483, 1706,
      493, 1704, 504, 1697, 507, 1684, 505, 1672, 508, 1660, 507, 1651, 501,
      1645, 485, 1632, 467, 1622, 462, 1618, 457, 1612, 451, 1605, 442, 1600,
      426, 1588, 414, 1578, 400, 1571,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      400, 1571, 386, 1567, 374, 1560, 362, 1553, 349, 1545, 338, 1533, 328,
      1513, 330, 1490, 336, 1474, 341, 1458, 346, 1451, 355, 1449, 373, 1450,
      391, 1451, 408, 1447, 423, 1434, 429, 1415, 427, 1402, 420, 1375, 411,
      1348, 408, 1340, 407, 1331, 402, 1324, 395, 1320, 387, 1323, 375, 1328,
      362, 1328, 346, 1330, 331, 1331, 301, 1328, 287, 1326, 280, 1319, 281,
      1310, 294, 1276, 296, 1262, 291, 1248, 287, 1236, 293, 1224, 301, 1217,
      309, 1213, 319, 1214, 338, 1221, 353, 1225, 367, 1224, 381, 1216, 395,
      1209, 411, 1210, 419, 1212, 447, 1219, 468, 1235, 484, 1258, 485, 1260,
      490, 1271, 500, 1277, 517, 1275, 530, 1265, 536, 1255, 545, 1242, 560,
      1236, 576, 1231, 584, 1221, 587, 1204, 586, 1194, 585, 1175, 585, 1156,
      590, 1140, 605, 1133, 610, 1133, 613, 1139, 612, 1171, 612, 1182, 615,
      1189, 622, 1192, 635, 1190, 651, 1186, 668, 1187, 678, 1185, 699, 1181,
      712, 1176, 716, 1161, 714, 1140, 714, 1129, 720, 1120, 730, 1113, 741,
      1112, 749, 1114, 759, 1144, 761, 1154, 758, 1163, 755, 1176, 761, 1189,
      769, 1197, 780, 1201, 787, 1203, 797, 1210, 800, 1221, 800, 1243, 788,
      1244, 777, 1242, 761, 1240, 756, 1241, 754, 1245, 753, 1263, 758, 1280,
      765, 1294, 767, 1302, 761, 1307, 730, 1320, 717, 1332, 715, 1347, 724,
      1362, 737, 1374, 743, 1382, 742, 1391, 740, 1404, 743, 1410, 754, 1416,
      761, 1419, 768, 1421, 776, 1419, 795, 1408, 800, 1408, 803, 1413, 802,
      1435, 803, 1442, 808, 1448, 818, 1451, 829, 1449, 842, 1444, 850, 1443,
      857, 1445, 869, 1452, 875, 1456, 876, 1463, 874, 1477, 873, 1487, 859,
      1486, 839, 1486, 820, 1494, 813, 1498, 805, 1491, 799, 1482, 787, 1461,
      781, 1456, 764, 1457, 752, 1470, 752, 1472, 746, 1481, 736, 1485, 722,
      1488, 711, 1497, 707, 1499, 703, 1500, 688, 1500, 672, 1499, 657, 1493,
      647, 1492, 627, 1492, 616, 1492, 606, 1499, 603, 1502, 598, 1504, 589,
      1505, 584, 1498, 573, 1466, 571, 1461, 568, 1458, 555, 1450, 543, 1446,
      536, 1448, 530, 1454, 525, 1460, 516, 1468, 510, 1479, 507, 1484, 502,
      1487, 493, 1491, 490, 1500, 486, 1517, 483, 1525, 480, 1527, 472, 1527,
      461, 1525, 452, 1529, 447, 1540, 444, 1543, 439, 1543, 428, 1539, 423,
      1538, 418, 1538, 410, 1542, 403, 1548, 401, 1557, 400, 1571,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      420, 2412, 433, 2416, 447, 2419, 456, 2418, 468, 2412, 473, 2417, 476,
      2424, 483, 2426, 485, 2444, 490, 2454, 498, 2455, 523, 2451, 533, 2454,
      536, 2463, 538, 2473, 541, 2478, 547, 2480, 557, 2480, 577, 2479, 585,
      2477, 591, 2473, 597, 2469, 604, 2467, 617, 2469, 625, 2477, 627, 2490,
      625, 2506, 624, 2523, 631, 2523, 637, 2520, 643, 2520, 652, 2514, 660,
      2513, 667, 2518, 673, 2523, 685, 2528, 711, 2529, 717, 2530, 718, 2536,
      716, 2564, 715, 2568, 714, 2571, 705, 2580, 702, 2591, 701, 2603, 702,
      2616, 706, 2630, 709, 2634, 714, 2635, 719, 2631, 735, 2596, 736, 2592,
      737, 2581, 743, 2574, 752, 2568, 767, 2558, 773, 2555, 780, 2556, 791,
      2558, 801, 2556, 807, 2555, 811, 2550, 813, 2539, 815, 2531, 818, 2526,
      823, 2524, 843, 2521, 849, 2519, 852, 2514, 853, 2508, 853, 2502, 849,
      2496, 842, 2492, 838, 2487, 836, 2480, 836, 2458, 836, 2450, 839, 2443,
      849, 2439, 860, 2440, 866, 2445, 882, 2471, 886, 2476, 893, 2477, 949,
      2475, 955, 2474, 971, 2468, 987, 2468, 995, 2467, 1000, 2461, 1007, 2452,
      1014, 2450, 1025, 2452, 1031, 2453, 1038, 2456, 1046, 2457, 1052, 2462,
      1044, 2476, 1034, 2488, 1023, 2492, 1017, 2501, 1012, 2511, 1011, 2517,
      999, 2519, 986, 2522, 975, 2529, 966, 2540, 963, 2555, 965, 2587, 962,
      2599, 952, 2607, 942, 2609, 931, 2613, 923, 2622, 915, 2631, 905, 2637,
      889, 2645, 873, 2653, 862, 2661, 855, 2673, 847, 2704, 845, 2716, 840,
      2726, 836, 2736, 836, 2747, 834, 2765, 825, 2780, 805, 2803, 800, 2807,
      793, 2805, 780, 2798, 769, 2788, 760, 2778, 749, 2775, 736, 2776, 718,
      2780, 700, 2787, 693, 2789, 685, 2789, 665, 2787, 659, 2786, 646, 2780,
      633, 2773, 622, 2763, 606, 2751, 585, 2747, 572, 2745, 544, 2734, 539,
      2731, 521, 2715, 504, 2698, 495, 2689, 485, 2682, 478, 2677, 470, 2668,
      468, 2657, 467, 2635, 466, 2619, 461, 2612, 453, 2608, 439, 2607, 425,
      2599, 420, 2585, 418, 2561, 419, 2537, 415, 2507, 401, 2479, 383, 2455,
      378, 2447, 385, 2440, 399, 2432, 411, 2424, 420, 2412,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4655, 2164, 4674, 2138, 4678, 2136, 4682, 2139, 4694, 2163, 4701, 2178,
      4707, 2184, 4715, 2182, 4723, 2173, 4726, 2166, 4731, 2152, 4741, 2144,
      4756, 2140, 4768, 2135, 4775, 2125, 4777, 2111, 4776, 2079, 4776, 2047,
      4778, 2042, 4783, 2040, 4803, 2042, 4813, 2036, 4829, 2011, 4833, 2006,
      4840, 2005, 4848, 2005, 4906, 1999, 4906, 1984, 4905, 1974, 4908, 1964,
      4909, 1959, 4907, 1953, 4898, 1927, 4897, 1921, 4901, 1917, 4919, 1907,
      4934, 1911, 4944, 1908, 4954, 1897, 4962, 1886, 4970, 1876, 4976, 1873,
      4983, 1874, 4995, 1879, 5026, 1892, 5042, 1899, 5056, 1907, 5065, 1916,
      5068, 1927, 5072, 1947, 5073, 1956, 5071, 1964, 5069, 1978, 5072, 1991,
      5076, 2006, 5076, 2020, 5074, 2034, 5070, 2045, 5062, 2053, 5035, 2079,
      5020, 2092, 5005, 2110, 5000, 2125, 5003, 2139, 5006, 2165, 4994, 2187,
      4986, 2201, 4984, 2217, 4984, 2229, 4982, 2239, 4976, 2248, 4971, 2252,
      4967, 2266, 4976, 2277, 4990, 2284, 5005, 2289, 5021, 2300, 5028, 2314,
      5028, 2332, 5020, 2343, 5003, 2348, 4985, 2347, 4971, 2343, 4953, 2343,
      4937, 2348, 4922, 2359, 4914, 2365, 4897, 2348, 4890, 2345, 4879, 2346,
      4868, 2348, 4858, 2353, 4852, 2365, 4847, 2374, 4841, 2384, 4835, 2390,
      4827, 2391, 4809, 2389, 4791, 2391, 4778, 2390, 4735, 2373, 4729, 2370,
      4726, 2364, 4722, 2353, 4713, 2347, 4703, 2344, 4695, 2341, 4684, 2340,
      4674, 2346, 4664, 2353, 4658, 2353, 4648, 2347, 4644, 2329, 4630, 2319,
      4621, 2312, 4610, 2303, 4597, 2300, 4583, 2293, 4574, 2280, 4571, 2263,
      4574, 2245, 4585, 2229, 4592, 2226, 4599, 2229, 4608, 2240, 4611, 2255,
      4613, 2265, 4619, 2268, 4629, 2265, 4634, 2263, 4641, 2257, 4642, 2248,
      4641, 2236, 4638, 2223, 4636, 2214, 4639, 2206, 4646, 2186, 4655, 2167,
      4655, 2164,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [2162, 1248, 2808, 1945],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4698, 1588, 4711, 1584, 4719, 1572, 4729, 1553, 4731, 1548, 4733, 1544,
      4734, 1530, 4739, 1517, 4745, 1505, 4747, 1495, 4741, 1488, 4731, 1476,
      4724, 1461, 4716, 1452, 4705, 1451, 4696, 1452, 4688, 1448, 4677, 1443,
      4666, 1440, 4661, 1437, 4659, 1432, 4658, 1420, 4656, 1405, 4655, 1389,
      4654, 1379, 4650, 1370, 4647, 1357, 4652, 1344, 4654, 1341, 4661, 1329,
      4667, 1325, 4673, 1327, 4683, 1334, 4693, 1339, 4704, 1337, 4721, 1326,
      4732, 1321, 4744, 1323, 4754, 1331, 4758, 1338, 4761, 1345, 4768, 1349,
      4778, 1347, 4785, 1340, 4797, 1322, 4805, 1327, 4816, 1338, 4818, 1347,
      4814, 1362, 4807, 1382, 4805, 1391, 4809, 1399, 4825, 1411, 4835, 1413,
      4845, 1412, 4867, 1404, 4876, 1399, 4880, 1388, 4879, 1377, 4871, 1368,
      4867, 1367, 4857, 1358, 4855, 1346, 4859, 1333, 4865, 1329, 4871, 1329,
      4934, 1360, 4950, 1372, 4958, 1389, 4959, 1409, 4958, 1419, 4955, 1429,
      4950, 1446, 4951, 1463, 4954, 1479, 4956, 1492, 4952, 1504, 4948, 1517,
      4952, 1528, 4964, 1536, 4977, 1543, 4983, 1550, 4983, 1560, 4977, 1573,
      4972, 1580, 4959, 1593, 4951, 1610, 4947, 1628, 4943, 1641, 4934, 1658,
      4917, 1664, 4902, 1669, 4892, 1680, 4865, 1724, 4858, 1736, 4847, 1744,
      4834, 1754, 4823, 1767, 4816, 1781, 4815, 1785, 4807, 1818, 4791, 1848,
      4786, 1859, 4789, 1868, 4800, 1874, 4815, 1876, 4825, 1875, 4832, 1868,
      4836, 1861, 4841, 1857, 4848, 1858, 4857, 1866, 4869, 1874, 4884, 1876,
      4894, 1876, 4907, 1880, 4914, 1892, 4919, 1907, 4901, 1917, 4897, 1921,
      4898, 1927, 4907, 1953, 4909, 1959, 4908, 1964, 4905, 1974, 4906, 1984,
      4906, 1999, 4848, 2005, 4840, 2005, 4833, 2006, 4829, 2011, 4813, 2036,
      4803, 2042, 4783, 2040, 4778, 2042, 4776, 2047, 4776, 2079, 4777, 2111,
      4775, 2125, 4768, 2135, 4756, 2140, 4741, 2144, 4731, 2152, 4726, 2166,
      4723, 2173, 4715, 2182, 4707, 2184, 4701, 2178, 4694, 2163, 4682, 2139,
      4678, 2136, 4674, 2138, 4655, 2164, 4640, 2154, 4623, 2152, 4601, 2153,
      4583, 2152, 4570, 2139, 4563, 2125, 4563, 2109, 4593, 2087, 4598, 2083,
      4611, 2067, 4627, 2054, 4635, 2048, 4645, 2050, 4656, 2052, 4668, 2050,
      4673, 2047, 4676, 2041, 4678, 2029, 4682, 2016, 4684, 2009, 4683, 2001,
      4674, 1968, 4673, 1959, 4679, 1951, 4684, 1941, 4684, 1929, 4682, 1923,
      4677, 1919, 4667, 1915, 4651, 1909, 4637, 1900, 4629, 1892, 4628, 1886,
      4633, 1877, 4639, 1865, 4640, 1851, 4641, 1833, 4648, 1792, 4645, 1783,
      4639, 1781, 4634, 1783, 4622, 1791, 4610, 1799, 4603, 1781, 4598, 1763,
      4598, 1754, 4604, 1747, 4610, 1745, 4617, 1747, 4624, 1750, 4633, 1749,
      4658, 1732, 4658, 1725, 4651, 1715, 4643, 1703, 4640, 1690, 4642, 1679,
      4651, 1670, 4656, 1660, 4653, 1648, 4646, 1636, 4646, 1630, 4649, 1626,
      4687, 1604, 4695, 1597, 4698, 1588,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1138, 1906, 1143, 1896, 1151, 1887, 1160, 1884, 1183, 1884, 1192, 1882,
      1197, 1875, 1219, 1874, 1227, 1874, 1232, 1877, 1235, 1884, 1242, 1909,
      1246, 1918, 1255, 1920, 1263, 1922, 1269, 1927, 1278, 1935, 1285, 1944,
      1293, 1951, 1303, 1953, 1319, 1953, 1335, 1956, 1343, 1957, 1348, 1952,
      1368, 1929, 1372, 1926, 1376, 1927, 1387, 1928, 1397, 1924, 1403, 1920,
      1404, 1913, 1403, 1887, 1403, 1881, 1406, 1876, 1414, 1872, 1423, 1872,
      1432, 1878, 1440, 1886, 1441, 1893, 1437, 1901, 1430, 1919, 1427, 1927,
      1427, 1934, 1427, 1944, 1420, 1951, 1410, 1958, 1403, 1967, 1403, 1979,
      1405, 1991, 1412, 2004, 1428, 2006, 1466, 2004, 1475, 2003, 1481, 1995,
      1487, 1984, 1498, 1977, 1511, 1970, 1518, 1968, 1525, 1970, 1537, 1976,
      1550, 1977, 1559, 1979, 1567, 1985, 1580, 2000, 1585, 2003, 1591, 2002,
      1609, 1993, 1614, 1992, 1619, 1995, 1629, 2005, 1631, 2010, 1630, 2015,
      1611, 2058, 1608, 2061, 1604, 2061, 1596, 2058, 1589, 2055, 1583, 2056,
      1578, 2064, 1572, 2075, 1565, 2088, 1563, 2095, 1565, 2102, 1571, 2117,
      1575, 2126, 1583, 2132, 1592, 2136, 1599, 2134, 1603, 2125, 1606, 2113,
      1614, 2080, 1616, 2077, 1620, 2077, 1634, 2084, 1646, 2091, 1659, 2095,
      1660, 2083, 1660, 2055, 1661, 2049, 1667, 2047, 1683, 2046, 1687, 2044,
      1688, 2040, 1686, 2026, 1684, 2008, 1686, 1990, 1688, 1978, 1690, 1971,
      1694, 1967, 1702, 1967, 1716, 1968, 1727, 1970, 1732, 1979, 1736, 1989,
      1743, 1996, 1773, 2011, 1778, 2012, 1782, 2011, 1791, 2006, 1801, 2008,
      1818, 2013, 1835, 2019, 1846, 2020, 1863, 2012, 1872, 2009, 1882, 2009,
      1896, 2008, 1904, 1998, 1909, 1987, 1915, 1977, 1919, 1973, 1924, 1974,
      1933, 1978, 1945, 1978, 1955, 1972, 1958, 1964, 1956, 1957, 1950, 1942,
      1950, 1937, 1954, 1933, 1966, 1927, 1977, 1923, 1989, 1926, 1993, 1931,
      1999, 1933, 2015, 1936, 2029, 1943, 2033, 1944, 2037, 1943, 2048, 1939,
      2062, 1934, 2071, 1933, 2077, 1939, 2085, 1952, 2086, 1955, 2086, 1957,
      2072, 1969, 2068, 1970, 2063, 1969, 2038, 1961, 2033, 1961, 2030, 1965,
      2026, 1972, 2019, 1976, 2008, 1980, 1998, 1986, 1996, 1998, 1993, 2007,
      1990, 2012, 1989, 2018, 1996, 2027, 2006, 2037, 2007, 2046, 1998, 2062,
      1995, 2066, 1989, 2068, 1969, 2069, 1961, 2071, 1957, 2077, 1950, 2091,
      1939, 2096, 1924, 2095, 1922, 2095, 1905, 2100, 1896, 2115, 1889, 2140,
      1888, 2145, 1885, 2149, 1877, 2156, 1873, 2166, 1870, 2175, 1865, 2184,
      1842, 2177, 1821, 2166, 1814, 2166, 1782, 2184, 1778, 2188, 1778, 2195,
      1783, 2208, 1783, 2215, 1778, 2220, 1768, 2229, 1753, 2242, 1744, 2254,
      1745, 2269, 1747, 2277, 1747, 2286, 1741, 2293, 1732, 2298, 1725, 2302,
      1715, 2307, 1707, 2314, 1707, 2324, 1711, 2334, 1711, 2342, 1705, 2346,
      1694, 2353, 1687, 2363, 1682, 2374, 1681, 2380, 1682, 2386, 1682, 2393,
      1675, 2397, 1656, 2402, 1651, 2388, 1655, 2374, 1658, 2365, 1656, 2355,
      1649, 2347, 1643, 2344, 1638, 2345, 1622, 2357, 1615, 2364, 1598, 2379,
      1579, 2385, 1557, 2381, 1555, 2354, 1553, 2347, 1545, 2344, 1538, 2345,
      1534, 2350, 1528, 2365, 1523, 2372, 1515, 2373, 1505, 2373, 1493, 2371,
      1484, 2362, 1475, 2351, 1463, 2342, 1454, 2334, 1448, 2324, 1446, 2321,
      1442, 2320, 1414, 2320, 1406, 2319, 1402, 2316, 1399, 2308, 1398, 2303,
      1394, 2294, 1385, 2291, 1376, 2288, 1370, 2280, 1364, 2267, 1364, 2252,
      1370, 2228, 1369, 2220, 1361, 2218, 1322, 2222, 1314, 2222, 1311, 2215,
      1307, 2191, 1304, 2186, 1298, 2185, 1273, 2189, 1258, 2186, 1247, 2177,
      1245, 2173, 1247, 2170, 1259, 2149, 1261, 2144, 1256, 2140, 1215, 2125,
      1209, 2122, 1205, 2117, 1200, 2102, 1205, 2087, 1209, 2080, 1213, 2070,
      1206, 2060, 1201, 2053, 1200, 2044, 1201, 2027, 1209, 2012, 1212, 2006,
      1208, 2000, 1185, 1981, 1173, 1970, 1169, 1965, 1170, 1958, 1175, 1945,
      1171, 1934, 1161, 1925, 1138, 1906,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4224, 1258, 4235, 1237, 4242, 1227, 4249, 1224, 4256, 1227, 4266, 1235,
      4279, 1239, 4292, 1241, 4298, 1240, 4300, 1233, 4303, 1207, 4306, 1199,
      4314, 1197, 4362, 1197, 4373, 1195, 4380, 1186, 4387, 1173, 4392, 1163,
      4397, 1159, 4403, 1158, 4415, 1162, 4425, 1163, 4435, 1160, 4448, 1153,
      4472, 1159, 4496, 1158, 4515, 1155, 4533, 1161, 4542, 1164, 4549, 1168,
      4552, 1175, 4553, 1183, 4556, 1192, 4560, 1196, 4570, 1198, 4578, 1199,
      4584, 1199, 4589, 1196, 4597, 1190, 4606, 1189, 4616, 1192, 4627, 1195,
      4633, 1200, 4643, 1213, 4658, 1221, 4662, 1224, 4667, 1228, 4677, 1235,
      4685, 1237, 4695, 1236, 4702, 1232, 4716, 1220, 4731, 1211, 4735, 1210,
      4740, 1213, 4736, 1224, 4736, 1235, 4744, 1242, 4758, 1252, 4768, 1263,
      4772, 1277, 4775, 1289, 4782, 1299, 4790, 1310, 4797, 1322, 4785, 1340,
      4778, 1347, 4768, 1349, 4761, 1345, 4758, 1338, 4754, 1331, 4744, 1323,
      4732, 1321, 4721, 1326, 4704, 1337, 4693, 1339, 4683, 1334, 4673, 1327,
      4667, 1325, 4661, 1329, 4654, 1341, 4652, 1344, 4647, 1357, 4650, 1370,
      4654, 1379, 4655, 1389, 4656, 1405, 4658, 1420, 4659, 1432, 4661, 1437,
      4666, 1440, 4677, 1443, 4688, 1448, 4696, 1452, 4705, 1451, 4716, 1452,
      4724, 1461, 4731, 1476, 4741, 1488, 4747, 1495, 4745, 1505, 4739, 1517,
      4734, 1530, 4733, 1544, 4731, 1548, 4729, 1553, 4719, 1572, 4711, 1584,
      4698, 1588, 4691, 1581, 4682, 1574, 4670, 1574, 4656, 1575, 4643, 1572,
      4635, 1561, 4628, 1545, 4624, 1540, 4619, 1538, 4609, 1538, 4600, 1543,
      4595, 1551, 4584, 1581, 4582, 1585, 4579, 1587, 4564, 1589, 4548, 1586,
      4541, 1581, 4533, 1577, 4526, 1577, 4520, 1583, 4518, 1592, 4516, 1601,
      4509, 1619, 4508, 1624, 4511, 1629, 4515, 1637, 4514, 1647, 4512, 1661,
      4508, 1674, 4496, 1682, 4482, 1689, 4476, 1691, 4472, 1690, 4467, 1685,
      4459, 1677, 4451, 1674, 4442, 1676, 4418, 1691, 4409, 1698, 4401, 1707,
      4392, 1718, 4383, 1721, 4369, 1717, 4363, 1715, 4350, 1711, 4342, 1710,
      4329, 1712, 4320, 1716, 4313, 1720, 4307, 1724, 4300, 1724, 4286, 1720,
      4271, 1716, 4259, 1707, 4252, 1704, 4243, 1700, 4238, 1692, 4232, 1678,
      4222, 1670, 4208, 1666, 4196, 1664, 4181, 1660, 4171, 1656, 4160, 1655,
      4146, 1656, 4142, 1647, 4137, 1630, 4141, 1614, 4143, 1608, 4140, 1603,
      4135, 1592, 4135, 1582, 4140, 1571, 4147, 1559, 4162, 1572, 4169, 1578,
      4177, 1583, 4184, 1578, 4190, 1571, 4195, 1569, 4201, 1570, 4212, 1575,
      4223, 1579, 4235, 1575, 4246, 1569, 4254, 1566, 4258, 1563, 4260, 1557,
      4259, 1540, 4262, 1524, 4262, 1519, 4259, 1515, 4256, 1512, 4238, 1497,
      4227, 1476, 4221, 1465, 4209, 1460, 4186, 1452, 4182, 1448, 4180, 1443,
      4181, 1429, 4185, 1418, 4193, 1409, 4206, 1400, 4212, 1389, 4206, 1378,
      4201, 1375, 4190, 1366, 4188, 1359, 4194, 1346, 4196, 1335, 4194, 1325,
      4202, 1319, 4211, 1315, 4220, 1308, 4225, 1299, 4226, 1289, 4224, 1273,
      4224, 1258,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1138, 1906, 1130, 1920, 1117, 1929, 1111, 1934, 1109, 1942, 1107, 1956,
      1100, 1968, 1098, 1973, 1097, 1979, 1097, 1986, 1101, 1991, 1109, 1998,
      1115, 2006, 1114, 2015, 1112, 2027, 1108, 2032, 1102, 2035, 1090, 2035,
      1084, 2035, 1071, 2036, 1061, 2043, 1053, 2053, 1053, 2060, 1060, 2074,
      1066, 2084, 1076, 2087, 1086, 2091, 1092, 2100, 1107, 2135, 1108, 2144,
      1103, 2151, 1097, 2156, 1089, 2155, 1058, 2145, 1049, 2144, 1040, 2148,
      1026, 2161, 1016, 2177, 1010, 2189, 1005, 2199, 998, 2206, 987, 2208, 982,
      2210, 979, 2215, 972, 2230, 969, 2245, 967, 2252, 961, 2255, 914, 2261,
      908, 2261, 904, 2257, 889, 2233, 884, 2228, 877, 2228, 859, 2235, 851,
      2236, 846, 2231, 828, 2197, 824, 2191, 820, 2189, 812, 2190, 800, 2192,
      789, 2189, 771, 2182, 755, 2173, 743, 2168, 730, 2165, 721, 2166, 703,
      2171, 693, 2172, 683, 2170, 675, 2163, 669, 2159, 662, 2162, 637, 2177,
      625, 2181, 619, 2169, 615, 2156, 609, 2141, 606, 2135, 600, 2131, 588,
      2126, 579, 2117, 562, 2104, 553, 2095, 551, 2082, 551, 2072, 549, 2066,
      544, 2063, 534, 2061, 518, 2060, 503, 2063, 497, 2065, 491, 2065, 483,
      2045, 481, 2025, 486, 2015, 496, 2012, 514, 2011, 531, 2008, 540, 2004,
      544, 1995, 544, 1978, 538, 1962, 533, 1956, 526, 1953, 512, 1946, 506,
      1937, 504, 1922, 504, 1900, 511, 1894, 517, 1886, 522, 1876, 527, 1871,
      533, 1869, 544, 1869, 548, 1869, 554, 1867, 555, 1861, 551, 1848, 544,
      1836, 540, 1827, 540, 1817, 547, 1803, 559, 1793, 572, 1783, 577, 1767,
      580, 1756, 588, 1750, 600, 1748, 615, 1751, 625, 1754, 634, 1753, 637,
      1745, 636, 1736, 640, 1727, 647, 1718, 654, 1713, 661, 1715, 668, 1720,
      684, 1732, 697, 1743, 706, 1757, 710, 1766, 709, 1775, 701, 1789, 677,
      1809, 672, 1817, 678, 1823, 710, 1839, 721, 1847, 725, 1859, 727, 1862,
      745, 1859, 764, 1854, 771, 1852, 778, 1853, 787, 1855, 795, 1849, 811,
      1831, 815, 1824, 812, 1817, 805, 1799, 809, 1780, 816, 1770, 827, 1765,
      833, 1764, 843, 1763, 851, 1759, 857, 1750, 859, 1748, 868, 1741, 871,
      1733, 871, 1723, 871, 1715, 873, 1709, 878, 1706, 889, 1705, 900, 1707,
      905, 1706, 910, 1702, 927, 1671, 940, 1675, 950, 1685, 958, 1697, 960,
      1702, 964, 1706, 974, 1709, 986, 1707, 994, 1703, 1006, 1693, 1019, 1687,
      1026, 1682, 1038, 1670, 1053, 1659, 1083, 1669, 1090, 1673, 1093, 1680,
      1096, 1704, 1098, 1707, 1102, 1708, 1123, 1706, 1130, 1706, 1135, 1710,
      1146, 1725, 1155, 1743, 1157, 1764, 1155, 1778, 1158, 1792, 1168, 1801,
      1180, 1809, 1179, 1819, 1181, 1829, 1191, 1852, 1197, 1875, 1192, 1882,
      1183, 1884, 1160, 1884, 1151, 1887, 1143, 1896, 1138, 1906,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      3951, 1533, 3949, 1507, 3950, 1500, 3955, 1494, 3964, 1488, 3967, 1483,
      3966, 1477, 3962, 1469, 3958, 1460, 3957, 1453, 3960, 1447, 3964, 1439,
      3963, 1429, 3959, 1403, 3955, 1390, 3947, 1379, 3943, 1376, 3938, 1375,
      3929, 1372, 3924, 1364, 3920, 1348, 3918, 1334, 3928, 1324, 3931, 1320,
      3929, 1315, 3908, 1286, 3902, 1279, 3892, 1276, 3888, 1274, 3885, 1270,
      3873, 1240, 3874, 1229, 3875, 1217, 3871, 1206, 3863, 1190, 3860, 1172,
      3859, 1167, 3854, 1163, 3836, 1153, 3819, 1140, 3812, 1134, 3808, 1126,
      3806, 1120, 3800, 1116, 3786, 1108, 3780, 1105, 3773, 1106, 3761, 1106,
      3753, 1098, 3748, 1092, 3741, 1088, 3713, 1077, 3710, 1065, 3703, 1054,
      3697, 1044, 3697, 1033, 3699, 1011, 3703, 990, 3705, 980, 3701, 971, 3697,
      962, 3697, 952, 3697, 926, 3694, 899, 3694, 887, 3698, 876, 3702, 870,
      3708, 867, 3730, 859, 3752, 854, 3762, 882, 3766, 887, 3773, 888, 3787,
      888, 3802, 891, 3816, 894, 3829, 888, 3841, 875, 3845, 871, 3849, 869,
      3868, 867, 3887, 860, 3897, 860, 3909, 860, 3914, 858, 3918, 853, 3924,
      834, 3929, 822, 3938, 815, 3950, 813, 3958, 812, 3964, 812, 3981, 813,
      3997, 809, 4018, 802, 4042, 812, 4046, 815, 4045, 820, 4033, 845, 4032,
      852, 4036, 857, 4055, 872, 4063, 880, 4062, 892, 4061, 898, 4062, 902,
      4068, 906, 4077, 911, 4102, 929, 4126, 949, 4131, 955, 4132, 962, 4130,
      975, 4133, 988, 4135, 995, 4136, 1003, 4138, 1014, 4145, 1022, 4154, 1031,
      4159, 1034, 4165, 1033, 4175, 1029, 4180, 1026, 4194, 1024, 4206, 1030,
      4213, 1038, 4213, 1049, 4211, 1062, 4216, 1076, 4219, 1080, 4223, 1081,
      4242, 1078, 4260, 1074, 4277, 1065, 4286, 1058, 4297, 1054, 4307, 1059,
      4315, 1069, 4322, 1074, 4328, 1074, 4334, 1068, 4338, 1063, 4340, 1058,
      4342, 1048, 4348, 1042, 4357, 1038, 4386, 1026, 4427, 1035, 4434, 1038,
      4436, 1045, 4438, 1060, 4450, 1071, 4465, 1083, 4469, 1092, 4463, 1099,
      4452, 1103, 4443, 1108, 4435, 1114, 4432, 1120, 4434, 1127, 4448, 1153,
      4435, 1160, 4425, 1163, 4415, 1162, 4403, 1158, 4397, 1159, 4392, 1163,
      4387, 1173, 4380, 1186, 4373, 1195, 4362, 1197, 4314, 1197, 4306, 1199,
      4303, 1207, 4300, 1233, 4298, 1240, 4292, 1241, 4279, 1239, 4266, 1235,
      4256, 1227, 4249, 1224, 4242, 1227, 4235, 1237, 4224, 1258, 4220, 1256,
      4207, 1245, 4193, 1238, 4176, 1235, 4160, 1231, 4146, 1221, 4140, 1207,
      4147, 1195, 4160, 1182, 4163, 1170, 4157, 1160, 4142, 1152, 4124, 1155,
      4105, 1166, 4094, 1178, 4093, 1195, 4099, 1213, 4108, 1230, 4118, 1247,
      4128, 1264, 4132, 1273, 4131, 1283, 4128, 1303, 4132, 1316, 4145, 1321,
      4194, 1325, 4196, 1335, 4194, 1346, 4188, 1359, 4190, 1366, 4201, 1375,
      4206, 1378, 4212, 1389, 4206, 1400, 4193, 1409, 4185, 1418, 4181, 1429,
      4180, 1443, 4182, 1448, 4186, 1452, 4209, 1460, 4221, 1465, 4227, 1476,
      4238, 1497, 4256, 1512, 4259, 1515, 4262, 1519, 4262, 1524, 4259, 1540,
      4260, 1557, 4258, 1563, 4254, 1566, 4246, 1569, 4235, 1575, 4223, 1579,
      4212, 1575, 4201, 1570, 4195, 1569, 4190, 1571, 4184, 1578, 4177, 1583,
      4169, 1578, 4162, 1572, 4147, 1559, 4146, 1551, 4143, 1548, 4136, 1547,
      4116, 1548, 4095, 1548, 4075, 1544, 4058, 1544, 4041, 1545, 4025, 1551,
      4019, 1552, 4014, 1549, 4002, 1533, 3996, 1528, 3988, 1528, 3975, 1532,
      3960, 1537, 3951, 1533,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      3368, 1863, 3380, 1865, 3391, 1862, 3405, 1858, 3409, 1858, 3412, 1862,
      3418, 1885, 3422, 1891, 3428, 1892, 3430, 1892, 3440, 1893, 3445, 1897,
      3448, 1907, 3453, 1925, 3457, 1936, 3470, 1930, 3482, 1922, 3491, 1918,
      3502, 1919, 3516, 1922, 3531, 1924, 3541, 1958, 3542, 1964, 3540, 1970,
      3533, 1985, 3532, 2002, 3534, 2010, 3540, 2014, 3558, 2021, 3572, 2035,
      3578, 2040, 3585, 2045, 3594, 2050, 3604, 2047, 3616, 2043, 3628, 2049,
      3634, 2053, 3642, 2057, 3651, 2057, 3659, 2058, 3665, 2065, 3668, 2070,
      3676, 2082, 3690, 2085, 3697, 2085, 3703, 2088, 3711, 2095, 3719, 2095,
      3728, 2092, 3737, 2088, 3742, 2089, 3745, 2092, 3750, 2116, 3753, 2125,
      3757, 2128, 3766, 2128, 3780, 2130, 3809, 2136, 3836, 2140, 3863, 2147,
      3869, 2149, 3874, 2152, 3891, 2164, 3908, 2176, 3906, 2197, 3905, 2205,
      3901, 2208, 3894, 2209, 3876, 2208, 3868, 2209, 3862, 2214, 3855, 2223,
      3847, 2225, 3830, 2216, 3815, 2209, 3808, 2208, 3803, 2212, 3793, 2221,
      3779, 2225, 3767, 2227, 3760, 2229, 3757, 2236, 3755, 2249, 3752, 2262,
      3747, 2277, 3737, 2289, 3726, 2301, 3722, 2316, 3718, 2327, 3716, 2339,
      3720, 2345, 3727, 2350, 3733, 2354, 3741, 2368, 3744, 2383, 3742, 2388,
      3733, 2396, 3726, 2399, 3718, 2397, 3704, 2390, 3690, 2389, 3675, 2392,
      3664, 2395, 3660, 2399, 3660, 2409, 3660, 2417, 3659, 2426, 3653, 2433,
      3644, 2441, 3640, 2446, 3643, 2452, 3650, 2463, 3652, 2466, 3636, 2483,
      3632, 2487, 3630, 2491, 3625, 2506, 3615, 2518, 3607, 2527, 3597, 2533,
      3583, 2542, 3571, 2552, 3554, 2562, 3536, 2569, 3518, 2570, 3498, 2567,
      3481, 2562, 3468, 2550, 3467, 2546, 3458, 2533, 3447, 2529, 3432, 2531,
      3420, 2534, 3402, 2535, 3386, 2529, 3373, 2517, 3366, 2504, 3369, 2489,
      3369, 2487, 3375, 2473, 3388, 2464, 3399, 2457, 3408, 2447, 3411, 2438,
      3404, 2432, 3379, 2425, 3349, 2424, 3338, 2427, 3330, 2436, 3328, 2446,
      3325, 2457, 3318, 2467, 3308, 2469, 3302, 2465, 3298, 2457, 3298, 2448,
      3299, 2444, 3306, 2430, 3309, 2414, 3312, 2399, 3311, 2388, 3308, 2371,
      3303, 2354, 3298, 2344, 3287, 2335, 3276, 2324, 3270, 2311, 3270, 2295,
      3270, 2288, 3269, 2282, 3260, 2260, 3247, 2240, 3240, 2229, 3234, 2217,
      3233, 2203, 3243, 2137, 3244, 2129, 3243, 2121, 3238, 2115, 3220, 2100,
      3201, 2085, 3195, 2079, 3192, 2070, 3186, 2039, 3186, 2033, 3192, 2010,
      3200, 1987, 3207, 1974, 3219, 1965, 3225, 1960, 3228, 1953, 3237, 1937,
      3250, 1928, 3266, 1921, 3280, 1918, 3289, 1906, 3298, 1894, 3308, 1883,
      3312, 1880, 3317, 1879, 3343, 1872, 3368, 1863,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1728, 1031, 1736, 1037, 1743, 1044, 1748, 1056, 1750, 1074, 1756, 1091,
      1765, 1106, 1774, 1119, 1779, 1134, 1786, 1149, 1802, 1156, 1813, 1160,
      1820, 1168, 1823, 1180, 1823, 1190, 1826, 1200, 1834, 1203, 1843, 1202,
      1851, 1197, 1858, 1190, 1870, 1187, 1879, 1193, 1889, 1202, 1900, 1207,
      1915, 1206, 1926, 1197, 1942, 1182, 1948, 1179, 1952, 1175, 1961, 1162,
      1972, 1151, 1982, 1138, 1985, 1133, 2003, 1121, 2020, 1107, 2036, 1095,
      2046, 1095, 2057, 1097, 2066, 1103, 2069, 1115, 2075, 1130, 2076, 1146,
      2076, 1150, 2079, 1159, 2087, 1164, 2109, 1167, 2118, 1171, 2119, 1179,
      2120, 1199, 2124, 1217, 2128, 1236, 2135, 1245, 2169, 1268, 2174, 1272,
      2180, 1274, 2193, 1278, 2205, 1285, 2212, 1287, 2220, 1286, 2241, 1280,
      2247, 1279, 2252, 1281, 2274, 1292, 2296, 1300, 2296, 1310, 2290, 1317,
      2268, 1332, 2262, 1333, 2257, 1331, 2247, 1326, 2238, 1327, 2229, 1333,
      2220, 1338, 2215, 1340, 2209, 1337, 2195, 1331, 2179, 1328, 2172, 1330,
      2162, 1347, 2163, 1352, 2167, 1357, 2177, 1374, 2182, 1379, 2189, 1379,
      2208, 1373, 2213, 1374, 2216, 1377, 2221, 1394, 2223, 1401, 2220, 1408,
      2214, 1418, 2215, 1429, 2221, 1440, 2230, 1450, 2242, 1457, 2249, 1463,
      2252, 1472, 2252, 1484, 2248, 1491, 2237, 1495, 2219, 1501, 2204, 1512,
      2197, 1520, 2196, 1527, 2199, 1538, 2207, 1557, 2210, 1578, 2207, 1598,
      2199, 1618, 2183, 1616, 2176, 1616, 2172, 1619, 2168, 1625, 2155, 1661,
      2152, 1667, 2148, 1670, 2141, 1669, 2130, 1667, 2120, 1670, 2110, 1673,
      2101, 1669, 2081, 1656, 2074, 1653, 2070, 1657, 2061, 1670, 2052, 1683,
      2048, 1699, 2046, 1702, 2036, 1713, 2025, 1721, 2010, 1724, 1992, 1725,
      1979, 1718, 1971, 1715, 1963, 1716, 1950, 1721, 1935, 1723, 1921, 1721,
      1900, 1715, 1869, 1708, 1865, 1702, 1868, 1684, 1867, 1678, 1863, 1674,
      1842, 1664, 1836, 1658, 1837, 1650, 1841, 1635, 1838, 1622, 1826, 1617,
      1810, 1617, 1799, 1617, 1788, 1616, 1782, 1611, 1777, 1605, 1759, 1592,
      1754, 1581, 1761, 1571, 1789, 1555, 1798, 1549, 1807, 1541, 1786, 1530,
      1778, 1524, 1775, 1515, 1769, 1492, 1767, 1488, 1765, 1485, 1751, 1479,
      1740, 1469, 1736, 1466, 1731, 1465, 1716, 1461, 1701, 1460, 1695, 1465,
      1692, 1479, 1692, 1485, 1689, 1490, 1681, 1495, 1668, 1501, 1656, 1506,
      1655, 1466, 1655, 1461, 1659, 1458, 1663, 1454, 1665, 1448, 1665, 1410,
      1663, 1406, 1658, 1406, 1648, 1408, 1634, 1408, 1623, 1401, 1612, 1396,
      1599, 1397, 1587, 1395, 1578, 1386, 1570, 1376, 1567, 1373, 1566, 1366,
      1572, 1349, 1577, 1334, 1576, 1328, 1572, 1324, 1560, 1321, 1542, 1319,
      1537, 1315, 1537, 1309, 1540, 1301, 1545, 1290, 1547, 1279, 1540, 1268,
      1530, 1260, 1528, 1259, 1524, 1233, 1524, 1214, 1523, 1205, 1519, 1201,
      1510, 1201, 1496, 1203, 1491, 1203, 1487, 1202, 1474, 1196, 1460, 1196,
      1447, 1193, 1436, 1185, 1442, 1175, 1446, 1163, 1450, 1157, 1457, 1152,
      1469, 1145, 1472, 1132, 1475, 1123, 1483, 1117, 1493, 1113, 1504, 1113,
      1516, 1112, 1526, 1106, 1534, 1102, 1545, 1100, 1558, 1104, 1565, 1111,
      1573, 1114, 1582, 1110, 1590, 1104, 1595, 1100, 1600, 1095, 1599, 1089,
      1598, 1083, 1601, 1078, 1614, 1065, 1624, 1057, 1633, 1057, 1643, 1065,
      1647, 1070, 1656, 1076, 1666, 1079, 1686, 1080, 1694, 1075, 1728, 1031,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      3368, 1862, 3372, 1849, 3371, 1838, 3364, 1830, 3354, 1822, 3342, 1817,
      3321, 1810, 3302, 1797, 3293, 1792, 3284, 1785, 3284, 1775, 3284, 1771,
      3288, 1759, 3289, 1746, 3290, 1735, 3297, 1725, 3303, 1719, 3314, 1704,
      3322, 1686, 3323, 1667, 3324, 1647, 3330, 1635, 3343, 1634, 3356, 1635,
      3368, 1629, 3376, 1619, 3376, 1607, 3364, 1554, 3359, 1546, 3342, 1535,
      3326, 1523, 3320, 1514, 3321, 1506, 3330, 1498, 3361, 1480, 3377, 1468,
      3388, 1452, 3394, 1444, 3402, 1438, 3416, 1429, 3428, 1417, 3432, 1414,
      3436, 1412, 3456, 1410, 3476, 1410, 3487, 1409, 3491, 1398, 3493, 1383,
      3500, 1369, 3504, 1352, 3509, 1340, 3520, 1336, 3549, 1327, 3568, 1324,
      3578, 1322, 3583, 1314, 3584, 1304, 3579, 1295, 3576, 1286, 3579, 1277,
      3580, 1267, 3582, 1255, 3585, 1243, 3588, 1237, 3593, 1232, 3599, 1229,
      3606, 1229, 3618, 1235, 3629, 1236, 3640, 1233, 3647, 1221, 3645, 1206,
      3627, 1173, 3624, 1158, 3630, 1146, 3644, 1140, 3655, 1138, 3665, 1134,
      3673, 1126, 3684, 1112, 3698, 1100, 3707, 1090, 3713, 1077, 3741, 1088,
      3748, 1092, 3753, 1098, 3761, 1106, 3773, 1106, 3780, 1105, 3786, 1108,
      3800, 1116, 3806, 1120, 3808, 1126, 3812, 1134, 3819, 1140, 3836, 1153,
      3854, 1163, 3859, 1167, 3860, 1172, 3863, 1190, 3871, 1206, 3875, 1217,
      3874, 1229, 3873, 1240, 3885, 1270, 3888, 1274, 3892, 1276, 3902, 1279,
      3908, 1286, 3929, 1315, 3931, 1320, 3928, 1324, 3918, 1334, 3920, 1348,
      3924, 1364, 3929, 1372, 3938, 1375, 3943, 1376, 3947, 1379, 3955, 1390,
      3959, 1403, 3963, 1429, 3964, 1439, 3960, 1447, 3957, 1453, 3958, 1460,
      3962, 1469, 3966, 1477, 3967, 1483, 3964, 1488, 3955, 1494, 3950, 1500,
      3949, 1507, 3951, 1533, 3924, 1529, 3917, 1529, 3914, 1536, 3908, 1565,
      3905, 1570, 3900, 1572, 3892, 1573, 3887, 1579, 3885, 1586, 3882, 1604,
      3877, 1621, 3872, 1637, 3873, 1654, 3875, 1673, 3874, 1685, 3868, 1692,
      3857, 1696, 3851, 1696, 3845, 1697, 3842, 1702, 3835, 1738, 3832, 1743,
      3827, 1742, 3802, 1730, 3793, 1728, 3786, 1733, 3778, 1742, 3770, 1750,
      3761, 1758, 3758, 1769, 3758, 1780, 3754, 1790, 3744, 1793, 3732, 1791,
      3721, 1792, 3712, 1797, 3707, 1807, 3703, 1808, 3698, 1803, 3678, 1780,
      3672, 1776, 3665, 1780, 3658, 1786, 3648, 1787, 3635, 1784, 3629, 1785,
      3627, 1792, 3625, 1805, 3624, 1809, 3621, 1833, 3610, 1855, 3600, 1867,
      3585, 1872, 3579, 1873, 3577, 1876, 3577, 1882, 3578, 1899, 3578, 1916,
      3563, 1919, 3548, 1920, 3540, 1920, 3532, 1924, 3517, 1922, 3502, 1919,
      3491, 1918, 3482, 1922, 3471, 1930, 3458, 1936, 3453, 1925, 3448, 1907,
      3445, 1897, 3441, 1893, 3430, 1892, 3428, 1892, 3422, 1891, 3418, 1885,
      3412, 1862, 3410, 1858, 3405, 1858, 3392, 1862, 3380, 1865, 3368, 1862,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      4633, 2549, 4621, 2559, 4616, 2562, 4612, 2564, 4598, 2567, 4585, 2573,
      4571, 2576, 4553, 2576, 4535, 2580, 4524, 2584, 4512, 2595, 4501, 2609,
      4495, 2625, 4493, 2635, 4486, 2647, 4473, 2651, 4444, 2656, 4416, 2662,
      4402, 2663, 4393, 2662, 4390, 2658, 4391, 2648, 4393, 2628, 4389, 2619,
      4382, 2613, 4374, 2612, 4368, 2616, 4356, 2631, 4342, 2637, 4326, 2639,
      4309, 2639, 4302, 2637, 4297, 2632, 4284, 2623, 4271, 2623, 4261, 2630,
      4249, 2642, 4238, 2643, 4225, 2632, 4205, 2616, 4192, 2611, 4181, 2613,
      4170, 2622, 4151, 2647, 4130, 2674, 4105, 2696, 4058, 2740, 4051, 2752,
      4046, 2767, 4036, 2799, 4030, 2811, 4019, 2815, 4000, 2818, 3985, 2827,
      3972, 2838, 3966, 2844, 3953, 2849, 3940, 2841, 3930, 2827, 3928, 2811,
      3932, 2795, 3950, 2756, 3956, 2743, 3956, 2729, 3955, 2715, 3953, 2707,
      3948, 2701, 3939, 2691, 3933, 2678, 3927, 2667, 3922, 2655, 3914, 2644,
      3901, 2645, 3894, 2649, 3888, 2651, 3883, 2648, 3877, 2642, 3861, 2638,
      3845, 2641, 3841, 2648, 3834, 2666, 3822, 2681, 3808, 2693, 3797, 2698,
      3785, 2696, 3774, 2691, 3761, 2686, 3747, 2688, 3732, 2695, 3719, 2705,
      3712, 2717, 3711, 2727, 3707, 2736, 3701, 2741, 3693, 2741, 3681, 2732,
      3674, 2717, 3673, 2703, 3677, 2696, 3723, 2650, 3730, 2642, 3734, 2631,
      3736, 2619, 3741, 2607, 3744, 2595, 3739, 2585, 3726, 2572, 3717, 2559,
      3716, 2543, 3718, 2531, 3717, 2519, 3716, 2509, 3715, 2496, 3718, 2489,
      3724, 2484, 3736, 2481, 3748, 2477, 3752, 2470, 3749, 2462, 3742, 2458,
      3733, 2456, 3720, 2456, 3708, 2452, 3694, 2449, 3682, 2454, 3668, 2462,
      3652, 2466, 3650, 2463, 3643, 2452, 3640, 2446, 3643, 2441, 3653, 2433,
      3658, 2426, 3660, 2417, 3660, 2409, 3660, 2399, 3664, 2395, 3675, 2392,
      3690, 2389, 3704, 2391, 3718, 2397, 3726, 2400, 3733, 2396, 3742, 2388,
      3744, 2383, 3741, 2368, 3733, 2354, 3727, 2350, 3720, 2346, 3716, 2339,
      3718, 2327, 3722, 2316, 3726, 2301, 3737, 2290, 3747, 2277, 3752, 2262,
      3755, 2249, 3757, 2236, 3760, 2230, 3767, 2227, 3779, 2225, 3793, 2222,
      3803, 2213, 3808, 2208, 3815, 2209, 3830, 2216, 3847, 2225, 3855, 2224,
      3862, 2214, 3868, 2209, 3876, 2208, 3894, 2209, 3901, 2208, 3905, 2205,
      3906, 2198, 3908, 2176, 3929, 2170, 3941, 2170, 3950, 2178, 3957, 2186,
      3967, 2189, 3981, 2192, 3992, 2196, 4001, 2203, 4008, 2208, 4019, 2221,
      4031, 2235, 4052, 2254, 4079, 2263, 4089, 2265, 4098, 2264, 4106, 2258,
      4110, 2256, 4116, 2255, 4133, 2250, 4140, 2245, 4143, 2237, 4144, 2231,
      4147, 2227, 4167, 2216, 4188, 2208, 4210, 2204, 4218, 2206, 4224, 2211,
      4228, 2219, 4235, 2226, 4244, 2222, 4257, 2216, 4264, 2218, 4272, 2229,
      4273, 2231, 4278, 2237, 4282, 2238, 4291, 2236, 4295, 2235, 4301, 2233,
      4306, 2228, 4306, 2218, 4301, 2209, 4294, 2203, 4282, 2192, 4280, 2188,
      4284, 2184, 4294, 2178, 4311, 2168, 4323, 2152, 4326, 2147, 4338, 2154,
      4346, 2164, 4347, 2178, 4349, 2200, 4352, 2209, 4361, 2214, 4382, 2222,
      4405, 2227, 4418, 2232, 4426, 2243, 4458, 2310, 4461, 2320, 4460, 2329,
      4458, 2347, 4465, 2363, 4471, 2376, 4473, 2390, 4477, 2407, 4487, 2422,
      4497, 2431, 4506, 2434, 4520, 2430, 4529, 2426, 4561, 2436, 4569, 2441,
      4571, 2449, 4573, 2461, 4580, 2469, 4591, 2473, 4600, 2476, 4607, 2479,
      4611, 2486, 4612, 2496, 4616, 2515, 4625, 2533, 4633, 2549,
    ],
  },
  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      1598, 2443, 1590, 2440, 1568, 2434, 1547, 2426, 1536, 2417, 1537, 2402,
      1544, 2389, 1557, 2381, 1555, 2354, 1553, 2347, 1545, 2344, 1538, 2345,
      1534, 2350, 1528, 2365, 1523, 2372, 1515, 2373, 1505, 2373, 1493, 2371,
      1484, 2362, 1475, 2351, 1463, 2342, 1454, 2334, 1448, 2324, 1446, 2321,
      1442, 2320, 1414, 2320, 1406, 2319, 1402, 2316, 1399, 2308, 1398, 2303,
      1394, 2294, 1385, 2291, 1376, 2288, 1370, 2280, 1364, 2267, 1364, 2252,
      1370, 2228, 1369, 2220, 1361, 2218, 1322, 2222, 1314, 2222, 1311, 2215,
      1307, 2191, 1304, 2186, 1298, 2185, 1273, 2189, 1258, 2186, 1247, 2177,
      1245, 2173, 1247, 2170, 1259, 2149, 1261, 2144, 1256, 2140, 1215, 2125,
      1209, 2122, 1205, 2117, 1200, 2102, 1205, 2087, 1209, 2080, 1213, 2070,
      1206, 2060, 1201, 2053, 1200, 2044, 1201, 2027, 1209, 2012, 1212, 2006,
      1208, 2000, 1175, 1972, 1169, 1966, 1170, 1958, 1175, 1945, 1171, 1934,
      1161, 1925, 1138, 1906, 1130, 1920, 1117, 1929, 1111, 1934, 1109, 1942,
      1107, 1956, 1100, 1968, 1098, 1973, 1097, 1979, 1097, 1986, 1101, 1991,
      1109, 1998, 1115, 2006, 1114, 2015, 1112, 2027, 1108, 2032, 1102, 2035,
      1090, 2035, 1084, 2035, 1071, 2036, 1061, 2043, 1053, 2053, 1053, 2060,
      1060, 2074, 1066, 2084, 1076, 2087, 1086, 2092, 1092, 2101, 1096, 2113,
      1100, 2125, 1108, 2136, 1108, 2144, 1103, 2151, 1097, 2156, 1089, 2155,
      1058, 2145, 1049, 2144, 1040, 2148, 1024, 2164, 1012, 2183, 1007, 2196,
      1000, 2205, 987, 2208, 982, 2210, 979, 2215, 972, 2230, 969, 2245, 967,
      2252, 961, 2255, 914, 2261, 908, 2261, 904, 2257, 889, 2233, 884, 2228,
      877, 2228, 859, 2235, 851, 2236, 846, 2231, 828, 2197, 824, 2191, 820,
      2189, 812, 2190, 800, 2192, 789, 2189, 771, 2182, 755, 2173, 743, 2168,
      730, 2165, 721, 2166, 703, 2171, 693, 2172, 683, 2170, 675, 2163, 669,
      2159, 662, 2162, 637, 2177, 625, 2181, 618, 2166, 611, 2149, 608, 2138,
      600, 2131, 583, 2122, 569, 2107, 554, 2097, 551, 2082, 551, 2072, 549,
      2066, 544, 2063, 534, 2061, 518, 2060, 503, 2063, 497, 2065, 491, 2065,
      489, 2071, 480, 2080, 468, 2080, 459, 2076, 451, 2069, 443, 2063, 432,
      2060, 426, 2061, 424, 2067, 422, 2087, 417, 2108, 416, 2115, 420, 2122,
      428, 2124, 442, 2123, 462, 2123, 470, 2126, 476, 2132, 484, 2147, 490,
      2163, 495, 2176, 507, 2183, 524, 2193, 528, 2198, 529, 2205, 525, 2218,
      519, 2233, 515, 2248, 510, 2258, 500, 2263, 482, 2268, 464, 2273, 457,
      2276, 452, 2282, 441, 2301, 433, 2311, 423, 2314, 411, 2312, 396, 2305,
      391, 2305, 386, 2308, 377, 2324, 377, 2340, 388, 2355, 407, 2369, 412,
      2374, 415, 2381, 420, 2412, 433, 2416, 447, 2419, 456, 2418, 468, 2412,
      473, 2417, 476, 2424, 483, 2426, 485, 2444, 490, 2454, 498, 2455, 523,
      2451, 533, 2454, 536, 2463, 538, 2473, 541, 2478, 547, 2480, 557, 2480,
      577, 2479, 585, 2477, 591, 2473, 597, 2469, 604, 2467, 617, 2469, 625,
      2477, 627, 2490, 625, 2506, 624, 2523, 631, 2523, 637, 2520, 643, 2520,
      652, 2514, 660, 2513, 667, 2518, 673, 2523, 685, 2528, 711, 2529, 717,
      2530, 718, 2536, 716, 2564, 715, 2568, 714, 2571, 705, 2580, 702, 2591,
      701, 2603, 702, 2616, 706, 2630, 709, 2634, 714, 2635, 719, 2631, 735,
      2596, 736, 2592, 737, 2581, 743, 2574, 752, 2568, 767, 2558, 773, 2555,
      780, 2556, 791, 2558, 801, 2556, 807, 2555, 811, 2550, 813, 2539, 815,
      2531, 818, 2526, 823, 2524, 843, 2521, 849, 2519, 852, 2514, 853, 2508,
      853, 2502, 849, 2496, 842, 2492, 838, 2487, 836, 2480, 836, 2458, 836,
      2450, 839, 2443, 849, 2439, 860, 2440, 866, 2445, 882, 2471, 886, 2476,
      893, 2477, 949, 2475, 955, 2474, 971, 2468, 987, 2468, 995, 2467, 1000,
      2461, 1007, 2452, 1014, 2450, 1025, 2452, 1031, 2453, 1038, 2456, 1046,
      2457, 1052, 2462, 1044, 2476, 1034, 2488, 1023, 2492, 1017, 2501, 1012,
      2511, 1011, 2517, 1014, 2518, 1074, 2512, 1091, 2510, 1109, 2512, 1117,
      2512, 1124, 2509, 1138, 2494, 1145, 2476, 1151, 2452, 1163, 2432, 1173,
      2424, 1186, 2425, 1211, 2429, 1223, 2431, 1231, 2436, 1235, 2442, 1233,
      2453, 1233, 2471, 1244, 2487, 1250, 2492, 1255, 2499, 1256, 2508, 1248,
      2527, 1231, 2539, 1217, 2545, 1205, 2556, 1190, 2569, 1178, 2579, 1172,
      2589, 1175, 2599, 1184, 2606, 1195, 2610, 1207, 2612, 1218, 2613, 1227,
      2619, 1245, 2626, 1252, 2625, 1256, 2620, 1259, 2604, 1266, 2591, 1275,
      2588, 1287, 2594, 1297, 2600, 1309, 2601, 1329, 2597, 1346, 2584, 1356,
      2577, 1369, 2576, 1381, 2577, 1394, 2575, 1403, 2568, 1408, 2556, 1411,
      2540, 1418, 2526, 1428, 2519, 1441, 2517, 1449, 2517, 1461, 2516, 1472,
      2512, 1491, 2497, 1515, 2482, 1543, 2472, 1560, 2468, 1576, 2475, 1587,
      2481, 1596, 2479, 1605, 2470, 1609, 2459, 1607, 2450, 1598, 2443,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2764, 1464, 2768, 1446, 2773, 1440, 2795, 1429, 2815, 1414, 2823, 1411,
      2830, 1414, 2844, 1424, 2861, 1431, 2875, 1439, 2890, 1450, 2907, 1455,
      2926, 1458, 2935, 1459, 2945, 1465, 2950, 1474, 2949, 1480, 2942, 1486,
      2926, 1494, 2923, 1499, 2926, 1504, 2953, 1514, 2977, 1519, 2984, 1520,
      2991, 1516, 3004, 1506, 3022, 1499, 3042, 1502, 3061, 1510, 3080, 1520,
      3091, 1526, 3104, 1529, 3113, 1533, 3114, 1544, 3108, 1556, 3090, 1572,
      3068, 1582, 3041, 1593, 3006, 1609, 2992, 1611, 2982, 1605, 2975, 1592,
      2974, 1582, 2970, 1573, 2961, 1572, 2944, 1577, 2921, 1584, 2898, 1580,
      2860, 1567, 2842, 1556, 2826, 1543, 2808, 1535, 2790, 1523, 2777, 1505,
      2765, 1471, 2764, 1464,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords:[
      2685,1945, 2670,1938, 2656,1931, 2625,1927, 2616,1923, 2613,1917, 2617,1903, 2619,1893, 2615,1883, 2609,1871, 2601,1862, 2593,1861, 2584,1863, 2576,1865, 2566,1867, 2561,1867, 2558,1863, 2551,1850, 2540,1841, 2515,1821, 2506,1816, 2498,1821, 2488,1829, 2476,1834,
2465,1802, 2463,1795, 2462,1788,
2461,1777, 2457,1767, 2454,1764, 2449,1764, 2435,1770, 2421,1775, 2416,1766, 2406,1750, 2402,1732, 2399,1721, 2392,1713, 2383,1708, 2373,1705, 2362,1708, 2354,1711, 2348,1712, 2342,1708, 2336,1703, 2328,1705, 2313,1710, 2297,1714, 2293,1684, 2290,1679, 2284,1678, 2276,1679, 2265,1679, 2256,1671, 2250,1665, 2241,1663, 2229,1664,
2223,1664, 2218,1659, 2209,1650, 2201,1641, 2200,1630, 2199,1618, 2207,1598, 2210,1578, 2207,1557, 2199,1538, 2196,1527, 2197,1520, 2204,1512, 2219,1501, 2237,1495, 2248,1491, 2252,1484, 2252,1472, 2249,1463, 2242,1457, 2230,1450, 2221,1440, 2215,1429, 2214,1418, 2220,1408, 2223,1401, 2221,1394, 2216,1377, 2213,1374, 2208,1373,
2189,1379, 2182,1379, 2177,1374, 2167,1357, 2163,1352, 2162,1347, 2172,1330, 2179,1328, 2195,1331, 2209,1337, 2215,1340, 2220,1338, 2229,1333, 2238,1327, 2247,1326, 2257,1331, 2262,1333, 2268,1332, 2290,1317, 2296,1310, 2296,1300, 2304,1292, 2314,1284, 2320,1282, 2331,1287, 2342,1292, 2349,1291, 2357,1282, 2359,1278, 2368,1268,
2379,1264, 2392,1267, 2402,1269, 2408,1269, 2413,1265, 2419,1255, 2421,1252, 2425,1249, 2429,1248, 2441,1251, 2452,1248, 2460,1249, 2484,1258, 2477,1274, 2463,1282, 2444,1288, 2433,1290, 2422,1289, 2408,1287, 2394,1293, 2390,1298, 2391,1303, 2399,1315, 2409,1324, 2432,1343, 2449,1368, 2460,1384, 2466,1395, 2467,1406, 2469,1423,
2469,1440, 2469,1457, 2469,1474, 2470,1483, 2473,1491, 2477,1505, 2477,1520, 2474,1550, 2468,1579, 2467,1592, 2469,1604, 2475,1615, 2486,1619, 2498,1624, 2506,1634, 2509,1646, 2505,1656, 2495,1663, 2486,1666, 2479,1673, 2473,1684, 2474,1692, 2485,1700, 2496,1708, 2504,1719, 2521,1738, 2546,1745, 2572,1747, 2591,1753, 2603,1769,
2609,1783, 2617,1793, 2628,1794, 2651,1792, 2673,1793, 2681,1794, 2700,1802, 2719,1811, 2735,1825, 2747,1836, 2762,1843, 2773,1849, 2783,1856, 2792,1865, 2794,1878, 2795,1887, 2802,1893, 2807,1899, 2807,1907, 2802,1914, 2793,1917, 2780,1918, 2766,1918, 2749,1918, 2732,1918, 2721,1919, 2712,1925, 2699,1936, 2685,1945
    ]
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2980, 2405, 2977, 2429, 2975, 2452, 2968, 2466, 2963, 2481, 2954, 2495,
      2950, 2501, 2947, 2509, 2939, 2515, 2928, 2514, 2919, 2506, 2914, 2500,
      2896, 2475, 2885, 2446, 2883, 2441, 2876, 2424, 2878, 2407, 2886, 2391,
      2890, 2384, 2892, 2371, 2884, 2360, 2874, 2352, 2866, 2344, 2862, 2335,
      2865, 2326, 2866, 2324, 2876, 2311, 2888, 2309, 2901, 2320, 2907, 2329,
      2917, 2343, 2930, 2351, 2945, 2355, 2964, 2360, 2972, 2365, 2975, 2373,
      2979, 2390, 2980, 2405,

      2774, 2732, 2767, 2732, 2763, 2732, 2749, 2729, 2740, 2719, 2731, 2697,
      2727, 2674, 2729, 2650, 2741, 2618, 2758, 2589, 2768, 2580, 2787, 2569,
      2797, 2565, 2808, 2565, 2820, 2566, 2831, 2564, 2836, 2559, 2838, 2550,
      2843, 2537, 2852, 2530, 2866, 2528, 2882, 2528, 2896, 2533, 2907, 2543,
      2913, 2552, 2912, 2561, 2903, 2569, 2892, 2573, 2854, 2584, 2842, 2589,
      2832, 2597, 2824, 2612, 2827, 2627, 2839, 2639, 2844, 2641, 2853, 2645,
      2856, 2651, 2853, 2662, 2848, 2675, 2843, 2690, 2838, 2698, 2796, 2728,
      2783, 2732, 2777, 2732, 2774, 2732,
    ],
  },

  {
    href: "#",
    fillColor: "rgba(255, 255, 255, 0.5)",
    strokeColor: "rgba(0, 0, 0, 0)",
    shape: "poly",
    coords: [
      2774, 2732, 2767, 2732, 2763, 2732, 2749, 2729, 2740, 2719, 2731, 2697,
      2727, 2674, 2729, 2650, 2741, 2618, 2758, 2589, 2768, 2580, 2787, 2569,
      2797, 2565, 2808, 2565, 2820, 2566, 2831, 2564, 2836, 2559, 2838, 2550,
      2843, 2537, 2852, 2530, 2866, 2528, 2882, 2528, 2896, 2533, 2907, 2543,
      2913, 2552, 2912, 2561, 2903, 2569, 2892, 2573, 2854, 2584, 2842, 2589,
      2832, 2597, 2824, 2612, 2827, 2627, 2839, 2639, 2844, 2641, 2853, 2645,
      2856, 2651, 2853, 2662, 2848, 2675, 2843, 2690, 2838, 2698, 2796, 2728,
      2783, 2732, 2777, 2732, 2774, 2732,
    ],
  },
];
