import React from "react";
import { Dialog } from "primereact/dialog";

import { ProgressBar } from "primereact/progressbar";
import utils from "../../utils";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import CountdownTimer from "../CountdownTimer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import queryString from 'query-string';

import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";

import Logo from "../../assets/img/LOGO.png";
import { Fieldset } from 'primereact/fieldset';

import finalFenix from "../../assets/img/nuevosJefes/public.png";

const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

const rareza = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
};

let iconoPower =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be578d7d-cbe7-4133-62d7-e881e6086400/public";


class Open extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 0,
      packs: 0,
      cards: []

    };

  }

  getPower(properties) {
    let atk = parseInt(properties?.p4);
    let def = parseInt(properties?.p5);
    let critic = parseInt(properties?.p6);
    return Math.trunc(atk * (critic * 0.2) + def * 3);
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  GetImage(type, data) {
    switch (type) {
      case "RAIDPOWER":
        return (
          <img src={iconoPower} style={{ maxWidth: "30%" }} alt="HEALTH" />
        );

      case "HEALTH":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
            style={{ maxWidth: "30%" }}
            alt="HEALTH"
          />
        );
      case "ATTACK":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
            style={{ maxWidth: "30%" }}
            alt="ATTACK"
          />
        );
      case "DEFENSE":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
            style={{ maxWidth: "30%" }}
            alt="DEFENSE"
          />
        );
      case "CRITICAL":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
            style={{ maxWidth: "30%" }}
            alt="CRITICAL"
          />
        );
      case "AFFINITY":
        return (
          <img src={SKILL[data]} style={{ maxWidth: "30%" }} alt="AFFINITY" />
        );
    }
  }


  render() {
    let { visibleLeft } = this.state;
    const end = <img
      style={{
        textAlign: "center",
        width: "30vh",
        height: "20vh"
      }}
      src={Logo}
      onClick={() => this.setState({ visibleLeft: true })}
    />
    return <>
      <SidebarGame
        visibleLeft={visibleLeft}
        onClose={() => this.setState({ visibleLeft: false })}
      />

      <Menubar
        model={[]}
        end={end}
        start={() => {
          return (
            <>
              <img
                src={this.getIcono()}
                style={{ width: "10%", cursor: "pointer" }}
                className="mr-2"
                onClick={() => {
                  this.setState({
                    visibleLeft: true,
                  });
                }}
              />
              {localStorage.getItem("username")}
            </>
          );
        }}
      />

      <br />
      <br />

      <div className="container">



        <Fieldset legend="Info">
          <p className="m-0">


            {this.state.packs <= 0 && <a>U dont have packs</a>}
          </p>

          <p className="m-0">
            PACKS V{this.state.version} : {this.state.packs}

            {this.state.packs > 0 && <Button onClick={e => { this.openPack() }} >Open</Button>}
          </p>
        </Fieldset>



        <br />
        <br />

        <section className="open-cards-section">



          {this.state.cards.map(res => {

            return <div className="open-card">
              <div className="open-card-container">
                <div className="open-card-front">
                  <img src={images[res.properties.p1]} />
                </div>
                <div className="open-card-description">
                  <br />
                  <p className="m-0">
                    <div className="grid">
                      <div className="col-4">
                        {this.GetImage("RAIDPOWER")}
                        {this.getPower(res.properties)}
                      </div>
                      <div className="col-4">
                        {this.GetImage("HEALTH")}
                        {res.properties?.p3}
                      </div>
                      <div className="col-4">
                        {this.GetImage("ATTACK")}
                        {res.properties?.p4}
                      </div>
                      <div className="col-4">
                        {this.GetImage("DEFENSE")}
                        {res.properties?.p5}
                      </div>
                      <div className="col-4">
                        {this.GetImage("CRITICAL")}
                        {res.properties?.p6}
                      </div>
                      <div className="col-4">
                        {this.GetImage("AFFINITY", res.properties?.p7)}
                        {res.properties?.p7}
                      </div>

                    </div>
                  </p>
                </div>

              </div>
            </div>
          })}


        </section>


      </div>



    </>


  }



  openPack() {
    //pregunta con sweet alert la cantidad de packs a abrir, y luego envia la peticion
    console.log("abriendo pack");
    Swal.fire({
      title: "How many packs do you want to open?",
      input: "number",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Open",
      showLoaderOnConfirm: true,
      preConfirm: (cantidad) => {

        if (cantidad <= 0) {

          Swal.showValidationMessage(
            `Please set a valid number`
          );

          return "";

        }

        if (cantidad > 9) {

          Swal.showValidationMessage(
            `You can only open 9 packs at a time`
          );

          return "";


        }

        let mutation = `mutation {
                                                  openPack(input:{version :${this.state.version}, quantity: ${cantidad}}) {
                                                    success
                                                    message
                                                  }
                                                }`


        return fetch("https://backend.infernalcoliseum.fun/api", {
          body: JSON.stringify({ query: mutation }),
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
            Dnt: "1",
          },
          method: "POST",
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data)


            if (data.errors) {
              throw data.errors;
            }


            if (data.data.openPack.success) {


              Swal.showLoading();
              //poner un tiempo y cerrar con swal.close

              setTimeout(e => {

                let datax = JSON.parse(data.data.openPack.message);

                let premiosFormato = datax.prizes.map((prize) => {

                  let premio = prize.contractPayload.instances;
                  return premio;

                })
                premiosFormato = premiosFormato.flat(Infinity);

                console.log("formato premios", premiosFormato)


                Swal.close();
                this.setState({
                  packs: this.state.packs - cantidad,
                  cards: premiosFormato,
                })
              }, 1000)



            }

            //Swal.close();
          }).catch(e => {
            Swal.showValidationMessage(
              `Request failed: please try again`
            );

            // Swal.close();
          });
      },

    })




  }




  componentDidMount() {
    // Opción 1: Sin usar una biblioteca de terceros
    const params = new URLSearchParams(this.props.location.search);
    const v = params.get('v');
    console.log(v); // Aquí tienes tu valor de 'v'


    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getUserData{\\n    _id\\n    username\\n    guild\\n    path\\n    lvl\\n    xp\\n  token\\n   signupDate\\n cantidad_tokens\\n fecha_solicitud_retiro\\n essenceFarmer\\n essenceFisher\\n essenceMiner\\n  utilityToken\\n {\\n token\\n quantity\\n  }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data.getUserData.username == localStorage.getItem("username")
        ) {


          let busqueda = v == 1 ? "ICPVONE" : v == 2 ? "ICPVTWO" : v == 3 ? "ICPVTHREE" : "";

          let p = data.data.getUserData.utilityToken.find(token => token.token == busqueda);

          console.log(busqueda, p, data.data.getUserData.utilityToken);
          if (!p) {
            p = 0;
          } else {
            p = p.quantity;

          }
          this.setState({
            ...this.state,
            version: v,
            packs: p
          })


          console.log();
        }
      });



    // Opción 2: Usando la biblioteca 'query-string'
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.v); // Aquí tienes tu valor de 'v'
  }


}

export default Open;