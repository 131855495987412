import React, { Component } from "react";
import Swal from "sweetalert2";

import "./home.css"

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <>
    
  
    <div style={{display:"none"}} className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
 
    <header className="header navbar-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="nav-inner">
                       
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="index.html">
                                <img src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c75d411a-f082-4479-8bfb-ba1ee7aa9100/public" alt="Logo"/>
                            </a>
                            <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul id="nav" className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a href="#home" className="page-scroll active"
                                            aria-label="Toggle navigation">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#features" className="page-scroll"
                                            aria-label="Toggle navigation">Features</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="javascript:void(0)" aria-label="Toggle navigation">Overview</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#pricing" className="page-scroll"
                                            aria-label="Toggle navigation">Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="javascript:void(0)" aria-label="Toggle navigation">Team</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                                            data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">Blog</a>
                                        <ul className="sub-menu collapse" id="submenu-1-4">
                                            <li className="nav-item"><a href="javascript:void(0)">Blog Grid Sidebar</a>
                                            </li>
                                            <li className="nav-item"><a href="javascript:void(0)">Blog Single</a></li>
                                            <li className="nav-item"><a href="javascript:void(0)">Blog Single
                                                    Sibebar</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="javascript:void(0)" aria-label="Toggle navigation">Contact</a>
                                    </li>
                                </ul>
                            </div> 
                          
                        </nav>
                       
                    </div>
                </div>
            </div> 
        </div> 
    </header>

    <section id="home" className="hero-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-12">
                    <div className="hero-content">
                        <h1 className="wow fadeInLeft" data-wow-delay=".4s">A powerful app for your business.</h1>
                        <p className="wow fadeInLeft" data-wow-delay=".6s">From open source to pro services, Piqes helps you
                            to build, deploy, test, and monitor apps.</p>
                        <div className="button wow fadeInLeft" data-wow-delay=".8s">
                            <a href="javascript:void(0)" className="btn"><i className="lni lni-apple"></i> App Store</a>
                            <a href="javascript:void(0)" className="btn btn-alt"><i className="lni lni-play-store"></i> Google
                                Play</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                    <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                        <img src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7e050942-8c4f-432d-1ecb-cee1d80c4b00/public" alt="#" />
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <section id="features" className="features section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h3 className="wow zoomIn" data-wow-delay=".2s">Features</h3>
                        <h2 className="wow fadeInUp" data-wow-delay=".4s">Your Experience Gets Better And Better Over Time.
                        </h2>
                        <p className="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                            Ipsum available, but the majority have suffered alteration in some form.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    
                    <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                        <i className="lni lni-cloud-upload"></i>
                        <h3>Push to Deploy</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                   
                    <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                        <i className="lni lni-lock"></i>
                        <h3>SSL Certificates</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                    
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                 
                    <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                        <i className="lni lni-reload"></i>
                        <h3>Simple Queues</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                    
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                 
                    <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                        <i className="lni lni-shield"></i>
                        <h3>Advanced Security</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                   
                </div>
                <div className="col-lg-4 col-md-6 col-12">
               
                    <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                        <i className="lni lni-cog"></i>
                        <h3>Powerful API</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                    
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                   
                    <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                        <i className="lni lni-layers"></i>
                        <h3>Database Backups</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page at its layout.</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    <section className="our-achievement section">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
                    <div className="title">
                        <h2>Trusted by developers from over 80 planets</h2>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-achievement wow fadeInUp" data-wow-delay=".2s">
                                <h3 className="counter"><span id="secondo1" className="countup" cup-end="100">100</span>%</h3>
                                <p>satisfaction</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-achievement wow fadeInUp" data-wow-delay=".4s">
                                <h3 className="counter"><span id="secondo2" className="countup" cup-end="120">120</span>K</h3>
                                <p>Happy Users</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                                <h3 className="counter"><span id="secondo3" className="countup" cup-end="125">125</span>k+</h3>
                                <p>Downloads</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="pricing" className="pricing-table section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h3 className="wow zoomIn" data-wow-delay=".2s">pricing</h3>
                        <h2 className="wow fadeInUp" data-wow-delay=".4s">Pricing Plan</h2>
                        <p className="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                            Ipsum available, but the majority have suffered alteration in some form.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    
                    <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                      
                        <div className="table-head">
                            <h4 className="title">Hobby</h4>
                            <p>All the basics for starting a new business</p>
                            <div className="price">
                                <h2 className="amount">$12<span className="duration">/mo</span></h2>
                            </div>
                            <div className="button">
                                <a href="javascript:void(0)" className="btn">Buy Hobby</a>
                            </div>
                        </div>
                        
                        <div className="table-content">
                            <h4 className="middle-title">What's Included</h4>
                            
                            <ul className="table-list">
                                <li><i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Potenti felis, in cras ligula.</li>
                            </ul>
                            
                        </div>
                     
                    </div>
                    
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    
                    <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                       
                        <div className="table-head">
                            <h4 className="title">Freelancer</h4>
                            <p>All the basics for starting a new business</p>
                            <div className="price">
                                <h2 className="amount">$24<span className="duration">/mo</span></h2>
                            </div>
                            <div className="button">
                                <a href="javascript:void(0)" className="btn">Buy Freelancer</a>
                            </div>
                        </div>
                       
                        <div className="table-content">
                            <h4 className="middle-title">What's Included</h4>
                            
                            <ul className="table-list">
                                <li><i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Potenti felis, in cras ligula.</li>
                            </ul>
                            
                        </div>
                     
                    </div>
                   
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    
                    <div className="single-table wow fadeInUp" data-wow-delay=".6s">
                       
                        <div className="table-head">
                            <h4 className="title">Startup</h4>
                            <p>All the basics for starting a new business</p>
                            <div className="price">
                                <h2 className="amount">$32<span className="duration">/mo</span></h2>
                            </div>
                            <div className="button">
                                <a href="javascript:void(0)" className="btn">Buy Startup</a>
                            </div>
                        </div>
                      
                        <div className="table-content">
                            <h4 className="middle-title">What's Included</h4>
                           
                            <ul className="table-list">
                                <li><i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Potenti felis, in cras ligula.</li>
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    
                    <div className="single-table wow fadeInUp" data-wow-delay=".8s">
                        
                        <div className="table-head">
                            <h4 className="title">Enterprise</h4>
                            <p>All the basics for starting a new business</p>
                            <div className="price">
                                <h2 className="amount">$48<span className="duration">/mo</span></h2>
                            </div>
                            <div className="button">
                                <a href="javascript:void(0)" className="btn">Buy Enterprise</a>
                            </div>
                        </div>
                     
                        <div className="table-content">
                            <h4 className="middle-title">What's Included</h4>
                            
                            <ul className="table-list">
                                <li><i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                                <li><i className="lni lni-checkmark-circle"></i> Potenti felis, in cras ligula.</li>
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
  
    <a href="#" className="scroll-top">
        <i className="lni lni-chevron-up"></i>
    </a>

    </>;
  }
}

export default Home;
