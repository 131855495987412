
import React, { Component } from "react";
import SidebarGame from "./sidebar";
import { Menubar } from "primereact/menubar";
import { Terminal } from "primereact/terminal";
import { Button } from "primereact/button";

import { getCellContent } from "./dungeonTools";

import DungeonMap from "./dungeonMap";

class Dungeon extends Component {


          constructor(props) {
                    super(props);

                    this.state = {
                              visibleLeft: false,
                              dungeon: {},
                              playerPosition: {},
                              startGame: false
                    }

                    this.generateDungeonMap = this.generateDungeonMap.bind(this);
          }

          getIcono() {
                    let angel =
                              "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
                    let demonio =
                              "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

                    let userData = JSON.parse(localStorage.getItem("userData"));

                    if (!userData) {
                              localStorage.clear();
                              window.location.href = "/";
                    }

                    return userData.path == "KNALAND" ? demonio : angel;
          }

          sendMessageToTerminal = (message) => {
                    if (message == "clear") {
                              document.querySelector(".p-terminal-content").innerHTML = "";
                    }
                    document.querySelector(
                              ".p-terminal-content"
                    ).innerHTML += `<div><div className="p-terminal-command"><pre>${message}</pre></div></div>`;
          };

          generateDungeonMap() {
                    const { dungeonWidth, dungeonHeight, rooms, corridors } = this.state.dungeon;
                    const dungeonMap = Array.from({ length: dungeonHeight }, () => Array(dungeonWidth).fill('.'));

                    // Fill in rooms
                    for (const room of rooms) {
                              for (let y = room.y; y < room.y + room.height; y++) {
                                        for (let x = room.x; x < room.x + room.width; x++) {
                                                  dungeonMap[y][x] = 'R';
                                        }
                              }
                    }

                    // Fill in corridors
                    for (const corridor of corridors) {
                              let { startX, startY, endX, endY } = corridor;
                              while (startX !== endX || startY !== endY) {
                                        dungeonMap[startY][startX] = 'C';
                                        if (startX < endX) startX++;
                                        else if (startX > endX) startX--;
                                        if (startY < endY) startY++;
                                        else if (startY > endY) startY--;
                              }
                    }

                    this.sendMessageToTerminal(dungeonMap.map(row => row.join('')).join('\n'));
          }

          start() {
                    const newSocket = new WebSocket("wss://infernal-backend-pvp.herokuapp.com");
                    let sendMessage = (message) => {
                              console.log("mensaje", message, newSocket);
                              if (newSocket) {
                                        newSocket.send(message);
                              }
                    };


                    newSocket.onmessage = (event) => {
                              let message = JSON.parse(event.data);
                              switch (message.message) {
                                        case "set name sucessfully":

                                                  console.log("puedes iniciar dungeon");
                                                  this.sendMessageToTerminal("init dungeon... please wait");

                                                  sendMessage(JSON.stringify({
                                                            action: "StartDungeon",
                                                  }));
                                                  break;


                                        case "u dont set character on pvp game":
                                                  this.sendMessageToTerminal("plase set firts 3 nfts on inventory pvp page");
                                                  break;


                                        case "you start a new dungeon":
                                                  this.sendMessageToTerminal("you start a new dungeon sucessfuly");
                                                  this.setState({
                                                            dungeon: message.dungeon,
                                                            playerPosition: message.position,
                                                            startGame: true
                                                  })
                                                  break;

                              }

                    }

                    this.sendMessageToTerminal("Wait... conecting to server ....")

                    // Asigna el evento de "open" para saber cuando se ha establecido la conexión
                    newSocket.onopen = () => {
                              newSocket.pingInterval = 5000;
                              this.sendMessageToTerminal(
                                        "Conexión establecida con el servidor de WebSockets. login"
                              );

                              sendMessage(
                                        JSON.stringify({
                                                  action: "setName",
                                                  token: "bearer " + localStorage.getItem("token"),
                                        })
                              );
                    };

                    // Asigna el evento de "close" para saber cuando se ha cerrado la conexión
                    newSocket.onclose = () => {
                              console.log("Conexión cerrada con el servidor de WebSockets");
                    };


          }




          render() {
                    const dungeonMap = `
                    ##########
                    #........#
                    #..####..#
                    #..####..#
                    #........#
                    ##########
                    `;


                    return <>
                              <SidebarGame
                                        visibleLeft={this.state.visibleLeft}
                                        onClose={() => this.setState({ visibleLeft: false })}
                              />

                              <Menubar
                                        model={[]}
                                        start={() => {
                                                  return (
                                                            <>
                                                                      <img
                                                                                src={this.getIcono()}
                                                                                style={{ width: "10%", cursor: "pointer" }}
                                                                                className="mr-2"
                                                                                onClick={() => {
                                                                                          this.setState({
                                                                                                    visibleLeft: true,
                                                                                          });
                                                                                }}
                                                                      />
                                                                      {localStorage.getItem("username")}
                                                            </>
                                                  );
                                        }}
                              />


                              <div className="terminal-container">
                                        <div className="actions">
                                                  {/* Botones de acciones */}
                                                  <Button disabled={this.state.startGame} onClick={() => { this.start() }}>Comenzar</Button>
                                                  <Button disabled={!this.state.startGame} onClick={() => { this.generateDungeonMap() }}>Ver Mapa</Button>
                                                  <Button disabled={!this.state.startGame}>Mover Izquierda</Button>
                                                  <Button disabled={!this.state.startGame}>Mover Derecha</Button>
                                                  <Button disabled={!this.state.startGame}>Mover Arriba</Button>
                                                  <Button disabled={!this.state.startGame}>Mover Abajo</Button>
                                                  <Button disabled={!this.state.startGame}>Salir</Button>
                                        </div>
                                        <div className="terminal">
                                                  {/* Contenido de la terminal */}
                                                  <div className="row">

                                                            <div className="col-md-6">
                                                                      <Terminal welcomeMessage="Welcome to Dungeons" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                      {this.state.dungeon?.connections && (<DungeonMap bsp={this.state.dungeon} />)}
                                                            </div>


                                                  </div>
                                        </div>

                              </div>




                    </>
          }

}



export default Dungeon;