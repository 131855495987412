
import SoulsImg from "../../assets/img/iconos/SOULS_DROP_ICON.png"

const PacksS1 =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/5ee7cee0-2ead-4f1b-03c4-1d4030b34c00/public";
const Staking =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/14945495-de3e-480a-fb0c-c5fd6b50b600/public";
const Discord =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/756ac249-423b-4e3a-de8a-e37cc23ba500/public";
const Bang =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e4a3a706-392e-4490-f126-f1f882665200/public";
const BangInventory =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/ab9f3f4c-2f0b-4898-d703-5b98b332cf00/public";
const WooPage =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/a784727d-00ca-4c04-fbb6-afc589dcc400/public";
const Merge =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e6f2f750-65f0-4b8e-af6f-6a369f144700/public";
const HivePosh =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9cc13bdf-ff92-4f74-5c7a-432c8ed01800/public";
const dCrops =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e3bd0b0b-092d-4305-624d-aa18e2edc800/public";
const dCity =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/d8ef5152-6987-4939-5a27-16b9690c7700/public";
const HivePizza =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/531ab1ae-d24c-4fb6-c650-50fb24fe3e00/public";
const BlockchainGaming =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9e432158-ea88-4ab2-e57f-f573ce9d2300/public";
const OneUp =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/8173b8c0-d704-455e-1cc9-f0776baada00/public";
const HiveLogo =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2311052a-330c-486b-07e9-7f0806e1fe00/public";
const NFTLogo =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/3ea1d055-2f5c-4cff-7e77-843627d23500/public";
const Peak =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/ce251574-5fbc-44e6-c625-a33249890900/public";
const InfoIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/78e21692-9e78-47d7-4e57-dc2c440d9800/public";
const HKBuds =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/c8ee24a0-bbbd-44d3-6c6a-4dac603d2200/public";
const HKLogo =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2f496f50-ff78-409e-1218-a12e79162300/public";
const PartnersIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/30ad7314-2567-4af2-cb81-8ece1b9a4600/public";
const HiveDappsIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/ef0bdea5-a0e0-403a-289b-268820b3b600/public";
const BangWeaponCrates =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/d28b66cc-a48a-4df4-4b39-e3bf64cd5100/public";
const BangTowersCrates =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/76b617cb-9238-4acd-44a2-6f5a0733d300/public";
const FarmingWarsIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/a834ec72-5074-4e01-c246-2243918fec00/public";
const FarmingWarsPresaleIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/95ee524a-7b14-4acd-2fa4-523d42e81a00/public";
const FarmingWarsInventoryIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9c90e6fb-7cff-4c2e-0616-880936bcf900/public";
const FarmingWarsCraftingIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e1aabe9e-143f-4d08-9ccd-a5f3992abd00/public";
const BigDogBoneIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/33289019-bf49-4604-cfe5-535a93d50b00/public";
const KodIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/1ea7156b-245c-46b5-3651-88b882159200/public";
const InfernalIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/bd53aabb-cc85-45d7-dfc6-819e674e8d00/public";
const QwoynStudiosIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/879e0327-3577-40a9-df0b-c5064db8bd00/public";
const BuyKeysIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/94ffb535-6067-4437-0aca-ba125da4a400/public";
//HIVE DAPPS ICONS
const SplinterLandsIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e822e9e1-45c4-4e74-9743-7edf44b4cb00/public";
const HiveBlogIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/c45b39d1-09e2-4927-1770-f0db952a6700/public";
const NFTShowroomIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/86375719-869f-4210-2c49-9388571c8a00/public";
const ThreeSpeakIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/f2923330-4ef0-4740-d0e4-72a5d69fdf00/public";
const EcencyIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/af04fc1c-59e0-4049-5fdb-d66f83de8400/public";
const LeoFinanceIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/4f723338-b11f-406a-98e5-f8f72a9a2b00/public";
const RabonaIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e9c46acb-bcc0-43cb-f983-32a5edb67b00/public";
const BrewMasterIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/4cddb124-0c6f-4c26-e63d-e39145adc900/public";
const DBuzzIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/1c813017-b133-42b1-c689-9b8fe0817700/public";
const EngageIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/600cbd80-e43a-4ce8-0135-013a6b8d2a00/public";
const TravelFeedIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9d9ff9b1-8853-4194-e681-697ddfce4f00/public";
const TruvvlIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/c70b1b0c-95b8-4705-fd82-c0abd3dff900/public";
const ExxpIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/0c68ad48-bd97-449c-3fa5-a083710a0f00/public";
const ActiFitIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2019779c-afde-47e0-1b97-63b271f59200/public";
const InjiIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/10f0b396-f55a-4d62-6e3f-914bd10cb000/public";
const DLeaseIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/f3f78a53-be8b-4e47-94f3-100981958900/public";
const EngraveIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/689fb397-fb95-4558-002e-0c6014084600/public";
const NFTTunzIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/b143436f-847b-4619-773e-350d91389200/public";
const LucksacksPokerIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/6c3ba901-4029-47cd-909a-6c28fa9a6800/public";
const STEMGeeksIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2f7af89d-0c58-442c-9ece-ebffd0f89c00/public";
const RisingStarIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/0bfd81c1-d5b6-4a46-dcd7-2c1975c6fe00/public";
const VimmIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2347a5be-d2cf-4c3a-20ce-1c3cb6ab8400/public";
const DLuxIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9f8ba560-5965-4207-fbc7-0713117e7200/public";
const HiveBuzzIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/55d8c2fe-a5da-4f85-3347-b22c599fbd00/public";
const HivePunksIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/85ec114c-2c24-455a-85d5-b72094acec00/public";
const LiketuIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/a8aa82bc-ed1d-4143-0127-3ab0afbc1a00/public";
const ApeMiningClubIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/c9449bb7-01d3-4be5-cd39-657556033f00/public";
const SkateHypeIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/08b22d6b-e171-491d-d7ff-c93ef1b0be00/public";
const HiveListIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/e1d256a2-b10d-4f9e-3941-a2c2d10f7000/public";
const WaivioIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/639a5d96-751d-45c5-2957-9e0d1d7c0700/public";
const HiveLandIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/1e5d3382-e27f-4ec3-c790-f55e0a23cd00/public";
const HiveBlocksIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/121b6572-80ea-47f6-e0e3-f7a75b56a800/public";
const HiveBlockExplorerIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/1adf496c-59a2-42a5-ff1e-0c60b08a9e00/public";
const HiveAtAusbitIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2cb40607-9825-4ae8-bbac-2b6ed20c1800/public";
const CryptoShotsIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/5445393f-051d-48b4-73a3-0d2247665600/public";

const DapplrIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/4ff46eac-778d-407a-09c6-d4db41977100/public";
const StemSocialIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/6c94d5d3-d1cd-44d0-79ae-fa4c8cf43000/public";
const QuelloIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/774da9ea-eb17-4185-0bef-1b4108650700/public";
const DiningGiftsIcon =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/7e08014e-c9e2-4228-9da5-414edbb98400/public";

const PizzaDiscord =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/b5b30f9e-4600-456b-8902-afddaaf3c500/public";
const Farm =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/9510b861-19a8-48ed-7303-bdd6227b1400/public";
const Forge =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/f852b8e5-339a-4fea-9b5e-329d3bc81f00/public";
const Rentals =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/0b9841b1-150d-41d0-99fb-554d9d309900/public";
const GitBook =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/f0700f91-accf-4f40-eda4-331c122c1500/public";
const Raids =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/7476cf75-b565-48fd-8169-fca6560ec500/public";
const News =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/772db4c1-ece9-40d0-58df-0325d5b81300/public";
const NFTMart =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/ec92e80b-af29-4dd6-9173-2dd47f15c400/public";
const Packs =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/f5a623d7-18f7-4d4e-0b6c-ab792cd32600/public";
const Stats =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/584a31b7-310d-4434-ad31-e2fcf5752000/public";
const HKLotto =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/06500b7d-d704-48d3-e5d1-08ee2f66c900/public";


const BudsImg = "https://images.hive.blog/p/2bP4pJr4wVimqCWjYimXJe2cnCgnK7tmkKv7Tzqdus4?format=match&mode=fit"



export const DownbarItems = [
  //HASHKINGS
  {
    tittle: "Hashkings",
    img: HKLogo,
    items: [
      {
        img: Farm,
        path: "https://farm.hashkings.app/play",
        name: "/play",
        tittle: "farming",
      },
      {
        img: Forge,
        path: "https://farm.hashkings.app/forge",
        name: "/forge",
        tittle: "forge",
      },
      {
        img: Merge,
        path: "https://farm.hashkings.app/merge",
        name: "/merge",
        tittle: "merge",
      },
      {
        img: Rentals,
        path: "https://farm.hashkings.app/rentals",
        name: "/rentals",
        tittle: "rentals",
      },
      {
        img: Raids,
        path: "https://farm.hashkings.app/raids",
        name: "/raids",
        tittle: "raids",
      },
      {
        img: NFTMart,
        path: "https://nftm.art/buy/hkfarm",
        name: "/nftmart",
        tittle: "nftmart",
      },
      {
        img: Packs,
        path: "https://farm.hashkings.app/avatarsset2",
        name: "/avatarsset2",
        tittle: "g2 packs",
      },
      {
        img: PacksS1,
        path: "https://farm.hashkings.app/avatars",
        name: "/avatars",
        tittle: "g1 packs",
      },
      {
        img: Stats,
        path: "https://stats.hashkings.app",
        name: "/stats",
        tittle: "stats",
      },
    ],
  },
  //BUDS
  {
    tittle: "Buds",
    img: HKBuds,
    items: [
      {
        img: Staking,
        path: "https://farm.hashkings.app/staking",
        name: "/staking",
        tittle: "staking",
      },
      {
        img: BudsImg,
        path: "buds",
        action: (action) => { action() },
        tittle: "Swap Buds"
      }
    ],
  },
  //SOULS
  {
    tittle: "Souls",
    img: SoulsImg,
    items: [
      {
        img: SoulsImg,
        path: "souls",
        action: (action) => { action() },
        tittle: "Swap Souls"
      }
    ],
  },
  //LOTTERY
  {
    tittle: "lottery",
    img: HKLotto,
    path: "https://lotto.hashkings.app/",
  },
  //FARMING WARS
  {
    tittle: "Farming Wars",
    img: FarmingWarsIcon,
    items: [
      {
        img: FarmingWarsPresaleIcon,
        path: "https://farm.hashkings.app/farmingwars?section=presale",
        name: "/presale",
        tittle: "presale",
      },
      {
        img: FarmingWarsInventoryIcon,
        path: "https://farm.hashkings.app/farmingwars?section=inventory",
        name: "/inventory",
        tittle: "inventory",
      },
      {
        img: FarmingWarsCraftingIcon,
        path: "https://farm.hashkings.app/farmingwars?section=craftingpanel",
        name: "/crafting",
        tittle: "crafting",
      },
    ],
  },
  //BANG DEFENSE
  {
    tittle: "Bang Defense",
    img: Bang,
    items: [
      {
        img: Bang,
        path: "https://bangdefense.net/home/index.html",
        name: "/bang",
        tittle: "bang",
      },
      // {
      //   img: BangPresale,
      //   path: "https://presale.bangdefense.net/",
      //   name: "/bang presale",
      //   tittle: "bang presale",
      // },
      {
        img: BangInventory,
        path: "https://bangdefense.net/home/Dashboard.html",
        name: "/inventory",
        tittle: "inventory",
      },
      {
        img: BangWeaponCrates,
        path: "https://bangdefense.net/home/crate/weapons/",
        name: "/weapon crates",
        tittle: "weapon crates",
      },
      {
        img: BangTowersCrates,
        path: "https://bangdefense.net/home/crate/towers/",
        name: "/towers crates",
        tittle: "towers crates",
      },
      {
        img: BuyKeysIcon,
        path: "https://bangdefense.net/home/crate/weapons/buyKeys.html",
        name: "/buy keys",
        tittle: "buy keys",
      },
    ],
  },
  //INFO
  {
    tittle: "Info",
    img: InfoIcon,
    items: [
      {
        img: GitBook,
        path: "https://docs.infernalcoliseum.fun/",
        name: "/gitbook",
        tittle: "Docs",
      },
      {
        img: News,
        path: "https://peakd.com/@infernalcoliseum",
        name: "/news",
        tittle: "news",
      },
      {
        img: Discord,
        path: "https://discord.gg/hpeAX6bzXP",
        name: "/discord",
        tittle: "discord",
      },
    ],
  },
  //HIVE DAPPS
  {
    tittle: "Hive Dapps",
    img: HiveDappsIcon,
    items: [
      {
        img: HiveLogo,
        path: "https://tribaldex.com/trade/BUDS",
        name: "/tribaldex",
        tittle: "tribaldex",
      },
      {
        img: Peak,
        path: "https://peakd.com/@hashkings",
        name: "/peakd",
        tittle: "peakd",
      },
      {
        img: WooPage,
        path: "https://wrestlingorganizationonline.com/",
        name: "/woo Page",
        tittle: "woo page",
      },
      {
        img: HivePosh,
        path: "https://hiveposh.com/",
        name: "/hive posh",
        tittle: "hive posh",
      },
      {
        img: dCrops,
        path: "https://www.dcrops.com/",
        name: "/dcrops",
        tittle: "dcrops",
      },
      {
        img: dCity,
        path: "https://dcity.io/",
        name: "/dCity",
        tittle: "dcity",
      },
      {
        img: BigDogBoneIcon,
        path: "https://www.mundovirtualhive.online/login",
        name: "/big dog bone",
        tittle: "big dog bone",
      },
      {
        img: KodIcon,
        path: "https://www.kingofduels.online/login",
        name: "/king of duels",
        tittle: "king of duels",
      },
      {
        img: SplinterLandsIcon,
        path: "https://splinterlands.com/?ref=hk-nvault",
        name: "/Splinterlands",
        tittle: "Splinterlands",
      },
      {
        img: HiveBlogIcon,
        path: "https://hive.blog/",
        name: "/Hive Blog",
        tittle: "hive blog",
      },
      {
        img: NFTShowroomIcon,
        path: "https://nftshowroom.com/",
        name: "/NFTShowroom",
        tittle: "NFTShowroom",
      },
      {
        img: ThreeSpeakIcon,
        path: "https://3speak.co/",
        name: "/3Speak",
        tittle: "3Speak",
      },
      {
        img: EcencyIcon,
        path: "https://ecency.com/",
        name: "/Ecency",
        tittle: "Ecency",
      },
      {
        img: LeoFinanceIcon,
        path: "https://leofinance.io/",
        name: "/LeoFinance",
        tittle: "LeoFinance",
      },
      {
        img: RabonaIcon,
        path: "https://rabona.io/",
        name: "/Rabona",
        tittle: "Rabona",
      },
      {
        img: BrewMasterIcon,
        path: "https://www.cryptobrewmaster.io/home",
        name: "/BrewMaster",
        tittle: "BrewMaster",
      },
      {
        img: DBuzzIcon,
        path: "https://d.buzz/#/",
        name: "/dbuzz",
        tittle: "dbuzz",
      },
      {
        img: EngageIcon,
        path: "https://engage.hivechain.app/login",
        name: "/Engage",
        tittle: "Engage",
      },
      {
        img: TravelFeedIcon,
        path: "https://travelfeed.io/",
        name: "/TravelFeed",
        tittle: "TravelFeed",
      },
      {
        img: TruvvlIcon,
        path: "https://truvvl.com/",
        name: "/Truvvl",
        tittle: "Truvvl",
      },
      {
        img: ExxpIcon,
        path: "https://exxp.io/",
        name: "/exxp",
        tittle: "exxp",
      },
      {
        img: ActiFitIcon,
        path: "https://actifit.io/",
        name: "/actifit",
        tittle: "actifit",
      },
      {
        img: InjiIcon,
        path: "https://inji.com/",
        name: "/inji",
        tittle: "inji",
      },
      {
        img: DLeaseIcon,
        path: "https://dlease.io/",
        name: "/dlease",
        tittle: "dlease",
      },
      {
        img: EngraveIcon,
        path: "https://dblog.org/",
        name: "/engrave",
        tittle: "engrave",
      },
      {
        img: NFTTunzIcon,
        path: "https://nfttunz.io/",
        name: "/nfttunz",
        tittle: "nfttunz",
      },
      {
        img: LucksacksPokerIcon,
        path: "https://lucksacks.com/",
        name: "/lucksacks poker",
        tittle: "lucksacks poker",
      },
      {
        img: STEMGeeksIcon,
        path: "https://stemgeeks.net/",
        name: "/stemgeeks",
        tittle: "stemgeeks",
      },
      {
        img: RisingStarIcon,
        path: "https://www.risingstargame.com/",
        name: "/risingstar",
        tittle: "risingstar",
      },
      {
        img: VimmIcon,
        path: "https://www.vimm.tv/",
        name: "/vimm",
        tittle: "vimm",
      },
      {
        img: DLuxIcon,
        path: "https://www.dlux.io/",
        name: "/dlux",
        tittle: "dlux",
      },
      {
        img: HiveBuzzIcon,
        path: "https://hivebuzz.me/",
        name: "/hivebuzz",
        tittle: "hivebuzz",
      },
      {
        img: HivePunksIcon,
        path: "https://punks.usehive.com/",
        name: "/hivepunks",
        tittle: "hivepunks",
      },
      {
        img: LiketuIcon,
        path: "https://www.liketu.org/",
        name: "/liketu",
        tittle: "liketu",
      },
      {
        img: ApeMiningClubIcon,
        path: "https://apemining.club/",
        name: "/apeminingclub",
        tittle: "apeminingclub",
      },
      {
        img: SkateHypeIcon,
        path: "https://www.skatehype.com/",
        name: "/skatehype",
        tittle: "skatehype",
      },
      {
        img: HiveListIcon,
        path: "https://hivelist.io/",
        name: "/hivelist",
        tittle: "hivelist",
      },
      {
        img: WaivioIcon,
        path: "https://www.waivio.com/",
        name: "/waivio",
        tittle: "waivio",
      },
      {
        img: HiveLandIcon,
        path: "https://hiveland.live/",
        name: "/hiveland",
        tittle: "hiveland",
      },
      {
        img: HiveBlocksIcon,
        path: "https://hiveblocks.com/",
        name: "/hiveblocks",
        tittle: "hiveblocks",
      },
      {
        img: HiveBlockExplorerIcon,
        path: "https://hiveblockexplorer.com/",
        name: "/hiveblocksexplorer",
        tittle: "hiveblocksexplorer",
      },
      {
        img: HiveAtAusbitIcon,
        path: "https://hive.ausbit.dev/",
        name: "/hiveatausbit",
        tittle: "hiveatausbit",
      },
      {
        img: CryptoShotsIcon,
        path: "https://www.crypto-shots.com/",
        name: "/crypto shots",
        tittle: "crypto shots",
      },
      {
        img: DapplrIcon,
        path: "https://dapplr.in",
        name: "/dapplr",
        tittle: "dapplr",
      },
      {
        img: QuelloIcon,
        path: "http://ww16.quello.io/?sub1=20221104-2236-3487-be15-2852d263d291",
        name: "/quello.io",
        tittle: "quello.io",
      },
      {
        img: StemSocialIcon,
        path: "https://stem.openhive.network/",
        name: "/stemsocial",
        tittle: "stemsocial",
      },
      {
        img: DiningGiftsIcon,
        path: "https://www.dining.gifts/",
        name: "/dining gifts",
        tittle: "dining gifts",
      },
    ],
  },
  //PARTNERS
  {
    tittle: "Partners",
    img: PartnersIcon,
    items: [
      {
        img: PizzaDiscord,
        path: "https://discord.gg/jwNTdE5Urv",
        name: "/pizza discord",
        tittle: "pizza discord",
      },
      {
        img: HivePizza,
        path: "https://hive.pizza/",
        name: "/hivepizza",
        tittle: "hive pizza",
      },
      {
        img: OneUp,
        path: "https://www.1up.zone/",
        name: "/oneup",
        tittle: "one up",
      },
      {
        img: BlockchainGaming,
        path: "https://blockchaingaming.com/",
        name: "/blockchaingaming",
        tittle: "blockchain gaming",
      },
      {
        img: NFTLogo,
        path: "https://nftm.art/inventory/hkfarm",
        name: "/nftmart",
        tittle: "nftmart",
      },
      {
        img: QwoynStudiosIcon,
        path: "https://cosmic-horizon.com/",
        name: "/qwoyn studios",
        tittle: "qwoyn studios",
      },
    ],
  },
];
