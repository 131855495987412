import React, { Component } from "react";
import ImageMapper from "react-img-mapper";

import Map from "../assets/img/Artanes.png";
import proj4 from 'proj4';
import areasFarming from "./datosFarming";
import socketIOClient from "socket.io-client";
import { Dropdown } from 'primereact/dropdown';

const ENDPOINT = "https://farminglandsinfernalbackend.herokuapp.com/";


export default class T extends Component {




          getTipPosition(area) {
                    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
          }

          enterArea(area) {
                    this.setState({ hoveredArea: area });
          }

          onCountryChange = (e) => {
                    this.setState({ selectedCountry: e.value });
          };


          constructor(props) {
                    super(props);
                    this.state = {
                              mapName: "Alexandreia",
                              selectedCountry: "",
                              auctions: [],
                              map_x: {
                                        name: "MAP",
                                        areas: areasFarming
                              }
                    };

          }


          leaveArea(area) {
                    this.setState({ hoveredArea: false });
          }


          render() {

                    const dropdownOptions = this.state.auctions.map((country) => ({
                              label: country.land.COUNTRY,
                              value: country.land.COUNTRY,
                    }));


                    return <><div className="container"><div className="row align-items-center justify-content-center">

                              <div className="col-md-6 " >
                                        <h1>{this.state.selectedCountry}</h1>
                                        <ImageMapper
                                                  onMouseLeave={(area) => this.leaveArea(area)}
                                                  src={Map}
                                                  map={this.state.map_x}
                                                  onClick={(area, index, e) => {

                                                  }}
                                                  onMouseEnter={(area) => this.enterArea(area)}
                                                  responsive={true}
                                                  parentWidth={window.innerWidth / 2.5}
                                        />
                              </div>


                              <div className="col-md-6">
                                        <Dropdown
                                                  value={this.state.selectedCountry}
                                                  options={dropdownOptions}
                                                  onChange={this.onCountryChange}
                                                  placeholder="Select Country"
                                        />


                                        <div>
                                                  <h1>Total Resources</h1>
                                                  <p>Fish: 0</p>
                                                  <p>Fruits and Vegetables: 0</p>
                                                  <p>Minerals: 0</p>
                                        </div>

                              </div>

                    </div></div>


                              {this.state.hoveredArea && (
                                        <span
                                                  className="tooltips"
                                                  style={{ ...this.getTipPosition(this.state.hoveredArea) }}
                                        >
                                                  {this.state.hoveredArea && this.state.hoveredArea.name}
                                        </span>
                              )}
                    </>
          }



          componentDidMount() {
                    /*
                    console.log("conectando socket", ENDPOINT)
                    const socket = socketIOClient(ENDPOINT);
                    socket.on("auctions", (mensaje) => {

                              console.log(mensaje);
                              this.setState({
                                        auctions: JSON.parse(mensaje),
                              });
                    });*/
          }

}


