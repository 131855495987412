export default function Component() {
          const datosUsuarios = [
                    { user: "miketronnn", souls: 755.30, buds: 40243.99, raids: 7004 },
                    { user: "drakienrising", souls: 611.43, buds: 32578.46, raids: 6466 },
                    { user: "alicia2022", souls: 503.53, buds: 26829.32, raids: 6015 },
                    { user: "emic", souls: 431.60, buds: 22996.56, raids: 4368 },
                    { user: "cazando", souls: 359.67, buds: 19163.80, raids: 2628 },
                    { user: "lightbruce17", souls: 287.73, buds: 15331.04, raids: 694 },
                    { user: "blockchainbeetle", souls: 215.80, buds: 11498.28, raids: 426 },
                    { user: "emichain", souls: 179.83, buds: 9581.90, raids: 426 },
                    { user: "agente44", souls: 143.87, buds: 7665.52, raids: 414 },
                    { user: "s3rg4", souls: 107.90, buds: 5749.14, raids: 96 },
          ];

          const datosGuilds = [
                    { guild: "Los KuAks", souls: 4795.56, buds: 255517.37 },
                    { guild: "Lynliss", souls: 2877.33, buds: 153310.42 },
                    { guild: "The Byte Cave", souls: 1918.22, buds: 102206.95 },
                    { guild: "Omni Knights", souls: 1438.67, buds: 76655.21 },
                    { guild: "Hive-Founders", souls: 959.11, buds: 51103.47 },
          ];

          return (
                    <div className="container mx-auto px-4 py-8">
                              {/* Mensaje en Español */}
                              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8" role="alert">
                                        <p className="font-bold">Fin de Temporada</p>
                                        <p>
                                                  La temporada ha finalizado. Gracias a todos nuestros seguidores por su apoyo constante.
                                                  El juego regresa pronto para iniciar una nueva temporada.
                                                  Por favor, contesten nuestra <a href="https://forms.gle/Sne5JMsYmnrmtSRK6" className="underline">encuesta</a>.
                                        </p>
                              </div>

                              {/* Mensaje en Inglés */}
                              <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8" role="alert">
                                        <p className="font-bold">End of Season</p>
                                        <p>
                                                  The season has ended. Thank you to all our supporters for your continuous support.
                                                  The game will return soon to start a new season.
                                                  Please fill out our <a href="https://forms.gle/Sne5JMsYmnrmtSRK6" className="underline">survey</a>.
                                        </p>
                              </div>

                              {/* Tabla de Usuarios */}
                              <table className="min-w-full bg-white mb-8">
                                        <caption className="text-lg font-semibold mb-2">Usuarios - Resumen de la Temporada</caption>
                                        <thead className="bg-gray-200">
                                                  <tr>
                                                            <th className="w-1/4 py-2 px-4 text-left">Usuario</th>
                                                            <th className="w-1/4 py-2 px-4 text-left">Souls</th>
                                                            <th className="w-1/4 py-2 px-4 text-left">Buds</th>
                                                            <th className="w-1/4 py-2 px-4 text-right"># of Raids</th>
                                                  </tr>
                                        </thead>
                                        <tbody>
                                                  {datosUsuarios.map((dato, index) => (
                                                            <tr key={index} className="border-b">
                                                                      <td className="py-2 px-4">{dato.user}</td>
                                                                      <td className="py-2 px-4">{dato.souls}</td>
                                                                      <td className="py-2 px-4">{dato.buds}</td>
                                                                      <td className="py-2 px-4 text-right">{dato.raids}</td>
                                                            </tr>
                                                  ))}
                                        </tbody>
                              </table>

                              {/* Tabla de Gremios */}
                              <table className="min-w-full bg-white">
                                        <caption className="text-lg font-semibold mb-2">Gremios - Resumen de la Temporada</caption>
                                        <thead className="bg-gray-200">
                                                  <tr>
                                                            <th className="w-1/3 py-2 px-4 text-left">Gremio</th>
                                                            <th className="w-1/3 py-2 px-4 text-left">Souls</th>
                                                            <th className="w-1/3 py-2 px-4 text-left">Buds</th>
                                                  </tr>
                                        </thead>
                                        <tbody>
                                                  {datosGuilds.map((guild, index) => (
                                                            <tr key={index} className="border-b">
                                                                      <td className="py-2 px-4">{guild.guild}</td>
                                                                      <td className="py-2 px-4">{guild.souls}</td>
                                                                      <td className="py-2 px-4">{guild.buds}</td>
                                                            </tr>
                                                  ))}
                                        </tbody>
                              </table>
                    </div>
          );
}
