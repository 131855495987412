import React, { Component } from "react";
import Swal from "sweetalert2";
import { Dialog } from "primereact/dialog";
import utils from "../../utils";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AdSenseAdsB from "../extras/AdsenseAdsB";
import { isMobile } from 'react-device-detect';

const rareza = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public<",
  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

let iconoPower =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be578d7d-cbe7-4133-62d7-e881e6086400/public";



class NftsToRaids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nfts: [],
      selectedCities1: [],
    };

    this.countryTemplate = this.countryTemplate.bind(this);
    this.sendNfts = this.sendNfts.bind(this);
    this.getTotalPower = this.getTotalPower.bind(this);
    this.getRaidPowerImg = this.getRaidPowerImg.bind(this);
  }

  countryTemplate(country) {
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt="RaidPower"
          src={rareza[country.properties.p1]}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="image-text">
          {country.properties.p1} - {this.getRaidPower(country)}{" "}
        </span>
      </div>
    );
  }

  getImage(option) {
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt={option.properties.p1}
          src={rareza[option.properties.p1]}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
      </div>
    );
  }

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />

        <Dialog
          header={"Send nfts to battle against " + this.props.datosZona.boss}
          visible={this.props.visible}
          style={{ width: "80vw" }}
          resizable
          maximizable
          maximized={isMobile ? true : false}
          onHide={() => {
            this.props.onHide();
          }}
        >
          <h4>Select NFT</h4>
          <MultiSelect
            value={this.state.selectedCities1}
            options={this.state.nfts}
            onChange={(e) => {
              if (this.state.selectedCities1.length < 3) {
                this.setState({ selectedCities1: e.value.slice(0, 3) });
              } else {
                alert("max 3 nfts for each raid");
                this.setState({ selectedCities1: e.value.slice(0, 3) });
              }
            }}
            optionLabel="properties.p1"
            filter
            itemTemplate={this.countryTemplate}
            placeholder={isMobile ? "Nfts" : "select the nfts that will enter the battle"}
            virtualScrollerOptions={{ itemSize: 10 }}
          />

          <h4>Nfts to add</h4>

          <DataTable
            value={this.state.selectedCities1}
            responsiveLayout="scroll"
          >
            <Column field="_id" header="ID" sortable></Column>
            <Column field="properties.p1" header="Name" sortable></Column>
            <Column
              field={this.getRaidPowerImg}
              header={this.RaidPowerImage}
              sortable
            ></Column>
            <Column field={this.getImage} sortable></Column>
          </DataTable>

          <h4>List of nfts on this raid</h4>



          <Button
            onClick={() => {
              this.sendNfts();
            }}
            label="Set Nfts"
            icon="pi pi-plus"
            className="mr-2"
          />


        </Dialog>
      </>
    );
  }

  getRaidPower(nft) {
    let bonus = false;

    if (
      (this.props.area.side == "demon" &&
        nft.properties.p1 == "OZARK - NIGHTMARE KING") ||
      (this.props.area.side == "angel" &&
        nft.properties.p1 == "IRIN - THE WAR QUEEN")
    ) {
      bonus = true;
    }

    let atk = parseInt(nft?.properties?.p4);
    let def = parseInt(nft?.properties?.p5);
    let critic = parseInt(nft?.properties?.p6);
    if (!bonus) {
      return atk * (critic * 0.2) + def * 3;
    } else {
      return (atk * (critic * 0.2) + def * 3) * 1.1;
    }
  }

  RaidPowerImage() {
    return (
      <img
        alt="RaidPower"
        src={iconoPower}
        width={32}
        style={{ verticalAlign: "middle" }}
      />
    );
  }

  getRaidPowerImg(nft) {
    //console.log("nft",nft)
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt="RaidPower"
          src={iconoPower}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="image-text">{this.getRaidPower(nft)}</span>
      </div>
    );
  }

  getTotalPower() {
    let totalPower = 0;
    for (let i = 0; i < this.state.selectedCities1.length; i++) {
      totalPower += this.getRaidPower(this.state.selectedCities1[i]);
    }
    return totalPower;
  }

  sendNfts() {
    if (this.state.selectedCities1.length <= 0) {
      alert("u need add nfts to battle");
      return;
    }
    Swal.fire({
      title: "Sending nfts...",
      text: "total power : " + this.getTotalPower(),
      icon: "info",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: async () => {
        Swal.showLoading();
        //onsole.log("enviando", this.props);

        let nftsAEnviar = this.state.selectedCities1.map((e) => {
          return {
            id: "" + e._id,
            name: e.properties.p1,
            RaidPower: "" + this.getRaidPower(e),
          };
        });

        utils
          .setNftsToRaid(
            this.props.datosZona.territorio,
            this.formatearParaEnviar(nftsAEnviar)
          )
          .then((e) => {
            Swal.close();

            this.toast.show([
              {
                severity: "info",
                summary: e.data.setNftsToRaid.success ? "Success" : "Error",
                detail: e.data.setNftsToRaid.message,
                life: 12000,
              },
            ]);
          });
      },
    });
  }

  formatearParaEnviar(nfts) {
    let formato = "[";

    for (let i = 0; i < nfts.length; i++) {
      formato += "{";
      formato += 'id:\\"' + nfts[i].id + '\\",';
      formato += 'name:\\"' + nfts[i].name + '\\",';
      formato += 'RaidPower:\\"' + nfts[i].RaidPower + '\\"';
      formato += "},";
    }

    formato = formato.substring(0, formato.length - 1);

    formato += "]";
    return formato;
  }

  componentDidMount() {
    Swal.fire({
      title: "Getting nfts...",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();

        if (this.props.datosZona.usuarios) {
          // console.log("datos de props",this.props.datosZona?.nftsAllraids)
          let mynfts = this.props.datosZona?.nftsAllraids;

          utils.getAllNftsConquers().then((data) => {
            this.setState(
              {
                nfts: removeArrayIdenticIds(
                  data.sort((a, b) => {
                    return this.getRaidPower(b) - this.getRaidPower(a);
                  }),
                  mynfts
                ),
              },
              () => {
                Swal.close();
              }
            );
          });
        }
      },
    });
  }
}

//remove array identic ids
function removeArrayIdenticIds(arrayresult, array) {
  //console.log(arrayresult, array);
  let result = [];
  for (let i = 0; i < arrayresult.length; i++) {
    let existe = false;
    for (let j = 0; j < array.length; j++) {
      if (arrayresult[i]._id == parseInt(array[j].id)) {
        existe = true;
        break;
      }
    }
    if (!existe) {
      result.push(arrayresult[i]);
    }
  }
  return result;
}

export default NftsToRaids;
