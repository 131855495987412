import React, { Component } from "react";
import Swal from "sweetalert2";
import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";

import utils from "../utils";

import { Button } from "primereact/button";

const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

class ListItemIventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nfts: [],
    };

    this.getAffinity = this.getAffinity.bind(this);
    this.rederButton = this.rederButton.bind(this);
    this.GetPrice = this.GetPrice.bind(this);
  }
  render() {
    let h = this.props.sellNft + " On " + this.props.buyinOn;
    return (
      <Dialog
        header={h}
        visible={this.props.displayBasic4}
        style={{ width: "90vw" }}
        onHide={() => {
          this.props.onHide();
        }}
      >
        <div className="card">
          <DataTable
            value={this.props.itemOnRender.filter((nft) => {
              if (nft.properties.p8 == "collector") {
                return true;
              }
              return parseInt(nft.properties.p4) > 0;
            })}
            paginator
            className="p-datatable-customers"
            rows={10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            rowHover
            filterDisplay="menu"
            loading={this.state.loading}
            responsiveLayout="scroll"
            scrollable
            scrollHeight="400px"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            <Column
              field="properties.p1"
              header="Name"
              style={{ minWidth: "14rem" }}
            />
            <Column
              field="properties.p2"
              header="Rarity"
              style={{ minWidth: "14rem" }}
            />
            <Column
              field="properties.p7"
              header={this.getAffinity}
              style={{ minWidth: "14rem" }}
              body={this.rederButton}
            />
            <Column
              field="properties.p3"
              header={this.GetImage("HEALTH")}
              style={{ minWidth: "14rem" }}
            />

            <Column
              field="properties.p4"
              header={this.GetImage("ATTACK")}
              style={{ minWidth: "14rem" }}
            />

            <Column
              field="properties.p5"
              header={this.GetImage("DEFENSE")}
              style={{ minWidth: "14rem" }}
            />

            <Column
              field="properties.p6"
              header={this.GetImage("CRITICAL")}
              style={{ minWidth: "14rem" }}
            />

            <Column style={{ minWidth: "14rem" }} />
          </DataTable>
        </div>
      </Dialog>
    );
  }

  GetPrice(item) {
    let itemToBuy = this.props.itemOnRender.find(
      (x) => parseInt(x.nftId) === item._id
    );

    return itemToBuy.price;
  }

  rederButton(item) {
    console.log("item a rendereizar boton", item);

    let itemToBuy = this.props.itemOnRender.find(
      (x) => parseInt(x._id) === item._id
    );

    return (
      <Button
        label="Set to sell"
        icon="pi pi-shopping-cart"
        className="mr-2 p-button-danger"
        onClick={() => {
          this.props.buyAction(itemToBuy);
        }}
      />
    );
  }

  getAffinity(nft) {
    return this.GetImage("AFFINITY", nft.props.value[0]?.properties?.p7);
  }

  GetImage(type, data) {
    switch (type) {
      case "HEALTH":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
            style={{ maxWidth: "30%" }}
            alt="HEALTH"
          />
        );
      case "ATTACK":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
            style={{ maxWidth: "30%" }}
            alt="ATTACK"
          />
        );
      case "DEFENSE":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
            style={{ maxWidth: "30%" }}
            alt="DEFENSE"
          />
        );
      case "CRITICAL":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
            style={{ maxWidth: "30%" }}
            alt="CRITICAL"
          />
        );
      case "AFFINITY":
        return (
          <img
            src={SKILL["HEAVENLY"]}
            style={{ maxWidth: "30%" }}
            alt="AFFINITY"
          />
        );
    }
  }

  componentDidMount() {
    this.setState({ loading: false });
  }
}

export default ListItemIventory;
