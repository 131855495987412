import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import React, { Component } from "react";
import Shine from "../assets/img/SHINE.webp";

class GameNavbar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default GameNavbar;
