import React, { Component } from "react";
import { DataScroller } from "primereact/datascroller";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import socketIOClient from "socket.io-client";
import LandSelectionModal from "./LandSelectionModal";

import utils from "../../utils";

import imgAngel from "../../assets/img/SIDEOFANGELS.png";
import imgDemon from "../../assets/img/SIDEOFDEMONS.png";
import { gql } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';

const MATCH_FOUND_SUBSCRIPTION = gql`
  subscription {
    matchFound {
      player1 {
        id
        name
        league
        characters {
          attack
          defense
          life
          critical
        }
      }
      player2 {
        id
        name
        league
        characters {
          attack
          defense
          life
          critical
        }
      }
    }
  }
`;

const NFTCard = ({ nft }) => {
  return (
    <div className="nft-card">
      <p style={{ fontWeight: "bold" }}>{nft.name}</p>
      <p>Type: {nft.type}</p>
      <p>Attack: {nft.attack}</p>
      <p>Defense: {nft.defense}</p>
      <p>Life: {nft.life}</p>
      <p>Critical: {nft.critical}</p>
      <p>ID: {nft.id}</p>
    </div>
  );
};
class Npvp extends Component {
  constructor(props) {
    super(props);
    // Otras inicializaciones si es necesario
    this.state = {
      parcelas: [],
      parcelasSelected: "",
      loading: false,
      landData: [],
      menuLeft: React.createRef(),
      playerInfo: [],
      setNfts: false,
      searching: false
    };
    this.subscription = null;
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  // Función para cerrar el modal
  closeModal = () => {
    this.setState({ setNfts: false });
  };

  getImage = () => {
    let username = JSON.parse(localStorage.getItem("userData"));
    if (username.path == "NOGROD") {
      return imgAngel;
    } else {
      return imgDemon;
    }

  }


  getUsername = () => {
    let username = JSON.parse(localStorage.getItem("userData"));
    return username.username;

  }



  render() {


    return (
      <Dialog
        style={{ width: "80vw" }}
        visible={this.props.visible}
        onHide={() => this.props.close()}
      >

        <div class="container">
          <div class="card">
            <div class="image-container">
              <p>{this.getUsername()}</p>
              <img src={this.getImage()} alt="Ninja Blaze" />


              <p>You have {this.state.playerInfo.length} nfts</p>
              <p>{this.state.playerInfo.length == 0 && ("please set nfts on inventory page")}</p>
              {this.state.playerInfo.map(element => {
                return <NFTCard key={element.id} nft={element} />
              })}

            </div>
            <div class="form-container">
              <h2>Welcome To Warrior Challenge</h2>

              <div class="tabcontent">
                <button class="wallet-button">Metamask</button>
                <button class="wallet-button">Keplr</button>
                <button class="wallet-button">Leap</button>
                <button class="wallet-button">Cosmostation</button>
                <button class="wallet-button">Wallet Connect</button>
              </div>


              {this.state.playerInfo.length != 0 && this.state.searching == false && (<button class="connect-wallet-button" onClick={e => { this.searchBattle() }}>Search Battle</button>)}
              {this.state.playerInfo.length != 0 && this.state.searching == true && (<button class="connect-wallet-button">Searching...</button>)}

            </div>
          </div>
        </div>

      </Dialog>
    );
  }

  searchBattle() {
    this.setState({ searching: true })
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  joinQueue {\\n    success\\n    message\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        Dnt: "1"
      },
      method: "POST"
    }).then(response => response.json()).then(response => {
      console.log("🚀 ~ Npvp ~ searchBattle ~ response:", response)

      if (response.data.joinQueue.success == true) {
        this.props.close();
        Swal.fire({
          icon: "success",
          text: response.data.joinQueue.message == "matchMakerResponse" ? "you were successfully added to the queue, you can continue playing until you find an opponent" : response.data.joinQueue.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.joinQueue.message,
        });
      }
      this.setState({ searching: false })
    })
  }

  soulsHora() {
    let lands = {
      "Kalamar Island": 5,
      Sylvana: 5,
      Rakamah: 5,
      Rurkoc: 10,
      "Ixian Desert": 10,
      "South Kyme": 15,
      Xaian: 15,
      "Dubkovian Union": 25,
      Nilx: 25,
      Tsuki: 37.5,
      "North  Kyme": 37.5,
      Shexai: 45,
      Vereya: 45,
      Fjelldal: 50,
      Tsune: 50,
      Alzokh: 62.5,
      Lugmoor: 62.5,
      "Lio Kai Island": 5,
      Alexandreia: 5,
      Morntaric: 5,
      Marhelm: 10,
      Santana: 10,
      Castilla: 15,
      Mugmodror: 15,
      Dornhan: 25,
      Hertford: 25,
      Berford: 37.5,
      "Evraland Forest": 37.5,
      Goiania: 45,
      Freville: 45,
      Frostland: 50,
      Tamarand: 50,
      "Honk Hill": 62.5,
      Bayfrost: 62.5,
      Froznorth: 62.5,
    };

    return lands[this.state.parcelasSelected.COUNTRY] / 24;
  }

  getTotalPower(type) {
    return this.state.playerInfo.cards[type].reduce((a, b) => {
      {
        if (type == "attackers") {
          return a + b.ATTACK;
        }

        if (type == "defenders") {
          return a + b.DEFENSE;
        }
      }
    }, 0);
  }

  img(name) {
    const images = {
      PURPPLEKONG:
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
      "PURPPLEKONG specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

      "ALYRAN - THE MAD ELF":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
      "ALYRAN - THE MAD ELF special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
      "ALYRAN - THE MAD ELF specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

      "VASS - THE HERALD OF FALLEN":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
      "VASS - THE HERALD OF FALLEN special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
      "VASS - THE HERALD OF FALLEN specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
      "HANZO - THE SOULESS NINJA":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
      "HANZO - THE SOULESS NINJA special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
      "HANZO - THE SOULESS NINJA specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

      "ENNORE KILNAMA":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
      "ENNORE KILNAMA specialp":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
      "ENNORE KILNAMA special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
      "ENNORE KILNAMA specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

      "ANDRAS - THE WOLF SWORD":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
      "ANDRAS - THE WOLF SWORD special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
      "KAYN - THE WILL OF DEATH":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
      "GOLBIN FARMER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
      "GOLBIN FISHER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
      "GOLBIN MINER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
      "HUMAN FARMER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
      "HUMAN FISHER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
      "HUMAN MINER":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
      "KAYN - THE WILL OF DEATH special":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
      "KAYN - THE WILL OF DEATH specialc":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
      "OZARK - NIGHTMARE KING":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
      "IRIN - THE WAR QUEEN":
        "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
    };

    return images[name];
  }

  getCardImage(name) {
    return this.img(name);
  }


  readers() {

  }


  subscribeToMatchFound() {

    console.log(this.props);
    this.props.client
      .subscribe({
        query: MATCH_FOUND_SUBSCRIPTION,
      })
      .subscribe({
        next: ({ data }) => {
          console.log("data de la subscription", data);
          const playerInfo = [data.matchFound.player1, data.matchFound.player2];
          this.setState({ playerInfo, loading: false, error: null });
        },
        error: (error) => {
          this.setState({ loading: false, error });
          console.error('Subscription error:', error);
        },
      });
  }

  componentDidMount() {

    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.subscribeToMatchFound();

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getNftsOnPvp {\\n    success\\n    message\\n    data {\\n      id\\n      name\\n      life\\n      attack\\n      defense\\n      critical\\n      type\\n    }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        Authorization: "Bearer " + localStorage.getItem("token"),
        Dnt: "1"
      },
      method: "POST"
    }).then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ playerInfo: data.data.getNftsOnPvp.data });
        Swal.close();
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });



  }

  getImage(item) {
    return `https://images.hive.blog/u/${item.username}/avatar`;
  }


  itemTemplate(item) {
    console.log("RENDERIZANDO", item);
    return (
      <div className="datascroller-item">
        {/* Imagen a la izquierda */}
        <div className="image-container">
          <img src={this.getImage(item)} alt={item.username} />
        </div>

        {/* Contenido a la derecha */}
        <div className="info-container">
          <div className="title">{item.username.toUpperCase()}</div>
          <div className="stat-row">
            <div>Atack power:</div>
            <div>
              {item.cards.attackers.reduce((a, b) => {
                return a + b.ATTACK;
              }, 0)}
            </div>
          </div>
          <div className="stat-row">
            <div>Defensa power:</div>
            <div>
              {item.cards.defenders.reduce((a, b) => {
                return a + b.DEFENSE;
              }, 0)}
            </div>
          </div>
          <div className="stat-row">
            <div>Critical attack:</div>
            <div>
              {item.cards.attackers.reduce((a, b) => {
                return a + b.CRITICAL;
              }, 0)}
            </div>
          </div>
          <div className="stat-row">
            <div>Souls:</div>
            <div>{item.tokens}</div>
          </div>
          <div className="stat-row">
            <div>
              <Button>attack</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(Npvp);
