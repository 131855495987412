import React, { useState, useEffect } from "react";

import ALEXANDREIA from "../../assets/3d/ALEXANDREIA.glb";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
export default function Auction({
  username,
  description,
  currentPrice,
  deadline,
}) {
  const [timeLeft, setTimeLeft] = useState(deadline);

  useEffect(() => {
    console.log(timeLeft);
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const deadLine = new Date(deadline).getTime();

      const diff = deadLine - now;

      const seconds = Math.floor(diff / 1000) % 60;
      const minutes = Math.floor(diff / 1000 / 60) % 60;
      const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      setTimeLeft(
        days +
          " days " +
          hours +
          " hours " +
          minutes +
          " minutes " +
          seconds +
          " sends left"
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  const deposit = () => {
    let amount = 0;

    try {
      amount = parseFloat(prompt("Amount", currentPrice + currentPrice * 0.1));
    } catch (e) {
      amount = "";
    }
    console.log(amount);
    if (isNaN(amount)) {
      Swal.fire({
        title: "set a valid number please",
      });
      return;
    }
    const keychain = window.hive_keychain;
    keychain.requestSendToken(
      localStorage.getItem("username"),
      "infernalcoliseum",
      amount.toFixed(3),
      "auction",
      "SOULS",
      (response) => {
        Swal.fire({
          title: response.message,
        });
      }
    );
  };

  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-md-6">
          <div className="card-image">
            <model-viewer
              style={{ height: "50vh", width: "100%" }}
              src={ALEXANDREIA}
              ar
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              poster="poster.webp"
              shadow-intensity="1"
              auto-rotate
            >
              <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
              </div>
            </model-viewer>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-description">
            <h3 style={{ color: "white" }}>{description}</h3>
            <p style={{ color: "white" }}>Actual price: {currentPrice}</p>
            <p style={{ color: "white" }}>Land lord: {username}</p>
            <p style={{ color: "white" }}>Time left: {timeLeft}</p>
          </div>
          <div className="card-button">
            <Button onClick={(e) => deposit()}>Deposit</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
