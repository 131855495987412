import React from "react";

class CountdownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { deadline } = this.props;
      const time = Date.parse(deadline) - Date.parse(new Date());
      if (time < 0) {
        clearInterval(this.interval);
      } else {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        this.setState({ days, hours, minutes, seconds });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "baseline",
        }}
      >
        <p>
          {days} days {hours} hours {minutes} minutes {seconds} seconds
        </p>
      </div>
    );
  }
}

export default CountdownTimer;
