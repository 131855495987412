import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import ORZAKINTRO_1 from "../../assets/video/IRINTEXTO.mp4";
import HivePay from "../../utils/HivePay";

class Information extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customIcons = (
      <React.Fragment>
        <Button id="pbtn" onClick={() => this.dontDisplayMore()}>
          Dont display more
        </Button>

        <Button id="pbtn" onClick={() => this.buy()}>
          Buy
        </Button>
        <>
          <br></br>
        </>
      </React.Fragment>
    );
    return (
      <div className="card flex justify-content-center">
        <Sidebar
          icons={customIcons}
          fullScreen
          visible={this.props.visible}
          id="sbar"
          onHide={() => this.props.close()}
        >
          <div className="containerI">
            {/* */}
            <video autoPlay={true} muted loop style={{ width: "100%" }}>
              <source muted src={ORZAKINTRO_1} type="video/mp4" />
            </video>

          </div>
        </Sidebar>
      </div>
    );
  }

  buy() {
    const HP = new HivePay("infernalcoliseum");
    HP.setItemName("Event card")
      .setItemDescription("OZARK")
      .setMerchant_email("blackmirague@gmail.com")
      .setNotifyUrl("https://guerrerosconsultoresas.com.co/IF/events.php")
      .setReturnUrl("https://game.infernalcoliseum.fun")
      .setAmount(2.06418)
      .setQuantity(1)
      .setDiscountAmount(0)
      .setDiscountToken("SOULS")
      .setBaseCurrency()
      .setPayCurrencies(["HIVE,SWAP.HIVE,HBD"]);

    HP.submit("https://hivepay.io/pay/");
  }

  dontDisplayMore() {
    localStorage.setItem("displayMore", "false");
    this.props.close();
  }
}

export default Information;
