import React, { Component } from "react";
import "./store.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";

import { Tooltip } from "primereact/tooltip";
//import Logo from "../../assets/img/LOGO.png";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

const Logo =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e04892d9-7483-4aec-d720-d9b53b57f600/public";

const cofreLegendario =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ad8d4cc-9dc0-401d-5288-6e372941d100/public";

const cofreDesafortunado =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/61da1083-fa2b-4492-0c5e-fdfbc0875e00/public";

const cofrePlateado =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/bc791049-0ea3-4a61-4f25-0d6157412100/public";

const cofreEpico =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1167d8d1-da2c-4621-9c18-b0d89471e400/public";

const cofreComun =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/20812a74-f347-4569-9b9b-fdc787a5cb00/public";

const cofreRaro =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6ad76654-9cc1-45a3-2cb9-01741f8a2800/public";

class Store extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Initial: 0,
      Common: 0,
      Rare: 0,
      Epic: 0,
      Silver: 0,
      Legendary: 0,
      visibleLeft: false,
      sellData: []
    };
  }
  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }
  render() {
    let { visibleLeft } = this.state;





    const end = <img
      style={{
        textAlign: "center",
        width: "15%",
      }}
      src={Logo}
      onClick={() => this.setState({ visibleLeft: true })}
    />


    const tokenAmountSummary = this.state.sellData.reduce((acc, item) => {
      const token = item.token;
      const amount = parseFloat(item.token_amount);
      acc[token] = (acc[token] || 0) + amount;
      return acc;
    }, {});

    const footer = (
      <div>
        <span>Total Token Amount: </span>
        {Object.entries(tokenAmountSummary).map(([token, amount]) => (
          <span key={token}>{`${token}: ${amount.toFixed(3)} `}</span>
        ))}
      </div>
    );

    const dialogContent = (
      <div>
        <DataTable value={this.state.sellData} footer={footer}>
          <Column field="pack" header="Pack" />
          <Column field="username" header="Username" />
          <Column field="token_amount" header="Token Amount" />
          <Column field="token" header="Token" />
          <Column field="status" header="Status" />
        </DataTable>
      </div>
    );




    return (
      <>

        <Dialog
          header="Details"
          visible={this.state.displayModal}
          style={{ width: '50vw' }}
          onHide={() => { this.setState({ displayModal: false }) }}
        >
          {dialogContent}
        </Dialog>



        <Dialog
          header="Open Chest"
          visible={this.state.displayModalOpenChest}
          style={{ width: '50vw' }}
          onHide={() => { this.setState({ displayModalOpenChest: false }) }}
        >

          <div>
            <DataTable value={this.state.chests}>
              <Column field="token" header="Chest" />
              <Column field="quantity" header="Quantity" />
              <Column
                body={(rowData) => (
                  <div>
                    <label>
                      <input
                        type="number"
                        onChange={(e) =>
                          this.setState({ openChestQuantity: parseInt(e.target.value) })
                        }
                      />
                    </label>
                    <button onClick={() => this.openChest(rowData)}>Open Chest</button>
                  </div>
                )}
              />
            </DataTable>

          </div>

        </Dialog>




        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />

        <Menubar
          model={[]}
          end={end}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    this.setState({
                      visibleLeft: true,
                    });
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />



        <div className="bv-div-store">
          <div className="container">


            { /*
            <Fieldset legend="Info">
              <p className="m-0">

              </p>
            </Fieldset>
            */
            }

            <div className="row">
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <Tooltip target=".imgxw" mouseTrack mouseTrackLeft={10} />
                      <img className="imgzx" src={cofreDesafortunado} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Initial</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Initial}</span>
                      </div>

                      <div className="size">
                        <h3>
                          75% ( XP min 2 - max 10 ) or 24 % Common Hero or 1%
                          Rare
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();

                          this.buyChest("Initial");
                        }}
                        className="imgxw"
                        data-pr-tooltip="0,5$ SOUL"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <img className="imgzx" src={cofreComun} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Common</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Common}</span>
                      </div>

                      <div className="size">
                        <h3>
                          60% ( XP min 5 - max 15 ) or 25% Common Hero or 10%
                          Rare Hero or 5% V3 Pack
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();

                          this.buyChest("Common");
                        }}
                        className="imgxw"
                        data-pr-tooltip="1$ Souls or Hive "
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <img className="imgzx" src={cofreRaro} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Rare</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Rare}</span>
                      </div>

                      <div className="size">
                        <h3>
                          1 x V3 Pack & Guaranteed ( XP min 10 - max 20 ) & 1%
                          rare card
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.Rare > 0) {
                            this.buyChest("Rare");
                          }
                        }}
                        className="imgxw"
                        data-pr-tooltip="2.5$ Souls or Hive"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <img className="imgzx" src={cofreEpico} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Epic</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Epic}</span>
                      </div>

                      <div className="size">
                        <h3>
                          Random 2 - 3 x V3 Packs & Guaranteed (XP min 15 - 25 )
                          & 5% Rare Card
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.Epic > 0) {
                            this.buyChest("Epic");
                          }
                        }}
                        className="imgxw"
                        data-pr-tooltip="5$ Hive or tokens  "
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <img className="imgzx" src={cofrePlateado} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Silver</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Silver}</span>
                      </div>

                      <div className="size">
                        <h3>
                          Random 4 - 8 x V3 Packs & Guaranteed (XP min 20 - 35 )
                          & 7% Epic card
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.Silver > 0) {
                            this.buyChest("Silver");
                          }
                        }}
                        className="imgxw"
                        data-pr-tooltip="Pay on Hive "
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="store-container">
                  <div className="store-card">
                    <div className="imgBx">
                      <img className="imgzx" src={cofreLegendario} />
                    </div>
                    <div className="store-contentBx">
                      <h2>Legendary</h2>
                      <div className="size">
                        <h3>left:</h3>
                        <span>{this.state.Legendary}</span>
                      </div>

                      <div className="size">
                        <h3>
                          Random 8 - 20 x V3 Packs & Guaranteed (XP min 25 - 50)
                          & 10% Legendary card
                        </h3>
                      </div>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.Legendary > 0) {
                            this.buyChest("Legendary");
                          }
                        }}
                        className="imgxw"
                        data-pr-tooltip="20$ Hive"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">


              <div className="size" style={{
                textAlign: "center",
                marginTop: "1%"
              }}>   <Button onClick={e => { this.displayModalOpenChest() }}  >Open chest</Button>  <Button onClick={e => { this.displayList() }}>Sell list</Button> </div>
            </div>
          </div>
        </div>


        <Dialog
          header="Winnings"
          visible={this.state.displayWinningsModal}
          style={{ width: '50vw' }}
          onHide={() => {
            this.setState({
              winnings: null,
              displayWinningsModal: false,
            });
          }}
        >
          {this.renderWinnings()}
        </Dialog>


      </>
    );
  }


  openChest(datos) {

    console.log(datos);
    console.log(this.state.openChestQuantity);

    if (this.state.openChestQuantity) {


      if (this.state.openChestQuantity <= datos.quantity) {

        let mutation = `mutation {
        openChest(input: { token:"${datos.token}", quantity: ${this.state.openChestQuantity} }) {
          success
          message
        }
      }`

        fetch("https://backend.infernalcoliseum.fun/api", {
          body: JSON.stringify({ query: mutation }),
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
            Dnt: "1",
          },
          method: "POST",
        })
          .then((response) => response.json())
          .then(e => {
            console.log("response", e);
            if (e?.data) {

              if (e.data.openChest.success) {

                this.setState({
                  openChestQuantity: null,
                  displayModalOpenChest: false,
                  winnings: JSON.parse(e.data.openChest.message),
                  displayWinningsModal: true,
                });
              }


            }
          })

      }
    }

  }



  displayModalOpenChest() {

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getUserData{\\n utilityToken\\n {\\n token\\n quantity\\n  }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then(e => {


        if (e?.data) {

          console.log(e);

          let xxe = e.data.getUserData?.utilityToken.filter(token => {
            if (token.token == "Initial" ||
              token.token == "Common" ||
              token.token == "Rare" ||
              token.token == "Epic" ||
              token.token == "Silver" ||
              token.token == "Legendary"
            ) {
              if (token.quantity > 0) {
                return token;
              }

            }

          })

          this.setState({
            displayModalOpenChest: true,

            chests: xxe
          })
        }


      })




    this.setState({ displayModalOpenChest: true });
  }

  renderWinnings = () => {
    const { winnings } = this.state;

    if (!winnings) {
      return null;
    }

    // Filtrar premios por tipo
    const ixpPrizes = winnings.prizes.filter((prize) => prize.contractName === "tokens" && prize.contractAction === "issue" && prize.contractPayload.symbol === "IXP");
    const icpvthreePrizes = winnings.prizes.filter((prize) => prize.contractName === "tokens" && prize.contractAction === "issue" && prize.contractPayload.symbol === "ICPVTHREE");
    const nftPrizes = winnings.prizes.filter((prize) => prize.contractName === "nft" && prize.contractAction === "issueMultiple" && prize.contractPayload.instances.length > 0);

    return (
      <div>
        <h4>Winnings</h4>
        <ul>
          {ixpPrizes.map((prize, index) => (
            <li key={index}>{`IXP: ${prize.contractPayload.quantity}`}</li>
          ))}
          {icpvthreePrizes.map((prize, index) => (
            <li key={index}>{`ICPVTHREE: ${prize.contractPayload.quantity}`}</li>
          ))}
          {nftPrizes.map((prize, index) => (
            <li key={index}>
              NFTs:
              <ul>
                {prize.contractPayload.instances.map((nft, nftIndex) => (
                  <li key={nftIndex}>{`${nft.properties.p1} - ${nft.properties.p2}`}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <p>You will receive the rewards soon in your account</p>
      </div>
    );
  };


  displayList() {

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getAllChestSell{\\n   pack\\n username\\n token_amount\\n token\\n status\\n }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      }).then((data) => {
        if (data?.data) {
          this.setState({
            sellData: data.data.getAllChestSell,
            displayModal: true
          })
        }
      })

  }

  buyChest(pack) {
    let currency = [];
    let quantity = 0;

    switch (pack) {
      case "Initial":
        currency = ["SOULS"];
        quantity = 0.5;
        break;

      case "Common":
        currency = [
          "SOULS",
          "HIVE",
          "SWAP.HIVE",
          "SWAP.HBD",
          "HBD",

        ];
        quantity = 1;
        break;

      case "Rare":
        currency = [
          "SOULS",
          "HIVE",
          "SWAP.HIVE",
          "SWAP.HBD",
          "HBD",

        ];
        quantity = 2.5;
        break;

      case "Epic":
        currency = [
          "SOULS",
          "HIVE",
          "SWAP.HIVE",
          "SWAP.HBD",
          "HBD",
        ];
        quantity = 5;
        break;

      case "Silver":
        currency = [
          "SOULS",
          "HIVE",
          "SWAP.HIVE",
          "SWAP.HBD",
          "HBD",
        ];
        quantity = 10;
        break;

      case "Legendary":
        currency = [
          //"SOULS",
          "HIVE",
          //"BUDS",
          "SWAP.HIVE",
          "SWAP.HBD",
          "HBD",
          //"DEC",
          //"LVL",
          //"STARBITS",
          //"STARPRO",
          //"KOD",
          //"HUESO"
        ];
        quantity = 20;
        break;

      default:
        break;
    }

    const HP = new HivePay("infernalcoliseum");
    HP.setItemName("Buy Chest " + pack)
      .setItemDescription("Open chest")
      .setMerchant_email("blackmirague@gmail.com")
      .setNotifyUrl("https://guerrerosconsultoresas.com.co/IF/buyChest.php")
      .setReturnUrl("https://game.infernalcoliseum.fun/store")
      .setAmount(quantity)
      .setQuantity(1)
      .setBaseCurrency("USD")
      .setCustom(
        JSON.stringify({
          pack: pack,
          username: localStorage.getItem("username"),
        })
      )
      .setPayCurrencies(currency)
      .setDiscountToken("HIVE,SWAP.HIVE")
      .setDiscountAmount(10)

    HP.submit();
  }

  componentDidMount() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getAllChest{\\n    chestName\\n    chestQuantity\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);

        let c = {
          Initial: 0,
          Common: 0,
          Rare: 0,
          Epic: 0,
          Silver: 0,
          Legendary: 0,
        };

        data.data.getAllChest.forEach((chest) => {
          c[chest.chestName] = chest.chestQuantity;
        });

        //console.log(c);

        this.setState({
          Initial: c.Initial,
          Common: c.Common,
          Rare: c.Rare,
          Epic: c.Epic,
          Silver: c.Silver,
          Legendary: c.Legendary,
        });
      });
  }
}

export default Store;
