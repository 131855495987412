import React, { Component } from "react";
import Swal from "sweetalert2";

class Login extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log("entrando al login");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("token");
    console.log(foo);

    if (foo) {
      let query = `{"query":"mutation($publicKey:String!) {\\n  utkn(data:{tkey:$publicKey}) {\\n    user {\\n      _id\\n      username\\n      path\\n      guild\\n      lvl\\n      xp\\n   token\\n    }\\n    token\\n  }\\n}\\n","variables":{"publicKey":"${foo}"}}`;

      fetch("https://backend.infernalcoliseum.fun/api", {
        body: query,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          origin: "https://game.infernalcoliseum.fun",
        },
        method: "POST",
      })
        .then((res) => {
          return res.json();
        })
        .then((e) => {
          console.log(e);
          Swal.close();
          if (e.data?.utkn?.user && e.data?.utkn?.token) {
            localStorage.setItem("username", e.data.utkn.user.username);
            localStorage.setItem("token", e.data.utkn.token);
            localStorage.setItem("userData", JSON.stringify(e.data.utkn.user));

            window.location.href = "/";
          } else {
            alert("Error, verify account and try again");
          }
        });
    }
  }

  render() {
    return (
      <div className="container">
        <div style={{ marginTop: "-10%" }} className="row align-items-center">
          <div
            style={{ marginTop: "5%" }}
            className="col-lg-5 col-md-12 col-12"
          >
            <img
              width="100%"
              src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c75d411a-f082-4479-8bfb-ba1ee7aa9100/public"
            />
            <div className="hero-content">
              <h1 className="wow fadeInLeft">
                Infernal Coliseum is an exciting new game
              </h1>
              <p className="wow fadeInLeft">
                {" "}
                where Angels and Demons will compete to conquer lands and
                ultimately battle it out so see who is the greatest of them all.
              </p>
              <div
                style={{ textAlign: "center" }}
                className="button wow fadeInLeft"
                data-wow-delay=".8s"
              >
                <button
                  style={{
                    background: "linear-gradient(to right,  #f3148b, #3100ff)",
                    color: "white",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    margin: "10px",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => {
                    this.login();
                  }}
                >
                  LOGIN
                </button>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "10%" }}
            className="col-lg-7 col-md-12 col-12"
          >
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img
                style={{ width: "100vh" }}
                src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/aba96fcf-f16a-4815-69f3-46bc1f05cd00/public"
                alt="#"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  login() {
    let username = prompt("write username");
    if (!username) {
      alert("Error, verify account and try again");
      return;
    }
    window.hive_keychain.requestSignBuffer(
      username,
      "Login",
      "Posting",
      (response) => {
        this.validatePath(response.data.username, response.publicKey);
      }
    );
  }

  validatePath(username, publicKey) {
    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        this.getMemo(username, publicKey);
      },
    });
  }

  getMemo(username, publicKey) {
    let query = `{"query":"mutation($username: String!,$publicKey:String!) { \\n fetch_memo(memo:{ \\n username:$username,\\n    publicKey:$publicKey\\n  })\\n}","variables":{"username":"${username}","publicKey":"${publicKey}"}}`;
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: query,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        origin: "https://game.infernalcoliseum.fun",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.data.fetch_memo) {
          this.getInfoUsername(username, res.data.fetch_memo);
        } else {
          alert("Error, verify account and try again");
          Swal.close();
        }
      });
  }

  getInfoUsername(username, memo) {
    window.hive_keychain.requestVerifyKey(
      username,
      memo,
      "Posting",
      (response) => {
        let query = `{"query":"mutation($username: String!,$publicKey:String!) {\\n  username(data:{username:$username,publicKey:$publicKey}) {\\n    user {\\n      _id\\n      username\\n      path\\n      guild\\n      lvl\\n      xp\\n   token\\n cantidad_tokens\\n fecha_solicitud_retiro\\n essenceFarmer\\n essenceFisher\\n essenceMiner\\n league\\n}\\n    token\\n }\\n}\\n","variables":{"username":"${username}","publicKey":"${response.result}"}}`;

        fetch("https://backend.infernalcoliseum.fun/api", {
          body: query,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            origin: "https://game.infernalcoliseum.fun",
          },
          method: "POST",
        })
          .then((res) => {
            return res.json();
          })
          .then((e) => {
            //console.log(e);
            Swal.close();
            if (e.data?.username?.user && e.data?.username?.token) {
              localStorage.setItem("username", e.data.username.user.username);
              localStorage.setItem("token", e.data.username.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(e.data.username.user)
              );
              if (e.data.username.user.path === "null") {
                window.location.href = "/choosePath";
              } else {
                window.location.href = "/";
              }
            } else {
              alert("Error, verify account and try again");
            }
          });
      }
    );
  }
}

export default Login;
