import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import HivePay from "../../utils/HivePay";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import socketIOClient from "socket.io-client";

const ENDPOINT = "https://farminglandsinfernalbackend.herokuapp.com/";

class TokenStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctions: [],
    };
  }
  render() {
    const sortedAuctions = this.state.auctions.sort((a, b) => {
      if (a.status === "complete" && b.status !== "complete") {
        return -1; // a viene antes que b
      } else if (a.status !== "complete" && b.status === "complete") {
        return 1; // b viene antes que a
      } else if (a.status === "active" && b.status !== "active") {
        return -1; // a viene antes que b
      } else if (a.status !== "active" && b.status === "active") {
        return 1; // b viene antes que a
      } else {
        return 0; // a y b tienen el mismo status
      }
    });
    return (
      <Sidebar
        fullScreen
        visible={this.props.visible}
        onHide={() => this.props.close()}
      >
        <h1>Auctions</h1>
        <div className="container flex justify-content-center">
          <table className="table">
            <thead className="thead-dark">
              <th scope="col">#</th>
              <th scope="col">LAND ID</th>
              <th scope="col">Land</th>
              <th scope="col">Price</th>
              <th scope="col">Deadline</th>
              <th scope="col">Status</th>
              <th scope="col">Land Master</th>
              <th scope="col">Trx</th>
            </thead>

            <tbody>
              {sortedAuctions.map((e, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index}</th>
                    <td>{e?.land?._id}</td>
                    <td>{e?.land?.COUNTRY}</td>
                    <td>{e?.currentPrice} SOULS</td>
                    <td>{e?.deadline}</td>
                    <td>{e?.status}</td>
                    <td>{e?.username}</td>
                    <td>{e?.trx}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Sidebar>
    );
  }

  componentDidMount() {
    /*
    const socket = socketIOClient(ENDPOINT);

    
    socket.on("auctions", (mensaje) => {
      let activeAuction = JSON.parse(mensaje);

      console.log(activeAuction);
      this.setState({
        activeAuction: activeAuction,
        auctions: JSON.parse(mensaje),
      });
    });*/


  }
}

export default TokenStats;
