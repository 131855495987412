import axios from "axios";

let URL = "https://herpc.dtools.dev/contracts";
const CONTRACT = "nft"; // Should be nft
const TABLE_POSTFIX = "instances"; // ShoAuld be the same
const NFT_SYMBOL = "IFCNFTS"; // Your NFT Symbol

const CONTRACT_MARKET = "nftmarket";
const TABLE_POSTFIX_MARKET = "sellBook";

class Utils {
  isNullOrEmpty(variable) {
    return variable === null || variable === undefined;
  }

  async axiosRequest({ contract, table, query, offset }) {
    // Headers
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
    };
    // Request POST body data
    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: contract,
          table: table,
          query: query,
          limit: 1000,
          offset: offset,
          indexes: [],
        },
        id: 1,
      },
    ]);
    // Make request.
    return await axios.post(URL, body, config).then((data) => {
      console.log("data", data);
      return data;
    });
  }

  async queryContract({ contract, table, query = {} }, offset = 0) {
    // Request data
    let response = await this.axiosRequest({ contract, table, query, offset });

    // Return result
    if (
      response &&
      response.data !== undefined &&
      response.data !== null &&
      !this.isNullOrEmpty(response.data[0].result)
    )
      return response.data[0].result;

    // Else return false
    return false;
  }

  async getAllNfts() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                account: localStorage.getItem("username"),
                "properties.p8": { $nin: ["invalidNFT"] },
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getAllPlayerConquers() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                account: localStorage.getItem("username"),
                "properties.p2": { $nin: ["land"] },
                "properties.p3": { $nin: ["0"] },
                "properties.p8": { $nin: ["collector"] },
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getTodosLosNFtsInfernal() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                "properties.p1": {
                  $nin: ["invalidNFT", "merged"],
                },
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getTodosLosNFtsRecolectoresInfernal() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                "properties.p1": {
                  $nin: ["invalidNFT", "merged"],
                },
                "properties.p8": {
                  $in: ["collector"],
                },
                account: localStorage.getItem("username"),
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getAllRents() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                "properties.RENTEDINFO": "" + localStorage.getItem("username"),
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          let nft = {
            id: nfts[i]._id,
            properties: nfts[i].properties,
            owner: nfts[i].account,
          };
          t.push(nft);
        }

        resolve(t);
      })();
    });
  }

  async getAllRented() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                "properties.RENTEDINFO": { $exists: true, $nin: ["n/a"] },
                account: "" + localStorage.getItem("username"),
                "properties.TYPE": "plot",
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          let nft = {
            _id: nfts[i]._id,
            properties: nfts[i].properties,
            owner: nfts[i].account,
          };
          t.push(nft);
        }

        resolve(t);
      })();
    });
  }

  async getMarketData(priceSymbol, account = null, filter = null) {
    let query = {
      priceSymbol,
    };
    if (account) {
      query["account"] = account;
    }
    if (filter) {
      query["grouping.TYPE"] = filter.type;

      if (filter.type === "water") {
        query["grouping.LVL"] = filter.name;
      } else if ((filter.type = "consumable")) {
        query["grouping.CONSUMABLETYPE"] = filter.name;
      } else {
        query["grouping.NAME"] = filter.name;
      }
    }
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT_MARKET,
              table: NFT_SYMBOL + TABLE_POSTFIX_MARKET,
              query,
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          t.push(nfts[i]);
        }

        resolve(t);
      })();
    });
  }

  async getAllMarketPlaceNfts(token) {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT_MARKET,
              table: NFT_SYMBOL + TABLE_POSTFIX_MARKET,
              query: { priceSymbol: token },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          t.push(nfts[i]);
        }

        resolve(t);
      })();
    });
  }

  async getAllMyMarketPlaceNfts(token) {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT_MARKET,
              table: NFT_SYMBOL + TABLE_POSTFIX_MARKET,
              query: { account: token },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          t.push(nfts[i]);
        }

        resolve(t);
      })();
    });
  }

  async getAllMYMarketPlaceNfts(username) {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT_MARKET,
              table: NFT_SYMBOL + TABLE_POSTFIX_MARKET,
              query: { account: username },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        let t = [];

        for (let i = 0; i < nfts.length; i++) {
          t.push(nfts[i]);
        }

        resolve(t);
      })();
    });
  }

  async getNftById(nftsids) {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: { _id: { $in: nftsids } },
            },
            offset
          );
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 499;

            if (get_nfts.length !== 499) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getNftByIds(nftsids) {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: { _id: { $in: nftsids } },
            },
            offset
          );
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getNftLands() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                account: localStorage.getItem("username"),
                "properties.p2": "land",
              },
            },
            offset
          );
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async getInfoForSide(side) {
    return await fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"query{\\n  getInfoZona(input:\\"${side}\\"){\\n    territorio\\n    side\\n    boss\\n    souls\\n  XP\\n  conquistado\\n  }\\n}\\n"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        origin: "https://game.infernalcoliseum.fun",
      },
      method: "POST",
    }).then((response) => response.json());
  }

  async getInfoForTerritory(side) {
    return await fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"query{\\n  getInfoTerritory(input:\\"${side}\\"){\\n    territorio\\n    side\\n    boss\\n    souls\\n    XP\\n  conquistado\\n usuarios\\n{ id\\n username\\n raidPower\\n  }\\n nftsAllraids\\n{ id\\n username   } }\\n}\\n"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
        Dnt: "1",
        origin: "https://game.infernalcoliseum.fun",
      },
      method: "POST",
    }).then((response) => response.json());
  }

  async getAllNftsConquers() {
    return new Promise(async (resolve) => {
      (async () => {
        let complete = false;
        let nfts = [];
        let offset = 0;

        while (!complete) {
          let get_nfts = await this.queryContract(
            {
              contract: CONTRACT,
              table: NFT_SYMBOL + TABLE_POSTFIX,
              query: {
                account: localStorage.getItem("username"),
                "properties.p8": "conqueror",
              },
            },
            offset
          ).catch((err) => {
            console.log("Error getting data from Hive ENGINE :>>", err);
          });
          if (get_nfts !== false) {
            nfts = nfts.concat(get_nfts);
            offset += 1000;

            if (get_nfts.length !== 1000) {
              complete = true;
            }
          } else {
            complete = true;
          }
        }

        resolve(nfts);
      })();
    });
  }

  async setNftsToRaid(zona, nfts) {
    return fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation{\\n  setNftsToRaid(nfts:{zona:\\"${zona}\\" , nfts:${nfts}}){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
        origin: "https://game.infernalcoliseum.fun",
      },
      method: "POST",
    }).then((response) => response.json());
  }

  async getAllSoulssend() {
    let transactions = [];
    let complete = false;
    let offset = 0;
    while (!complete) {
      let get_transactions = await axios({
        url: "https://accounts.hive-engine.com/accountHistory",
        method: "GET",
        params: {
          account: "if-xp-pool",
          limit: "1000",
          offset: offset,
          type: "user",
          symbol: "SOULS",
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (get_transactions !== false) {
        transactions = transactions.concat(get_transactions);
        offset += 1000;
        if (get_transactions.length !== 1000) {
          complete = true;
        }
      } else {
        complete = true;
      }
    }

    let data = await axios({
      url: "https://guerrerosconsultoresas.com.co/time.php",
      method: "GET",
    })
      .then((response) => {
        console.log("time responde", response.data);
        return response.data;
      })
      .catch((err) => {
        return false;
      });

    if (!data) {
      return [];
    }

    // let t = data.t;
    let y = data.y;

    //console.log("reemplace with this on next day", t, y);

    let transactionsx = transactions.filter((t) => {
      if (t.timestamp >= y) {
        return t;
      }
    });

    let filter = [];

    for (let i = 0; i < transactionsx.length; i++) {
      let u = transactionsx[i].from;

      if (u != "infernalcoliseum") {
        if (transactionsx[i].memo == "pool xp") {
          filter.push({
            user: transactionsx[i].from,
            depositedBuds: transactionsx[i].quantity,
          });
        }
      }
    }

    let response = this.groupBy(filter, "user");

    let fin = [];

    for (const key in response) {
      fin.push(response[key]);
    }

    let settingMotaWin = this.setMotaWin(fin);

    return settingMotaWin.sort(function (a, b) {
      return b.depositedBuds - a.depositedBuds;
    });

    // console.log(fin[5], fin.length);
  }


  setMotaWin(listOfUsers) {
    let userQuantity = 0;
    for (let i = 0; i < listOfUsers.length; i++) {
      userQuantity += parseFloat(listOfUsers[i].depositedBuds);
    }
    let ratio = 375 / userQuantity;

    for (let i = 0; i < listOfUsers.length; i++) {
      let userGet = ratio * parseFloat(listOfUsers[i].depositedBuds);

      if (userGet < 0.001) {
        listOfUsers[i].mota = 0.001;
      } else {
        listOfUsers[i].mota = userGet.toFixed(4);
      }
    }

    return listOfUsers;
  }

  groupBy(miarray, prop) {
    return miarray.reduce(function (groups, item) {
      var val = item[prop];
      groups[val] = groups[val] || { user: item.user, depositedBuds: 0 };
      groups[val].depositedBuds =
        parseFloat(groups[val].depositedBuds) + parseFloat(item.depositedBuds);
      return groups;
    }, {});
  }

  async getTokensData() {
    return await fetch("https://engine.rishipanthee.com/contracts", {
      headers: {
        accept: "application/json, text/plain, */*",
        "accept-language": "es-US,es-CO;q=0.9,es-419;q=0.8,es;q=0.7",
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "sec-ch-ua":
          '"Google Chrome";v="113", "Chromium";v="113", "Not-A.Brand";v="24"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"Windows"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrer: "https://game.infernalcoliseum.fun/",
      referrerPolicy: "strict-origin-when-cross-origin",
      body: '{"jsonrpc":"2.0","id":1685310348188,"method":"find","params":{"contract":"tokens","table":"tokens","query":{"issuer":"infernalcoliseum"},"offset":0,"limit":1000}}',
      method: "POST",
      mode: "cors",
      credentials: "omit",
    });
  }
}

export default new Utils();
