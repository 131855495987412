import React from "react";
import { Dialog } from "primereact/dialog";

import { ProgressBar } from "primereact/progressbar";
import utils from "../../utils";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import CountdownTimer from "../CountdownTimer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lands: [],
      withdraw: false,
      retiros: [],
      qt: 0,
    };
  }

  render() {
    let img = `https://images.hive.blog/u/${localStorage.getItem(
      "username"
    )}/avatar`;
    return (
      <>
        <Dialog
          header={localStorage.getItem("username")}
          visible={this.props.displayBasic}
          style={{ width: "50vw" }}
          onHide={() => this.props.onHide()}
        >
          <div className="col-12">
            <div className="row">
              <div
                className="col-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={img} />
              </div>

              <div className="col-7">
                <div className="col-12">LVL: {this.props.lvl}</div>
                <div className="col-12">XP: {this.props.xp}</div>
                <div className="col-12">Guild: {this.props.guild}</div>
                <div className="col-12">Side: {this.props.side}</div>
                <div className="col-12">Remote url login: {this.props.url}</div>
                <div className="col-12">
                  {this.props.url == "" ? (
                    <Button
                      label="Create"
                      onClick={() => {
                        this.createLink();
                      }}
                      link
                    ></Button>
                  ) : (
                    <Button
                      label="Refresh"
                      onClick={() => {
                        this.createLink();
                      }}
                      link
                    ></Button>
                  )}
                </div>
                <div className="col-12">
                  Season End: <CountdownTimer deadline="2024-08-16" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <h5>Total souls stake</h5>
                <p>{this.props.totalUserStake}</p>
                <Button
                  onClick={(e) => {
                    this.Stake();
                  }}
                >
                  Stake
                </Button>
              </div>
              <div className="col-4">
                <h5>Souls in game</h5>
                <p>
                  {this.props.utilityToken.find((e) => e.token == "SOULS")
                    ?.quantity || 0}
                </p>
                <Button
                  onClick={(e) => {
                    this.setState({
                      withdraw: true,
                      tokenW: "SOULS",
                    });
                  }}
                >
                  withdraw
                </Button>
              </div>

              <div className="col-4">
                <h5>IXP in game</h5>
                <p>
                  {this.props.utilityToken.find((e) => e.token == "IXP")
                    ?.quantity || 0}
                </p>
                <Button
                  onClick={(e) => {
                    this.setState({
                      withdraw: true,
                      tokenW: "IXP",
                    });
                  }}
                >
                  withdraw
                </Button>
              </div>

              <div className="col-4">
                <h5>ICPVTHREE in game</h5>
                <p>
                  {this.props.utilityToken.find((e) => e.token == "ICPVTHREE")
                    ?.quantity || 0}
                </p>
                <Button
                  onClick={(e) => {
                    this.setState({
                      withdraw: true,
                      tokenW: "ICPVTHREE",
                    });
                  }}
                >
                  withdraw
                </Button>
              </div>
            </div>
          </div>


          <div className="row">

            <div className="col-4">
              <h5>Essences</h5>
              <ul>
                <li>Essence Farmer: {this.props.essenceFarmer}</li>
                <li>Essence Fisher: {this.props.essenceFisher}</li>
                <li>Essence Miner: {this.props.essenceMiner}</li>
              </ul>
            </div>

            <div className="col-4">
              <h5>Chest </h5>
              <ul>
                <li>Initial:  {this.props.utilityToken.find((e) => e.token == "Initial")
                  ?.quantity || 0}</li>
                <li>Common:  {this.props.utilityToken.find((e) => e.token == "Common")
                  ?.quantity || 0}</li>
                <li>Rare:  {this.props.utilityToken.find((e) => e.token == "Rare")
                  ?.quantity || 0}</li>
                <li>Epic:  {this.props.utilityToken.find((e) => e.token == "Epic")
                  ?.quantity || 0}</li>
                <li>Silver:  {this.props.utilityToken.find((e) => e.token == "Silver")
                  ?.quantity || 0}</li>
                <li>Legendary:  {this.props.utilityToken.find((e) => e.token == "Legendary")
                  ?.quantity || 0}</li>
              </ul>
              <Button
                label="Open"
                onClick={() => {
                  window.location.href = "/store"
                }}
                link
              ></Button>
            </div>


            <div className="col-4">
              <h5>Percentage token</h5>
              {this.props.percentageToken}
              <Button onClick={() => this.setState({ createPercent: true })}> Create </Button>
            </div>

          </div>


          <div className="col-12">
            <h5>Daily mission</h5>
            <ProgressBar value={0}></ProgressBar>
          </div>
        </Dialog >

        <Dialog
          header="Withdrawals"
          visible={this.state.withdraw}
          onHide={() => this.setState({ withdraw: false })}
        >
          <div className="col-12">
            <div className="row" style={{ flexDirection: "column" }}>
              <h1>
                {this.state.tokenW} on wallet:{" "}
                {this.props.utilityToken.find(
                  (e) => e.token == this.state.tokenW
                )?.quantity || 0}
              </h1>
              <br></br>
              <input
                onChange={(e) =>
                  this.setState({ qt: parseFloat(e.target.value).toFixed(3) })
                }
                type="number"
                placeholder="quantity"
              ></input>
              <br></br>
              <Button
                onClick={(e) => {
                  Swal.fire({
                    title: "Sending...",
                    allowOutsideClick: false,
                    didOpen: () => {
                      Swal.showLoading();
                    },
                  });

                  console.log("ENVIANDO", this.state);
                  fetch("https://backend.infernalcoliseum.fun/api", {
                    body: `{"query":"mutation {\\n  solicitarRetiro (quantity:\\"${this.state.qt}\\", token:\\"${this.state.tokenW}\\"){\\n    success\\n    message\\n  }\\n}"}`,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + localStorage.getItem("token"),
                      Dnt: "1",
                    },
                    method: "POST",
                  })
                    .then((e) => e.json())
                    .then((r) => {
                      console.log(r)
                      if (r?.data.solicitarRetiro) {
                        Swal.close();
                        Swal.fire({
                          icon: r.data.solicitarRetiro.success
                            ? "success"
                            : "error",
                          title: r.data.solicitarRetiro.message,
                          showConfirmButton: true,
                        }).then((res) => { });
                      }

                    });
                }}
              >
                Withdraw
              </Button>
            </div>
            <div className="row">
              <DataTable
                value={this.state.retiros}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="_id" header="Id"></Column>
                <Column field="token" header="Token"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="fechaRegistro" header="Date"></Column>
                <Column field="status" header="Status"></Column>
              </DataTable>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  Stake() {
    Swal.fire({
      title: "Souls to stake",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "stake",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        try {
          var a = parseFloat(login);
          console.log(a);

          if (!a) {
            alert("please enter a valid number");
            return false;
          }
        } catch (e) {
          alert("please enter a valid number");
          return false;
        }

        return new Promise((resolve, reyect) => {
          window.hive_keychain.requestSendToken(
            localStorage.getItem("username"),
            "if-stk-vault",
            parseFloat("" + login).toFixed(3),
            "stake",
            "SOULS",
            (resp) => {
              resolve(resp);
            },
            null
          );
        });
      },
      allowOutsideClick: () => false,
    }).then((result) => {
      if (result?.value?.message) {
        Swal.fire({
          title: `${result.value.message}`,
        });
      }
    });
  }

  createLink() {
    alert(
      "For security reasons, this token only works to perform in-game actions, not to interact with the blockchain, keep it safe and don't share it with anyone"
    );
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"mutation {\\n  createToken{\\n    message\\n    success\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          alert(response.data.createToken.message);
          window.location.reload();
        }
      });
  }

  componentDidMount() {
    utils.getNftLands().then((e) => {
      this.setState({
        ...this.state,
        lands: e,
      });
      fetch("https://backend.infernalcoliseum.fun/api", {
        body: `{"query":"query {\\n  getAllRegistros{\\n    _id\\n    username\\n    quantity\\n    fechaRegistro\\n    status\\n token}\\n   \\n}"}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          Dnt: "1",
        },
        method: "POST",
      })
        .then((e) => e.json())
        .then((res) => {
          if (res?.data?.getAllRegistros) {
            this.setState({
              ...this.state,
              retiros: res?.data?.getAllRegistros,
            });
          }
        });
    });
  }
}

export default Profile;
