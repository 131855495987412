import React, { Component } from "react";

import { Dialog } from "primereact/dialog";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import CountdownTimer from "../CountdownTimer";
import AdSenseAds from "../extras/AdSenseAds";
import { Accordion, AccordionTab } from 'primereact/accordion';
class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      guilds: [],
      numerodeVecesEnRaids: [],
      guildSouls: 0,
      soloPlayer: 0,
      keepSoul: 0,
      totalSoul: 0,
      guildBuds: 0,
      soloPlayerBuds: 0,
      totalBuds: 0,
      keepBuds: 0,
    };

    this.numerodeVecesEnRaids = this.numerodeVecesEnRaids.bind(this);
  }

  render() {
    return (
      <Dialog
        header="Leaderboard"
        visible={this.props.displayposition}
        position="center"
        modal
        style={{ width: "70vw" }}
        onHide={() => this.props.Hide()}
        draggable={false}
        resizable={true}
      >



        <h3 style={{
          color: "#007bff"
        }}>Welcome to Season 10</h3>

        <p>Time left: <CountdownTimer deadline="2024-08-16" /></p>

        <h4 style={{
          color: "#28a745"
        }}>SOULS Rewards:</h4>
        <ul style={{
          listStyleType: "none",
          padding: "0",
          marginTop: "10px"
        }}>
          <li style={{ marginBottom: "8px" }}>50% to the guilds souls rewards pool: {this.state.guildSouls.toLocaleString()}</li>
          <li style={{ marginBottom: "8px" }}>15% to the solo player souls rewards pool: {this.state.soloPlayer.toLocaleString()}</li>
          <li style={{ marginBottom: "8px" }}>35% rolls over to the next season: {this.state.keepSoul.toLocaleString()}</li>
        </ul>

        <h4 style={{
          color: "#28a745"
        }}>BUDS Rewards:</h4>
        <ul style={{
          listStyleType: "none",
          padding: "0",
          marginTop: "10px"
        }}>
          <li style={{ marginBottom: "8px" }}>50% to the guilds buds rewards pool: {this.state.guildBuds.toLocaleString()}</li>
          <li style={{ marginBottom: "8px" }}>15% to the solo player buds rewards pool: {this.state.soloPlayerBuds.toLocaleString()}</li>
          <li style={{ marginBottom: "8px" }}>35% rolls over to the next season: {this.state.keepBuds.toLocaleString()}</li>
        </ul>

        <p>Think, improve, and stay tuned for more updates!</p>


        <Accordion activeIndex={0}>
          <AccordionTab header="Solo rewards">

            <DataTable
              value={this.state.users}
              header="Solo user"
              responsiveLayout="scroll"
              scrollable
              scrollHeight="400px"
            >
              <Column field="username" header="User"></Column>
              <Column
                field="souls"
                body={this.formatSouls}
                header="Souls"
              ></Column>
              <Column
                field="buds"
                body={this.formatBuds}
                header="Buds"
              ></Column>
              <Column
                field=""
                body={this.numerodeVecesEnRaids}
                header="# of raids"
              ></Column>
            </DataTable>

          </AccordionTab>
          <AccordionTab header="Guild Rewards">
            <DataTable
              scrollable
              value={this.state.guilds}
              header="Guilds"
              scrollHeight="400px"
              responsiveLayout="scroll"
            >
              <Column field="name" header="Guild"></Column>
              <Column
                field="souls"
                body={this.formatSouls}
                header="Souls"
              ></Column>
              <Column
                field="buds"
                body={this.formatBuds}
                header="Buds"
              ></Column>
              <Column
                field=""
                body=""
                header="# of raids"
              ></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>


      </Dialog>
    );
  }

  formatSouls(item) {
    if (item.souls) {
      return parseFloat("" + item.souls).toFixed(2);
    }

    return 0;
  }
  formatBuds(item) {
    if (item.buds) {
      return parseFloat("" + item.buds).toFixed(2);
    }

    return 0;
  }

  numerodeVecesEnRaids(item) {
    if (item.username) {
      return this.state.numerodeVecesEnRaids.find(e => e._id === item.username)?.count ? this.state.numerodeVecesEnRaids.find(e => e._id === item.username).count : 0;
    }
  }

  componentDidMount() {
    Swal.showLoading();

    fetch("https://herpc.dtools.dev/contracts", {
      headers: {
        accept: "application/json, text/plain, */*",
        "accept-language": "es-US,es-CO;q=0.9,es-419;q=0.8,es;q=0.7",
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "sec-ch-ua":
          '" Not A;Brand";v="99", "Chromium";v="102", "Google Chrome";v="102"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"Windows"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrerPolicy: "no-referrer",
      body: '{"jsonrpc":"2.0","id":1,"method":"find","params":{"contract":"tokens","table":"balances","query":{"account":"pcsteem"},"limit":1000,"offset":0,"indexes":""}}',
      method: "POST",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then((res) => {
        let a = res.result.filter((element) => {
          return element.symbol === "SOULS";
        });

        let b = res.result.filter((element) => {
          return element.symbol === "BUDS";
        });

        this.setState({
          ...this.state,
          guildSouls: parseFloat(a[0].balance) * 0.5,
          soloPlayer: parseFloat(a[0].balance) * 0.15,
          keepSoul: parseFloat(a[0].balance) * 0.35,
          totalSoul: parseFloat(a[0].balance),
          guildBuds: parseFloat(b[0].balance) * 0.5,
          soloPlayerBuds: parseFloat(b[0].balance) * 0.15,
          keepBuds: parseFloat(b[0].balance) * 0.35,
          totalBuds: parseFloat(b[0].balance),
        });
      })
      .finally(() => {
        fetch("https://backend.infernalcoliseum.fun/api", {
          body: `{"query":"query{\\n  leaderBoardData{\\n    success\\n    message\\n    data{\\n      users {\\n        username\\n        xp\\n      }\\nguilds {\\n  name\\n   xp\\n}\\n numerodeVecesEnRaids { \\n _id  \\n count \\n }   }\\n  }\\n}"}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Dnt: "1",
          },
          method: "POST",
        })
          .then((data) => data.json())
          .then((response) => {
            //console.log(response);
            if (response.data.leaderBoardData.success) {
              Swal.close();

              let users = response.data.leaderBoardData.data.users;
              let guilds = response.data.leaderBoardData.data.guilds;
              let numerodeVecesEnRaids = response.data.leaderBoardData.data.numerodeVecesEnRaids;

              // Crear un mapa para acceder rápidamente a la cantidad de veces en raids por usuario
              let raidsMap = {};
              numerodeVecesEnRaids.forEach(entry => {
                raidsMap[entry._id] = entry.count;
              });

              // Asignar una ponderación a la XP y a las veces en raids
              const xpWeight = 0.3;
              const raidsWeight = 0.7;

              users = users.map(user => {
                const xp = parseInt(user.xp);
                const raids = raidsMap[user.username] || 0;
                console.log(user, "tiene ", raids);
                const combinedScore = (xp * xpWeight) + (raids * raidsWeight);
                return {
                  ...user,
                  combinedScore
                };
              });

              // Ordenar usuarios por la puntuación combinada
              users = users.sort((a, b) => b.combinedScore - a.combinedScore);



              /*users = users.sort((a, b) => {
                return parseInt(b.xp) - parseInt(a.xp);
              });*/

              try {
                users[0].souls = this.state.soloPlayer * 0.21;
                users[1].souls = this.state.soloPlayer * 0.17;
                users[2].souls = this.state.soloPlayer * 0.14;
                users[3].souls = this.state.soloPlayer * 0.12;
                users[4].souls = this.state.soloPlayer * 0.1;
                users[5].souls = this.state.soloPlayer * 0.08;
                users[6].souls = this.state.soloPlayer * 0.06;
                users[7].souls = this.state.soloPlayer * 0.05;
                users[8].souls = this.state.soloPlayer * 0.04;
                users[9].souls = this.state.soloPlayer * 0.03;

                users[0].buds = this.state.soloPlayerBuds * 0.21;
                users[1].buds = this.state.soloPlayerBuds * 0.17;
                users[2].buds = this.state.soloPlayerBuds * 0.14;
                users[3].buds = this.state.soloPlayerBuds * 0.12;
                users[4].buds = this.state.soloPlayerBuds * 0.1;
                users[5].buds = this.state.soloPlayerBuds * 0.08;
                users[6].buds = this.state.soloPlayerBuds * 0.06;
                users[7].buds = this.state.soloPlayerBuds * 0.05;
                users[8].buds = this.state.soloPlayerBuds * 0.04;
                users[9].buds = this.state.soloPlayerBuds * 0.03;
              } catch (e) { }
              guilds = guilds.sort((a, b) => {
                return parseInt(b.xp) - parseInt(a.xp);
              });
              try {
                guilds[0].souls = this.state.guildSouls * 0.4;
                guilds[1].souls = this.state.guildSouls * 0.24;
                guilds[2].souls = this.state.guildSouls * 0.16;
                guilds[3].souls = this.state.guildSouls * 0.12;
                guilds[4].souls = this.state.guildSouls * 0.08;

                guilds[0].buds = this.state.guildBuds * 0.4;
                guilds[1].buds = this.state.guildBuds * 0.24;
                guilds[2].buds = this.state.guildBuds * 0.16;
                guilds[3].buds = this.state.guildBuds * 0.12;
                guilds[4].buds = this.state.guildBuds * 0.08;

                console.log(guilds[0]);
              } catch (e) { }

              this.setState({
                users: users,
                guilds: guilds,
                numerodeVecesEnRaids
              });
            } else {
              Swal.fire({
                title: "Error",
                text: response.data.leaderBoardData.message,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          });
      });
  }
}

export default Leaderboard;
