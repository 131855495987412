import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import "./pvp.css";
import { Terminal } from "primereact/terminal";
import { TerminalService } from "primereact/terminalservice";

const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

const rareza = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
};

let iconoPower =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be578d7d-cbe7-4133-62d7-e881e6086400/public";

const GetImage = (type, data) => {
  switch (type) {
    case "RAIDPOWER":
      return <img src={iconoPower} style={{ maxWidth: "30%" }} alt="HEALTH" />;

    case "HEALTH":
      return (
        <img
          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
          style={{ maxWidth: "30%" }}
          alt="HEALTH"
        />
      );
    case "ATTACK":
      return (
        <img
          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
          style={{ maxWidth: "30%" }}
          alt="ATTACK"
        />
      );
    case "DEFENSE":
      return (
        <img
          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
          style={{ maxWidth: "30%" }}
          alt="DEFENSE"
        />
      );
    case "CRITICAL":
      return (
        <img
          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
          style={{ maxWidth: "30%" }}
          alt="CRITICAL"
        />
      );
    case "AFFINITY":
      return (
        <img src={SKILL[data]} style={{ maxWidth: "30%" }} alt="AFFINITY" />
      );
  }
};

const getIcono = () => {
  let angel =
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
  let demonio =
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

  let userData = JSON.parse(localStorage.getItem("userData"));

  if (!userData) {
    localStorage.clear();
    window.location.href = "/";
  }

  return userData.path == "KNALAND" ? demonio : angel;
};

const getImagen = (res) => {
  return images[res.name];
};

const getPower = (properties) => {
  let atk = parseInt(properties?.attack);
  let def = parseInt(properties?.defense);
  let critic = parseInt(properties?.critical);
  return atk * (critic * 0.2) + def * 3;
};

const itemTemplate = (
  item,
  render,
  atacante,
  target,
  setTarget,
  setAtacante
) => {
  //console.log("intentando mostrar imagen", item);
  return (
    <div
      key={item.id}
      className={
        atacante == item.id || target == item.id ? "resaltado col" : "col"
      }
    >
      <div style={{ textAlign: "center" }} className="product-item">
        <img
          style={{ width: "50%" }}
          className={item.life <= 0 ? "deadNft" : ""}
          src={`${getImagen(item)}`}
          onClick={(e) => {
            if (item.life > 0) {
              if (render == "othercharacters") {
                e.preventDefault();
                setTarget(item.id);
              } else {
                e.preventDefault();
                setAtacante(item.id);
              }
            } else {
              if (render == "othercharacters") {
                setTarget(null);
              } else {
                setAtacante(null);
              }
            }
          }}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          alt={item.name}
        />
      </div>
      <div className="product-list-detail">
        <div className="grid">
          <div className="col-4">
            {GetImage("HEALTH")}
            {Math.round(item.life)}
          </div>
          <div className="col-4">
            {GetImage("ATTACK")}
            {Math.round(item.attack)}
          </div>
          <div className="col-4">
            {GetImage("DEFENSE")}
            {Math.round(item.defense)}
          </div>
          <div className="col-4">
            {GetImage("CRITICAL")}
            {Math.round(item.critical)}
          </div>
          <div className="col-4">
            {GetImage("AFFINITY", item.type)}
            {item.type}
          </div>
        </div>
      </div>
    </div>
  );
};

function PVP() {
  const [socket, setSocket] = useState(null);
  const [searchGame, setSearchGame] = useState(false);
  const [stopSearch, setStopSearch] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [othercharacters, setOtherCharacters] = useState([]);
  const [turn, setTurn] = useState(null);
  const [message, setMessage] = useState(null);
  const [login, setLogin] = useState(null);
  const [searching, setSearching] = useState(false);
  const [gameStart, setGameStart] = useState(null);
  const [enemy, setEnemy] = useState(null);
  const [atacante, setAtacante] = useState(null);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    if (message) {
      sendMessage(message);
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {
    console.log("actualiznado ", characters, othercharacters);
  }, [
    socket,
    searchGame,
    stopSearch,
    characters,
    othercharacters,
    turn,
    login,
    searching,
    gameStart,
    enemy,
    atacante,
    target,
  ]);

  const Start = () => {
    // Crea una nueva conexión al servidor de WebSockets
    const newSocket = new WebSocket("wss://infernal-backend-pvp.herokuapp.com");
    setSocket(newSocket);
    // Asigna el evento de "message" para recibir mensajes del servidor
    newSocket.onmessage = (event) => {
      let message = JSON.parse(event.data);

      if (message.message != "ping") {
        console.log(message);
      }

      if (message.message?.includes("Game started!")) {
        setGameStart(true);

        for (const [key, value] of Object.entries(message.data.nfts)) {
          console.log(key, value);
          if (key === `${localStorage.getItem("username")}`) {
            setCharacters(value);
          } else {
            setEnemy(key);
            setOtherCharacters(value);
          }
        }

        if (message.message?.includes("It's your turn:")) {
          setTurn("yourTurn");
        }
        if (message.message?.includes("It's your opponent's turn:")) {
          setTurn("Oponent Turn");
        }
      }

      if (message.message?.includes("now its ur turn")) {
        setTurn("yourTurn");
      }
      if (message.message?.includes("turn finalized")) {
        setTurn("Oponent Turn");
      }

      if (message.message?.includes("attacked")) {
        //console.lo("mensaje", message.message,message.data);
        for (const [key, value] of Object.entries(message.data)) {
          if (key === `${localStorage.getItem("username")}`) {
            setCharacters(value);
          } else {
            setOtherCharacters(value);
          }
        }
      }

      if (message.logs) {
        sendMessageToTerminal(JSON.parse(message.logs).message);
      }

      switch (message.message) {
        case "ping":
          break;
        case "set name sucessfully":
          setCharacters(message.data.characters);
          //send to search game
          setLogin(true);
          break;
        case "searching game...":
          setSearching(true);
          break;
      }
    };

    // Asigna el evento de "open" para saber cuando se ha establecido la conexión
    newSocket.onopen = () => {
      newSocket.pingInterval = 5000;
      sendMessageToTerminal(
        "Conexión establecida con el servidor de WebSockets"
      );

      setMessage(
        JSON.stringify({
          action: "setName",
          token: "bearer " + localStorage.getItem("token"),
        })
      );
    };

    // Asigna el evento de "close" para saber cuando se ha cerrado la conexión
    newSocket.onclose = () => {
      console.log("Conexión cerrada con el servidor de WebSockets");
      setLogin(false);
      setSearchGame(false);
      setStopSearch(false);
      setCharacters([]);
      setOtherCharacters([]);
      setTurn(null);
      setMessage(null);
    };

    // Asigna el evento de "error" para manejar errores durante la conexión
    newSocket.onerror = (error) => {
      console.error(
        "Error durante la conexión con el servidor de WebSockets:",
        error
      );
    };

    // Almacena la conexión en el estado del componente
  };

  const search = () => {
    if (login) {
      setMessage(
        JSON.stringify({
          action: "searchGame",
        })
      );
    } else {
      console.log("no funciono si no estoy logeado", login);
    }
  };

  const Close = () => {
    if (socket) {
      socket.close();
    }
  };

  const attack = () => {
    if (login) {
      setMessage(
        JSON.stringify({
          type: "attack",
          atacante: atacante,
          target: target,
        })
      );
    } else {
      console.log("no funciono si no estoy logeado", login);
    }
  };

  // Define una función para enviar mensajes al servidor
  const sendMessage = (message) => {
    console.log("mensaje", message, socket);
    if (socket) {
      socket.send(message);
    }
  };

  const sendMessageToTerminal = (message) => {
    if (message == "clear") {
      document.querySelector(".p-terminal-content").innerHTML = "";
    }
    document.querySelector(
      ".p-terminal-content"
    ).innerHTML += `<div><div className="p-terminal-command">${message}</div></div>`;
  };

  return (
    <div className="pvp">
      <Menubar
        model={[]}
        start={() => {
          return (
            <div className="grid">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src={getIcono()}
                    style={{ width: "10%", cursor: "pointer" }}
                    className="mr-2"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  />
                  {localStorage.getItem("username")}
                </div>
                <div className="col">
                  <Button
                    style={{ visibility: `${login ? "hidden" : "visible"}` }}
                    className="fa fa-search"
                    onClick={(e) => Start()}
                    aria-hidden="true"
                    label="Login"
                    disabled={login === true}
                  />
                </div>
                <div className="col">
                  <Button
                    disabled={!turn}
                    label={turn ? "now is " + turn : "no game active"}
                  ></Button>
                </div>
                <div className="col">
                  <Button
                    className="fa fa-search"
                    onClick={(e) => search()}
                    aria-hidden="true"
                    label="Search Game..."
                    disabled={login !== true}
                  />
                </div>
              </div>
            </div>
          );
        }}
      />
      <div className="pvp__header">
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <Button
                    label={localStorage.getItem("username") + " Cards"}
                  ></Button>
                </div>
              </div>

              <div className="row">
                {characters.map((character) => {
                  return itemTemplate(
                    character,
                    "myCharacters",
                    atacante,
                    target,
                    setTarget,
                    setAtacante
                  );
                })}
              </div>
              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <Button label={(enemy ? enemy : "Enemy") + " Cards"}></Button>
                </div>
              </div>

              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <Button
                    disabled={turn !== "yourTurn"}
                    label={"attack"}
                    onClick={(e) => attack()}
                  ></Button>
                </div>
              </div>

              <div className="row">
                {othercharacters.map((character) => {
                  return itemTemplate(
                    character,
                    "othercharacters",
                    atacante,
                    target,
                    setTarget,
                    setAtacante
                  );
                })}
              </div>
            </div>
            <div className="col-md-6 terminal-demo">
              <Terminal welcomeMessage="Welcome to Infernal coliseum pvp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  /**/
}

export default PVP;
