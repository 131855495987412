export default class HivePay {
  constructor(merchant) {
    this.merchant = merchant;
  }

  setItemName(name) {
    this.itemName = name;
    return this;
  }

  setItemDescription(description) {
    this.itemDescription = description;
    return this;
  }

  setNotifyUrl(url) {
    this.notifyUrl = url;
    return this;
  }

  setReturnUrl(url) {
    this.returnUrl = url;
    return this;
  }

  setAmount(amount) {
    this.amount = amount;
    return this;
  }

  setBaseCurrency(currency = "USD") {
    this.baseCurrency = currency;
    return this;
  }

  setMerchant_email(merchant_email) {
    this.merchant_email = merchant_email;
    return this;
  }

  setPayCurrencies(currencies) {
    this.payCurrency = Array.isArray(currencies)
      ? currencies.join(",")
      : currencies;
    return this;
  }

  setQuantity(quantity = 0) {
    this.quantity = quantity;
    return this;
  }

  setCustom(custom) {
    this.custom = custom;
    return this;
  }

  setDiscountToken(tokens = "BUDS,SOULS") {
    this.discountToken = tokens;
    return this;
  }

  setDiscountAmount(amount = 10) {
    this.discountAmount = amount;
    return this;
  }

  submit(action = "https://hivepay.io/pay/") {
    const form = document.createElement("form");
    form.style.display = "none";
    form.method = "POST";
    form.action = action;/*"https://hivepay.io/pay/"*/ //"https://paywithhive.com/invoice";https://api.hivepay.io/

    const quantity = document.createElement("input");
    quantity.type = "hidden";
    quantity.name = "quantity";
    quantity.value = this.quantity;
    form.appendChild(quantity);

    const merchant = document.createElement("input");
    merchant.type = "hidden";
    merchant.name = "merchant";
    merchant.value = this.merchant;
    form.appendChild(merchant);

    const itemName = document.createElement("input");
    itemName.type = "hidden";
    itemName.name = "item_name";
    itemName.value = this.itemName;
    form.appendChild(itemName);

    const itemDescription = document.createElement("input");
    itemDescription.type = "hidden";
    itemDescription.name = "description";
    itemDescription.value = this.itemDescription;
    form.appendChild(itemDescription);

    const notifyUrl = document.createElement("input");
    notifyUrl.type = "hidden";
    notifyUrl.name = "notify_url";
    notifyUrl.value = this.notifyUrl;
    form.appendChild(notifyUrl);

    const cancel_url = document.createElement("input");
    cancel_url.type = "hidden";
    cancel_url.name = "cancel_url";
    cancel_url.value = this.returnUrl;
    form.appendChild(cancel_url);

    const returnUrl = document.createElement("input");
    returnUrl.type = "hidden";
    returnUrl.name = "return_url";
    returnUrl.value = this.returnUrl;
    form.appendChild(returnUrl);

    const amount = document.createElement("input");
    amount.type = "hidden";
    amount.name = "amount";
    amount.value = this.amount;
    form.appendChild(amount);

    const baseCurrency = document.createElement("input");
    baseCurrency.type = "hidden";
    baseCurrency.name = "base_currency";
    baseCurrency.value = this.baseCurrency;
    form.appendChild(baseCurrency);

    const payCurrencies = document.createElement("input");
    payCurrencies.type = "hidden";
    payCurrencies.name = "pay_currency";
    payCurrencies.value = this.payCurrency;
    form.appendChild(payCurrencies);

    const merchant_email = document.createElement("input");
    merchant_email.type = "hidden";
    merchant_email.name = "merchant_email";
    merchant_email.value = this.merchant_email;
    form.appendChild(merchant_email);

    const merchant_image = document.createElement("input");
    merchant_image.type = "hidden";
    merchant_image.name = "merchant_image";
    merchant_image.value =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c308aa0d-49d2-4635-af63-a881b4e4fe00/public";
    form.appendChild(merchant_image);

    const background = document.createElement("input");
    background.type = "hidden";
    background.name = "background";
    background.value = "medium";
    form.appendChild(background);

    const hpRef = document.createElement("input");
    hpRef.type = "hidden";
    hpRef.name = "hp-ref";
    hpRef.value = "chocolatoso";
    form.appendChild(hpRef);

    const merchant_webpage = document.createElement("input");
    hpRef.type = "hidden";
    hpRef.name = "merchant_webpage";
    hpRef.value = "https://game.infernalcoliseum.fun/";
    form.appendChild(merchant_webpage);

    const discount_token = document.createElement("input");
    discount_token.type = "hidden";
    discount_token.name = "discount_token";
    discount_token.value = this.discountToken || "BUDS,SOULS";
    form.appendChild(discount_token);

    const discount_amount = document.createElement("input");
    discount_amount.type = "hidden";
    discount_amount.name = "discount_amount";
    discount_amount.value = this.discountAmount || 10;
    form.appendChild(discount_amount);

    if (this.custom) {
      const custom = document.createElement("input");
      custom.type = "hidden";
      custom.name = "custom";
      custom.value = this.custom;
      form.appendChild(custom);
    }

    document.children[0].appendChild(form);
    form.submit();
  }
}
