import React, { Component } from "react";
//import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import "./guilds.css";
import { Skeleton } from "primereact/skeleton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressBar } from "primereact/progressbar";

class GuildZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleLeft: false,
      loading: false,
      actualPath: "none",
      xp: 0,
      xpEnviada: 0,
      guild: {},
    };
    this.getOption = this.getOption.bind(this);
  }

  getXpNecesaria() {
    let actualPath = this.state.actualPath;
    console.log(
      "🚀 ~ file: guildzone.js ~ line 34 ~ GuildZone ~ getXpNecesaria ~ actualPath",
      actualPath
    );
    let xp = this.state.xpEnviada;
    console.log(
      "🚀 ~ file: guildzone.js ~ line 36 ~ GuildZone ~ getXpNecesaria ~ xp",
      xp
    );

    switch (actualPath) {
      case "XP farming":
        if (xp < 250) {
          return 250;
        }

        if (xp > 250 && xp < 750) {
          return 750;
        }

        if (xp > 750 && xp < 1250) {
          return 1500;
        }

        if (xp > 1250 && xp < 1750) {
          return 1750;
        }

        if (xp > 1750 && xp < 2250) {
          return 2250;
        }

        if (xp >= 2250) {
          return 2250;
        }
        break;
      case "Bonus Chest":
        if (xp < 300) {
          return 300;
        }

        if (xp < 600) {
          return 600;
        }

        if (xp < 900) {
          return 900;
        }

        if (xp < 1200) {
          return 1200;
        }

        if (xp < 1500) {
          return 1500;
        }

        if (xp < 1800) {
          return 1800;
        }

        if (xp < 2100) {
          return 2100;
        }

        if (xp < 2400) {
          return 2400;
        }

        if (xp < 2700) {
          return 2700;
        }

        if (xp < 3000) {
          return 3000;
        }

        if (xp < 3300) {
          return 3300;
        }

        break;
      case "Side of the Angels":
        if (xp < 250) {
          return 250;
        }

        if (xp < 500) {
          return 500;
        }

        if (xp < 750) {
          return 750;
        }

        if (xp < 1000) {
          return 1000;
        }

        if (xp < 1250) {
          return 1250;
        }

        if (xp < 1500) {
          return 1500;
        }

        if (xp < 1750) {
          return 1750;
        }

        if (xp < 2000) {
          return 2000;
        }

        if (xp < 2250) {
          return 2250;
        }

        if (xp < 2500) {
          return 2500;
        }

        if (xp < 2750) {
          return 2750;
        }

        if (xp < 3000) {
          return 3000;
        }
        break;
      case "Side of the Demons":
        if (xp < 250) {
          return 250;
        }

        if (xp < 500) {
          return 500;
        }

        if (xp < 750) {
          return 750;
        }

        if (xp < 1000) {
          return 1000;
        }

        if (xp < 1250) {
          return 1250;
        }

        if (xp < 1500) {
          return 1500;
        }

        if (xp < 1750) {
          return 1750;
        }

        if (xp < 2000) {
          return 2000;
        }

        if (xp < 2250) {
          return 2250;
        }

        if (xp < 2500) {
          return 2500;
        }

        if (xp < 2750) {
          return 2750;
        }

        if (xp < 3000) {
          return 3000;
        }
        break;
      case "Over Powered":
        if (xp < 500) {
          return 500;
        }

        if (xp < 1000) {
          return 1000;
        }

        if (xp < 1500) {
          return 1500;
        }

        if (xp < 2000) {
          return 2000;
        }

        if (xp < 2500) {
          return 2500;
        }

        if (xp < 3000) {
          return 3000;
        }

        if (xp < 3500) {
          return 3500;
        }

        if (xp < 4000) {
          return 4000;
        }

        if (xp < 4500) {
          return 4500;
        }

        if (xp < 5000) {
          return 5000;
        }
        break;
    }
  }
  getXpGanada() {
    let xp = this.state.xpEnviada;
    console.log(
      "🚀 ~ file: guildzone.js ~ line 160 ~ GuildZone ~ getXpGanada ~ xp",
      xp
    );

    if (xp == 250) {
      return 0;
    }

    if (xp > 250 && xp < 750) {
      return 1;
    }

    if (xp > 750 && xp < 1250) {
      return 2;
    }

    if (xp > 1250 && xp < 1750) {
      return 3;
    }

    if (xp > 1750 && xp < 2250) {
      return 4;
    }

    if (xp >= 2250) {
      return 5;
    }

    return 0;
  }

  getActive(actualPath) {
    let active = actualPath;
    switch (active) {
      case "":
        return 0;
      case "XP farming":
        return 0;
      case "Bonus Chest":
        return 1;
      case "Side of the Angels":
        return 2;
      case "Side of the Demons":
        return 3;
      case "Over Powered":
        return 4;
      case "The Bridge":
        return 5;
      default:
        return 0;
    }
  }

  render() {
    return this.state.loading ? (
      <div className="field col-12 md:col-6">
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton height="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    ) : (
      <div className="orderlist-demo field col-12 md:col-6">
        <div className="product-item">
          <div className="image-container">
            <img
              src={this.state.guild?.img}
              onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
            />
          </div>
          <div className="product-list-detail">
            <h5 className="mb-2">{this.state.guild?.name}</h5>

            <span className="product-category">
              LVL: {parseInt("" + this.state.xp / 1000)}
            </span>
          </div>
          <div className="product-list-action">
            <h6 className="mb-2">XP: {this.state.xp}</h6>
            <span className={`product-badge status-instock`}>
              max members: {this.state.guild?.members}
            </span>
            <span className={`product-badge status-instock`}>
              <Button
                label="Leave"
                icon="pi pi-check"
                onClick={() => {
                  this.leaveGuild();
                }}
              />
            </span>
          </div>
        </div>

        <h1>Actual path : {this.state.actualPath}</h1>
        <ProgressBar
          value={100 * (this.state.xpEnviada / this.getXpNecesaria()) || 0}
        ></ProgressBar>
        <br></br>
        <Accordion activeIndex={5}>
          <AccordionTab header="XP farming">
            <h1>
              Bonus - All Guild Member get +
              <strong>{this.getXpGanada()}</strong> XP per day.
            </h1>
            <h3> Unlock values XP</h3>
            <ul>
              <li>250 - +1xp</li>
              <li>750 - +2xp</li>
              <li>1250 - +3xp</li>
              <li>1750 - +4xp</li>
              <li>2250 - +5xp</li>
            </ul>
          </AccordionTab>
          <AccordionTab header="Bonus Chest">
            <h1>
              Bonus - Opens a free chest which gives the same bonus to all
              members.
            </h1>
            <h3> Unlock values XP</h3>
            <ul>
              <li>300 - 14 days</li>
              <li>600 - 13 days</li>
              <li>900 - 12 days</li>
              <li>1200 - 11 days</li>
              <li>1500 - 10 days</li>
              <li>1800 - 9 days</li>
              <li>2100 - 8 days</li>
              <li>2400 - 7 days</li>
              <li>2700 - 6 days</li>
              <li>3000 - 5 days</li>
              <li>3300 - 4 days</li>
            </ul>

            <strong>Prizes are guaranteed 1-10XP , 3% V2 </strong>
          </AccordionTab>
          <AccordionTab header="Side of the Angels">
            <h1>Bonus- bonus power to all NFTs Angel side</h1>
            <h3> Unlock values XP</h3>
            <ul>
              <li>250 - 1% </li>
              <li>500 - 2% </li>
              <li>750 - 3% </li>
              <li>1000 - 4% </li>
              <li>1250 - 5% </li>
              <li>1500 - 6% </li>
              <li>1750 - 7% </li>
              <li>2000 - 8% </li>
              <li>2250 - 9% </li>
              <li>2500 - 10% </li>
              <li>2750 - 11% </li>
              <li>3000 - 12% </li>
            </ul>
          </AccordionTab>
          <AccordionTab header="Side of the Demon">
            <h1>Bonus- bonus power to all NFTs Demon side</h1>
            <h3> Unlock values XP</h3>
            <ul>
              <li>250 - 1% </li>
              <li>500 - 2% </li>
              <li>750 - 3% </li>
              <li>1000 - 4% </li>
              <li>1250 - 5% </li>
              <li>1500 - 6% </li>
              <li>1750 - 7% </li>
              <li>2000 - 8% </li>
              <li>2250 - 9% </li>
              <li>2500 - 10% </li>
              <li>2750 - 11% </li>
              <li>3000 - 12% </li>
            </ul>
          </AccordionTab>
          <AccordionTab header="Over Powered">
            <h1>
              {" "}
              Bonus- stacks with side of angels/demons but applies to both
              sides.{" "}
            </h1>
            <h3> Unlock values XP</h3>
            <ul>
              <li>500 - 1% </li>
              <li>1000 - 2% </li>
              <li>1500 - 3% </li>
              <li>2000 - 4% </li>
              <li>2500 - 5% </li>
              <li>3000 - 6% </li>
              <li>3500 - 7% </li>
              <li>4000 - 8% </li>
              <li>4500 - 9% </li>
              <li>5000 - 10% </li>
            </ul>
          </AccordionTab>

          {this.state.guild?.guildmaster == localStorage.getItem("username") ? (
            <AccordionTab header="The Bridge">
              <h1>Change Path</h1>
              <p>Only one path can be activated at the same time</p>

              {localStorage.getItem("username") ==
                this.state.guild.guildmaster ? (
                <ul>
                  <li>
                    {" "}
                    <Button
                      label="Change Path to XP farming"
                      onClick={() => this.changePath("XP farming")}
                    />
                  </li>
                  <li>
                    {" "}
                    <Button
                      label="Change Path Bonus Chest"
                      onClick={() => this.changePath("Bonus Chest")}
                    />
                  </li>
                  <li>
                    {" "}
                    <Button
                      label="Change Path  Side of the Angels"
                      onClick={() => this.changePath("Side of the Angels")}
                    />
                  </li>
                  <li>
                    {" "}
                    <Button
                      label="Change Path Side of the Demon"
                      onClick={() => this.changePath("Side of the Demon")}
                    />
                  </li>
                  <li>
                    {" "}
                    <Button
                      label="Change Path Over Powered"
                      onClick={() => this.changePath("Over Powered")}
                    />
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </AccordionTab>
          ) : (
            <></>
          )}
        </Accordion>
        <br></br>
        <h1>User Donation</h1>
        <DataTable
          header="Users on guild"
          responsiveLayout="scroll"
          value={this.state.data.usuarios}
        >
          <Column field="username" header="Username" />
          <Column field="xp" header="Xp" />
          <Column field="xpdonated" header="Ixp donated" />
          <Column body={this.getOption} header="option" />
        </DataTable>
      </div>
    );
  }

  getOption(item) {
    if (item.username == localStorage.getItem("username")) {
      return (
        <Button
          label="Donate"
          onClick={() => {
            let amount = prompt("How much IXP do you want to donate?");
            if (amount == null || amount == "") {
              alert("You must enter a number");
            }

            const HP = new HivePay("infernalcoliseum");
            HP.setItemName("Donate guild")
              .setItemDescription("Path " + this.state.actualPath)
              .setMerchant_email("blackmirague@gmail.com")
              .setNotifyUrl(
                "https://guerrerosconsultoresas.com.co/IF/HPdonateGuild.php"
              )
              .setReturnUrl("https://game.infernalcoliseum.fun/guilds")
              .setAmount(amount)
              .setQuantity(1)
              .setBaseCurrency("IXP")
              .setPayCurrencies(["IXP"])
              .setCustom(
                JSON.stringify({
                  username: localStorage.getItem("username"),
                })
              );

            HP.submit();
          }}
        />
      );
    }
  }

  leaveGuild() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, leave me!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        fetch("https://backend.infernalcoliseum.fun/api", {
          body: `{"query":"mutation{\\n  salirUsuarioGuild(username:\\"${localStorage.getItem(
            "username"
          )}\\"){\\n    success\\n    message\\n  }\\n}"}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            Dnt: "1",
          },
          method: "POST",
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.data.expulsarUsuarioGuild.success) {
              alert("You have left the guild");
              window.location.href = "https://game.infernalcoliseum.fun/guilds";
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.data.expulsarUsuarioGuild.message,
              });
            }
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your are safe :)", "error");
      }
    });
  }

  changePath(newPath) {
    Swal.showLoading();
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation{\\n  changePath(path:\\"${newPath}\\"){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((e) => e.json())
      .then((response) => {
        console.log(
          "🚀 ~ file: guildzone.js ~ line 295 ~ GuildZone ~ .then ~ response",
          response
        );

        if (response.data.changePath.success) {
          Swal.fire({
            title: "Success",
            text: response.data.changePath.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.changePath.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  }

  componentDidMount() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n   getGuildData{\\n    success\\n    message\\n    data {\\n guild{\\n name\\n lvl\\n xp\\n members\\n img\\n discordUrl\\n guildmaster } \\n      guildXp\\n      xpEnviada\\n      actualPath\\n      usuarios{\\n        username\\n        xp\\n   xpdonated\\n   }\\n      xpusuarios {\\n        username\\n        xp\\n      } \\n    }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.data.getGuildData.success) {
          this.setState({
            data: response.data.getGuildData.data,
            guild: response.data.getGuildData.data.guild,
            loading: false,
            actualPath: response.data.getGuildData.data.actualPath,
            xp: response.data.getGuildData.data.guildXp,
            xpEnviada: response.data.getGuildData.data.xpEnviada,
            activeIndex: this.getActive(
              response.data.getGuildData.data.actualPath
            ),
          });
        } else {
          this.setState({
            loading: false,
          });

          Swal.fire({
            title: "Error",
            text: response.data.getGuildData.message,
            icon: "error",
            button: "Ok",
          });
        }
      });
  }
}

export default GuildZone;
