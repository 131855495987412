import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

class LandFarming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plots: [],
      sellingLandId: null,
    };
  }

  renderLandChildren(land) {
    return (
      <div>
        <p>CAPITAL: {land.CAPITAL}</p>
        <p>CITY: {land.CITY}</p>
        <p>TOWN: {land.TOWN}</p>
        <Button
          label="startAuction"
          onClick={() => this.handleSellButtonClick(land)}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignContent: "space-between",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {this.renderSellSection()}
          {/* Renderizar el espacio de venta si está activo */}
        </div>
      </div>
    );
  }

  handleSellButtonClick(landId) {
    // Establecer el ID de la tierra que se está vendiendo en el estado
    this.setState({ sellingLandId: landId });
  }

  renderSellSection() {
    const { sellingLandId } = this.state;

    if (!sellingLandId) {
      return null;
    }

    let {
      CAPITAL,

      CITY,

      TOWN,
    } = sellingLandId;

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignContent: "space-between",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <InputText placeholder="Cantidad" />
        <Dropdown
          value={this.state.selectedToken}
          options={["SOULS", "BUDS", "SWAP.HIVE"]}
          onChange={(e) => this.setState({ selectedToken: e.value })}
          placeholder="Seleccione un token"
        />
        <Dropdown
          value={this.state.selectedState}
          options={[CAPITAL, CITY, TOWN]}
          onChange={(e) => this.setState({ selectedState: e.value })}
          placeholder="Seleccione un token"
        />
        <Button label="Send to Sell" />
      </div>
    );
  }

  renderLandAccordion(land) {
    let header = land.land.COUNTRY + " - " + land.currentPrice + " SOULS";
    return (
      <AccordionTab key={land._id} header={header}>
        {this.renderLandChildren(land.land)}
      </AccordionTab>
    );
  }

  render() {
    const { plots } = this.state;
    return (
      <Sidebar
        fullScreen
        visible={this.props.visible}
        onHide={() => this.props.close()}
      >
        <div className="container flex justify-content-center">
          <div className="container text-center">
            <h1>Lands Board</h1>
          </div>
        </div>
        <div className="container text-center">
          {this.state.plots.length == 0 && (
            <>
              <h1> U dont have plots owned</h1>
            </>
          )}

          <Accordion multiple={true}>
            {plots.map((land) => this.renderLandAccordion(land))}
          </Accordion>
        </div>
      </Sidebar>
    );
  }

  componentDidMount() {
    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"{\\n  plotsDataOwned {\\n    _id\\n    land {\\n      _id\\n      COUNTRY\\n      CAPITAL\\n      CITY\\n      TOWN\\n      SIDE\\n    }\\n    currentPrice\\n    deadline\\n    status\\n    username\\n    trx\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        this.setState({
          plots: e.data.plotsDataOwned || [],
        });

        console.log("estados", this.state.plots);

        Swal.close();
      });
  }
}

export default LandFarming;
