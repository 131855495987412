import React, { Component } from "react";
//import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";

import { Avatar } from "primereact/avatar";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";
import { TabView, TabPanel } from "primereact/tabview";

import { Dialog } from "primereact/dialog";
import GuildList from "./guildList";
import GuildManager from "./guildManager";
import GuildZone from "./guildzone";
import LandSale from "../lands/landSale";
import BattleZone from "./guildBattle";

class Guilds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleLeft: false,
      createGuild: false,
      guildN: "",
    };
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  render() {
    let { visibleLeft } = this.state;

    const items = [
      {
        label: "Create Guild",
        command: () => {
          this.setState({ createGuild: true });
        },
      },
    ];

    return (
      <div className="c">
        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />

        {/* mostrar creacion de guild */}

        <Dialog
          header="Create guild form"
          visible={this.state.createGuild}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ createGuild: false })}
        >
          <label for="text" className="col-4 col-form-label">
            Guild Name
          </label>
          <div className="col-8">
            <div className="input-group">
              <input
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ guildN: e.target.value });
                }}
                id="text"
                name="text"
                type="text"
                className="form-control"
              />
            </div>
            <p>
              the cost of the guild is 10 dollars, it includes a discord channel
              with a forum. send a message to discord once the payment is made{" "}
              <a href="https://discord.com/channels/785537182324621332/812402652750544937">
                here
              </a>
            </p>

            <button
              onClick={() => {
                let validator = this.state.guildN.trim();

                if (validator.length < 2) {
                  alert(
                    "the name of the guild must be at least 2 characters long"
                  );
                  return;
                }

                fetch("https://backend.infernalcoliseum.fun/api", {
                  body: `{"query":"mutation{\\n  validateGuild(guild:\\"${this.state.guildN}\\") {\\n    success\\n    message\\n  }\\n}"}`,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Dnt: "1",
                  },
                  method: "POST",
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (!res.data.validateGuild.success) {
                      const HP = new HivePay("infernalcoliseum");
                      HP.setItemName("Create guild")
                        .setItemDescription("Create guild " + this.state.guildN)
                        .setMerchant_email("blackmirague@gmail.com")
                        .setNotifyUrl(
                          "https://guerrerosconsultoresas.com.co/IF/HPcreateGuild.php"
                        )
                        .setReturnUrl("https://game.infernalcoliseum.fun")
                        .setAmount(10)
                        .setQuantity(1)
                        .setBaseCurrency("USD")
                        .setCustom(
                          JSON.stringify({
                            guild: this.state.guildN,
                            username: localStorage.getItem("username"),
                          })
                        )
                        .setBaseCurrency(
                          "SOULS,HIVE,BUDS,SWAP.HIVE, SWAP.HBD,HBD,DEC"
                        )
                        .setPayCurrencies([
                          "SOULS",
                          "BUDS",
                          "HIVE",
                          "SWAP.HIVE",
                          "SWAP.HBD",
                          "HBD",
                          "DEC",
                        ]);

                      HP.submit();
                    } else {
                      Swal.fire({
                        title: "Error",
                        text: res.data.validateGuild.message,
                        icon: "error",
                        confirmButtonText: "Ok",
                      });
                    }
                  });
              }}
            >
              Validate
            </button>
          </div>
        </Dialog>

        <Menubar
          model={items}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    this.setState({
                      visibleLeft: true,
                    });
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />

        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Guilds</h1>

              <TabView>
                <TabPanel header="Guild List">
                  <GuildList />
                </TabPanel>
                <TabPanel header="Manage Guild">
                  <GuildManager />
                </TabPanel>
                <TabPanel header="Guild zone">
                  <GuildZone />
                </TabPanel>
                <TabPanel header="Battle zone">
                  <BattleZone />
                </TabPanel>
                <TabPanel header="Badge zone"></TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guilds;
