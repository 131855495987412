import React, { Component } from "react";
//import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import "./guilds.css";
import { Skeleton } from "primereact/skeleton";
class GuildList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleLeft: false,
      loading: true,
      guilds: [],
    };
    this.getGuilds = this.getGuilds.bind(this);
  }

  imageBodyTemplate(rowData) {
    return <img src={`${rowData.img}`} className="product-image" />;
  }

  ratingBodyTemplate(rowData) {
    return <Rating value={rowData.lvl} readOnly cancel={false} />;
  }

  render() {
    const header = (
      <div className="table-header">
        GuildList
        <Button
          onClick={() => {
            this.setState({
              loading: true,
            });
            this.getGuilds();
          }}
          icon="pi pi-refresh"
        />
      </div>
    );
    const footer = `In total there are ${this.state.guilds ? this.state.guilds.length : 0
      } guilds.`;
    return this.state.loading ? (
      <div className="field col-12 md:col-6">
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton height="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    ) : (
      <DataTable
        value={this.state.guilds}
        header={header}
        footer={footer}
        responsiveLayout="scroll"
      >
        <Column field="guildmaster" header="Guild Master"></Column>
        <Column field="name" header="Name"></Column>
        <Column header="Shield" body={this.imageBodyTemplate}></Column>
        <Column
          field="lvl"
          header="LVL"
          body={this.ratingBodyTemplate}
        ></Column>
        <Column field="members" header="Members"></Column>
        <Column body={this.getActions} header="Actions"></Column>
      </DataTable>
    );
  }

  getActions(data) {
    if (data.guildmaster != localStorage.getItem("username")) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (!userData?.guild) {
        return (
          <Button
            label="Join"
            icon="pi pi-check"
            onClick={() => {
              fetch("https://backend.infernalcoliseum.fun/api", {
                body: `{"query":"mutation {\\n  solicitudUnionGuild(guild:\\"${data.name}\\"){\\n    success\\n    message\\n  }\\n}"}`,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                  Dnt: "1",
                },
                method: "POST",
              })
                .then((r) => r.json())
                .then((r) => {
                  console.log(r);
                  if (r.data.solicitudUnionGuild.success) {
                    Swal.fire({
                      icon: "success",
                      title: "Success",
                      text: r.data.solicitudUnionGuild.message,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: r.data.solicitudUnionGuild.message,
                    });
                  }
                });
            }}
          />
        );
      }
    }
  }

  getGuilds() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getGuilds{\\n    guildmaster\\n    name\\n    lvl\\n    xp\\n    members\\n    discordUrl\\n    img\\n  }  \\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((r) => r.json())
      .then((response) => {
        console.log("response", response);
        if (response.data?.getGuilds) {
          this.setState({
            ...this.state,
            loading: false,
            guilds: response.data.getGuilds,
          });
          Swal.close();
        }
      });
  }

  componentDidMount() {
    this.getGuilds();
  }
}

export default GuildList;
