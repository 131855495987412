import React, { Component } from "react";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";

import { Tooltip } from "primereact/tooltip";
//import Logo from "../../assets/img/LOGO.png";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

class Venta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descuento: 0,
      visibleLeft: false,
      cantidad: 0,
      total: 0,
    };
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  evaluateAll(e) {
    let descuento = 0;
    if (e.value >= 300) {
      descuento = descuento + 10;
    } else if (e.value >= 200) {
      descuento = descuento + 7;
    } else if (e.value >= 150) {
      descuento = descuento + 5;
    } else if (e.value >= 50) {
      descuento = descuento + 3;
    } else if (e.value >= 10) {
      descuento = descuento + 1;
    }
    this.setState({
      cantidad: e.value,
      descuento: descuento,
      total: 2.5 * e.value - 2.5 * e.value * (descuento / 100),
    });
  }

  buy() {
    let userData = JSON.parse(localStorage.getItem("userData"));

    let { cantidad, descuento, total } = this.state;
    if (userData) {
      let startDate = new Date(parseInt(userData.signupDate));

      // Calcula la cantidad de milisegundos que han pasado desde esa fecha hasta el momento actual
      var currentTime = Date.now();
      var timeDifference = currentTime - startDate;

      // Divide el número de milisegundos por el número de milisegundos en un día para obtener la cantidad de días que han pasado
      var daysSinceStart = timeDifference / 86400000;

      let dias = parseInt("" + daysSinceStart, 10);

      if (dias >= 365) {
        descuento = descuento + 10;
      } else if (dias >= 180) {
        descuento = descuento + 7;
      } else if (dias >= 91) {
        descuento = descuento + 5;
      } else {
        descuento = descuento + 2;
      }
    }

    Swal.fire({
      title: "full discount applies on hivePay page...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });


    const HP = new HivePay("infernalcoliseum");
    HP.setItemName("Buy infernal packs")
      .setItemDescription(
        "infernal packs u can get 3 nfts 1 conqueror 2 recolectors"
      )
      .setMerchant_email("blackmirague@gmail.com")
      .setNotifyUrl("https://guerrerosconsultoresas.com.co/IF/HPbuyChest.php")
      .setReturnUrl("https://game.infernalcoliseum.fun");

    utils
      .getAllNfts()
      .then((e) => {
        let quantityOfNfs = e.length;
        if (quantityOfNfs >= 500) {
          descuento = descuento + 10;
        } else if (quantityOfNfs >= 300) {
          descuento = descuento + 7;
        } else if (quantityOfNfs >= 250) {
          descuento = descuento + 5;
        } else if (quantityOfNfs >= 100) {
          descuento = descuento + 3;
        } else if (quantityOfNfs >= 50) {
          descuento = descuento + 1;
        }
      })
      .finally(function () {
        HP.setAmount(2.5)
          .setDiscountAmount(30)
          .setDiscountToken("SOULS,BUDS,HIVE,HBD,SWAP.HIVE,SWAP.HBD,DEC,KOD")
          .setPayCurrencies("SOULS,BUDS,HIVE,HBD,SWAP.HIVE,SWAP.HBD,DEC,KOD")
          .setQuantity(cantidad)
          .setBaseCurrency("USD")
          .setCustom(
            JSON.stringify({
              pack: "packs",
              username: localStorage.getItem("username"),
              amount: "" + cantidad
            })
          );
        HP.submit();
      });
  }

  render() {
    let { visibleLeft } = this.state;
    return (
      <>
        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />
        <Menubar
          model={[]}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    this.setState({
                      visibleLeft: true,
                    });
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            className="pulsate-fwd"
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2983ed18-e4f5-42a4-75e0-c7434c710300/public"
            alt="Cofre"
          />
          <h2 style={{ color: "black" }}>
            Can you get ICPVTWO packages which contain 1 conqueror nft and 2 collector nfts.
          </h2>
          <h3 style={{ color: "white" }}>Discount: {this.state.descuento}%</h3>

          <h2 style={{ color: "white" }}>Total: {this.state.total} USD</h2>
          <p>
            <label style={{ color: "white" }} htmlFor="integeronly">
              Quantity:
            </label>

            <InputNumber
              inputId="integeronly"
              value={this.state.cantidad}
              onChange={(e) => this.evaluateAll(e)}
            />
          </p>
          <Button onClick={(e) => this.buy()}>Comprar</Button>
        </div>
      </>
    );
  }
}

export default Venta;
