import React, { Component } from "react";

import { mapAreas } from "../configs/map";

import ImageMapper from "react-img-mapper";

import Map from "../assets/img/LAND.webp";
//import Map from "../assets/img/LAND NAVIDAD.png";
import mapInfo from "../assets/img/MAPinfo.png";

//import mapInfo from "../assets/img/MAPinfoNavidad.png";

import Ocean from "../assets/img/OCEAN.webp";
import Maploop from "../assets/img/NUBES LOOP.webm";
//import Maploop from "../assets/img/NIEVE WEB1.webm";

import Logo from "../assets/img/public.avif";
import Loading from "../assets/img/FAST LOADING.webm";

import { ProgressBar } from "primereact/progressbar";

import InfoModalMap, { hover, showModal } from "./modals/informap";
import GameNavbar from "./navbar";

import HivePay from "../utils/HivePay";

import SidebarGame from "./sidebar";
//const Map = "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be15e513-7c7f-4e43-0eb1-54db7f466800/public";

//const Logo = "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e04892d9-7483-4aec-d720-d9b53b57f600/public";
import { Dialog } from "primereact/dialog";
import WS from "../utils/ws";

import Information from "./modals/information";

import AdSenseAds from "./extras/AdSenseAds";
import { Menubar } from "primereact/menubar";
import utils from "../utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from 'primereact/toast';
import { InputSwitch } from "primereact/inputswitch";

import StWidget from "./extras/stwidget";

import { isMobile } from 'react-device-detect';
import { Dropdown } from 'primereact/dropdown';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';




import { FarmeoCiudades } from './ciudades/farming';


let ws = new WS();
class Game extends Component {
  constructor(props) {
    super(props);
    console.log("🚀 ~ Game ~ constructor ~ props:", props)
    this.state = {
      showModal: false,
      area: {},
      loading: true,
      presaleCounter: false,
      amount: 0,
      preventa: false,
      visibleLeft: true,
      KNALAND: 0,
      NOGROD: 0,
      now: 25,
      statusLoading: "Loading...",
      dialog: true,
      hoveredArea: false,
      displayInformation: false,
      divSize: { width: 0, height: 0 },
      principal: true,
      infoAllZone: [],
      checked: false,
      subPlots: []
    };
    this.divRef = React.createRef();
    this.toast = React.createRef();
    let map_x = {
      name: "MAP",
      areas: mapAreas,
    };
    this.map_x = map_x;
    this.showModal = showModal;
    this.hover = hover;
    this.pve = this.pve.bind(this);
    this.pvp = this.pvp.bind(this);
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  getDivSize = () => {
    const div = this.divRef.current;
    if (div) {
      const rect = div.getBoundingClientRect();
      this.setState({ divSize: { width: rect.width, height: rect.height } });

    }
  };

  enterArea(area) {
    this.setState({ hoveredArea: area });
  }

  leaveArea(area) {
    this.setState({ hoveredArea: false });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }


  selectedLandTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name} {option.xp}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  LandTemplate = (option) => {
    return (
      <div className="flex align-items-center" style={{ backgroundColor: option?.hasNft ? "green" : "unset" }}>
        <div>{option.name + " " + option.xp}</div>
      </div>
    );
  };



  render() {
    let { loading, presaleCounter, preventa, visibleLeft, principal } = this.state;

    let img = `https://images.hive.blog/u/${localStorage.getItem(
      "username"
    )}/avatar`;

    let username = JSON.parse(localStorage.getItem("userData"));

    if (loading) {
      return (
        <div className="authentication">
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              width: '100%',
              height: '100vh', // Usando unidades relativas para el alto
              fontSize: '4vh', // Usando unidades relativas para el tamaño de fuente
            }}
          >
            <video autoPlay muted style={{ width: '100%', maxWidth: '900px' }}>
              <source muted loop src={Loading} type="video/webm" />
            </video>
            <h6 style={{
              color: 'red', fontFamily: 'Verdana', marginTop: '1rem', textDecoration: "solid",
              textTransform: "uppercase"
            }}>
              Infernal Coliseum
            </h6>

            <ProgressBar style={{ width: '80%', marginTop: '1rem' }} mode="indeterminate" />

            <label
              style={{
                fontSize: '2vh', // Usando unidades relativas para el tamaño de fuente
                fontFamily: 'Verdana',
                marginTop: '1rem',
              }}
            >
              {this.state.statusLoading}
            </label>
          </div>
        </div>
      );
    } else if (!isMobile) {
      return (

        <>


          <img
            style={{

              zIndex: "999999999999",
              borderRadius: "9999px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#0003",
              position: "fixed",
              inset: " 90% 0% 0% 96%",
              width: "4%",
              cursor: "pointer",
            }}
            id="chat"
            src="https://images.hive.blog/u/hive-125785/avatar/medium"
          />



          <>          <Toast ref={this.toast} /> </>
          <div className="flex">
            <div style={{ zIndex: "9", width: "100%" }}>
              <Menubar
                model={[]}
                end={<></>}
                start={() => {
                  return (
                    <>
                      <img
                        src={this.getIcono()}
                        style={{ width: "10%", cursor: "pointer" }}
                        className="mr-2"
                        onClick={() => {
                          this.setState({
                            visibleLeft: false,
                            principal: false
                          });
                        }}
                      />
                      {localStorage.getItem("username")}
                    </>
                  );
                }}
              />
            </div>
          </div>


          <div className="flex">
            <div style={{
              overflowY: "scroll",
              maxHeight: "80vh"
            }} className="d-none d-md-flex flex-none align-items-center justify-content-center bg-primary font-bold m-2 px-5 py-3 border-round">
              <SidebarGame
                visibleLeft={!visibleLeft}
                principal={principal}
                onClose={() => this.setState({ visibleLeft: true, principal: true })}
              />
            </div>

          </div>
          <div className="flex">

            <div className="flex-auto flex align-items-center justify-content-center bg-primary font-bold m-2 px-5 py-3 border-round">

              <span
                style={{
                  color: "#fff",
                  padding: "10px",
                  background: "rgba(0, 0, 0, 0.8)",
                  borderRadius: "5px",
                  pointerEvents: "none",
                  zIndex: "1"
                }}
              >
                {this.state.hoveredArea &&
                  "Welcome to: " + this.state.hoveredArea.name +
                  " XP:" +
                  this.state.hoveredArea.xp}

                {!this.state.hoveredArea && "Welcome to infernal coliseum"}
              </span>



            </div>
          </div>

          <div className="flex">

            <div className="flex-auto flex align-items-center justify-content-center bg-primary font-bold m-2 px-5 py-3 border-round">
              <div class="grid w-100 h-100" >
                <div className="col-12" >
                  <div
                    ref={this.divRef}
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >

                    <Information
                      visible={this.state.displayInformation}
                      close={() => this.setState({ displayInformation: false })}
                    ></Information>


                    <img
                      style={{
                        width: "100%",
                        position: "fixed",
                        height: "100%",
                        inset: "0% 0% auto auto",
                        zIndex: "0",
                      }}
                      src={Ocean}
                    />

                    {!this.state.checked && (<>




                      {this.state.showModal && (

                        <InfoModalMap
                          username={this.props.username}
                          area={this.state.area}
                          shouldModalShow={() => this.shouldModalShow()}
                          modalStatus={this.state.showModal}
                          close={() => {
                            this.setState({ showModal: false });
                          }}
                        />
                      )}



                      <ImageMapper
                        src={Map}
                        map={this.map_x}
                        onMouseLeave={(area) => this.leaveArea(area)}
                        onClick={(area, index, e) => {
                          this.leaveArea(area);
                          //console.log(area, e);
                          //console.log(area,"userdata",localStorage.getItem("userData"))
                          this.hover(area, JSON.parse(localStorage.getItem("userData")), e);
                        }}
                        onMouseEnter={(area) => this.enterArea(area)}
                        responsive={true}
                        parentWidth={this.state.divSize.width}
                        parentHeight={this.state.divSize.height}
                        width={this.state.divSize.width}
                        height={this.state.divSize.height}
                      />


                    </>)}


                  </div>
                </div>

                {/* 
                <div className="col-12" style={{ zIndex: "1" }}>
                  <div className="table-responsive">

                    <Accordion>
                      {this.state.subPlots.map(item => (
                        <AccordionTab key={item.plot} header={this.GetNamePlot(item)}>



                          {item.subzones.map(subzone => (


                            <div key={subzone._id} className="flex flex-wrap p-2 align-items-center gap-3">
                              <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                                <span className="font-bold">{subzone.name}</span>

                              </div>
                              {subzone?.zona?.length > 0 && (<div style={{ float: 'right', display: "flex" }}>
                                <Button label="PVE" severity="info" onClick={e => { this.setState({ farmeoCiudad: true, ciudad: subzone }) }} />
                                <Button label="PVP" severity="warning" />
                              </div>)}

                            </div>


                          ))}

                        </AccordionTab>
                      ))}
                    </Accordion>

                  </div>


                    </div> */}


              </div>
            </div>

          </div>

          { /* false && <FarmeoCiudades ciudad={this.state.ciudad} onClose={() => this.setState({ farmeoCiudad: false })} ></FarmeoCiudades> */}

        </>




      )
    } else {
      if (this.map_x) {
        return (<>

          <img
            style={{
              width: "100%",
              position: "fixed",
              height: "100%",
              inset: "0% 0% auto auto",
              zIndex: "0",
            }}
            src={Ocean}
          />

          <div className="flex">

            <SidebarGame
              visibleLeft={!visibleLeft}
              principal={false}
              onClose={() => this.setState({ visibleLeft: true, principal: true })}
            />


            <Menubar
              model={[]}
              end={<></>}
              start={() => {
                return (
                  <>
                    <img
                      src={this.getIcono()}
                      style={{ width: "10%", cursor: "pointer" }}
                      className="mr-2"
                      onClick={() => {
                        this.setState({
                          visibleLeft: false,
                          principal: false
                        });
                      }}
                    />
                    {localStorage.getItem("username")}
                  </>
                );
              }}
            />

          </div>

          <div className="flex">


            <div style={{ zIndex: 1 }} class="flex-auto align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round">
              <Dropdown filter value={this.state.selectedCity} onChange={(e) => { this.setState({ selectedCity: e.value }) }} options={this.map_x.areas} optionLabel="name"
                valueTemplate={this.selectedLandTemplate} itemTemplate={this.LandTemplate}
                placeholder="Please select a land" className="w-full md:w-14rem" />
            </div>

          </div>

          <div className="flex">


            <div style={{ zIndex: 1 }} class="flex-auto align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round">
              {this.state.selectedCity && (<InfoModalMap username={this.props.username}
                area={this.state.selectedCity}
                isMobile={true}></InfoModalMap>)}
            </div>

          </div>

        </>)
      }

    }


  }


  GetNamePlot(plot) {

    return plot.plot;
  }




  comprar() {
    if (this.state.amount <= 0) {
      alert("Please enter a valid number");
      return;
    }
    let realPrice = this.state.amount * 2;

    const HP = new HivePay("infernalcoliseum");
    HP.setItemName("Token Pack")
      .setItemDescription("Get pack token")
      .setMerchant_email("blackmirague@gmail.com")
      .setNotifyUrl("https://guerrerosconsultoresas.com.co/IF/presaleSouls.php")
      .setReturnUrl("https://game.infernalcoliseum.fun")
      .setAmount(2)
      .setQuantity(realPrice / 2)
      .setDiscountAmount(30)
      .setDiscountToken("SOULS")
      .setBaseCurrency("SOULS")
      .setPayCurrencies(["SOULS"]);

    HP.submit();
  }

  inventory() {
    window.location.href = "/inventory";
  }

  displayModal(area) {
    // console.log(area);
  }

  shouldModalShow() {
    return this.state.showModal == true ? "block" : "none";
  }

  componentDidMount() {

    if (localStorage.getItem("displayMore") == "false") {
      //this.setState({ displayInformation: false });
    }

    if (this.state.presaleCounter || this.state.preventa) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000);
      return;
    }


    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getAllSubPlots {\\n_id \\n    name\\n    plot\\n  ZONA {\\n_id \\n name \\n coords } }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => {
        return response.json();
      }).then((data) => {

        const organizedData = {};

        for (const item of data.data.getAllSubPlots) {
          const plot = item.plot;
          const name = item.name;

          if (!organizedData[plot]) {
            organizedData[plot] = {
              plot: plot,
              subzones: []
            };
          }

          organizedData[plot].subzones.push({ name, _id: item._id, zona: item?.ZONA });
        }

        this.setState({ subPlots: Object.values(organizedData) }, () => {
          console.log("datos subplots", this.state.subPlots)
        })



      })



    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getGameMode\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data.data.getGameMode = 1;
        if (data.data.getGameMode == "1") {
          this.setState(
            { pve: true, statusLoading: "Getting PVE data...", now: 50 },
            () => {
              this.pve();
            }
          );
        } else if (data.data.getGameMode == "2") {
          this.setState(
            { pve: true, statusLoading: "Getting GUILDS data...", now: 50 },
            () => {
              this.guild();
            }
          );
        }
      });


    this.getDataSide();
  }

  getDataSide() {

    const promise1 = utils.getInfoForSide('demon');
    const promise2 = utils.getInfoForSide("angel");

    Promise.all([promise1, promise2])
      .then((responses) => {
        // Filtrar y combinar los datos
        const newData = responses.reduce((combinedData, data) => {
          return combinedData.concat(data.data.getInfoZona.filter((e) => e.conquistado === "system"));
        }, []);

        console.log("informacion", responses)
        // Actualizar el estado con los datos combinados
        this.setState({ infoAllZone: newData }, () => {

        });
      })
      .catch((error) => {
        // Manejar errores si es necesario
        console.error("Error al obtener datos:", error);
      });


  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getDivSize);
  }

  pve() {

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  userisLogin\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    }).then((response) => {
      response.json().then((data) => {
        //console.log("user is login", data);
        if (data.data.userisLogin) {
        } else {
          window.location.href = "/login";
        }
      });
    });

    console.log("getting user data ");
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getUserData{\\n    _id\\n    username\\n    guild\\n    path\\n    lvl\\n    xp\\n  token\\n   signupDate\\n cantidad_tokens\\n fecha_solicitud_retiro\\n essenceFarmer\\n essenceFisher\\n essenceMiner\\n  league\\n utilityToken\\n {\\n token\\n quantity\\n  }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data.getUserData.username == localStorage.getItem("username")
        ) {
          localStorage.removeItem("userData");
          localStorage.setItem(
            "userData",
            JSON.stringify(data.data.getUserData)
          );
        }
      });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {  \\n  parcelasNuevo {\\n    _id\\n    ZONA\\n    COUNTRY\\n    CAPITAL\\n    CITY\\n    TOWN\\n    SIDE\\n  strokeColor\\n  fillColor\\n XP\\n userHasnft \\n master} \\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        // console.log("server get", response);

        let mapAreas = response.data.parcelasNuevo.map((res) => {
          return {
            fullname: res.COUNTRY,
            name: res.COUNTRY,
            href: "#",
            fillColor: res?.userHasnft ? "rgba(0,255,0,1)" : res.fillColor,
            strokeColor: res?.userHasnft
              ? "rgba(0, 0, 0, 0.5)"
              : res.strokeColor,
            stayHighlighted: res?.userHasnft,
            preFillColor: res?.userHasnft ? "rgba(0,255,0,0.4)" : undefined,
            shape: "poly",
            coords: res.ZONA,
            side: res.SIDE,
            capital: res.CAPITAL,
            city: res.CITY,
            town: res.TOWN,
            xp: res.XP,
            master: res.master,
            hasNft: res?.userHasnft
          };
        });
        let map_y = {
          name: "MAP",
          areas: mapAreas,
        };
        this.map_x = map_y;
        this.showModal = showModal;
        this.hover = hover;



        setTimeout(() => {
          this.setState({
            ...this.state,
            loading: false,
          });

          if (!isMobile) {
            let img = document.getElementsByClassName("img-mapper-img")[0];
            let cloneImage = img.cloneNode(true);
            let infoClone = img.cloneNode(true);

            infoClone.src = mapInfo;
            infoClone.style.zIndex = 1;
            infoClone.style.display = "block";

            img.style.opacity = 0;

            img.style.zIndex = 99999;

            cloneImage.style.zIndex = 0;
            cloneImage.style.display = "block";

            img.parentElement.appendChild(cloneImage);
            img.parentElement.appendChild(infoClone);



            this.getDivSize();
            window.addEventListener('resize', this.getDivSize);
            //this.toast.current?.show({ severity: 'success', summary: 'Message', detail: 'a new season has begun...', life: 8000 });

            //console.log(img.parentElement);
            this.onPageLoaded()
          }
        }, 5000);
      });
  }

  guild() {
    ws.connect();
    ws.initMethods(this.onMessage);

    setTimeout((E) => {
      ws.logIn();
    }, 1500);
  }

  onPageLoaded() {
    // Acciones que deseas realizar
    var stwidget = new StWidget("https://chat.peakd.com/t/hive-125785/0");
    stwidget.properties = {
      requireLogin: false,
      showSidebar: true,
      sidebar: 2,
      sidebar2enableSharedView: false,
      sidebarToggleByChannelNameOnDirectGroup: false,
      streambarExpand: true,
      streambarMode: 1,
      sidebarAddButton: 1,
      communityChannelNameFormat: "C/<title>/<name>",
      messageIconFlexClass: "block text-justify lg:text-left sm:flex",
      messageIconClass: "iconFloat",
      "--appCommunityIconFontSize": "18px",
      "--appCommunityIconSize": "42px",
      homeTabCommunities: false,
      homeTabPreferences: true,
      homeTabThemes: true,
      onlyPrependCommunities: false,
      prependCommunities: ["hive-163399"],
      defaultTheme: "Light",
      "--appFontFamily": "'Century Schoolbook L'",
      "--appFontSize": "16px",
      "--appMessageFontFamily": "'Century Schoolbook L'",
      "--appMessageFontSize": "16px",
    };
    var element = stwidget.createElement("450px", "556px", true, true);

    stwidget.setStyle({
      direction: "rtl",
      top: "51px",
      right: "32px",
      position: "fixed",
      "z-index": 9999999,
      display: "none",
    });
    var chatImage = document.getElementById("chat");

    let open = false;
    var c = document.getElementById("bo");
    c.append(element);
    chatImage.addEventListener("click", () => {
      if (!open) {
        stwidget.setStyle({
          display: "flex",
        });
        open = true;
      } else {
        stwidget.setStyle({
          display: "none",
        });
        open = false;
      }
    });
  }


  onMessage(event) {
    let message = JSON.parse(event.data);

    if (message.message != "ping") {
      console.log(message);
    }

    if (message.logs) {
    }

    if (message.error) {
      if (message.type == "ncgg") {
        alert(message.message);
        window.location = "/guilds?m=guildSeason";
      }
    }

    switch (message.message) {
      case "ping":
        break;
      case "set name sucessfully":
        // setCharacters(message.data.characters);
        //send to search game
        // setLogin(true);
        break;
      case "searching game...":
        //  setSearching(true);
        break;
    }
  }

  pvp() { }
}

export default Game;
