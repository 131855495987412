let areasFarming = [
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa1",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1518, 2867, 1536, 2968, 1836, 2950, 1829, 2842]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa2",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1753, 3103, 1782, 3208, 1887, 3161, 1869, 3089]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa3",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1981, 3092, 2006, 3168, 2147, 3150, 2139, 3056]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa4",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2096, 2890, 2111, 2991, 2248, 2962, 2237, 2886]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa5",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1948, 2708, 1966, 2773, 2114, 2759, 2111, 2687]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa6",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2125, 2766, 2150, 2846, 2237, 2850, 2233, 2774]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa7",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2457, 3532, 2479, 3608, 2616, 3583, 2612, 3525]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa8",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2417, 3055, 2443, 3134, 2634, 3113, 2620, 3037]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa9",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2367, 2842, 2367, 2922, 2511, 2893, 2490, 2813]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa10",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2367, 2698, 2363, 2781, 2544, 2748, 2537, 2669]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa11",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2327, 2564, 2338, 2676, 2464, 2643, 2443, 2553]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa12",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2121, 2476, 2154, 2653, 2248, 2610, 2212, 2448]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa13",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1652, 2708, 1656, 2795, 1829, 2777, 1829, 2690]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa14",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1706, 2556, 1706, 2632, 1804, 2621, 1818, 2542]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa15",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1995, 2480, 2009, 2578, 2096, 2574, 2082, 2462]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa16",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1706, 2415, 1656, 2501, 1731, 2541, 1789, 2447]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa17",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": []
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa18",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1601, 2283, 1453, 2380, 1511, 2488, 1645, 2373]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa19",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2417, 2301, 2367, 2366, 2508, 2438, 2562, 2351]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa20",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2233, 1871, 2230, 1958, 2407, 1965, 2414, 1882]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa21",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1515, 1550, 1518, 1643, 1605, 1643, 1601, 1564]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa22",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2385, 2974, 2385, 3017, 2522, 3006, 2511, 2934]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa23",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1637, 1514, 1713, 1510, 1717, 1619, 1645, 1622]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa24",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1757, 1492, 1749, 1643, 1829, 1633, 1836, 1499]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa25",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1916, 1365, 1887, 1444, 1977, 1477, 1999, 1397]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa26",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2215, 1709, 2147, 1756, 2204, 1817, 2266, 1763]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa27",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2082, 1534, 2053, 1614, 2197, 1650, 2215, 1585]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa28",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2306, 1409, 2287, 1489, 2349, 1503, 2385, 1438]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa29",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2129, 1423, 2092, 1484, 2233, 1535, 2241, 1473]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa30",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2165, 1074, 2129, 1236, 2194, 1233, 2241, 1099]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa31",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3476, 10, 3432, 150, 3533, 183, 3569, 13]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa32",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2291, 1109, 2241, 1254, 2295, 1286, 2363, 1131]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa33",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2392, 1112, 2345, 1303, 2432, 1336, 2490, 1141]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa34",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2612, 1204, 2598, 1283, 2703, 1301, 2732, 1233]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa35",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2810, 1188, 2789, 1350, 2872, 1371, 2894, 1217]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa36",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2847, 1079, 2836, 1169, 2937, 1205, 2941, 1111]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa37",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3060, 1286, 3049, 1366, 3154, 1373, 3169, 1297]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa38",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2609, 1459, 2598, 1589, 2685, 1589, 2706, 1477]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa39",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2739, 1495, 2721, 1617, 2793, 1625, 2818, 1505]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa40",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2847, 1509, 2826, 1675, 2901, 1693, 2927, 1527]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa41",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3078, 1546, 3071, 1651, 3143, 1673, 3172, 1554]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa42",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3197, 1560, 3208, 1719, 3281, 1697, 3288, 1571]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa43",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3346, 1550, 3353, 1723, 3432, 1727, 3425, 1568]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "casa44",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3190, 1355, 3187, 1453, 3266, 1475, 3266, 1348]
          }
          ,
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming1",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [778, 339, 504, 660, 464, 1267, 435, 1354, 959, 1430, 1374, 953]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming2",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1222, 668, 1442, 949, 2024, 870, 1822, 288]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming3",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1757, -4, 2085, 917, 2215, 989, 2526, 902, 2638, 10]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming4",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [1851, 993, 1887, 1191, 1981, 1191, 1973, 996]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming5",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2638, 1791, 2551, 2015, 2728, 2116, 2815, 1885]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming6",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2858, 1911, 2746, 2120, 2894, 2228, 3014, 2012, 2912, 1940]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming7",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [3732, 1879, 3934, 2580, 4093, 2609, 4075, 1811]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming8",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [2706, 2730, 2558, 3380, 3342, 3741, 3541, 3380, 2981, 2806]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming9",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [796, 2496, 894, 2911, 1294, 2792, 1215, 2413]
          },
          {
                    "fillColor": "rgba(1,255,0,0.5)",
                    "name": "farming10",
                    "strokeColor": "rgba(1,0,0,0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(1,255,1,0.5)",
                    "shape": "poly",
                    "coords": [720, 2272, 785, 2460, 1182, 2330, 1157, 2221]
          },
          {
                    "fillColor": "rgba(173, 216, 230, 0.5)",
                    "name": "fishing1",
                    "strokeColor": "rgba(1, 0, 0, 0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(173, 216, 230, 0.5)",
                    "shape": "poly",
                    "coords": [16, 2031, 1507, 1753, 1544, 2150, 2, 2330]
          },
          {
                    "fillColor": "rgba(173, 216, 230, 0.5)",
                    "name": "fishing2",
                    "strokeColor": "rgba(1, 0, 0, 0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(173, 216, 230, 0.5)",
                    "shape": "poly",
                    "coords": [1572, 1793, 2132, 1890, 2053, 2201, 1609, 2129]
          },
          {
                    "fillColor": "rgba(173, 216, 230, 0.5)",
                    "name": "fishing3",
                    "strokeColor": "rgba(1, 0, 0, 0.5)",
                    "stayHighlighted": true,
                    "preFillColor": "rgba(173, 216, 230, 0.5)",
                    "shape": "poly",
                    "coords": [2201, 1930, 2121, 2175, 2779, 2558, 3454, 3284, 4068, 3612, 4071, 3251, 3013, 2352, 2620, 2088]
          }

]

export default areasFarming;