import React, { Component } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
//import logo from "../assets/img/LOGO.png";
import { Dropdown } from "primereact/dropdown";

import { Chip } from "primereact/chip";
import Swal from "sweetalert2";
import utils from "../utils";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { ScrollPanel } from "primereact/scrollpanel";

import ListItemMarketplace from "./listItemMarketplace";
import ListItemIventory from "./ListItemIventory";

const logo =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e04892d9-7483-4aec-d720-d9b53b57f600/public";

const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

class Marketplace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionSelected: "SWAP.HIVE",
      nfts: [],
      mycards: false,
      mysells: false,
      nftsmysells: [],
      inventory: [],
      sells: [],
      ventas: [],
      displayBasic2: false,
      displayBasic3: false,
      displayBasic4: false,
      sellPrice: 0,
      perPage: 25,
      page: 0,
      pages: 0,
      layout: "grid",
      sellNft: "",
    };

    this.sortOptions = [
      { label: "Price High to Low", value: "!price" },
      { label: "Price Low to High", value: "price" },
    ];

    this.itemTemplate = this.itemTemplate.bind(this);
    this.renderGridItem = this.renderGridItem.bind(this);
    this.renderGridItemIventory = this.renderGridItemIventory.bind(this);
    this.itemTemplateIventory = this.itemTemplateIventory.bind(this);
  }

  renderHeader() {
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left" }}>
          <p>Marketplace status: ALFA - token: {this.state.optionSelected} </p>
        </div>
      </div>
    );
  }

  gettingDataForNft(nftid) {
    utils.getNftById(nftid).then((res) => {
      return (
        <div className="grid">
          <div className="col-4">
            {this.GetImage("HEALTH")}
            {res.p3}
          </div>
          <div className="col-4">
            {this.GetImage("ATTACK")}
            {res.p4}
          </div>
          <div className="col-4">
            {this.GetImage("DEFENSE")}
            {res.p5}
          </div>
          <div className="col-4">
            {this.GetImage("CRITICAL")}
            {res.p6}
          </div>
          <div className="col-4">
            {this.GetImage("AFFINITY", res.p7)}
            {res.p7}
          </div>
        </div>
      );
    });
  }

  getQuantity(data) {
    let quantity = 0;
    this.state.nfts.forEach((element) => {
      if (element.grouping.p1 === data) {
        if (parseInt(element.grouping.p3) > 0) {
          quantity += 1;
        }
        if (element.grouping.p2 == "") {
          quantity += 1;
        }
      }
    });
    return quantity;
  }

  getQuantityIventory(data) {
    console.log(
      "🚀 ~ file: marketplace.js:202 ~ Marketplace ~ getQuantityIventory ~ data",
      data
    );
    let quantity = 0;

    this.state.inventory.forEach((element) => {
      if (element.properties.p1 === data) {
        if (parseInt(element.properties.p3) > 0) {
          if (quantity > 0) {
            quantity -= 1;
          }
        }

        quantity += 1;
      }
    });
    return quantity;
  }

  renderGridItemIventory(data) {
    let cantidad = this.getQuantityIventory(data);
    return (
      <div className="col-4">
        <div className="product-grid-item card">
          <div className="product-grid-item-top">
            <div>
              <i className="pi pi-tag product-category-icon"></i>
              <span className="product-category"></span>
            </div>
            <span className={`product-badge status`}>X{cantidad}</span>
          </div>
          <div className="product-grid-item-content">
            <img
              src={images[data]}
              style={{ cursor: "pointer" }}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={data.name}
              onClick={() => {
                this.setState({
                  sellNft: data,
                  displayBasic4: true,
                });
              }}
            />
            <div className="product-name">{data}</div>
            <div className="product-description">
              {/*this.gettingDataForNft(data._id)*/}
            </div>
          </div>
          <div className="product-grid-item-bottom">
            <span className="product-price">
              {/*parseFloat(data.price).toFixed(3)*/}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderGridItem(data) {
    let cantidad = this.getQuantity(data);
    return (
      <div className="col-4">
        <div className="product-grid-item card">
          <div className="product-grid-item-top">
            <div>
              <i className="pi pi-tag product-category-icon"></i>
              <span className="product-category">
                {this.state.optionSelected}
              </span>
            </div>
            <span className={`product-badge status`}>X{cantidad}</span>
          </div>
          <div className="product-grid-item-content">
            <img
              src={images[data]}
              style={{ cursor: "pointer" }}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={data.name}
              onClick={() => {
                this.setState({
                  sellNft: data,
                  displayBasic3: true,
                });
              }}
            />
            <div className="product-name">{data}</div>
            <div className="product-description">
              {/*this.gettingDataForNft(data._id)*/}
            </div>
          </div>
          <div className="product-grid-item-bottom">
            <span className="product-price">
              {/*parseFloat(data.price).toFixed(3)*/}
            </span>
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(product, layout) {
    return this.renderGridItem(product);
  }

  itemTemplateIventory(product) {
    return this.renderGridItemIventory(product);
  }

  FiltrarPorNombre(items) {
    let onlyNames = items.map((e) => e.grouping.p1);

    const dataArr = new Set(onlyNames);

    let result = [...dataArr];

    console.log("imprimiendo solo nombres", result);
    return result;
  }

  FiltrarPorNombreInventory(items) {
    let onlyNames = items.map((e) => e.properties.p1);

    const dataArr = new Set(onlyNames);

    let result = [...dataArr];

    console.log("imprimiendo solo nombres inventory", result);
    return result;
  }

  render() {
    const header = this.renderHeader();
    const tokens = [
      { name: "SWAP.HIVE" },
      { name: "SWAP.HDB" },
      { name: "SOULS" },
      { name: "BUDS" },
    ];
    const leftContents = (
      <React.Fragment>
        <img
          style={{ width: "10%", cursor: "pointer" }}
          src={logo}
          onClick={() => {
            window.location.href = "/";
          }}
        ></img>
        <Dropdown
          className="mr-2"
          optionLabel="name"
          value={this.state.optionSelected}
          options={tokens}
          onChange={(e) => {
            this.setState(
              {
                ...this.state,

                optionSelected: e.value.name,
              },
              () => {
                this.getNfts();
              }
            );
          }}
          placeholder="Select a token"
        />
        <Button
          onClick={() => {
            window.location.reload();
          }}
          label="Buy"
          icon="pi pi-plus"
          className="mr-2"
        />
        <Button
          onClick={() => {
            this.getInventory();
            this.setState({
              ...this.state,
              mycards: true,
              mysells: false,
            });
          }}
          label="Cards"
          icon="pi pi-plus"
          className="mr-2"
        />
        <Button
          onClick={() => {
            this.getNftsMySells();
            this.setState({
              ...this.state,
              mysells: true,
              mycards: false,
            });
          }}
          label="Selling"
          icon="pi pi-upload"
          className="p-button-success"
        />
      </React.Fragment>
    );

    const rightContents = (
      <React.Fragment>
        <Chip
          className="mr-2"
          label={localStorage.getItem("username")}
          image={`https://images.hive.blog/u/${localStorage.getItem(
            "username"
          )}/avatar`}
        />
        <Button
          type="button"
          label="cart"
          icon="pi pi-shopping-cart"
          className="p-button-danger"
          onClick={() => {
            this.setState({
              ...this.state,
              displayBasic2: true,
            });
          }}
        >
          <Badge value={this.state.sells.length} severity="danger"></Badge>
        </Button>
      </React.Fragment>
    );

    const { page, perPage, pages } = this.state;
    let items = this.state.nfts;
    let itemRender = this.FiltrarPorNombre(items);

    let itemsRendererInventory = this.FiltrarPorNombreInventory(
      this.state.inventory
    );

    let itemOnRenderIventory = this.state.inventory.filter((r) => {
      if (r.properties.p1 == this.state.sellNft) {
        return r;
      }
    });

    let itemOnRender = items.filter((r) => {
      if (r.grouping.p1 == this.state.sellNft) {
        return r;
      }
    });

    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Toolbar left={leftContents} right={rightContents} />
        <br></br>
        <div
          className="container"
          style={{
            display:
              this.state.mycards || this.state.mysells ? "none" : "block",
          }}
        >
          <div className="dataview-demo">
            <div className="card">
              <DataView
                value={itemRender}
                layout={this.state.layout}
                header={header}
                itemTemplate={this.itemTemplate}
                paginator
                rows={9}
                sortOrder={this.state.sortOrder}
                sortField={this.state.sortField}
              />
            </div>
          </div>
        </div>

        <div
          className="c"
          style={{ display: this.state.mycards ? "block" : "none" }}
        >
          <div className="dataview-demo">
            <div className="card">
              <DataView
                value={itemsRendererInventory}
                layout={this.state.layout}
                header={
                  <div className="grid grid-nogutter">
                    <div className="col-6" style={{ textAlign: "left" }}>
                      <p>Iventory</p>
                    </div>
                  </div>
                }
                itemTemplate={this.itemTemplateIventory}
                paginator
                rows={9}
                sortOrder={this.state.sortOrder}
                sortField={this.state.sortField}
              />
            </div>
          </div>
        </div>

        <div
          className="c"
          style={{ display: this.state.mysells ? "block" : "none" }}
        >
          <ul className="cards">
            {this.state.ventas.map((resx, index) => {
              let res = resx.grouping;

              return (
                <li key={index}>
                  <a className="cardNew">
                    <img
                      src={images[res.p1]}
                      alt="Card"
                      className="card__image"
                    />

                    <div className="card__overlay">
                      <div className="card__header">
                        <svg
                          className="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <img
                          className="card__thumb"
                          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0100e80c-c3a0-40b6-f53a-1e6953761900/public"
                          alt=""
                        />
                        <div className="card__header-text">
                          <h3 className="card__title">{res.p1}</h3>
                          <span className="card__status">#{resx._id}</span>
                        </div>
                      </div>
                      <div className="card__description">
                        <div className="grid">
                          <div className="col-4">
                            {resx.price} - {resx.priceSymbol}
                          </div>
                          <div className="col-4">
                            <Button
                              onClick={() => {
                                this.cancelSellPlace(resx);
                              }}
                              label="Cancel"
                              icon="pi pi-shopping-cart"
                              className="mr-2 p-button-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <Dialog
          header="Sell Card"
          visible={this.state.displayBasic2}
          style={{ width: "50vw" }}
          onHide={() => {
            this.setState({ ...this.state, displayBasic2: false });
          }}
        >
          <div className="grid">
            <div className="col-4">
              <InputText
                value={this.state.sellPrice}
                onChange={(e) => {
                  this.setState({ ...this.state, sellPrice: e.target.value });
                }}
              />
            </div>
            <div
              className="col-4"
              style={{
                alignItems: "center",
                margin: "auto",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                on {this.state.optionSelected}
              </p>
            </div>

            <div className="col-4">
              <Button
                label="Sell"
                icon="pi pi-shopping-cart"
                className="mr-2 p-button-success"
                onClick={() => {
                  this.sellCards();
                }}
              />
            </div>
          </div>

          {this.state.sells.map((resx, index) => {
            return (
              <ul>
                <li>
                  {resx.card.properties.p1} #{resx.card._id}{" "}
                  <i
                    className="pi pi-times"
                    onClick={() => this.cancelSell(resx)}
                  ></i>
                </li>
              </ul>
            );
          })}
        </Dialog>

        {this.state.displayBasic3 && (
          <ListItemMarketplace
            sellNft={this.state.sellNft}
            displayBasic3={this.state.displayBasic3}
            itemOnRender={itemOnRender}
            onHide={() => {
              this.setState({ ...this.state, displayBasic3: false });
            }}
            buyAction={(e) => {
              this.checkAndBuy(e);
            }}
            buyinOn={this.state.optionSelected}
          ></ListItemMarketplace>
        )}

        {this.state.displayBasic4 && (
          <ListItemIventory
            sellNft={this.state.sellNft}
            displayBasic4={this.state.displayBasic4}
            itemOnRender={itemOnRenderIventory}
            onHide={() => {
              this.setState({ ...this.state, displayBasic4: false });
            }}
            buyAction={(e) => {
              console.log("digo que voy a agregar", e);
              this.checkAndAdd(e, this.state.optionSelected);
            }}
            buyinOn={this.state.optionSelected}
          ></ListItemIventory>
        )}
      </>
    );
  }

  cancelSellPlace(item) {
    let json = [
      {
        contractName: "nftmarket",
        contractAction: "cancel",
        contractPayload: {
          symbol: "IFCNFTS",
          nfts: ["" + item.nftId],
        },
      },
    ];

    window.hive_keychain.requestCustomJson(
      localStorage.getItem("username"),
      "ssc-mainnet-hive",
      "Active",
      `${JSON.stringify(json)}`,
      "Cancel sell infernal nfts",
      (resp) => {
        if (resp.success) {
          this.toast.show([
            {
              severity: "info",
              summary: "Cancel",
              detail: resp.message,
              life: 12000,
            },
          ]);
        } else {
          this.toast.show([
            {
              severity: "info",
              summary: "Cancel",
              detail: resp.message,
              life: 12000,
            },
          ]);
        }
      }
    );
  }

  checkAndBuy(item) {
    if (item.account === localStorage.getItem("username")) {
      alert("You can't buy your own card!");
      return;
    }

    let json = [
      {
        contractName: "nftmarket",
        contractAction: "buy",
        contractPayload: {
          symbol: "IFCNFTS",
          nfts: ["" + item.nftId],
          expPrice: parseFloat(item.price).toFixed(2),
          expPriceSymbol: this.state.optionSelected,
          marketAccount: "infernalcoliseum",
        },
      },
    ];

    window.hive_keychain.requestCustomJson(
      localStorage.getItem("username"),
      "ssc-mainnet-hive",
      "Active",
      `${JSON.stringify(json)}`,
      "Buy infernal nfts",
      (resp) => {
        if (resp.success) {
          this.toast.show([
            {
              severity: "info",
              summary: "Buy",
              detail: resp.message,
              life: 12000,
            },
          ]);

          this.setState({ ...this.state, displayBasic2: false, sells: [] });
        } else {
          this.toast.show([
            {
              severity: "info",
              summary: "Buy",
              detail: resp.message,
              life: 12000,
            },
          ]);
        }
      }
    );
  }

  sellCards() {
    if (this.state.sellPrice <= 0) {
      alert("Invalid price");
      return;
    }

    let json = [
      {
        contractName: "nftmarket",
        contractAction: "sell",
        contractPayload: {
          symbol: "IFCNFTS",
          nfts: this.state.sells.map((resx) => {
            return "" + resx.card._id;
          }),
          price: this.state.sellPrice,
          priceSymbol: this.state.optionSelected,
          fee: 500,
        },
        memo: "you sold this nft on IFCMARKETEST",
      },
    ];

    window.hive_keychain.requestCustomJson(
      localStorage.getItem("username"),
      "ssc-mainnet-hive",
      "Active",
      `${JSON.stringify(json)}`,
      "Sell infernal nfts",
      (resp) => {
        if (resp.success) {
          this.toast.show([
            {
              severity: "info",
              summary: "SELL",
              detail: resp.message,
              life: 12000,
            },
          ]);

          this.setState({ ...this.state, displayBasic2: false, sells: [] });
        } else {
          this.toast.show([
            {
              severity: "info",
              summary: "SELL",
              detail: resp.message,
              life: 12000,
            },
          ]);
        }
      }
    );
  }

  cancelSell(resx) {
    let sells = this.state.sells;
    sells.splice(sells.indexOf(resx), 1);
    this.setState({ ...this.state, sells: sells });
  }

  checkAndAdd(card, option) {
    console.log("sells", card, option);
    let sells = this.state.sells;

    if (sells.length > 0) {
      let sellprimero = sells[0];
      if (sellprimero.option != option) {
        alert(
          "You can only sell cards of the same token " +
            sellprimero.option +
            " you try add " +
            option
        );
      } else {
        let estaEnArray = sells.find((r) => {
          if (r.card._id == card._id) {
            return r;
          }
        });

        if (estaEnArray) {
          alert("nft already set to sell, check your car");
        } else {
          sells.push({ card: card, option: option });
          this.setState({ ...this.state, sells: sells });
        }
      }
    } else {
      sells.push({ card: card, option: option });
      this.setState({ ...this.state, sells: sells });
    }
  }

  GetImage(type, data) {
    switch (type) {
      case "HEALTH":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
            style={{ maxWidth: "30%" }}
            alt="HEALTH"
          />
        );
      case "ATTACK":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
            style={{ maxWidth: "30%" }}
            alt="ATTACK"
          />
        );
      case "DEFENSE":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
            style={{ maxWidth: "30%" }}
            alt="DEFENSE"
          />
        );
      case "CRITICAL":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
            style={{ maxWidth: "30%" }}
            alt="CRITICAL"
          />
        );
      case "AFFINITY":
        return (
          <img src={SKILL[data]} style={{ maxWidth: "30%" }} alt="AFFINITY" />
        );
    }
  }

  getNfts() {
    console.log("buscando nfts en ", this.state.optionSelected);

    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getAllMarketPlaceNfts(this.state.optionSelected).then((res) => {
      Swal.close();
      this.setState({
        ...this.state,
        nfts: res,
        pages: Math.floor(res.length / 25),
      });
      console.log("nfts", res);
    });
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
  };

  getInventory() {
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getAllNfts().then((res) => {
      Swal.close();
      this.setState({
        ...this.state,
        inventory: res,
        pages: Math.floor(res.length / 25),
      });
      console.log("nfts", res);
    });
  }

  getNftsMySells() {
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils
      .getAllMyMarketPlaceNfts(localStorage.getItem("username"))
      .then((res) => {
        Swal.close();
        this.setState({
          ...this.state,
          ventas: res,
          pages: Math.floor(res.length / 25),
        });
        console.log("nfts", res);
      });
  }

  componentDidMount() {
    if (!localStorage.getItem("userData")) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    this.getNfts();
  }
}

export default Marketplace;
