import React, { Component } from "react";
import Swal from "sweetalert2";
import { confirmDialog } from "primereact/confirmdialog";
import { Menubar } from "primereact/menubar";
import utils from "../utils";
import { Card } from "primereact/card";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import HivePay from "../utils/HivePay";
import { Toast } from "primereact/toast";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { SplitButton } from "primereact/splitbutton";
import { PickList } from "primereact/picklist";
import { Steps } from "primereact/steps";
import { Panel } from "primereact/panel";
import { Splitter, SplitterPanel } from 'primereact/splitter';


const images = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",
  "PURPPLEKONG specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be32a723-ae34-4a37-3d9e-75b1622eea00/public",

  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/02a593c8-bf96-4136-e66b-53e86d7c0b00/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7ab4ba22-7128-4dfa-d20f-966b3130f300/public",
  "ALYRAN - THE MAD ELF specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/dea54973-8602-4c6a-89f9-34df0954f200/public",

  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/37445818-e1b5-4083-f4b3-4f5bc2fe0100/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "VASS - THE HERALD OF FALLEN specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ada2506d-2ce0-4260-03fc-b57937928600/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0e243242-0e59-4588-5abd-1c8215ddce00/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",
  "HANZO - THE SOULESS NINJA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f91e2219-aee7-401a-df67-ea61b268bd00/public",

  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8894b7e8-d88e-46c3-6478-607dd1b8e900/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2857ff80-f5bc-4632-2b3e-cabec5fcc900/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",
  "ENNORE KILNAMA specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1d74d09a-ce87-4b08-39e4-c41cdd07b900/public",

  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/f3ee5937-87cb-4f41-59cb-2b9a6f89e500/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/e18ea9b8-e38f-4fb2-91c9-75e9e55dd500/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/272f93a7-42bb-4be0-0041-4d8963d84600/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c4e455e7-e061-4e18-8217-11fe09e48e00/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0252def1-99b7-46f3-4aff-0d6593588000/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/13f1248c-ae40-43c4-a061-3738d6c53300/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/46f81ef4-2225-43bf-5db7-6aa3e689fb00/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9029ae01-1326-4948-a115-0c7ee5453400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/022a7323-06ac-495b-6c7a-1f97292f9300/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4ba7953a-e230-46b9-329c-089c60138700/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1a56ca4b-0d84-4961-1198-ef0f85677400/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8a19b65c-d6d7-4d51-b3d3-cf6df8e11100/public",
};

const SKILL = {
  FIRE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2a06a63a-05e3-4329-6d81-fb46ee921300/public",
  WATER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/76588110-ce5e-4df5-7ca3-12bcb0cace00/public",
  GROUND:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/6787ebb0-bd46-4e04-5505-aaf7698df200/public",
  WIND: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/4174d4bf-98a8-43b1-89a9-d7bca3f71f00/public",
  LAVA: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/d80be6db-e13f-4ebc-22e3-df4dd4490900/public",
  ICE: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9762869a-c680-4d40-44d4-ca52f6597a00/public",
  STEEL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cab52bca-1d28-49cd-d2e9-13b584c00600/public",
  LIGHTNING:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/932ca4a4-c688-471f-d07a-42bebecef800/public",
  ATOMIC:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/755a1db3-03f8-4b17-f7e0-29840ee61600/public",
  POISON:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/116c9f00-0d93-43c3-084c-4ef1a65d8400/public",
  CRYSTAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ef5f7f98-b9e4-4147-b352-f3b264954f00/public",
  LASER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/40293408-8a1a-4327-83bc-2e19e16e4d00/public",
  INFERNAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/1b453f52-a49c-431f-00ce-26210dc69200/public",
  HEAVENLY:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/50d904eb-84b1-4ee8-cc51-84ecf18fff00/public",
  SPIRITUAL:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/73083a72-5a33-44b7-d2e4-6ce262e0ce00/public",
  SINISTER:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/5a438eee-0978-47f1-e5d2-33e346432400/public",
};

const rareza = {
  PURPPLEKONG:
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ALYRAN - THE MAD ELF":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "ALYRAN - THE MAD ELF special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "VASS - THE HERALD OF FALLEN special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HANZO - THE SOULESS NINJA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "HANZO - THE SOULESS NINJA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/8ef543be-3910-46fa-0b19-bbd3e0703100/public",
  "ENNORE KILNAMA":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA specialp":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ENNORE KILNAMA special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "ANDRAS - THE WOLF SWORD special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/04d1e7c8-1f91-4871-20e3-4939b27f0c00/public",
  "KAYN - THE WILL OF DEATH":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "GOLBIN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "GOLBIN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FARMER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN FISHER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "HUMAN MINER":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/2bc83660-43f6-4a90-97a5-6b3976efd400/public",
  "KAYN - THE WILL OF DEATH special":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "KAYN - THE WILL OF DEATH specialc":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "OZARK - NIGHTMARE KING":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
  "IRIN - THE WAR QUEEN":
    "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/daf71da2-0225-4550-b862-5315edb6b500/public",
};

let iconoPower =
  "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/be578d7d-cbe7-4133-62d7-e881e6086400/public";

const tipos = [
  { name: "Reset" },
  { name: "FIRE" },
  { name: "WATER" },
  { name: "GROUND" },
  { name: "WIND" },
  { name: "LAVA" },
  { name: "ICE" },
  { name: "STEEL" },
  { name: "LIGHTNING" },
  { name: "ATOMIC" },
  { name: "POISON" },
  { name: "CRYSTAL" },
  { name: "LASER" },
  { name: "INFERNAL" },
  { name: "CELESTIAL" },
  { name: "SPIRITUAL" },
  { name: "SINISTER" },
];

const nombres = [
  { name: "Reset" },
  { name: "ALYRAN - THE MAD ELF" },
  { name: "VASS - THE HERALD OF FALLEN" },
  { name: "HANZO - THE SOULESS NINJA" },
  { name: "PURPPLEKONG" },
  { name: "ENNORE KILNAMA" },
  { name: "ANDRAS - THE WOLF SWORD" },
  { name: "KAYN - THE WILL OF DEATH" },
  { name: "OZARK - NIGHTMARE KING" },
  { name: "IRIN - THE WAR QUEEN" },
];

const orderBy = [
  { name: "Reset" },
  { name: "POWER ⬆", code: "p1" },
  { name: "LIFE ⬆", code: "p3" },
  { name: "ATTACK ⬆", code: "p4" },
  { name: "DEFENSE ⬆", code: "p5" },
  { name: "CRITIC ⬆", code: "p6" },
  { name: "POWER ⬇", code: "p1" },
  { name: "LIFE ⬇", code: "p3" },
  { name: "ATTACK ⬇", code: "p4" },
  { name: "DEFENSE ⬇", code: "p5" },
  { name: "CRITIC ⬇", code: "p6" },
];

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nfts: [],
      userData: {
        username: "",
        xp: 0,
        lvl: 0,
        visibleFullScreen: false,
        visibleFusion: false,
      },
      selectedNft1: null,
      selectedNft2: null,
      allNfts: [],
      disableSecond: false,
      filter: "All",
      property: "All",
      nft: [],
      target: [],
      activeIndex: 0,
      selectedNFT1: null,
      selectedNFT2: null,
    };

    this.selectedCountryTemplate = this.selectedCountryTemplate.bind(this);
    this.SelectedNft2 = this.SelectedNft2.bind(this);
    this.countryOptionTemplate = this.countryOptionTemplate.bind(this);
    this.Nft2OptionTemplate = this.Nft2OptionTemplate.bind(this);
    this.onNftChange = this.onNftChange.bind(this);
    this.onNftChange2 = this.onNftChange2.bind(this);
    this.enableMerge = this.enableMerge.bind(this);
    this.filterNftsByType = this.filterNftsByType.bind(this);
    this.onTiposChange = this.onTiposChange.bind(this);
    this.onNombreChange = this.onNombreChange.bind(this);
    this.setOrRemoveNft = this.setOrRemoveNft.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.itemTemplateCollector = this.itemTemplateCollector.bind(this);
    this.onChange = this.onChange.bind(this);
    this.transferAll = this.transferAll.bind(this);
  }

  orderByChange = (e) => {
    if (e.value.name === "Reset") {
      this.setState({ filter: "All", property: "All" });
      return;
    }
    this.setState({ filter: e.value.name, property: e.value.code });
  };

  onTiposChange = (e) => {
    if (e.value.name === "Reset") {
      this.setState({ filter: "All", property: "All" });
      return;
    }
    this.setState({ filter: e.value.name, property: "p7" });
  };

  onNombreChange = (e) => {
    if (e.value.name === "Reset") {
      this.setState({ filter: "All", property: "All" });
      return;
    }
    this.setState({ filter: e.value.name, property: "p1" });
  };

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  getPower(properties) {
    let atk = parseInt(properties?.p4);
    let def = parseInt(properties?.p5);
    let critic = parseInt(properties?.p6);
    return Math.trunc(atk * (critic * 0.2) + def * 3);
  }

  filterNftsByType(property, value) {
    if (value == "All") return this.state.nfts;

    if (value.includes("POWER")) {
      if (value.includes("⬆")) {
        return this.state.nfts.sort(
          (a, b) => this.getPower(a.properties) - this.getPower(b.properties)
        );
      }
      if (value.includes("⬇")) {
        return this.state.nfts.sort(
          (a, b) => this.getPower(b.properties) - this.getPower(a.properties)
        );
      }
    } else {
      console.log("filtrando", property, value);
      if (value.includes("⬆")) {
        return this.state.nfts.sort(
          (a, b) =>
            parseFloat(a.properties[property]) -
            parseFloat(b.properties[property])
        );
      }
      if (value.includes("⬇")) {
        return this.state.nfts.sort(
          (a, b) =>
            parseFloat(b.properties[property]) -
            parseFloat(a.properties[property])
        );
      }
    }

    return this.state.nfts.filter((nft) => nft.properties[property] === value);
  }

  setOrRemoveNft(nftx) {
    console.log("settin ", nftx);
    let nft = this.state.nft;
    let indice = nft.indexOf(nftx);
    if (indice > -1) {
      nft.splice(indice, 1);
      this.setState({ nft: nft });
      return;
    }
    nft.push(nftx);
    this.setState({
      ...this.state,
      nft: nft,
    });
    console.log(nft);
  }

  onChange(event) {
    if (event.target.length >= 50) {
      return;
    }
    this.setState({
      allNfts: event.source,
      target: event.target,
    });
  }

  itemTemplate(item) {
    item = JSON.parse(item);
    return (
      <div className="product-item">
        <div style={{ textAlign: "center" }} className="image-container">
          <img
            style={{ width: "20%" }}
            src={`${this.getImagen(item)}`}
            onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.p1}
          />
        </div>
        <div className="product-list-detail">
          <div className="grid">
            <div className="col-4">
              {this.GetImage("RAIDPOWER")}
              {this.getPower(item)}
            </div>
            <div className="col-4">
              {this.GetImage("HEALTH")}
              {item.p3}
            </div>
            <div className="col-4">
              {this.GetImage("ATTACK")}
              {item.p4}
            </div>
            <div className="col-4">
              {this.GetImage("DEFENSE")}
              {item.p5}
            </div>
            <div className="col-4">
              {this.GetImage("CRITICAL")}
              {item.p6}
            </div>
            <div className="col-4">
              {this.GetImage("AFFINITY", item.p7)}
              {item.p7}
            </div>
          </div>
        </div>
      </div>
    );
  }

  itemTemplateCollector(item) {
    return (
      <div className="product-item">
        <div style={{ textAlign: "center" }} className="image-container">
          <img
            style={{ width: "20%" }}
            src={`${this.getImagen(item.properties)}`}
            onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.properties.p1}
          />
          <p>{item.properties.p1}</p>
        </div>
      </div>
    );
  }

  render() {
    let nfts = this.filterNftsByType(this.state.property, this.state.filter);

    const items = [
      {
        label: "Pack info",
        icon: "pi pi-fw pi-power-off",
        command: () => {
          this.getNftsFromBlockchain();
        },
      },
      {
        label: "PVP",
        icon: "pi pi-fw pi-user-edit",
        command: () => {
          this.setState({
            visiblePVP: true,
          });
        },
      },
      {
        label: "Fusion",
        icon: "pi pi-sort-amount-up-alt",
        command: () => {
          this.enableMerge();
        },
      },
      {
        label: "Fusion IXP",
        icon: "pi pi-sort-amount-up-alt",
        command: () => {
          this.enableMergeXP();
        },
      },
      {
        label: "Burning",
        icon: "pi pi-sort-amount-up-alt",
        command: () => {
          this.getCollectorsFromBlockchain();
        },
      },
    ];

    const itemSteps = [
      {
        label: "Miner",
      },
      {
        label: "Farmer",
      },
      {
        label: "Fisher",
      },
      {
        label: "Confirmation",
      },
    ];

    const nftsLVL = this.state.nftsIXP;

    // Filtra los NFTs disponibles en función del nivel seleccionado
    const getAvailableNFTs = (level, status = false) => {
      if (level == null) {
        return nftsLVL;
      }

      if (!status) {
        return nftsLVL.filter((nft) => nft.lvl === level);
      }
      return nftsLVL
        .filter((nft) => nft.lvl === level)
        .filter((e) => e != this.state.selectedNFT1);
    };

    // Opciones de niveles disponibles
    const levelOptions = [
      { label: "lvl 0", value: 0 },
      { label: "lvl 1", value: 1 },
      { label: "lvl 2", value: 2 },
      { label: "lvl 3", value: 3 },
      { label: "lvl 4", value: 4 },
      { label: "lvl 5", value: 5 },
      { label: "lvl 6", value: 6 },
      { label: "lvl 7", value: 7 },
      { label: "lvl 8", value: 8 },
      { label: "lvl 9", value: 9 },
      // ... Agrega más opciones de nivel según sea necesario
    ];

    const onNFT1Change = (event) => {
      this.setState({ selectedNFT1: event.value });
    };

    // Actualiza la selección del segundo NFT
    const onNFT2Change = (event) => {
      this.setState({ selectedNFT2: event.value });
    };

    // Maneja el evento de fusión de NFTs
    const mergeNFTs = () => {
      // Lógica de fusión de NFTs aquí
      console.log(
        `Fusionando NFT ${JSON.stringify(
          this.state.selectedNFT1
        )} con NFT ${JSON.stringify(this.state.selectedNFT2)}`
      );

      if (this.state.selectedNFT1._id == this.state.selectedNFT2._id) {
        alert("Please select a different selection");
      } else {
        Swal.fire({
          title: "Fusioning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        fetch("https://backend.infernalcoliseum.fun/api", {
          body: `{"query":"mutation{\\n  fusionNfts(nfta:\\"${this.state.selectedNFT1._id}\\",nftb:\\"${this.state.selectedNFT2._id}\\"){\\n    success\\n    message\\n  }\\n}"}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            Dnt: "1",
          },
          method: "POST",
        })
          .then((response) => {
            return response.json();
          })
          .then(async (response) => {
            if (response.data?.fusionNfts) {
              await Swal.fire({
                title: "Fusion",
                text: response.data?.fusionNfts.message,
              });
              window.location.reload();
            }
          });
      }
      // Restablece la selección después de la fusión
      // setSelectedNFT1(null);
      //setSelectedNFT2(null);
    };

    const selectedCountryTemplate = (option, props) => {
      if (option) {
        return (
          <div className="flex align-items-center">
            <div>
              {option.name} {option.lvl}
            </div>
          </div>
        );
      }

      return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
      return (
        <div className="flex align-items-center">
          <div>
            {option.name} {option.lvl}
          </div>
        </div>
      );
    };

    return (
      <div className="c">
        <Toast ref={(el) => (this.toast = el)} />
        <Menubar
          model={items}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                />
                {this.state.userData.username}
              </>
            );
          }}
        />


        <Splitter style={{ height: '100%' }}>


          <SplitterPanel size={25} minSize={10} className="filter-panel">
            <div className="filters-container">
              <div className="filter-item">
                <h2>Filter by type</h2>
                <Dropdown
                  value={{ name: this.state.filter }}
                  options={tipos}
                  onChange={this.onTiposChange}
                  optionLabel="name"
                  placeholder="Select a type"
                />
              </div>
              <div className="filter-item">
                <h2>Filter by name</h2>
                <Dropdown
                  value={{ name: this.state.filter }}
                  options={nombres}
                  onChange={this.onNombreChange}
                  optionLabel="name"
                  placeholder="Select a name"
                />
              </div>
              <div className="filter-item">
                <h2>Filter by Stats</h2>
                <Dropdown
                  value={{ name: this.state.filter, code: this.state.property }}
                  options={orderBy}
                  onChange={this.orderByChange}
                  optionLabel="name"
                  placeholder="Select a filter"
                />
              </div>
            </div>
          </SplitterPanel>

          <SplitterPanel size={75} className="inventory-panel">

            {/* Aquí va el contenido del inventario */}
            <ul className="cards">
              {nfts
                .sort((a, b) => {
                  let aPeso = a.properties.p8 === "conqueror" ? 1 : 0;
                  let bPeso = b.properties.p8 === "conqueror" ? 1 : 0;
                  return bPeso - aPeso;
                })
                .filter((e) => e.properties.p8 !== "merged")
                .map((resx, index) => {
                  let res = resx.properties;
                  return (
                    <li key={index} className="card-item">
                      <div className="card-content">
                        <div className="card-header">
                          <img
                            src={res?.p8 === "collector" ? images[res.p1] : this.getImagen(res)}
                            alt="Card"
                            className="card-thumb"
                          />
                          <div className="card-title-section">
                            <h3 className="card-title">{res.p1}</h3>
                            <span className="card-status">{res.p8} #{resx._id}</span>
                          </div>
                        </div>
                        <div className="card-stats">
                          <div className="stats-grid">
                            <div className="stat-item">
                              {this.GetImage("RAIDPOWER")} {this.getPower(res) ? this.getPower(res) : 0}
                            </div>
                            <div className="stat-item">
                              {this.GetImage("HEALTH")} {res.p3 ? res.p3 : 0}
                            </div>
                            <div className="stat-item">
                              {this.GetImage("ATTACK")} {res.p4 ? res.p4 : 0}
                            </div>
                            <div className="stat-item">
                              {this.GetImage("DEFENSE")} {res.p5 ? res.p5 : 0}
                            </div>
                            <div className="stat-item">
                              {this.GetImage("CRITICAL")} {res.p6 ? res.p6 : 0}
                            </div>
                            <div className="stat-item">
                              {this.GetImage("AFFINITY", res.p7 ? res.p7 : "SINISTER")} {res.p7 ? res.p7 : "none"}
                            </div>
                          </div>
                          <div className="actions-grid">
                            <Button
                              onClick={() => {
                                this.transferNft(resx._id);
                              }}
                            >
                              Transfer
                            </Button>
                            <Button
                              label="PvP"
                              onClick={() => {
                                this.setOrRemoveNft(
                                  JSON.stringify({ ...res, id: resx._id })
                                );
                              }}
                              className={
                                this.state.nft.indexOf(
                                  JSON.stringify({ ...res, id: resx._id })
                                ) > -1
                                  ? "p-button-success"
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </SplitterPanel>

        </Splitter>



        <br></br>


        <Sidebar
          visible={this.state.visibleFullScreen}
          fullScreen
          onHide={() => this.setState({ visibleFullScreen: false })}
        >
          <div className="grid">
            <div className="col">
              <h3>Nfts stats list</h3>

              <ul>
                <li>
                  <h3>Total nfts: {this.state.allNfts.length}</h3>
                </li>

                <li>
                  IRIN:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "IRIN - THE WAR QUEEN";
                    }).length
                  }
                </li>

                <li>
                  OZARK:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "OZARK - NIGHTMARE KING";
                    }).length
                  }
                </li>
                <li>
                  HUNTER-C50:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "HUNTER-C50";
                    }).length
                  }
                </li>
                <li>
                  PURPPLEKONG:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "PURPPLEKONG";
                    }).length
                  }
                </li>
                <li>
                  ALYRAN - THE MAD ELF:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "ALYRAN - THE MAD ELF";
                    }).length
                  }
                </li>
                <li>
                  VASS - THE HERALD OF FALLEN:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return (
                        element.properties.p1 == "VASS - THE HERALD OF FALLEN"
                      );
                    }).length
                  }
                </li>
                <li>
                  HANZO - THE SOULESS NINJA:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return (
                        element.properties.p1 == "HANZO - THE SOULESS NINJA"
                      );
                    }).length
                  }
                </li>
                <li>
                  ENNORE KILNAMA:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "ENNORE KILNAMA";
                    }).length
                  }
                </li>
                <li>
                  ANDRAS - THE WOLF SWORD:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "ANDRAS - THE WOLF SWORD";
                    }).length
                  }
                </li>
                <li>
                  KAYN - THE WILL OF DEATH:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return (
                        element.properties.p1 == "KAYN - THE WILL OF DEATH"
                      );
                    }).length
                  }
                </li>
                <li>
                  GOLBIN FARMER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN FARMER";
                    }).length
                  }
                </li>
                <li>
                  GOLBIN FISHER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN FISHER";
                    }).length
                  }
                </li>
                <li>
                  GOLBIN MINER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN MINER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN FARMER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "HUMAN FARMER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN FISHER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "HUMAN FISHER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN MINER:{" "}
                  {
                    this.state.allNfts.filter((element) => {
                      return element.properties.p1 == "HUMAN MINER";
                    }).length
                  }
                </li>
              </ul>
            </div>

            <div className="col">
              <h3>MY Nfts list</h3>

              <ul>
                <li>
                  <h3>Total nfts: {this.state.nfts.length}</h3>
                </li>
                <li>
                  ALYRAN - THE MAD ELF:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "ALYRAN - THE MAD ELF";
                    }).length
                  }
                </li>
                <li>
                  VASS - THE HERALD OF FALLEN:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return (
                        element.properties.p1 == "VASS - THE HERALD OF FALLEN"
                      );
                    }).length
                  }
                </li>
                <li>
                  HANZO - THE SOULESS NINJA:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return (
                        element.properties.p1 == "HANZO - THE SOULESS NINJA"
                      );
                    }).length
                  }
                </li>
                <li>
                  ENNORE KILNAMA:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "ENNORE KILNAMA";
                    }).length
                  }
                </li>
                <li>
                  ANDRAS - THE WOLF SWORD:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "ANDRAS - THE WOLF SWORD";
                    }).length
                  }
                </li>
                <li>
                  KAYN - THE WILL OF DEATH:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return (
                        element.properties.p1 == "KAYN - THE WILL OF DEATH"
                      );
                    }).length
                  }
                </li>
                <li>
                  GOLBIN FARMER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN FARMER";
                    }).length
                  }
                </li>
                <li>
                  GOLBIN FISHER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN FISHER";
                    }).length
                  }
                </li>
                <li>
                  GOLBIN MINER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "GOLBIN MINER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN FARMER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "HUMAN FARMER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN FISHER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "HUMAN FISHER";
                    }).length
                  }
                </li>
                <li>
                  HUMAN MINER:{" "}
                  {
                    this.state.nfts.filter((element) => {
                      return element.properties.p1 == "HUMAN MINER";
                    }).length
                  }
                </li>
              </ul>
            </div>
          </div>
        </Sidebar>

        <Sidebar
          className="fusionbar"
          showCloseIcon
          visible={this.state.visibleFusionXP}
          onHide={() =>
            this.setState({ ...this.state, visibleFusionXP: false })
          }
        >
          <div className="grid">
            <div className="col-12" style={{ textAlign: "center" }}>
              <div>
                <h3>NFT 1:</h3>
                <Dropdown
                  value={this.state.selectedNFT1}
                  options={getAvailableNFTs(
                    this.state.selectedNFT2 ? this.state.selectedNFT2.lvl : null
                  )}
                  onChange={onNFT1Change}
                  optionLabel="lvl"
                  itemTemplate={countryOptionTemplate}
                  valueTemplate={selectedCountryTemplate}
                  placeholder="Select NFT"
                  disabled={this.state.selectedNFT1 !== null}
                />
              </div>
              <div>
                <h3>NFT 2:</h3>
                <Dropdown
                  value={this.state.selectedNFT2}
                  options={getAvailableNFTs(
                    this.state.selectedNFT1
                      ? this.state.selectedNFT1.lvl
                      : null,
                    true
                  )}
                  onChange={onNFT2Change}
                  optionLabel="lvl"
                  itemTemplate={countryOptionTemplate}
                  valueTemplate={selectedCountryTemplate}
                  placeholder="Seleccione un NFT"
                  disabled={this.state.selectedNFT1 === null}
                />
              </div>

              <br></br>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <Button
                    label="Fusionar NFTs"
                    disabled={
                      !this.state.selectedNFT1 || !this.state.selectedNFT2
                    }
                    onClick={mergeNFTs}
                  />{" "}
                </div>
                <div className="col-md-6">
                  {" "}
                  <Button
                    label="Clear"
                    onClick={() => {
                      this.setState({ selectedNFT1: null, selectedNFT2: null });
                    }}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </Sidebar>

        <Sidebar
          className="fusionbar"
          fullScreen
          visible={this.state.visibleFusion}
          onHide={() => this.setState({ ...this.state, visibleFusion: false })}
        >
          <div className="grid">
            <div className="col-4" style={{ textAlign: "center" }}>
              <br />
              <br />
              <br />
              {this.state.selectedNft1 && (
                <li>
                  <a className="cardNew">
                    <img
                      src={images[this.state.selectedNft1.properties.p1]}
                      alt="Card"
                      className="card__image"
                    />

                    <div className="card__overlay">
                      <div className="card__header">
                        <svg
                          className="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <img
                          className="card__thumb"
                          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0100e80c-c3a0-40b6-f53a-1e6953761900/public"
                          alt=""
                        />
                        <div className="card__header-text">
                          <h3 className="card__title">
                            {this.state.selectedNft1.properties.p1}
                          </h3>
                          <span className="card__status">
                            {this.state.selectedNft1.properties.p8} #
                            {this.state.selectedNft1._id}
                          </span>
                        </div>
                      </div>
                      <div className="card__description">
                        <div className="grid">
                          <div className="col-4">
                            {this.GetImage("HEALTH")}
                            {this.state.selectedNft1.properties.p3}
                          </div>
                          <div className="col-4">
                            {this.GetImage("ATTACK")}
                            {this.state.selectedNft1.properties.p4}
                          </div>
                          <div className="col-4">
                            {this.GetImage("DEFENSE")}
                            {this.state.selectedNft1.properties.p5}
                          </div>
                          <div className="col-4">
                            {this.GetImage("CRITICAL")}
                            {this.state.selectedNft1.properties.p6}
                          </div>
                          <div className="col-4">
                            {this.GetImage(
                              "AFFINITY",
                              this.state.selectedNft1.properties.p7
                            )}
                            {this.state.selectedNft1.properties.p7}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              )}

              <Dropdown
                value={this.state.selectedNft1}
                options={nfts.filter(
                  (r) =>
                    r.properties?.p8 != "collector" &&
                    r.properties?.p8 != "merged"
                )}
                onChange={this.onNftChange}
                optionLabel="properties.p1"
                filter
                showClear
                filterBy="properties.p1"
                placeholder="Select a Nft"
                valueTemplate={this.selectedCountryTemplate}
                itemTemplate={this.countryOptionTemplate}
              />
            </div>

            <div className="col-4" style={{ textAlign: "center" }}>
              <br />
              <br />
              <br />
              {this.state.selectedNft1 && this.state.disableSecond && (
                <li>
                  <a className="cardNew">
                    <img
                      src={images[this.state.selectedNft1.properties.p1]}
                      alt="Card"
                      className="card__image"
                    />

                    <div className="card__overlay">
                      <div className="card__header">
                        <svg
                          className="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <img
                          className="card__thumb"
                          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0100e80c-c3a0-40b6-f53a-1e6953761900/public"
                          alt=""
                        />
                        <div className="card__header-text">
                          <h3 className="card__title">
                            {this.state.selectedNft1.properties.p1}
                          </h3>
                          <span className="card__status">
                            {this.state.selectedNft1.properties.p8} #
                            {this.state.selectedNft1._id}
                          </span>
                        </div>
                      </div>
                      <div className="card__description">
                        <div className="grid">
                          <div className="col-4">
                            {this.GetImage("HEALTH")}
                            {this.state.selectedNft1.properties.p3} +
                            {this.getDataPropertiesUpdate(
                              this.state.selectedNft2,
                              "p3"
                            )}
                          </div>
                          <div className="col-4">
                            {this.GetImage("ATTACK")}
                            {this.state.selectedNft1.properties.p4}+
                            {this.getDataPropertiesUpdate(
                              this.state.selectedNft2,
                              "p4"
                            )}
                          </div>
                          <div className="col-4">
                            {this.GetImage("DEFENSE")}
                            {this.state.selectedNft1.properties.p5}+
                            {this.getDataPropertiesUpdate(
                              this.state.selectedNft2,
                              "p5"
                            )}
                          </div>
                          <div className="col-4">
                            {this.GetImage("CRITICAL")}
                            {this.state.selectedNft1.properties.p6}+
                            {this.getDataPropertiesUpdate(
                              this.state.selectedNft2,
                              "p6"
                            )}
                          </div>
                          <div className="col-4">
                            {this.GetImage(
                              "AFFINITY",
                              this.state.selectedNft1.properties.p7
                            )}
                            {this.state.selectedNft1.properties.p7}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              )}

              <Button
                style={{
                  visibility:
                    this.state.selectedNft2 &&
                      this.state.selectedNft1?._id &&
                      this.state.selectedNft2?.length > 0
                      ? "visible"
                      : "hidden",
                }}
                label="fusing"
                className="p-button-success"
                onClick={() => {
                  this.Fusion();
                }}
              />
              <Button
                style={{
                  visibility:
                    this.state.selectedNft2?._id &&
                      this.state.selectedNft1?._id &&
                      this.state.selectedNft1?._id == this.state.selectedNft2?._id
                      ? "visible"
                      : "hidden",
                }}
                label="Fusion"
                className="p-button-danger"
              />
            </div>

            <div className="col-4" style={{ textAlign: "center" }}>
              <br />
              <br />
              <br />
              {this.state.selectedNft2 && (
                <li>
                  <a className="cardNew">
                    <img
                      src={images[this.state.selectedNft2[0]?.properties.p1]}
                      alt="Card"
                      className="card__image"
                    />

                    <div className="card__overlay">
                      <div className="card__header">
                        <svg
                          className="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <img
                          className="card__thumb"
                          src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0100e80c-c3a0-40b6-f53a-1e6953761900/public"
                          alt=""
                        />
                        <div className="card__header-text">
                          <h3 className="card__title">
                            {this.state.selectedNft2[0]?.properties.p1}
                          </h3>
                          <span className="card__status">
                            {this.state.selectedNft2[0]?.properties.p8} #
                            {this.state.selectedNft2[0]?._id}
                          </span>
                        </div>
                      </div>
                      <div className="card__description">
                        <div className="grid">
                          <div className="col-4">
                            {this.GetImage("HEALTH")}
                            {this.state.selectedNft2[0]?.properties.p3}
                          </div>
                          <div className="col-4">
                            {this.GetImage("ATTACK")}
                            {this.state.selectedNft2[0]?.properties.p4}
                          </div>
                          <div className="col-4">
                            {this.GetImage("DEFENSE")}
                            {this.state.selectedNft2[0]?.properties.p5}
                          </div>
                          <div className="col-4">
                            {this.GetImage("CRITICAL")}
                            {this.state.selectedNft2[0]?.properties.p6}
                          </div>
                          <div className="col-4">
                            {this.GetImage(
                              "AFFINITY",
                              this.state.selectedNft2[0]?.properties.p7
                            )}
                            {this.state.selectedNft2[0]?.properties.p7}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              )}
              <MultiSelect
                value={this.state.selectedNft2}
                options={nfts.filter((r) => {
                  if (
                    r.properties?.p8 != "collector" &&
                    r.properties?.p8 != "merged" &&
                    r._id != this.state.selectedNft1?._id
                  ) {
                    return r;
                  }
                })}
                onChange={this.onNftChange2}
                filter
                showClear
                filterBy="properties.p1"
                placeholder="Select one o multiple Nfts"
                selectedItemTemplate={this.SelectedNft2}
                maxSelectedLabels={49}
                itemTemplate={this.Nft2OptionTemplate}
                disabled={!this.state.disableSecond}
              />
            </div>
          </div>
        </Sidebar>

        <Sidebar
          fullScreen
          onHide={() => this.setState({ ...this.state, visiblePVP: false })}
          visible={this.state.visiblePVP}
        >
          <h1 style={{ textAlign: "center" }}>
            Please select 3 nfts for pvp battle
          </h1>

          <PickList
            source={this.state.nft}
            target={this.state.target}
            itemTemplate={this.itemTemplate}
            sourceHeader="Available"
            targetHeader="Selected"
            sourceStyle={{ height: "342px" }}
            targetStyle={{ height: "342px" }}
            onChange={this.onChange}
          />

          <Button
            onClick={(e) => this.sendPvp()}
            className="p-button-success"
            label="submit"
          ></Button>
        </Sidebar>

        <Sidebar
          fullScreen
          onHide={() =>
            this.setState({ ...this.state, visibleFusionIXP: false })
          }
          visible={this.state.visibleFusionIXP}
        >
          <div className="col-12">
            <h1 style={{ textAlign: "center" }}>
              Please select 50 nfts max for burning
            </h1>

            <PickList //collector
              source={this.state.allNfts.filter(
                (nft) => nft.properties.p8 == "collector"
              )}
              target={this.state.target}
              itemTemplate={this.itemTemplateCollector}
              sourceHeader="Available"
              targetHeader="Selected"
              sourceStyle={{ height: "342px" }}
              targetStyle={{ height: "342px" }}
              onChange={this.onChange}
            />

            <Button
              onClick={(e) => this.transferAll()}
              className="p-button-success"
              label="Burning"
            ></Button>
          </div>
        </Sidebar>
      </div>
    );
  }

  transferAll() {
    console.log(this.state.target);
    let json = [
      {
        contractName: "nft",
        contractAction: "transfer",
        contractPayload: {
          nfts: [
            {
              symbol: "IFCNFTS",
              ids: this.state.target.map((e) => "" + e._id).slice(0, 49),
            },
          ],
          to: "infernalcoliseum",
          memo: "burn for essences",
        },
      },
    ];

    window.hive_keychain.requestCustomJson(
      localStorage.getItem("username"),
      "ssc-mainnet-hive",
      "Active",
      `${JSON.stringify(json)}`,
      "transfer infernal nfts",
      (resp) => {
        if (resp.success) {
          this.toast.show([
            {
              severity: "info",
              summary: "Correct",
              detail: resp.message,
              life: 12000,
            },
          ]);
          window.location.reload();
        } else {
          this.toast.show([
            {
              severity: "info",
              summary: "Cancel",
              detail: resp.message,
              life: 12000,
            },
          ]);
        }
      }
    );
  }

  sendPvp() {
    let string = this.state.target.map((e) => JSON.parse(e).id).toString();
    console.log(string);
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (this.state.target.length != 3) {
      Swal.close();
      alert("Please select 3 nfts to battle on pvp");
      return;
    }

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation {\\n  setNftsToPvp(nfts:\\"${string}\\"){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((e) => e.json())
      .then((response) => {
        console.log(
          "🚀 ~ file: inventory.js:1185 ~ Inventory ~ .then ~ response",
          response
        );

        if (response?.data?.setNftsToPvp) {
          Swal.fire({
            icon: "info",
            title: response.data.setNftsToPvp.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({
            nft: [],
            target: [],
            visiblePVP: false,
          });
        }
      })
      .finally((e) => {
        Swal.close();
      });
  }

  getDataPropertiesUpdate(nfts, property) {
    if (!nfts) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < nfts.length; i++) {
      if (nfts[i]?.properties) {
        sum = sum + parseInt(nfts[i].properties[property]);
      }
    }
    return sum;
  }

  getImagen(res) {
    if (res.p10?.includes("special")) {
      return images[res.p1 + " " + res.p10];
    }
    return images[res.p1];
  }

  selectedCountryTemplate(option) {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>
            {option.properties.p1} {option._id}
          </div>
        </div>
      );
    }

    return "Select Nft";
  }

  SelectedNft2(option) {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.properties.p1}</div>
        </div>
      );
    }

    return "Select one or multiple Nfts";
  }

  countryOptionTemplate(option) {
    if (option) {
      return (
        <div className="country-item">
          <div>
            {option.properties.p1} {option._id}
          </div>
        </div>
      );
    }
  }

  Nft2OptionTemplate(option) {
    if (option) {
      return (
        <div className="country-item">
          <div>
            {option.properties.p1} {option._id}
          </div>
        </div>
      );
    }
  }

  onNftChange(e) {
    this.setState({
      ...this.state,
      selectedNft1: e.value,
      disableSecond: true,
    });
  }

  onNftChange2(e) {
    let display = Array.isArray(e.value) && e.value.length > 0;

    if (display) {
      if (e.value.length > 49) {
        alert("You can only select 49 Nfts at time");
      }

      this.setState({
        ...this.state,
        selectedNft2: e.value.slice(0, 49),
      });
    } else {
      this.setState({
        ...this.state,
        selectedNft2: null,
      });
    }
  }

  GetImage(type, data) {
    switch (type) {
      case "RAIDPOWER":
        return (
          <img src={iconoPower} style={{ maxWidth: "30%" }} alt="HEALTH" />
        );

      case "HEALTH":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/0298fa4b-150d-4cf3-797e-e0ebc76ffe00/public"
            style={{ maxWidth: "30%" }}
            alt="HEALTH"
          />
        );
      case "ATTACK":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c59cdcaa-9306-4737-66aa-e0ccda34cf00/public"
            style={{ maxWidth: "30%" }}
            alt="ATTACK"
          />
        );
      case "DEFENSE":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/27c6f939-1b59-4480-dd89-29f9ac499200/public"
            style={{ maxWidth: "30%" }}
            alt="DEFENSE"
          />
        );
      case "CRITICAL":
        return (
          <img
            src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/cd5206e5-ca94-4862-525f-d0cf1a575b00/public"
            style={{ maxWidth: "30%" }}
            alt="CRITICAL"
          />
        );
      case "AFFINITY":
        return (
          <img src={SKILL[data]} style={{ maxWidth: "30%" }} alt="AFFINITY" />
        );
    }
  }

  enableMerge() {
    this.setState({
      ...this.state,
      visibleFusion: true,
    });
  }

  enableMergeXP() {
    this.setState({
      ...this.state,
      visibleFusionXP: true,
    });
  }

  enableMergeIXP() {
    this.setState({
      ...this.state,
      visibleFusionIXP: true,
    });
  }

  getNftsFromBlockchain(side = null) {
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getTodosLosNFtsInfernal().then((res) => {
      //console.log("nfts", res);
      Swal.close();

      if (side) {
        this.setState({
          ...this.state,
          visibleFusion: true,
          allNfts: res,
        });
      } else {
        this.setState({
          ...this.state,
          visibleFullScreen: true,
          allNfts: res,
        });
      }
    });
  }

  getCollectorsFromBlockchain() {
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getTodosLosNFtsRecolectoresInfernal().then((res) => {
      //console.log("nfts", res);
      Swal.close();

      this.setState({
        ...this.state,
        visibleFusionIXP: true,
        allNfts: res,
      });
    });
  }

  Fusion() {
    if (this.state.selectedNft1 && this.state.selectedNft2) {
      Swal.fire({
        title: "initiating fusion...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      this.fusionNfts(this.state.selectedNft1, this.state.selectedNft2);
      Swal.close();
      this.setState({
        ...this.state,
        visibleFusion: false,
        selectedNft1: null,
        selectedNft2: null,
        disableSecond: false,
      });
    }
  }

  transferNft(nft) {
    let promp = prompt(" write hive user");

    if (!promp) return null;

    let json = [
      {
        contractName: "nft",
        contractAction: "transfer",
        contractPayload: {
          nfts: [
            {
              symbol: "IFCNFTS",
              ids: ["" + nft],
            },
          ],
          to: promp,
          memo: "Transfer from infernal game",
        },
      },
    ];

    window.hive_keychain.requestCustomJson(
      localStorage.getItem("username"),
      "ssc-mainnet-hive",
      "Active",
      `${JSON.stringify(json)}`,
      "transfer infernal nfts",
      (resp) => {
        if (resp.success) {
          this.toast.show([
            {
              severity: "info",
              summary: "Cancel",
              detail: resp.message,
              life: 12000,
            },
          ]);
        } else {
          this.toast.show([
            {
              severity: "info",
              summary: "Cancel",
              detail: resp.message,
              life: 12000,
            },
          ]);
        }
      }
    );
  }

  fusionNfts(nft1, nft2) {
    console.log(
      nft1,
      "🚀 ~ file: inventory.js ~ line 1131 ~ Inventory ~ fusionNfts ~ nft2",
      nft2.map((nft) => nft._id)
    );


    let quantity = 0.25;


    if (nft1.properties.p1 == "IRIN - THE WAR QUEEN" || nft1.properties.p1 == "OZARK - NIGHTMARE KING") {
      quantity = 0.001
    }
    //alert(JSON.stringify(nft2.map((n) => n._id)));

    const HP = new HivePay("infernalcoliseum");
    HP.setItemName("Fusion conqueror")
      .setItemDescription("fusion two conqueror")
      .setMerchant_email("blackmirague@gmail.com")
      .setNotifyUrl("https://guerrerosconsultoresas.com.co/IF/HPfusion.php")
      .setReturnUrl("https://game.infernalcoliseum.fun/inventory")
      .setAmount(quantity)
      .setQuantity(nft2.length)
      .setBaseCurrency("USD")
      .setDiscountToken("SOULS")
      .setDiscountAmount(0)
      .setPayCurrencies([
        "SOULS",
        //"HIVE",
        //"BUDS",
        //"SWAP.HIVE",
        //"SWAP.HBD",
        //"HBD",
        //"DEC",
      ])
      .setCustom(
        JSON.stringify({
          nft1: nft1._id,
          nft2: JSON.stringify(nft2.map((n) => n._id)),
          username: localStorage.getItem("username"),
        })
      );

    return HP.submit();
  }

  componentDidMount() {
    if (!localStorage.getItem("userData")) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.path == "null") {
      window.location.href = "/choosePath";
      return;
    }

    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getAllNfts().then((res) => {
      fetch("https://backend.infernalcoliseum.fun/api", {
        body: '{"query":"query {\\n  allNftIXP{\\n    _id\\n    name\\n    username\\n    lvl\\n  }\\n}"}',
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Dnt: "1",
        },
        method: "POST",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Swal.close();
          if (data?.data?.allNftIXP) {
            Swal.close();
            this.setState({
              ...this.state,
              nfts: res,
              userData,
              nftsIXP: data?.data?.allNftIXP,
            });
          }
        });

      // console.log("nfts", res);
    });
  }
}

export default Inventory;
