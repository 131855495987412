import React, { Component } from "react";
import Swal from "sweetalert2";
import { confirmDialog } from "primereact/confirmdialog";

class choosePath extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // console.log("entrando al choosePath    ");
  }

  render() {
    return (
      <div
        style={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
        className="container"
      >
        <div className="row">
          <div className="col-md-8">
            <div className="pb-3 " style={{ textAlign: "center" }} >
              <img
                className="img-thumbnail"
                style={{
                  width: "80vh",
                  backgroundColor: "unset",
                  border: "unset",
                }}
                src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c75d411a-f082-4479-8bfb-ba1ee7aa9100/public"
              />
              <h2 style={{ color: "white" }}>
                To enter the game you must first pick your side, will you choose
                the island of Demons or Angels you decide ?
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ textAlign: "center" }} >
                <img
                  style={{
                    width: "36%",
                    fontSize: "68%",
                    cursor: "pointer",
                  }}
                  src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/9541d1a4-519f-4575-1885-9a5580fe8a00/public"
                  onClick={(e) => {
                    this.seleccionarPath("KNALAND");
                  }}
                />
              </div>
              <div className="col-md-6" style={{ textAlign: "center" }} >
                <img
                  style={{
                    width: "36%",
                    fontSize: "68%",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    this.seleccionarPath("NOGROD");
                  }}
                  src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/980c5204-d32e-42ad-aaf5-fc8937687900/public"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <img src="https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/c21446f9-9758-4172-1010-2536b4e46d00/public" />
          </div>
        </div>
      </div>
    );
  }

  seleccionarPath(path) {
    confirmDialog({
      message:
        "Are you sure you want to proceed? you selected " +
        path +
        " and cant change it later",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.ElegirPath(path);
      },
      reject: () => { },
    });
  }

  ElegirPath(path) {
    Swal.fire({
      title: "Setting path...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let query = `{"query":"mutation($path: String!) {\\n  setpath(path: $path) {\\n    _id\\n    username\\n    path\\n    lvl\\n    xp\\n  }\\n}\\n","variables":{"path":"${path}"}}`;
    let aut = `Bearer ${localStorage.getItem("token")}`;

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: query,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: aut,
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res?.data?.setpath) {
          Swal.fire({
            icon: "success",
            title: "You have successfully selected your path",
            showConfirmButton: false,
            timer: 1500,
          }).then((res) => {
            this.props.history.push("/");
          });
        } else {
          Swal.close();
          alert("Error try to set path again");
          this.props.history.push("/");
        }
      });
  }
}

export default choosePath;
