import logo from "./logo.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import "primeflex/primeflex.css"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Game from "./componentes/game";
import Login from "./componentes/login";
import choosePath from "./componentes/choosePath";
import Inventory from "./componentes/inventory";

import Marketplace from "./componentes/marketplace";

import LandSale from "./componentes/lands/landSale";

import XP from "./componentes/xp/xpool";

import Store from "./componentes/store/store";

import Guilds from "./componentes/guilds/guilds";

import Home from "./componentes/home";
import Downbar from "./componentes/extras/downbar";
import Venta from "./componentes/store/venta";
import Te from "./componentes/te";
import T from "./componentes/test";
import ReactGA from "react-ga";

import React, { useEffect } from "react";

import PVP from "./componentes/pvp/pvp";

import Stake from "./componentes/staking/staking";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dungeon from "./componentes/dungeon";
import Open from "./componentes/packs/open";

import { ApolloProvider } from '@apollo/client';
import client from './apolloClient'; // Importar el cliente Apollo configurado

import finalTemporada from "./componentes/finalTemporada";

function App() {
  useEffect(() => {
    if (window.matchMedia("(max-width: 600px)").matches) {
      import("./estilos-mobile.css");
    } else {
      import("./App.css");
    }
  }, []);

  useEffect(() => {
    // Inicializa el seguimiento de Google Analytics
    ReactGA.initialize("4359206526");
  }, []);

  // Envía una vista de página cada vez que se cambie de ruta
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname, window.location.search]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename={"/"}>
        <Switch>
          <ShopGuardRoute exact path="/dungeon" component={Dungeon} />
          <ShopGuardRoute exact path="/t" component={T} />
          <ShopGuardRoute exact path="/" component={finalTemporada} />
          <ShopGuardRoute exact path="/choosePath" component={choosePath} />
          <ShopGuardRoute exact path="/inventory" component={Inventory} />
          <ShopGuardRoute exact path="/market" component={Marketplace} />
          <ShopGuardRoute exact path="/xp" component={XP} />
          <ShopGuardRoute exact path="/store" component={Store} />
          <ShopGuardRoute exact path="/guilds" component={Guilds} />
          <ShopGuardRoute exact path="/test" component={Te} />
          <ShopGuardRoute exact path="/lands" component={LandSale} />
          <ShopGuardRoute exact path="/pvp" component={PVP} />
          <ShopGuardRoute exact path="/stake" component={Stake} />
          <ShopGuardRoute exact path="/open" component={Open} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/home" component={Home} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

const ShopGuardRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={(routeProps) => {
      const item = localStorage.getItem("username");
      // Do all your conditional tests here
      // <Downbar name={routeProps.location.pathname}></Downbar>
      return item !== null ? (
        <>
          <Component {...routeProps} />
        </>
      ) : (
        //<Component {...routeProps} />
        <Redirect to="/login" />
      );
    }}
  />
);

export default App;
