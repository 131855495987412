function getCellContent(x, y, rooms, corridors) {
          const position = { x, y };
          console.log("validando", position);
          // Verificar si la posición es una habitación
          for (const room of rooms) {
                    if (isInsideRoom(position, room)) {
                              return 'R';
                    }
          }

          // Verificar si la posición es un corredor
          for (const corridor of corridors) {
                    if (isInsideCorridor(position, corridor)) {
                              return 'C';
                    }
          }

          // Verificar si la posición es una coordenada
          if (isCoordinate(position)) {
                    return 'X';
          }

          // Si no es ninguna de las anteriores, es un espacio vacío
          return '.';
}

function isInsideRoom(position, room) {
          console.log("verificando si posiicion esta en una room", position, room);
          return (
                    position.x >= room.x &&
                    position.x < room.x + room.width &&
                    position.y >= room.y &&
                    position.y < room.y + room.height
          );
}

function isInsideCorridor(position, corridor) {
          const minX = Math.min(corridor.startX, corridor.endX);
          const maxX = Math.max(corridor.startX, corridor.endX);
          const minY = Math.min(corridor.startY, corridor.endY);
          const maxY = Math.max(corridor.startY, corridor.endY);

          return position.x >= minX && position.x <= maxX && position.y >= minY && position.y <= maxY;
}

function isCoordinate(position) {
          // Ejemplo: Consideramos una coordenada en (5, 5)
          return position.x === 5 && position.y === 5;
}



export default { getCellContent, isInsideRoom, isInsideCorridor, isCoordinate };