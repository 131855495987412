import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import panelNogrod from "../../assets/img/PANEL_NOGROD.png";

import panelKnaland from "../../assets/img/PANEL_KNALAND.png";
import panelKnalan from "../../assets/img/PANEL_KNALAND.png";

import { Avatar } from "primereact/avatar";

import BotonCerrarKnaland from "../../assets/img/BOTON_CERRAR_2.png";
import BotonCerrarNogrod from "../../assets/img/BOTON_CERRAR_1-05.png";

import inconoNombreBoss from "../../assets/img/iconos/BOSS_NAME_ICON.png";
import bossOff from "../../assets/img/iconos/BOSS_OFF.png";
import bossOn from "../../assets/img/iconos/BOSS_ON.png";
import botonAtacar from "../../assets/img/iconos/BOTON_ATACAR.png";
import botonDefender from "../../assets/img/iconos/BOTON_DEFENDER.png";
import capitalIcono from "../../assets/img/iconos/CAPITAL_ICON.png";
import cityIcono from "../../assets/img/iconos/CITY_ICON.png";
import puebloIcono from "../../assets/img/iconos/TOWN_ICON.png";
import poblationIcon from "../../assets/img/iconos/POBLATION_ICON.png";
import soulsDropIcon from "../../assets/img/iconos/SOULS_DROP_ICON.png";

//knaland
import alexandreia from "../../assets/img/territorios/knaland/Alexandreia.png";
import Castilla from "../../assets/img/territorios/knaland/Castilla.png";
import Dornhan from "../../assets/img/territorios/knaland/Dornhan.png";
import LioKaiIsland from "../../assets/img/territorios/knaland/LioKaiIsland.png";
import Marhelm from "../../assets/img/territorios/knaland/Marhelm.png";
import Mugmodror from "../../assets/img/territorios/knaland/Mugmodror.png";
import Santana from "../../assets/img/territorios/knaland/Santana.png";
import Morntaric from "../../assets/img/territorios/knaland/Morntaric.png";
import Bayfrost from "../../assets/img/territorios/knaland/Bayfrost.png";
import Berford from "../../assets/img/territorios/knaland/Berford.png";
import EvralandForest from "../../assets/img/territorios/knaland/EvralandForest.png";
import Freville from "../../assets/img/territorios/knaland/Freville.png";
import Frostland from "../../assets/img/territorios/knaland/Frostland.png";
import Goiania from "../../assets/img/territorios/knaland/Goiania.png";
import Hertford from "../../assets/img/territorios/knaland/Hertford.png";
import HonkHill from "../../assets/img/territorios/knaland/HonkHill.png";
import Tamarand from "../../assets/img/territorios/knaland/Tamarand.png";

//nogrod
import Alzokh from "../../assets/img/territorios/nogrod/Alzokh.png";
import DubkovianUnion from "../../assets/img/territorios/nogrod/Dubkovian Union.png";
import Fjelldal from "../../assets/img/territorios/nogrod/Fjelldal.png";
import IxianDesert from "../../assets/img/territorios/nogrod/Ixian Desert.png";
import KalamarIsland from "../../assets/img/territorios/nogrod/Kalamar Island.png";
import Lugmoor from "../../assets/img/territorios/nogrod/Lugmoor.png";
import Nilx from "../../assets/img/territorios/nogrod/Nilx.png";
import NorthKyme from "../../assets/img/territorios/nogrod/North  Kyme.png";
import Rakamah from "../../assets/img/territorios/nogrod/Rakamah.png";
import Rurkoc from "../../assets/img/territorios/nogrod/Rurkoc.png";
import Shexai from "../../assets/img/territorios/nogrod/Shexai.png";
import SouthKyme from "../../assets/img/territorios/nogrod/South Kyme.png";
import Sylvana from "../../assets/img/territorios/nogrod/Sylvana.png";
import Tsuki from "../../assets/img/territorios/nogrod/Tsuki.png";
import Tsune from "../../assets/img/territorios/nogrod/Tsune.png";
import Vereya from "../../assets/img/territorios/nogrod/Vereya.png";
import Xaian from "../../assets/img/territorios/nogrod/Xaian.png";

import { ScrollPanel } from "primereact/scrollpanel";
import utils from "../../utils";

import NftsToRaids from "./setNftsToRaid";

//froznorth
import Froznorth from "../../assets/img/territorios/nogrod/Froznorth.png";

import rank1 from "../../assets/img/ranking/RANK-1.webp";
import { Dialog } from "primereact/dialog";

import AdSenseAdsB from "../extras/AdsenseAdsB";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";


import FinalFenix from "../../assets/img/nuevosJefes/final fenix.webp"
import THEENT from "../../assets/img/nuevosJefes/the ent.webp"
import AGNAROK from "../../assets/img/nuevosJefes/agnarok.webp"
import THEKINGMANTICORE from "../../assets/img/nuevosJefes/manticore.webp"
import SAGITARIO from "../../assets/img/nuevosJefes/sagitario.webp"
import WATERTERRORNESSIE from "../../assets/img/nuevosJefes/nessie.webp"
import TRITON from "../../assets/img/nuevosJefes/triton.webp"
import DRAGONFLY from "../../assets/img/nuevosJefes/dragon volador.webp"
import DRAGONWATER from "../../assets/img/nuevosJefes/dragonWater.webp"
import SIREN from "../../assets/img/nuevosJefes/siren.png"

import border from "../../assets/img/ranking/RANK-1.webp"
import { isMobile } from 'react-device-detect';
import { Sidebar } from 'primereact/sidebar';

import { Accordion, AccordionTab } from 'primereact/accordion';




const areas = {
  Alexandreia: "KNALAND",
  Castilla: "KNALAND",
  Dornhan: "KNALAND",
  "Lio Kai Island": "KNALAND",
  Marhelm: "KNALAND",
  Mugmodror: "KNALAND",
  Santana: "KNALAND",
  Morntaric: "KNALAND",
  Bayfrost: "KNALAND",
  Berford: "KNALAND",
  "Evraland Forest": "KNALAND",
  Freville: "KNALAND",
  Frostland: "KNALAND",
  Goiania: "KNALAND",
  Hertford: "KNALAND",
  "Honk Hill": "KNALAND",
  Tamarand: "KNALAND",
  Alzokh: "NOGROD",
  "Dubkovian Union": "NOGROD",
  Fjelldal: "NOGROD",
  "Ixian Desert": "NOGROD",
  "Kalamar Island": "NOGROD",
  Lugmoor: "NOGROD",
  Nilx: "NOGROD",
  "North - Kyme": "NOGROD",
  Rakamah: "NOGROD",
  Rurkoc: "NOGROD",
  Shexai: "NOGROD",
  "South - Kyme": "NOGROD",
  Sylvana: "NOGROD",
  Tsuki: "NOGROD",
  Tsune: "NOGROD",
  Vereya: "NOGROD",
  Xaian: "NOGROD",
  Froznorth: "neutral",
};

export function showModal(area, props) {
  this.setState({ showModal: false }, () => {
    this.setState({ showModal: true, area: area });
  });
}

export function hover(area, username, e) {
  //console.log("datos que entran aqui ", areas, area, username);
  /*
  if (areas[area.name] === username.path || areas[area.name] === "neutral") {
    area.side = username.path == "NOGROD" ? "angel" : "demon";
    this.showModal(area, username);
  } else {
    if (parseInt(username.lvl) < 16) {
      alert("you need to be over level 16 to see this continent");
    }
    //console.log("you can see ", area, username);
  }
  */
  this.showModal(area, username);
}

export function clicked(area, index, e) {
  const elm = "#mapa-termindo";
}

//
const RaidBoss = [
  {
    NAME: "Final Fenix",
    IMG: FinalFenix,
  },
  {
    NAME: "THE ENT",
    IMG: THEENT,
  },
  {
    NAME: "AGNAROK",
    IMG: AGNAROK,
  },
  {
    NAME: "THE KING MANTICORE",
    IMG: THEKINGMANTICORE,
  },
  {
    NAME: "SAGITARIO",
    IMG: SAGITARIO,
  },
  {
    NAME: "WATER TERROR - NESSIE",
    IMG: WATERTERRORNESSIE,
  },
  {
    NAME: "TRITON",
    IMG: TRITON,
  },
  {
    NAME: "DRAGONFLY",
    IMG: DRAGONFLY,
  },
  {
    NAME: "DRAGONWATER",
    IMG: DRAGONWATER,
  },
  {
    NAME: "PAPA NO-EL",
    IMG: "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/ad0e7251-3d5e-4be6-4539-719692835f00/public",
  },
  {
    NAME: "SIREN",
    IMG: SIREN
  }
];

class InfoModalMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      area: null,
      loading: true,
      customers1: [],
      displayed: false,
      infoAllZone: [],
      premio: [],
      displayMining: false,
      nftsIXP: [],
    };
    this.calcularPremio = this.calcularPremio.bind(this);
    this.setNft = this.setNft.bind(this);
    this.getHeaderMining = this.getHeaderMining.bind(this);
    this.claim = this.claim.bind(this);
  }

  render() {

    if (!this.props.isMobile) {
      return (
        <Sidebar className="w-full md:w-20rem lg:w-30rem" visible={this.state.showModal} position={
          this.props.area.side == "angel" ? "left" : "right"
        } onHide={() => this.setState({ showModal: false })}>

          <Accordion activeIndex={0}>

            <AccordionTab header="Farming">

              {this.props.area.side == "demon" &&
                this.props.area.name != "Lio Kai Island" && (

                  <div
                  >


                    <div className="container-fluid">
                      <div className="row">
                        <div class="col">



                          <div class="d-flex align-items-center">


                            <img
                              style={
                                {
                                  width: "50%"
                                }
                              }
                              className="img-fluid mx-auto d-block"
                              src={this.getImgTitle(this.props.area.name)}
                            ></img>


                          </div>



                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div class="align-items-center">
                            <img
                              className="img-fluid mx-auto d-block"
                              style={{
                                fontSize: "small",
                                width: "50%",
                                borderRadius: "5%",
                              }}
                              src={this.getBoosImg(this.state.infoAllZone.boss)}
                            ></img>
                            <p
                              style={{
                                color: "white",
                                textAlign: "center"
                              }}
                            >
                              {this.state.infoAllZone.boss}
                            </p>
                          </div>


                        </div>
                        <div className="col" style={{ maxWidth: "50%" }}>

                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                src={inconoNombreBoss}
                              ></img>
                            </div>

                            <div className="col">
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "small",
                                  width: "8%",
                                }}
                              >
                                Required: {this.state.infoAllZone.XP}xp
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                src={soulsDropIcon}
                              ></img>
                            </div>

                            <div className="col">
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "small",
                                  width: "8%",
                                }}
                              >
                                {this.state.infoAllZone.souls}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                style={{
                                  cursor: "pointer",
                                }}
                                src={this.getImgBossOffON(this.state.infoAllZone)}
                                onClick={(e) => {
                                  this.displayFarmingxp();
                                }}
                              ></img>
                            </div>

                            <div className="col">

                              {this.getbossOffONassetNogrod(this.state.infoAllZone)}
                            </div>


                          </div>


                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div className="table-responsive">
                            <table className="table table-dark table-sm tableB">
                              <thead>
                                <tr>
                                  <th>Place</th>
                                  <th>User</th>
                                  <th>Souls</th>
                                  <th>Raid Power</th>
                                </tr>
                              </thead>

                              <tbody>
                                {this.state.premio.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="destacado">
                                        <div
                                          className={
                                            "neon-bg neon-bgx neon-bg" + (index + 1)
                                          }
                                        >
                                          {index + 1}
                                        </div>
                                      </td>
                                      <td>{element.username}</td>
                                      <td>{element.souls}</td>
                                      <td>{element.raidPower}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      {/*obtencion de recursos*/}
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={capitalIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "54% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.capital}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "65% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={cityIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "67% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.city}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={puebloIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "80% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.town}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "64% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "64% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                    </div>



                  </div>
                )}
              {this.props.area.side == "angel" &&
                this.props.area.name != "Kalamar Island" && (

                  <div

                  >


                    <div className="container-fluid">
                      <div className="row">
                        <div class="col">



                          <div class="d-flex align-items-center">



                            <img
                              style={
                                {
                                  width: "50%"
                                }
                              }
                              className="img-fluid mx-auto d-block"
                              src={this.getImgTitle(this.props.area.name)}
                            ></img>


                          </div>



                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div class="d-flex align-items-center">
                            <img
                              className="img-fluid mx-auto d-block"
                              style={{
                                fontSize: "small",
                                width: "50%",
                                borderRadius: "5%",
                              }}
                              src={this.getBoosImg(this.state.infoAllZone.boss)}
                            ></img>

                          </div>


                        </div>
                        <div className="col" style={{ maxWidth: "50%" }}>

                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                src={inconoNombreBoss}
                              ></img>
                            </div>

                            <div className="col">
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "small",
                                  width: "8%",
                                }}
                              >
                                {this.state.infoAllZone.boss} {this.state.infoAllZone.XP}xp
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                src={soulsDropIcon}
                              ></img>
                            </div>

                            <div className="col">
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "small",
                                  width: "8%",
                                }}
                              >
                                {this.state.infoAllZone.souls}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <img
                                className="img-fluid"
                                style={{
                                  cursor: "pointer",
                                }}
                                src={this.getImgBossOffON(this.state.infoAllZone)}
                                onClick={(e) => {
                                  this.displayFarmingxp();
                                }}
                              ></img>
                            </div>

                            <div className="col">

                              {this.getbossOffONassetNogrod(this.state.infoAllZone)}
                            </div>


                          </div>


                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div className="table-responsive">
                            <table className="table table-dark table-sm tableB">
                              <thead>
                                <tr>
                                  <th>Place</th>
                                  <th>User</th>
                                  <th>Souls</th>
                                  <th>Raid Power</th>
                                </tr>
                              </thead>

                              <tbody>
                                {this.state.premio.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="destacado">
                                        <div
                                          className={
                                            "neon-bg neon-bgx neon-bg" + (index + 1)
                                          }
                                        >
                                          {index + 1}
                                        </div>
                                      </td>
                                      <td>{element.username}</td>
                                      <td>{element.souls}</td>
                                      <td>{element.raidPower}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      {/*obtencion de recursos*/}
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={capitalIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "54% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.capital}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "65% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={cityIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "67% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.city}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 33% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={puebloIcono}
                      ></img>
                      <p
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "80% 30% auto auto",
                          fontSize: "small",
                          width: "2%",
                        }}
                        src={poblationIcon}
                      >
                        {this.props.area.town}
                      </p>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "52% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "64% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "64% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 14% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonAtacar}
                      ></img>
                      <img
                        style={{
                          color: "white",
                          position: "fixed",
                          inset: "78% 20% auto auto",
                          fontSize: "small",
                          width: "3%",
                        }}
                        src={botonDefender}
                      ></img>
                    </div>



                  </div>

                )}



              {this.props.area.name == "Froznorth" && (

                <div
                >


                  <div className="container-fluid">
                    <div className="row">
                      <div class="col">

                        <div class="d-flex align-items-center">

                          <img
                            style={
                              {
                                width: "50%"
                              }
                            }
                            className="img-fluid mx-auto d-block"
                            src={this.getImgTitle(this.props.area.name)}
                          ></img>


                        </div>



                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div class="d-flex align-items-center">
                          <img
                            className="img-fluid mx-auto d-block"
                            style={{
                              fontSize: "small",
                              width: "50%",
                              borderRadius: "5%",
                            }}
                            src={this.getBoosImg(this.state.infoAllZone.boss)}
                          ></img>

                        </div>


                      </div>
                      <div className="col" style={{ maxWidth: "50%" }}>

                        <div className="row">
                          <div className="col">
                            <img
                              className="img-fluid"
                              src={inconoNombreBoss}
                            ></img>
                          </div>

                          <div className="col">
                            <p
                              style={{
                                color: "white",
                                fontSize: "small",
                                width: "8%",
                              }}
                            >
                              {this.state.infoAllZone.boss} {this.state.infoAllZone.XP}xp
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <img
                              className="img-fluid"
                              src={soulsDropIcon}
                            ></img>
                          </div>

                          <div className="col">
                            <p
                              style={{
                                color: "white",
                                fontSize: "small",
                                width: "8%",
                              }}
                            >
                              {this.state.infoAllZone.souls}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <img
                              className="img-fluid"
                              style={{
                                cursor: "pointer",
                              }}
                              src={this.getImgBossOffON(this.state.infoAllZone)}
                              onClick={(e) => {
                                this.displayFarmingxp();
                              }}
                            ></img>
                          </div>

                          <div className="col">

                            {this.getbossOffONassetNogrod(this.state.infoAllZone)}
                          </div>


                        </div>


                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div className="table-responsive">
                          <table className="table table-dark table-sm tableB">
                            <thead>
                              <tr>
                                <th>Place</th>
                                <th>User</th>
                                <th>Souls</th>
                                <th>Raid Power</th>
                              </tr>
                            </thead>

                            <tbody>
                              {this.state.premio.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="destacado">
                                      <div
                                        className={
                                          "neon-bg neon-bgx neon-bg" + (index + 1)
                                        }
                                      >
                                        {index + 1}
                                      </div>
                                    </td>
                                    <td>{element.username}</td>
                                    <td>{element.souls}</td>
                                    <td>{element.raidPower}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div
                    style={{
                      display: "none",
                    }}
                  >
                    {/*obtencion de recursos*/}
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "52% 33% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={capitalIcono}
                    ></img>
                    <p
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "54% 30% auto auto",
                        fontSize: "small",
                        width: "2%",
                      }}
                      src={poblationIcon}
                    >
                      {this.props.area.capital}
                    </p>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "65% 33% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={cityIcono}
                    ></img>
                    <p
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "67% 30% auto auto",
                        fontSize: "small",
                        width: "2%",
                      }}
                      src={poblationIcon}
                    >
                      {this.props.area.city}
                    </p>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "78% 33% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={puebloIcono}
                    ></img>
                    <p
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "80% 30% auto auto",
                        fontSize: "small",
                        width: "2%",
                      }}
                      src={poblationIcon}
                    >
                      {this.props.area.town}
                    </p>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "52% 14% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonAtacar}
                    ></img>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "52% 20% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonDefender}
                    ></img>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "64% 14% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonAtacar}
                    ></img>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "64% 20% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonDefender}
                    ></img>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "78% 14% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonAtacar}
                    ></img>
                    <img
                      style={{
                        color: "white",
                        position: "fixed",
                        inset: "78% 20% auto auto",
                        fontSize: "small",
                        width: "3%",
                      }}
                      src={botonDefender}
                    ></img>
                  </div>



                </div>

              )}


              {this.state.setNfts == true && (
                <NftsToRaids
                  visible={this.state.setNfts}
                  onHide={() => {
                    this.setState({ setNfts: false });
                  }}
                  area={this.props.area}
                  datosZona={this.state.infoAllZone}
                  nfts={[]}
                />
              )}
              {this.state.displayMining && (
                <Dialog
                  header={this.getHeaderMining}
                  visible={this.state.displayMining}
                  position="center"
                  modal
                  style={{ width: "70vw" }}
                  onHide={() => this.hide()}
                  draggable={false}
                  resizable={true}
                >
                  <p>
                    Time mining:{" "}
                    {this.state.ixpInfo.lastClaim
                      ? new Date(
                        parseInt(this.state.ixpInfo.lastClaim)
                      ).toLocaleString()
                      : "none"}
                  </p>

                  <div className="row">
                    <div className="col-6 text-center">
                      <Dropdown
                        options={this.state.nftsIXP.map((e) => {
                          return { value: e._id, label: e.name + " " + e.lvl };
                        })} // Generar las opciones del combobox
                        value={this.state.createM} // Valor seleccionado
                        optionLabel="label"
                        placeholder="Select a NFT"
                        onChange={(e) => this.setState({ createM: e.value })} // Manejar el cambio de cantidad seleccionada
                      />
                    </div>

                    <div className="col-6 text-center">
                      <Button
                        onClick={(e) => {
                          this.setNft();
                        }}
                      >
                        Set Miner
                      </Button>
                    </div>
                  </div>
                  <h3>prizes</h3>
                  <ul>
                    {this.state.ixpInfo?.prizes?.map((e) => {
                      return <li>{e.token + " " + e.quantity} </li>;
                    })}
                  </ul>

                  <h3>Miners</h3>
                  <ul>
                    {this.state.ixpInfo?.miners?.map((e) => {
                      return (
                        <li>
                          {e.name + " " + e.lvl + " XP HOUR:" + this.getXP(e.lvl)}
                        </li>
                      );
                    })}
                  </ul>

                  <Button
                    onClick={(e) => {
                      this.claim();
                    }}
                  >
                    Claim
                  </Button>
                </Dialog>
              )}

            </AccordionTab>
            <AccordionTab header="Guild">
            </AccordionTab>
            <AccordionTab header="PVP">
            </AccordionTab>


          </Accordion>


        </Sidebar>
      );
    } else {

      return <div>
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center">
              <img
                style={{
                  width: "50%",
                }}
                className="img-fluid mx-auto d-block"
                src={this.getImgTitle(this.props.area.name)}
                alt="Area Title"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center flex-column">
              <img
                className="img-fluid mx-auto d-block"
                style={{
                  fontSize: "small",
                  width: "70%",
                  borderRadius: "5%",
                }}
                src={this.getBoosImg(this.state.infoAllZone.boss)}
                alt="Boss Image"
              />
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                {this.state.infoAllZone.boss}
              </p>
            </div>
          </div>
        </div>



        <div className="flex">
          <div className="flex-initial flex align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round">
            <img className="img-fluid" src={soulsDropIcon} alt="Icono Souls Drop" />   {this.state.infoAllZone.souls}
          </div>
          <div className="flex-initial flex align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round">
            <img className="img-fluid" src={inconoNombreBoss} alt="Icono Nombre Boss" /> {this.state.infoAllZone.XP}xp
          </div>
        </div>
        <div className="flex">
          <div className="flex-initial flex align-items-center justify-content-center  font-bold m-2 px-5 py-3 border-round">
            <img
              className="img-fluid"
              style={{
                cursor: "pointer",
                width: "100vh",
              }}
              src={this.getImgBossOffON(this.state.infoAllZone)}
              alt="Icono Boss Off/ON"
              onClick={(e) => {
                this.displayFarmingxp();
              }}
            />
          </div>
          <div className="flex-initial flex align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round">
            {this.getbossOffONassetNogrod(this.state.infoAllZone, true)}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="table-responsive">
              <DataTable value={this.state.premio}>
                <Column header="Place" body={(rowData, rowIndex) => (
                  <div className={`neon-bg neon-bgx neon-bg${rowIndex.rowIndex + 1}`}>
                    {rowIndex.rowIndex + 1}
                  </div>
                )} />
                <Column header="User" field="username" />
                <Column header="Souls" field="souls" />
                <Column header="Raid Power" field="raidPower" />
              </DataTable>
            </div>
          </div>
        </div>


        {this.state.setNfts == true && (
          <NftsToRaids
            visible={this.state.setNfts}
            onHide={() => {
              this.setState({ setNfts: false });
            }}
            area={this.props.area}
            datosZona={this.state.infoAllZone}
            nfts={[]}
          />
        )}
        {this.state.displayMining && (
          <Dialog
            header={this.getHeaderMining}
            visible={this.state.displayMining}
            position="center"
            modal
            style={{ width: "60vw" }}
            onHide={() => this.hide()}
            draggable={false}
            resizable={true}
          >
            <p>
              Time mining:{" "}
              {this.state.ixpInfo.lastClaim
                ? new Date(
                  parseInt(this.state.ixpInfo.lastClaim)
                ).toLocaleString()
                : "none"}
            </p>

            <div className="row">
              <div className="col-6 text-center">
                <Dropdown
                  options={this.state.nftsIXP.map((e) => {
                    return { value: e._id, label: e.name + " " + e.lvl };
                  })} // Generar las opciones del combobox
                  value={this.state.createM} // Valor seleccionado
                  optionLabel="label"
                  placeholder="Select a NFT"
                  onChange={(e) => this.setState({ createM: e.value })} // Manejar el cambio de cantidad seleccionada
                />
              </div>

              <div className="col-6 text-center">
                <Button
                  onClick={(e) => {
                    this.setNft();
                  }}
                >
                  Set Miner
                </Button>
              </div>
            </div>
            <h3>prizes</h3>
            <ul>
              {this.state.ixpInfo?.prizes?.map((e) => {
                return <li>{e.token + " " + e.quantity} </li>;
              })}
            </ul>

            <h3>Miners</h3>
            <ul>
              {this.state.ixpInfo?.miners?.map((e) => {
                return (
                  <li>
                    {e.name + " " + e.lvl + " XP HOUR:" + this.getXP(e.lvl)}
                  </li>
                );
              })}
            </ul>

            <Button
              onClick={(e) => {
                this.claim();
              }}
            >
              Claim
            </Button>
          </Dialog>
        )}



      </div>

    }

  }

  claim() {
    Swal.fire({
      title: "Claimining...",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();

        fetch("https://backend.infernalcoliseum.fun/api", {
          body: `{"query":"mutation{\\n  claimPRIZES(territory:\\"${this.state.infoAllZone.territorio}\\"){\\n    success\\n    message\\n  }\\n}"}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            Dnt: "1",
          },
          method: "POST",
        }).then(e => e.json()).then(e => {

          alert(e.data.claimPRIZES.message);
          window.location.reload();

          Swal.close();
        });
      },
    });
    console.log(this.state.infoAllZone.territorio);
  }

  getHeaderMining() {
    console.log(this.state);

    return `Mining xp on ${this.state.infoAllZone.territorio}`;
  }

  getXP(lvl) {
    console.log("lvl personaje" + lvl);
    let xp = this.state.infoAllZone.XP;
    if (!xp) {
      xp = 0;
    }

    if (xp == 0) {
      xp = 5;
    }
    return (xp / (7 * 24 * 10)) * (1 / ((11 - lvl) * (11 - lvl)));
  }

  hide() {
    this.setState({
      displayMining: false,
    });
  }

  getSouls(element) {
    let totalPower = this.state.infoAllZone?.usuarios?.reduce(
      (total, usuario) => {
        return total + parseInt(usuario.raidPower);
      },
      0
    );

    let ratio = parseInt(this.state.infoAllZone.souls) / totalPower;

    return (parseInt(element.raidPower) * ratio).toFixed(8);
  }

  getImgTitle(title) {
    //console.log(title);
    switch (title) {
      case "Alexandreia":
        return alexandreia;
      case "Castilla":
        return Castilla;
      case "Dornhan":
        return Dornhan;
      case "Lio Kai Island":
        return LioKaiIsland;
      case "Marhelm":
        return Marhelm;
      case "Mugmodror":
        return Mugmodror;
      case "Santana":
        return Santana;
      case "Morntaric":
        return Morntaric;
      case "Berford":
        return Berford;
      case "Evraland Forest":
        return EvralandForest;
      case "Freville":
        return Freville;
      case "Frostland":
        return Frostland;
      case "Goiania":
        return Goiania;
      case "Hertford":
        return Hertford;
      case "Honk Hill":
        return HonkHill;
      case "Tamarand":
        return Tamarand;
      case "Bayfrost":
        return Bayfrost;

      case "Alzokh":
        return Alzokh;
      case "Dubkovian Union":
        return DubkovianUnion;
      case "Fjelldal":
        return Fjelldal;
      case "Ixian Desert":
        return IxianDesert;
      case "Kalamar Island":
        return KalamarIsland;
      case "Lugmoor":
        return Lugmoor;
      case "Nilx":
        return Nilx;
      case "North - Kyme":
        return NorthKyme;
      case "Rakamah":
        return Rakamah;
      case "Rurkoc":
        return Rurkoc;
      case "Shexai":
        return Shexai;
      case "South - Kyme":
        return SouthKyme;
      case "Sylvana":
        return Sylvana;
      case "Tsuki":
        return Tsuki;
      case "Tsune":
        return Tsune;
      case "Vereya":
        return Vereya;
      case "Xaian":
        return Xaian;

      case "Froznorth":
        return Froznorth;

      default:
        return "";
    }
  }

  getImgBossOffON(boss) {
    /*
    let bossStatus = boss.conquistado == "system" ? true : false;
    if (bossStatus) {
      
    } else {
      return bossOff;
    }
*/
    if (this.props.area.master == "") {
      return bossOff;
    } else {
      return `https://images.hive.blog/u/${this.props.area.master}/avatar`;
    }
  }

  getBoosImg(boss) {
    //console.log("buscando", boss);
    for (let i = 0; i < RaidBoss.length; i++) {
      if (RaidBoss[i].NAME == boss) {
        return RaidBoss[i].IMG;
      }
    }
  }

  getbossOffONasset(boss) {
    let bossStatus = boss.conquistado == "system" ? true : false;
    if (!bossStatus) {
      return (
        <h4
          style={{
            color: "white",
            position: "fixed",
            inset: "45% 10% auto auto",
            fontSize: "small",
            width: "8%",
          }}
        >
          boss well be back soon
        </h4>
      );
    } else {
      return (
        <img
          style={{
            color: "white",
            position: "fixed",
            inset: "43% 14% auto auto",
            fontSize: "small",
            width: "3%",
            cursor: "pointer",
          }}
          src={botonAtacar}
          onClick={() => {
            let myxp = JSON.parse(localStorage.getItem("userData"));
            myxp = parseInt(myxp.xp);

            if (myxp >= boss?.XP) {
              this.setState({ setNfts: true });
            } else {
              Swal.fire({
                icon: "info",
                title:
                  "to enter this raid you need at least " + boss.XP + " xp",
                showConfirmButton: false,
                timer: 1500,
              }).then((res) => { });
            }
          }}
        ></img>
      );
    }
  }

  getbossOffONassetNogrod(boss, mobile = false) {
    let bossStatus = boss.conquistado == "system" ? true : false;
    if (!bossStatus) {
      return (
        <h4
          style={{
            color: "white",
            fontSize: "small",
            width: mobile ? "100%" : "8%",
          }}
        >
          boss well be back soon
        </h4>
      );
    } else {
      return (
        <img
          className="img-fluid"
          style={{
            color: "white",
            fontSize: "small",
            width: mobile ? "100%" : "50%",
            cursor: "pointer",
          }}
          src={botonAtacar}
          onClick={() => {
            let myxp = JSON.parse(localStorage.getItem("userData"));
            myxp = parseInt(myxp.xp);

            if (myxp >= boss?.XP) {
              this.setState({ setNfts: true });
            } else {
              Swal.fire({
                icon: "info",
                title:
                  "to enter on this raid you need at least " + boss.XP + " xp",
                showConfirmButton: true,
                timer: 5000,
              }).then((res) => { });
            }
          }}
        ></img>
      );
    }
  }

  displayFarmingxp() {
    if (this.props.area.master != "" && this.props.area.name != "Lio Kai Island" && this.props.area.name != "Kalamar Island") {
      console.log("buscando", this.props.area.name);
      Swal.fire({
        title: "Getting info...",
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();
          //
          fetch("https://backend.infernalcoliseum.fun/api", {
            body: `{"query":"query {\\n  getdataXPOnLand(land:\\"${this.props.area.name}\\"){\\n    username\\n    land {\\n      _id\\n      COUNTRY\\n    } \\n    miners {\\n      _id\\n      name\\n      lvl\\n    }\\n    prizes {\\n      token\\n      quantity\\n    }\\n    lastClaim\\n  }\\n}"}`,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
              Dnt: "1",
            },
            method: "POST",
          })
            .then((e) => e.json())
            .then((e) => {
              console.log("data on land", e);
              this.setState({
                displayMining: true,
                ixpInfo: {
                  land: e?.data?.getdataXPOnLand?.land,
                  lastClaim: e?.data?.getdataXPOnLand?.lastClaim,
                  miners: e?.data?.getdataXPOnLand?.miners,
                  prizes: e?.data?.getdataXPOnLand?.prizes,
                  username: e?.data?.getdataXPOnLand?.username,
                },
              });

              Swal.close();
            });
        },
      });
    }
  }

  setNft() {
    console.log("creando", this.state, this.state.createM);
    Swal.showLoading();
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation{\\n  setNFTXPtoLand(land:\\"${this.state.infoAllZone.territorio}\\",nft:\\"${this.state.createM}\\"){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((e) => e.json())
      .then((e) => {
        console.log(e);
        alert(e?.data.setNFTXPtoLand.message);
        this.displayFarmingxp();
      });
  }

  componentDidMount() {

    this.loadInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.area !== prevProps.area) {
      this.loadInfo()

      // Coloca aquí la lógica que deseas ejecutar cuando miProp cambie
    }
  }

  loadInfo() {

    console.log(this.props.area, "averifguando por ")

    if (this.props.area.name == "Lio Kai Island" ||
      this.props.area.name == "Kalamar Island") {
      return;
    }
    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();

        fetch("https://backend.infernalcoliseum.fun/api", {
          body: '{"query":"query {\\n  allNftIXPDISPONIBLES{\\n _id\\n   name\\n    username\\n    lvl\\n  }\\n}"}',
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
            Dnt: "1",
          },
          method: "POST",
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log("getting data de nfts ", data);
            Swal.close();
            if (data?.data?.allNftIXPDISPONIBLES) {
              this.setState({
                nftsIXP: data?.data?.allNftIXPDISPONIBLES,
              });
            }
          });

        if (
          this.props.area.name == "Lio Kai Island" ||
          this.props.area.name == "Kalamar Island"
        ) {
          utils.getInfoForSide(this.props.area.side).then((data) => {
            this.setState(
              {
                infoAllZone: data.data.getInfoZona.filter(
                  (e) => e.conquistado == "system"
                ),
              },
              () => {
                Swal.close();
              }
            );
          });
        } else {
          utils.getInfoForTerritory(this.props.area.name).then((data) => {
            console.log("datos de territorio", data, this.props.area.name);

            let datax = data.data.getInfoTerritory[0]?.usuarios?.reduce(
              (acc, obj) => {
                let key = obj.username;
                if (!acc[key]) {
                  acc[key] = obj;
                } else {
                  if (typeof acc[key].raidPower == "string") {
                    acc[key].raidPower = parseInt(acc[key].raidPower);
                  }
                  acc[key].raidPower += parseInt(obj.raidPower);
                }
                return acc;
              },
              {}
            );

            let d = [];
            for (const key in datax) {
              d.push({
                username: key,
                raidPower: datax[key].raidPower,
              });
            }
            data.data.getInfoTerritory[0].usuarios = d;
            this.calcularPremio(data.data.getInfoTerritory[0]?.souls, d);

            this.setState(
              {
                infoAllZone: data.data.getInfoTerritory[0],
              },
              () => {
                Swal.close();
              }
            );
          });
        }
      },
    });
  }

  calcularPremio(souls, usuarios) {
    //order array by raidPower
    /*1st - 20 Souls (highest power) (20%) 
2nd 15 souls (15%)
3rd 10 souls (10%)
4th 8 souls 8%
5th 7 souls 7%
6th 6 souls 6%
7th 5 souls 5%
8th 4 souls  4%
 9th 3 souls   3%
 10th 2 souls   2%
 11-15 th 1 souls   1%
16-20th 0.8 souls    0.8%
 21-30th 0.5 souls    0.5%
 31-40 0.4 Souls      0.4%
 41-50 0.2 Souls      0.2%*/
    let ordenado = usuarios.sort((a, b) => {
      return b.raidPower - a.raidPower;
    });
    //console.log(ordenado);
    let arrayPremio = [
      ["pool", parseInt(souls) * 0.2, 0],
      ["pool", parseInt(souls) * 0.15, 0],
      ["pool", parseInt(souls) * 0.1, 0],
      ["pool", parseInt(souls) * 0.08, 0],
      ["pool", parseInt(souls) * 0.07, 0],
      ["pool", parseInt(souls) * 0.06, 0],
      ["pool", parseInt(souls) * 0.05, 0],
      ["pool", parseInt(souls) * 0.04, 0],
      ["pool", parseInt(souls) * 0.03, 0],
      ["pool", parseInt(souls) * 0.02, 0],
      ["pool", parseInt(souls) * 0.01, 0],
      ["pool", parseInt(souls) * 0.01, 0],
      ["pool", parseInt(souls) * 0.01, 0],
      ["pool", parseInt(souls) * 0.01, 0],
      ["pool", parseInt(souls) * 0.01, 0],
      ["pool", parseInt(souls) * 0.008, 0],
      ["pool", parseInt(souls) * 0.008, 0],
      ["pool", parseInt(souls) * 0.008, 0],
      ["pool", parseInt(souls) * 0.008, 0],
      ["pool", parseInt(souls) * 0.008, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.005, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.004, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
      ["pool", parseInt(souls) * 0.002, 0],
    ];

    for (let i = 0; i < ordenado.length; i++) {
      try {
        arrayPremio[i][0] = ordenado[i].username;
        arrayPremio[i][2] = ordenado[i].raidPower;
      } catch (error) { }
    }

    //agrupar repeditos por nombre
    let premioAgrupado = arrayPremio.reduce((acc, obj) => {
      let key = obj[0];
      if (!acc[key]) {
        acc[key] = obj;
      } else {
        acc[key][1] += obj[1];
      }
      return acc;
    }, {});

    let d = [];
    for (const key in premioAgrupado) {
      d.push({
        username: key,
        raidPower: premioAgrupado[key][2],
        souls: premioAgrupado[key][1].toFixed(8),
      });
    }

    d = d.sort((a, b) => {
      return b.raidPower - a.raidPower;
    });

    this.setState({
      premio: d,
    });
    console.log(d);
  }
}

export default InfoModalMap;
