import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import HivePay from "../utils/HivePay";
import Swal from "sweetalert2";
import Leaderboard from "./modals/leaderboard";
import Dropdown from "react-bootstrap/Dropdown";
import { PrimeIcons } from "primereact/api";

import * as THREE from "three";

let renderer, scene, camera;

class Te extends Component {
  constructor(props) {
    super(props);
    this.state = {
      points: [],
    };
    this.animate = this.animate.bind(this);
    this.init = this.init.bind(this);
  }

  init() {
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x2a3e00);
    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    renderer = new THREE.WebGLRenderer();

    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    const geometry = new THREE.BufferGeometry();

    // geometry.setFromPoints(this.state.points);
    //geometry.faces.push(new THREE.Face3(0, 1, 2), new THREE.Face3(0, 2, 3), ...);
    // Crea las demás caras del polígono y agrégalas a la geometría
    /*const material = new THREE.MeshBasicMaterial({
      color: 0xf2f4ef,
      side: THREE.DoubleSide,
    });*/
    //let points = this.state.points;
    //points.push(new THREE.Vector3(-5, 0, 0));
    //points.push(new THREE.Vector3(5, 0, 0));
    geometry.setFromPoints(this.state.points);
    let line = new THREE.Line(
      geometry,
      new THREE.LineBasicMaterial({ color: 0x888888 })
    );
    scene.add(line);
    scene.add(line);
    // const polygon = new THREE.Mesh(geometry, material);
    //scene.add(polygon);

    // Mueve la cámara hacia atrás para que se pueda ver el polígono
    camera.position.z = 5;

    let model;

    return renderer.domElement;
  }

  animate() {
    requestAnimationFrame(this.animate);
    renderer.render(scene, camera);
  }

  render() {
    return <div id="eletry"></div>;
  }

  componentDidMount() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getGameMode\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "",
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.data.getGameMode == "1") {
          this.setState(
            { pve: true, statusLoading: "Getting PVE data...", now: 50 },
            () => {
              this.pve();
            }
          );
        }
      });
  }
  pve() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  userisLogin\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    }).then((response) => {
      response.json().then((data) => {
        //console.log("user is login", data);
        if (data.data.userisLogin) {
        } else {
          window.location.href = "/login";
        }
      });
    });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getUserData{\\n    _id\\n    username\\n    guild\\n    path\\n    lvl\\n    xp\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data.getUserData.username == localStorage.getItem("username")
        ) {
          localStorage.removeItem("userData");
          localStorage.setItem(
            "userData",
            JSON.stringify(data.data.getUserData)
          );
        }
      });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {  \\n  parcelasNuevo {\\n    _id\\n    ZONA\\n    COUNTRY\\n    CAPITAL\\n    CITY\\n    TOWN\\n    SIDE\\n  strokeColor\\n  fillColor\\n XP\\n userHasnft} \\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log("server get", response);

        let mapAreas = response.data.parcelasNuevo[0].ZONA.map(
          (e) => new THREE.Vector3(parseInt(e), 1, 0)
        );
        console.log(mapAreas);
        this.setState({ points: mapAreas });
        let i = this.init();
        document.getElementById("eletry").appendChild(i);
        this.animate();
      });
  }
}

export default Te;
