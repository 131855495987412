import React, { Component } from "react";
//import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import HivePay from "../../utils/HivePay";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import "./guilds.css";
import { Skeleton } from "primereact/skeleton";
class GuildManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleLeft: false,
      loading: true,
      guild: "",
    };
  }

  render() {
    return this.state.loading ? (
      <div className="field col-12 md:col-6">
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton height="10rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    ) : (
      <div className="field col-12 md:col-6">
        <DataTable
          header="Users on guild"
          responsiveLayout="scroll"
          value={this.state.data.usuarios}
        >
          <Column field="username" header="Username" />
          <Column field="path" header="Path" />
          <Column field="lvl" header="Lvl" />
          <Column field="xp" header="Xp" />
          <Column body={this.actionsUsers} header="Actions" />
        </DataTable>

        <DataTable
          header="users who want to join the guild"
          responsiveLayout="scroll"
          value={this.state.data.solicitudesPendientes}
        >
          <Column field="username" header="Username" />
          <Column field="path" header="Path" />
          <Column field="lvl" header="Lvl" />
          <Column field="xp" header="Xp" />
          <Column body={this.actions} header="Actions" />
        </DataTable>
      </div>
    );
  }
  actionsUsers(data) {
    return (
      <Button
        label="Kick"
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          Swal.showLoading();
          fetch("https://backend.infernalcoliseum.fun/api", {
            body: `{"query":"mutation{\\n  expulsarUsuarioGuild(username:\\"${data.username}\\"){\\n    success\\n    message\\n  }\\n}"}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
              Dnt: "1",
            },
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.data.expulsarUsuarioGuild.success) {
                Swal.fire({
                  title: "Success",
                  text: "User kicked",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
              } else {
                Swal.fire({
                  title: "Error",
                  text: res.data.expulsarUsuarioGuild.message,
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            });
        }}
      ></Button>
    );
  }

  actions(data) {
    return (
      <div>
        <Button
          label="Accept"
          icon="pi pi-check"
          className="p-button-success p-mr-2"
          onClick={() => {
            Swal.showLoading();
            fetch("https://backend.infernalcoliseum.fun/api", {
              body: `{"query":"mutation{\\n  aceptarSolicitudUnionGuild(username:\\"${data.username}\\"){\\n    success\\n    message\\n  }\\n}"}`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Dnt: "1",

                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              method: "POST",
            })
              .then((e) => e.json())
              .then((response) => {
                if (response.data.aceptarSolicitudUnionGuild.success) {
                  Swal.fire({
                    title: "Success",
                    text: response.data.aceptarSolicitudUnionGuild.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                  }).then(() => {
                    window.location.reload();
                  });
                } else {
                  Swal.fire({
                    title: "Error",
                    text: response.data.aceptarSolicitudUnionGuild.message,
                    icon: "error",
                    confirmButtonText: "Ok",
                  }).then(() => {
                    window.location.reload();
                  });
                }
              });
          }}
        />
        <Button
          label="Reject"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            Swal.showLoading();
            fetch("https://backend.infernalcoliseum.fun/api", {
              body: `{"query":"mutation{\\n  rechazarSolicitudUnionGuild(username:\\"${data.username}\\"){\\n    success\\n    message\\n  }\\n}"}`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Dnt: "1",

                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              method: "POST",
            })
              .then((e) => e.json())
              .then((response) => {
                if (response.data.rechazarSolicitudUnionGuild.success) {
                  Swal.fire({
                    title: "Success",
                    text: response.data.rechazarSolicitudUnionGuild.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                } else {
                  Swal.fire({
                    title: "Error",
                    text: response.data.rechazarSolicitudUnionGuild.message,
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                }
              });
          }}
        />
      </div>
    );
  }

  componentDidMount() {
    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query \\n{\\n  getGuildInfo{\\n    success\\n    message\\n    data{\\n      usuarios {\\n        username\\n        path\\n        lvl\\n        xp\\n        guild\\n      }\\n      solicitudesPendientes {\\n        username\\n        path\\n        lvl\\n        xp\\n        guild\\n      }\\n    }\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Dnt: "1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
    })
      .then((e) => e.json())
      .then((res) => {
        console.log("respuesta", res);
        if (res.data.getGuildInfo.success) {
          this.setState({ data: res.data.getGuildInfo.data, loading: false });
        } else {
          Swal.fire({
            title: "Error",
            text: res.data.getGuildInfo.message,
            icon: "error",
            confirmButtonText: "Ok",
          });

          this.setState({ loading: false });
        }
      });
  }
}

export default GuildManager;
