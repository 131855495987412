import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import HivePay from "../../utils/HivePay";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";

class GuildFarming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createM: "",
      nfts: [],
      essenceFisher: 0,
      essenceFarmer: 0,
      essenceMiner: 0,
    };
  }
  render() {
    let data = JSON.parse(localStorage.getItem("userData"));
    const maxAmount = Math.min(
      this.state.essenceFisher,
      this.state.essenceFarmer,
      this.state.essenceMiner
    );
    const generateOptions = () => {
      const options = [];
      // Agregar opciones de 1, 5, 20, 50 y 100
      const amounts = [1, 5, 20, 50, 100];
      amounts.forEach((amount) => {
        if (amount <= maxAmount) {
          options.push({ value: amount, label: amount.toString() });
        }
      });

      return options;
    };
    return (
      <Sidebar
        fullScreen
        visible={this.props.visible}
        onHide={() => this.props.close()}
      >
        <div className="container flex justify-content-center">
          <div className="container text-center">
            <h1>Farming board</h1>

            <div className="d-flex mb-3">
              <div className="me-auto p-2">Essences</div>
              <div className="p-2">Fisher: {this.state.essenceFisher}</div>
              <div className="p-2">Farmer: {this.state.essenceFarmer}</div>
              <div className="p-2">Miner: {this.state.essenceMiner}</div>
            </div>

            <div className="row align-items-end">
              <div className="col">
                {" "}
                <Dropdown
                  options={generateOptions()} // Generar las opciones del combobox
                  value={this.state.createM} // Valor seleccionado
                  optionLabel="label"
                  onChange={(e) => this.setState({ createM: e.value })} // Manejar el cambio de cantidad seleccionada
                />
              </div>
              <div className="col">
                <Button
                  onClick={(e) => {
                    this.createNft(this.state.createM);
                  }}
                  disabled={this.state.createM <= 0}
                >
                  Create Miner
                </Button>
              </div>
            </div>

            <div className="table-responsive pt-2 px-2">
              <DataTable
                value={this.state.nfts}
                dataKey="id"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="name" header="NAME"></Column>
                <Column field="lvl" header="LVL"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </Sidebar>
    );
  }

  componentDidMount() {
    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  getUserData{\\n   essenceMiner\\n    essenceFarmer\\n    essenceFisher\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        Swal.close();
        if (data?.data?.getUserData) {
          this.setState({
            essenceFarmer: data?.data?.getUserData.essenceFarmer,
            essenceMiner: data?.data?.getUserData.essenceMiner,
            essenceFisher: data?.data?.getUserData.essenceFisher,
          });
        }
      });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: '{"query":"query {\\n  allNftIXP{\\n    name\\n    username\\n    lvl\\n  }\\n}"}',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        Swal.close();
        if (data?.data?.allNftIXP) {
          this.setState({
            nfts: data?.data?.allNftIXP,
          });
        }
      });
  }

  createNft(number) {
    Swal.fire({
      title: "Getting info...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch("https://backend.infernalcoliseum.fun/api", {
      body: `{"query":"mutation {\\n  createNft(input:${number}){\\n    success\\n    message\\n  }\\n}"}`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        Dnt: "1",
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (response) => {
        if (response.data.createNft?.success) {
          await Swal.fire({
            icon: "info",
            title: response.data.createNft.message,
            showConfirmButton: true,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: response.data.createNft.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }
}

export default GuildFarming;
