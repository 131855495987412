import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { PickList } from "primereact/picklist";
import { Button } from "primereact/button";
class LandSelectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attackersSource: this.props.allAttackers,
      defenderSource: this.props.allDefenders,
      attackers: [], // Cartas atacantes seleccionadas
      defenders: [], // Cartas defensoras seleccionadas
    };
  }

  // Función para actualizar el estado cuando se seleccionan cartas atacantes
  onAttackerChange = (event) => {
    this.setState({ attackers: event.target, attackersSource: event.source });
  };

  // Función para actualizar el estado cuando se seleccionan cartas defensoras
  onDefenderChange = (event) => {
    this.setState({ defenders: event.target, defenderSource: event.source });
  };

  // Función para cerrar el modal y guardar las selecciones
  saveSelections = () => {
    const { attackers, defenders } = this.state;

    // Verificar si hay elementos con el mismo ID en ambas listas
    const duplicateId = attackers.some((attacker) =>
      defenders.some((defender) => attacker._id === defender._id)
    );

    if (duplicateId) {
      // Mostrar mensaje de error
      const duplicatedCard = attackers.find((attacker) =>
        defenders.some((defender) => attacker._id === defender._id)
      );
      alert(
        `No puedes seleccionar la misma carta (${duplicatedCard._id}) como atacante y defensora.`
      );
    } else {
      // Enviar las selecciones al servidor o realizar las operaciones necesarias aquí

      if(attackers.length < 3 || defenders.length < 3) {
          alert("minimum 3 attackers or defenders required")
          return;
      }
      this.props.onSave(attackers, defenders);
      this.props.onHide();
    }
  };

  render() {
    return (
      <Dialog
        header="Select your cards"
        visible={this.props.visible}
        onHide={this.props.onHide}
      >
        <div className="p-grid">
          <div className="p-col-6">
            <h3>Attackers</h3>
            <PickList
              source={this.state.attackersSource} // Todas las cartas atacantes disponibles
              target={this.state.attackers} // Cartas atacantes seleccionadas
              itemTemplate={(item) => (
                <div>
                  <div>
                    {item.properties.p1} <strong>{item._id}</strong>
                  </div>
                  <div>
                    HEALTH: {item.properties.p3}, ATTACK: {item.properties.p4},
                    DEFENSE: {item.properties.p5}
                  </div>
                  <div>
                    CRITICAL: {item.properties.p6}, AFFINITY:{" "}
                    {item.properties.p7}
                  </div>
                </div>
              )} // Propiedad para mostrar en la lista
              sourceHeader="All attacking cards"
              targetHeader="Selected attacking cards"
              onChange={this.onAttackerChange}
            />
          </div>
          <div className="p-col-6">
            <h3>Defenders</h3>
            <PickList
              source={this.state.defenderSource} // Todas las cartas defensoras disponibles
              target={this.state.defenders} // Cartas defensoras seleccionadas
              itemTemplate={(item) => (
                <div>
                  <div>
                    {item.properties.p1} {item._id}
                  </div>
                  <div>
                    HEALTH: {item.properties.p3}, ATTACK: {item.properties.p4},
                    DEFENSE: {item.properties.p5}
                  </div>
                  <div>
                    CRITICAL: {item.properties.p6}, AFFINITY:{" "}
                    {item.properties.p7}
                  </div>
                </div>
              )} // Propiedad para mostrar en la lista
              sourceHeader="All defense cards"
              targetHeader="Selected Defender Cards"
              onChange={this.onDefenderChange}
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <Button onClick={this.saveSelections}>Save Selections</Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default LandSelectionModal;
