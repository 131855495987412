import React, { Component } from "react";
import "./xp.css";
import Swal from "sweetalert2";
import utils from "../../utils";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";

import { Fieldset } from 'primereact/fieldset';


class XP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }
  render() {
    let { visibleLeft } = this.state;
    return (
      <>
        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />

        <Menubar
          model={[]}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    this.setState({
                      visibleLeft: true,
                    });
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />

        <Fieldset legend="Info">
          <p className="m-0">
            🚨 Attention Miners! 🚨

            The IXP Pool event concludes today. Starting now, to earn XP, it's crucial to place XP avatars in the regions. This is your key to earning XPs from the mines.

            Get ready to strategize and deploy your avatars strategically to maximize your XP gains. The competition is heating up, and the XP awaits in the regions.

            Mine wisely, and may your avatars bring you abundant XP! 💎⛏️
          </p>
        </Fieldset>


        <div className="bvdiv">
          <div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
            <header className="masthead mb-auto">
              <div className="inner">
                <nav className="nav nav-masthead justify-content-center">
                  <div className="cover-heading">
                    <h1
                      onClick={() => {

                        /*
                        Swal.fire({
                          title: "Souls to burn",
                          input: "number",
                          inputAttributes: {
                            autocapitalize: "off",
                          },
                          showCancelButton: true,
                          confirmButtonText: "burn",
                          showLoaderOnConfirm: true,
                          preConfirm: (login) => {
                            return new Promise((resolve, reyect) => {
                              window.hive_keychain.requestSendToken(
                                localStorage.getItem("username"),
                                "if-xp-pool",
                                parseFloat("" + login).toFixed(3),
                                "pool xp",
                                "SOULS",
                                (resp) => {
                                  resolve(resp);
                                },
                                null
                              );
                            });
                          },
                          allowOutsideClick: () => false,
                        }).then((result) => {
                          if (result?.value?.message) {
                            Swal.fire({
                              title: `${result.value.message}`,
                            });
                          }
                        });

                        */
                      }}
                    >
                      IXP
                    </h1>
                    <h1>IXP</h1>
                  </div>
                </nav>
              </div>
            </header>

            <main role="main" className="inner cover">
              <p
                className="lead"
                style={{
                  overflowY: "auto",
                  height: "308px",
                  font: "-webkit-small-control",
                }}
              >
                <table className="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Username</th>
                      <th scope="col">Souls</th>
                      <th scope="col">IXP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((e) => {
                      return (
                        <tr>
                          <th scope="row">{e.user}</th>
                          <td>{e.depositedBuds}</td>
                          <td>{e.mota}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </p>
              <p className="lead"></p>
            </main>

            <footer className="mastfoot mt-auto">
              <div className="inner">

              </div>
            </footer>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const body = document.querySelector("body");

    //console.log(body);

    body.classList.add("landsale");
    Swal.fire({
      title: "Getting data from the blockchain...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    utils.getAllSoulssend().then((e) => {
      // console.log(e);
      this.setState({
        ...this.state,
        data: e,
      });
      Swal.close();
    });
  }
}

export default XP;
