import React, { Component } from "react";
import "./landSale.css";
import Slider from "react-slick";
import socketIOClient from "socket.io-client";
import Auction from "./Auction";
import "./slick.css";
import "./landSale.css";
import "./slick-theme.css";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";
const ENDPOINT = "https://farminglandsinfernalbackend.herokuapp.com/";
class LandSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auctions: [],
      currentIndex: 0,
      activeAuction: {},
      visibleLeft: false,
    };
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  render() {
    let { visibleLeft } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="card-slider">
        <Menubar
          model={[]}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />

        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />

        <div className="row" style={{ padding: "10vh" }}>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <Slider {...settings}>
              {this.state.auctions.map((auction) => {
                return (
                  <div>
                    <h1
                      style={{
                        position: "relative",
                        textAlign: "center",
                        padding: "2%",
                        color: "white",
                      }}
                    >
                      {auction.land.COUNTRY}
                    </h1>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="col-md-4"></div>
        </div>
        {this.state.activeAuction?.deadline && (<Auction
          username={this.state.activeAuction?.username}
          description={this.state.activeAuction?.land?.COUNTRY}
          currentPrice={this.state.activeAuction?.currentPrice}
          deadline={this.state.activeAuction?.deadline}
        />)
        }
      </div>
    );
  }

  componentDidMount() {
    /*
    const socket = socketIOClient(ENDPOINT);
    socket.on("auctions", (mensaje) => {
      let activeAuction = JSON.parse(mensaje).find(
        (auction) => auction.status === "active"
      );

      console.log(activeAuction);
      this.setState({
        activeAuction: activeAuction,
        auctions: JSON.parse(mensaje),
      });
    });*/
  }

  componentWillUnmount() { }
}

export default LandSale;
