export default class WS {
  ws;

  constructor(ws = "ws://localhost:4000") {
    this.ws = ws;
  }

  connect() {
    this.ws = new WebSocket(this.ws);
    this.ws.onclose = () => {
      console.log("Conexión cerrada con el servidor de WebSockets");
    };

    this.ws.onerror = (error) => {
      console.error(
        "Error durante la conexión con el servidor de WebSockets:",
        error
      );
    };
  }

  isConnected() {
    return this.ws.readyState;
  }

  initMethods(onMessage) {
    this.ws.onopen = () => {
      console.log("connected");
      this.ws.pingInterval = 5000;
    };

    this.ws.onmessage = onMessage;
  }

  logIn() {
    this.sendMessage(
      JSON.stringify({
        action: "setName",
        token: "bearer " + localStorage.getItem("token"),
      })
    );
  }

  sendMessage = (message) => {
    console.log("mensaje", message, this.ws);
    if (this.ws) {
      this.ws.send(message);
    }
  };
}
