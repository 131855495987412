import React, { useEffect, useRef } from 'react';

const DungeonMap = ({ bsp }) => {
          // Crea una referencia al elemento canvas
          console.log("datos", bsp);
          const canvasRef = useRef(null);

          // Usa el hook useEffect para dibujar la mazmorra en el canvas cuando cambie el parámetro
          useEffect(() => {
                    // Obtiene el elemento canvas y su contexto 2D a partir de la referencia
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext("2d");

                    // Limpia el canvas de cualquier dibujo previo
                    ctx.clearRect(0, 0, canvas.width, canvas.height);


                    ctx.strokeStyle = '0x888888';

                    for (var i = 0; i < bsp.connections.length; i++) {
                              var item = bsp.connections[i];

                              ctx.strokeRect(item.left.bounds.x, item.left.bounds.y,
                                        item.left.bounds.width, item.left.bounds.height);
                              ctx.strokeRect(item.right.bounds.x, item.right.bounds.y,
                                        item.right.bounds.width, item.right.bounds.height);
                    }

                    for (var y = 0; y < bsp.height; y++) {
                              for (var x = 0; x < bsp.width; x++) {
                                        var id = bsp.map[y * bsp.width + x];

                                        if (id == 0 || id === undefined)
                                                  continue;

                                        if (id == bsp.mapDef.WALL)
                                                  ctx.fillStyle = 'cyan';
                                        else if (id == bsp.mapDef.FLOOR)
                                                  ctx.fillStyle = 'lightgray';

                                        ctx.fillRect(x, y, 1, 1);
                              }
                    }

                    ctx.strokeStyle = 'red';
                    ctx.beginPath();
                    // Modify the existing loop to draw lines and add points
                    for (var i = 0; i < bsp.connections.length; i++) {
                              var item = bsp.connections[i];
                              console.log("validando item", item);
                              // Draw the start point (green sphere)






                              if (item.isInitial) {
                                        ctx.beginPath();
                                        ctx.arc(item.left.size.x + (item.left.size.width * 0.5), item.left.size.y + (item.left.size.height * 0.5), 5, 0, Math.PI * 2);

                                        ctx.fillStyle = 'blue';
                                        ctx.fill();
                              } else if (item.isFinal) {
                                        ctx.beginPath();
                                        ctx.arc(item.left.size.x + (item.left.size.width * 0.5), item.left.size.y + (item.left.size.height * 0.5), 5, 0, Math.PI * 2);

                                        ctx.fillStyle = 'black';
                                        ctx.fill();
                              } else {
                                        ctx.beginPath();
                                        //ctx.arc(item.left.size.x + (item.left.size.width * 0.5), item.left.size.y + (item.left.size.height * 0.5), 5, 0, Math.PI * 2);

                                        ctx.fillStyle = 'green';
                                        ctx.fill();
                              }


                              // Draw the line
                              ctx.beginPath();
                              ctx.moveTo(item.left.size.x + (item.left.size.width * 0.5), item.left.size.y + (item.left.size.height * 0.5));
                              ctx.lineTo(item.right.size.x + (item.right.size.width * 0.5), item.right.size.y + (item.right.size.height * 0.5));
                              ctx.stroke();

                              // Draw the end point (red sphere)

                              if (item.isInitial) {
                                        ctx.beginPath();
                                        ctx.arc(item.right.size.x + (item.right.size.width * 0.5), item.right.size.y + (item.right.size.height * 0.5), 5, 0, Math.PI * 2);
                                        ctx.fillStyle = 'blue';
                                        ctx.fill();
                              } else if (item.isFinal) {
                                        ctx.beginPath();
                                        ctx.arc(item.right.size.x + (item.right.size.width * 0.5), item.right.size.y + (item.right.size.height * 0.5), 5, 0, Math.PI * 2);
                                        ctx.fillStyle = 'black';
                                        ctx.fill();
                              } else {
                                        ctx.beginPath();
                                        //ctx.arc(item.right.size.x + (item.right.size.width * 0.5), item.right.size.y + (item.right.size.height * 0.5), 5, 0, Math.PI * 2);
                                        ctx.fillStyle = 'green';
                                        ctx.fill();
                              }



                    }
                    ctx.stroke();


          }, [bsp]);

          return <canvas ref={canvasRef} width={400} height={400} />;
};


export default DungeonMap;