import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { HttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = new HttpLink({
          uri: 'https://backend.infernalcoliseum.fun/',
});

const wsLink = new WebSocketLink({
          uri: 'wss://backend.infernalcoliseum.fun/subscriptions',
          options: {
                    reconnect: true,
          },
});

const splitLink = split(
          ({ query }) => {
                    const definition = getMainDefinition(query);
                    return (
                              definition.kind === 'OperationDefinition' &&
                              definition.operation === 'subscription'
                    );
          },
          wsLink,
          httpLink
);

const client = new ApolloClient({
          link: splitLink,
          cache: new InMemoryCache(),
});

export default client;