import React, { Component, useState } from "react";
import TagManager from "react-gtm-module/dist/TagManager";

import { DownbarItems } from "../configs/downbarItems";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import SSC from "sscjs";
const ssc = new SSC("https://herpc.dtools.dev");

const SwapIconImage =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/2be28177-500d-44fd-867a-57ba3051f400/public";

const OpenNav =
  "https://imagedelivery.net/u_WNggw44n9gZPCktlVv5A/02c7d715-bf40-4783-5ae2-f7bc313a1200/public";

class DownBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showSectionModal: false,
      selectedSection: null,
      display: "none",
      showSwapModal: false,
      amountToSwap: 0,
      amountFromSwap: 0,
      buyBuds: true,
      pairData: null,
      slippage: 0.5,
      swapHiveBalance: 0,
      budsBalance: 0,
      tokens: "BUDS",
    };

    this.showOrHideModal = this.showOrHideModal.bind(this);
    this.changeToken = this.changeToken.bind(this);
    this.getPairInfo = this.getPairInfo.bind(this);
  }

  getBalance() {
    this.getUserBalance(this.state.tokens, this.state.budsBalance);
    this.getUserBalance("SWAP.HIVE", this.state.swapHiveBalance);
    this.getPairInfo();

    setInterval(() => {
      this.getUserBalance(this.state.tokens, this.state.budsBalance);
      this.getUserBalance("SWAP.HIVE", this.state.swapHiveBalance);
      this.getPairInfo();
    }, 20000);
  }

  changeToken(token) {
    this.setState(
      {
        ...this.state,
        tokens: token,
        showSwapModal: true,
        amountFromSwap: 0,
        amountToSwap: 0,
      },
      () => {
        this.getBalance();
      }
    );
  }

  componentDidMount() {}

  checkBalance(value = this.state.amountFromSwap) {
    const swapButton = document.getElementById("swap-button");

    if (this.state.buyBuds) {
      if (this.state.swapHiveBalance < value) {
        swapButton.disabled = true;
      } else {
        swapButton.disabled = false;
      }
    } else {
      if (this.state.budsBalance < value) {
        swapButton.disabled = true;
      } else {
        swapButton.disabled = false;
      }
    }
  }

  getUserBalance(symbol, balance) {
    return new Promise(async (resolve) => {
      let result = await ssc.find(
        "tokens",
        "balances",
        {
          account: localStorage.getItem("username"),
          symbol: symbol,
        },
        1000,
        0,
        [],
        (err) => {
          if (err) {
            return [];
          }
        }
      );

      if (!result) {
        result = [];
      }

      if (balance === this.state.budsBalance)
        this.setState({ budsBalance: result[0].balance });
      else this.setState({ swapHiveBalance: result[0].balance });

      resolve();
    });
  }

  getPairInfo() {
    const url = "https://herpc.dtools.dev/contracts";

    // Headers
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
    };
    // Request POST body data
    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: "marketpools",
          table: "pools",
          query: {
            tokenPair: this.state.tokens + ":SWAP.HIVE",
          },
        },
        id: 1,
      },
    ]);

    axios.post(url, body, config).then((data) => {
      const LPData = data.data[0].result[0];
      this.setState({ pairData: LPData });
    });
  }

  calculatePriceImpact() {
    if (this.state.pairData) {
      let newPrice;
      let priceImpact;

      if (this.state.buyBuds) {
        newPrice =
          (this.state.amountFromSwap * 0.9975) / this.state.amountToSwap;
        priceImpact = (
          (newPrice * 100) / this.state.pairData.basePrice -
          100
        ).toFixed(2);
      } else {
        newPrice =
          this.state.amountToSwap / (this.state.amountFromSwap * 0.9975);
        priceImpact = (
          (this.state.pairData.basePrice * 100) / newPrice -
          100
        ).toFixed(2);
      }

      return priceImpact;
    }
  }

  calculateSwapOutput(value) {
    const constantProduct =
      parseFloat(this.state.pairData.quoteQuantity) *
      parseFloat(this.state.pairData.baseQuantity);
    let amountOut;

    if (this.state.buyBuds) {
      const newQuote = parseFloat(this.state.pairData.quoteQuantity) + value;
      amountOut =
        parseFloat(this.state.pairData.baseQuantity) -
        constantProduct / newQuote;
    } else {
      const newBase = parseFloat(this.state.pairData.baseQuantity) + value;
      amountOut =
        parseFloat(this.state.pairData.quoteQuantity) -
        constantProduct / newBase;
    }

    return (amountOut * 0.9975).toFixed(5);
  }

  calculateSwapInput(value) {
    let num;
    let den;
    let amountIn;

    if (this.state.buyBuds) {
      num = this.state.pairData.quoteQuantity * value;
      den = parseFloat(this.state.pairData.baseQuantity) - value;
      amountIn = num / den;
    } else {
      num = this.state.pairData.baseQuantity * value;
      den = parseFloat(this.state.pairData.quoteQuantity) - value;
      amountIn = num / den;
    }

    return (amountIn * 0.9975).toFixed(5);
  }

  render() {
    return (
      <>
        <img
          className="robots-highlight-on-hover downbar-selectButton"
          style={
            this.state.showModal ? { transform: "rotate( -180deg )" } : null
          }
          src={OpenNav}
          alt=""
          onClick={this.showOrHideModal}
        />
        <nav className="downbar" style={{ display: this.state.display }}>
          <div>
            {this.state.showModal ? (
              <>
                {this.renderSection(this.state.selectedSection)}
                <div className="downbarDiv">{this.renderDownbar()}</div>
              </>
            ) : null}
          </div>
        </nav>

        <Modal
          className="downbar-swap-modal"
          show={this.state.showSwapModal}
          onHide={() => {
            this.setState({ showSwapModal: false });
            this.showOrHideModal();
          }}
          onShow={this.showOrHideModal}
          size="lg"
        >
          <h3 style={{ color: "red" }}>
            Swap is in beta, please use it carefully by u own risk
          </h3>
          <h4>Insert Amount</h4>
          <div className="swapInput-box">
            <label htmlFor="" className="infoSwapLabel">
              {this.state.buyBuds
                ? "From SWAP.HIVE"
                : "From " + this.state.tokens}
            </label>
            <label htmlFor="" className="infoUserBalance">
              Your Balance:{" "}
              {this.state.buyBuds
                ? this.state.swapHiveBalance
                : this.state.budsBalance}
            </label>
            <input
              id="txInput"
              onChange={(value) => {
                const output = this.calculateSwapOutput(
                  parseFloat(value.target.value)
                );

                this.setState({
                  amountFromSwap: parseFloat(value.target.value),
                  amountToSwap: output,
                });

                document.getElementById("txOutput").value = output;
                this.checkBalance(parseFloat(value.target.value));
              }}
              type="number"
            />
            <Button
              onClick={() => {
                const maxAmount = this.state.buyBuds
                  ? this.state.swapHiveBalance
                  : this.state.budsBalance;
                document.getElementById("txInput").value = maxAmount;

                const output = this.calculateSwapOutput(parseFloat(maxAmount));

                this.setState({
                  amountFromSwap: parseFloat(maxAmount),
                  amountToSwap: output,
                });

                document.getElementById("txOutput").value = output;
                this.checkBalance(parseFloat(maxAmount));
              }}
            >
              Max
            </Button>
          </div>
          <Button
            className="change-button"
            onClick={() => {
              this.setState(
                {
                  buyBuds: this.state.buyBuds ? false : true,
                },
                () => {
                  const newInput = this.state.amountToSwap;
                  const newOutput = this.calculateSwapOutput(
                    parseFloat(newInput)
                  );

                  this.setState(
                    {
                      amountFromSwap: newInput,
                      amountToSwap: newOutput,
                    },
                    () =>
                      this.checkBalance(parseFloat(this.state.amountFromSwap))
                  );
                  document.getElementById("txInput").value = newInput;
                  document.getElementById("txOutput").value = newOutput;
                }
              );
            }}
          >
            <img style={{ width: "100%" }} src={SwapIconImage} alt="" />
          </Button>
          <div className="swapInput-box">
            <label htmlFor="">
              {this.state.buyBuds
                ? "To Approximated " + this.state.tokens
                : "To Approximated SWAP.HIVE"}
            </label>
            <label htmlFor="" className="infoUserBalance">
              Your Balance:{" "}
              {this.state.buyBuds
                ? this.state.budsBalance
                : this.state.swapHiveBalance}
            </label>
            <input
              id="txOutput"
              min="0"
              onChange={(value) => {
                const input = this.calculateSwapInput(
                  parseFloat(value.target.value)
                );

                this.setState({
                  amountToSwap: parseFloat(value.target.value),
                  amountFromSwap: input,
                });
                document.getElementById("txInput").value = input;
                this.checkBalance(input);
              }}
              type="number"
            />
            <Button
              onClick={() => {
                const maxAmount = this.state.buyBuds
                  ? this.state.budsBalance
                  : this.state.swapHiveBalance;
                document.getElementById("txOutput").value = maxAmount;

                const input = this.calculateSwapInput(parseFloat(maxAmount));

                this.setState({
                  amountToSwap: parseFloat(maxAmount),
                  amountFromSwap: input,
                });
                document.getElementById("txInput").value = input;
                this.checkBalance(parseFloat(maxAmount));
              }}
            >
              Max
            </Button>
          </div>
          <Button
            id="swap-button"
            onClick={() => {
              let body = [
                {
                  contractName: "marketpools",
                  contractAction: "swapTokens",
                  contractPayload: {
                    tokenPair: "BUDS:SWAP.HIVE",
                    tokenSymbol: this.state.buyBuds ? "SWAP.HIVE" : "BUDS",
                    tokenAmount: this.state.amountFromSwap + "",
                    tradeType: "exactInput",
                    minAmountOut:
                      this.state.amountToSwap *
                        ((100 - this.state.slippage) / 100) +
                      "",
                  },
                },
              ];

              window.hive_keychain.requestCustomJson(
                localStorage.getItem("username"),
                "ssc-mainnet-hive",
                "Active",
                `${JSON.stringify(body)}`,
                "Swap",
                (res) => {
                  // if (res.success) alert("Swap Succesful");
                  // else alert("Swap Failed");
                }
              );
            }}
          >
            Swap
          </Button>
          <p
            style={{
              display:
                this.state.pairData !== null
                  ? this.state.buyBuds
                    ? this.state.amountFromSwap >
                      parseFloat(this.state.pairData.quoteQuantity)
                      ? "block"
                      : "none"
                    : this.state.amountFromSwap >
                      parseFloat(this.state.pairData.baseQuantity)
                    ? "block"
                    : "none"
                  : "none",
            }}
          >
            Insufficent liquidity for this trade.
          </p>
          <p
            className={
              this.calculatePriceImpact() > 15 ? "highPriceImpact" : ""
            }
          >
            Price Impact:{" "}
            {this.calculatePriceImpact() < 0.01
              ? "<0.01"
              : this.calculatePriceImpact()}{" "}
            %
          </p>
          <label>Slippage in %</label>
          <input
            type="number"
            placeholder="0.5"
            onChange={(value) =>
              this.setState({ slippage: value.target.value })
            }
          />
          <p>
            Minimun received:{" "}
            {this.state.amountToSwap * ((100 - this.state.slippage) / 100)}
          </p>
        </Modal>
      </>
    );
  }

  renderDownbar() {
    return (
      <div className="assets-scroll scrollable">
        {DownbarItems.map((item, index) => {
          if (item.path === undefined) {
            return (
              <div className="downbarItem">
                <img
                  className="robots-highlight-on-hover"
                  style={{ width: "100px" }}
                  key={index}
                  src={item.img}
                  alt=""
                  onClick={(e) => {
                    if (
                      this.state.showSectionModal &&
                      item.items === this.state.selectedSection
                    )
                      this.setState({
                        showSectionModal: false,
                        selectedSection: null,
                      });
                    else
                      this.setState({
                        showSectionModal: true,
                        selectedSection: item.items,
                      });
                  }}
                />
                <p className="font-weight-bold">{item.tittle}</p>
              </div>
            );
          } else
            return (
              <div className="downbarItem" id={item.tittle}>
                <img
                  className="robots-highlight-on-hover"
                  style={{ width: "auto", height: "100px" }}
                  key={index}
                  src={item.img}
                  alt=""
                  onClick={(e) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "pageView",
                        path: item.tittle,
                      },
                    });
                    window.open(item.path, "_blank");
                  }}
                />
                <p className="font-weight-bold">{item.tittle}</p>
              </div>
            );
        })}
      </div>
    );
  }

  renderSection(section) {
    if (section == null) return;

    return (
      <div
        className="downbarDiv"
        style={{ width: "80%", display: "inline-block" }}
      >
        <div className="assets-scroll scrollable">
          {section.map((item, index) => {
            return (
              <div className="downbarItem" id={item.tittle}>
                <img
                  className="robots-highlight-on-hover"
                  style={{ width: "auto", height: "100px" }}
                  key={index}
                  src={item.img}
                  alt=""
                  onClick={(e) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "pageView",
                        path: item.tittle,
                      },
                    });
                    if (item.path === "buds") {
                      this.changeToken("BUDS");
                    } else if (item.path === "souls") {
                      this.changeToken("SOULS");
                    } else {
                      window.open(item.path, "_blank");
                    }
                  }}
                />
                <p className="font-weight-bold">{item.tittle}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  showOrHideModal() {
    if (this.state.showModal)
      this.setState({
        showModal: false,
        display: "none",
        showSectionModal: false,
        selectedSection: null,
      });
    else this.setState({ showModal: true, display: "block" });
  }
}

export default DownBar;
