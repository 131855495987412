import React, { Component } from "react";
import SidebarGame from "../sidebar";
import { Menubar } from "primereact/menubar";
import { Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Splitter, SplitterPanel } from 'primereact/splitter';
class Stake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleLeft: false,
      totalStake: "Loading...",
      yourStake: "Loading...",
      circulating: "Loading...",
      tokenValue: "Loading...",
      users: [],
    };
  }
  render() {
    let { visibleLeft } = this.state;
    return (
      <>
        <SidebarGame
          visibleLeft={visibleLeft}
          onClose={() => this.setState({ visibleLeft: false })}
        />

        <Menubar
          model={[]}
          start={() => {
            return (
              <>
                <img
                  src={this.getIcono()}
                  style={{ width: "10%", cursor: "pointer" }}
                  className="mr-2"
                  onClick={() => {
                    this.setState({
                      visibleLeft: true,
                    });
                  }}
                />
                {localStorage.getItem("username")}
              </>
            );
          }}
        />
        <br></br>
        <Splitter style={{ height: '300px' }}>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <p>Total Stake: {this.state.totalStake}</p>
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <p>Your Stake: {this.state.yourStake}</p>
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <p>Circulate: {this.state.circulating}</p>
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <p>Value to get V3: {this.state.tokenValue}</p>
          </SplitterPanel>
        </Splitter>

        <br></br>

        <DataTable value={this.state.users}>
          <Column field="username" header="Username"></Column>
          <Column field="stake" header="Stake"></Column>
          <Column field="tokens" header="Tokens V3"></Column>
        </DataTable>
      </>
    );
  }

  getIcono() {
    let angel =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/a6cb5324-4c32-40f3-83df-c1032b6f8b00/public";
    let demonio =
      "https://imagedelivery.net/WkQs5YB9-Nh8-ypKa2yDIA/7767e9c1-585b-4ab1-202a-30a2a55a3100/public";

    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      localStorage.clear();
      window.location.href = "/";
    }

    return userData.path == "KNALAND" ? demonio : angel;
  }

  async componentDidMount() {
    let myData = JSON.parse(localStorage.getItem("userData"));

    let dataSupply = await (
      await fetch("https://herpc.dtools.dev/contracts", {
        headers: {
          accept: "application/json, text/plain, */*",
          "accept-language": "es-US,es-CO;q=0.9,es-419;q=0.8,es;q=0.7",
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          "sec-ch-ua":
            '"Google Chrome";v="111", "Not(A:Brand";v="8", "Chromium";v="111"',
          "sec-ch-ua-mobile": "?1",
          "sec-ch-ua-platform": '"Android"',
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "cross-site",
        },
        referrerPolicy: "no-referrer",
        body: '{"jsonrpc":"2.0","id":2,"method":"find","params":{"contract":"tokens","table":"tokens","query":{"symbol":{"$in":["SOULS"]}},"limit":1000,"offset":0,"indexes":""}}',
        method: "POST",
        mode: "cors",
        credentials: "omit",
      })
    ).json();

    let tstake = await (
      await fetch("https://backend.infernalcoliseum.fun/api", {
        body: '{"query":"query {\\n  totalStake {\\n    username\\n    stake\\n  }\\n}"}',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Dnt: "1",
        },
        method: "POST",
      })
    ).json();

    let totalStake = tstake.data.totalStake.reduce((a, b) => {
      return a + b.stake;
    }, 0);

    let c = parseFloat(dataSupply.result[0].circulatingSupply);
    let tc = c * 0.005;

    tstake.data.totalStake = tstake.data.totalStake.map((e) => {
      let cc = e.stake / tc;
      return {
        username: e.username,
        stake: e.stake,
        tokens: parseInt("" + cc),
      };
    });

    tstake.data.totalStake = tstake.data.totalStake.sort(function (a, b) {
      return b.stake - a.stake;
    });

    tstake.data.totalStake = tstake.data.totalStake.map(e => {
      let cc = e.stake / tc;
      return {
        username: e.username,
        stake: e.stake.toFixed(3),
        tokens: parseInt("" + cc),
      };
    })

    this.setState({
      ...this.state,
      yourStake: myData.cantidad_tokens.toFixed(3),
      circulating: c.toFixed(3),
      tokenValue: tc.toFixed(3),
      totalStake: totalStake.toFixed(3),
      users: tstake.data.totalStake,
    });
  }
}
export default Stake;
